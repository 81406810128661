import { CircularProgress, styled, Typography } from "common/components";
import { useTranslation } from "i18n";

const StyledContainer = styled("div")(({ theme }) => ({
  height: "100%",
  width: "100%",
  display: "grid",
  placeItems: "center",
}));

const StyledSpinnerBox = styled("div")(({ theme }) => ({
  display: "grid",
  rowGap: theme.spacing(3),
  justifyItems: "center",
}));

export function LoadingRoute() {
  const { t } = useTranslation("common");

  return (
    <StyledContainer>
      <StyledSpinnerBox>
        <CircularProgress />
        <Typography>{t("loadingApp")}</Typography>
      </StyledSpinnerBox>
    </StyledContainer>
  );
}
