import { Theme, ThemeOptions } from "@mui/material";
import * as colors from "@mui/material/colors";
import { extendBaseTheme } from "styles/theme";

const xploraOptions: ThemeOptions = {
  palette: {
    primary: {
      main: colors.blue[400],
    },
    background: {
      paper: "#f1f5f8",
      default: "#e7f0f7",
    },
  },
  shape: {
    borderRadius: 0,
  },
  components: {
    MuiCard: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
      },
    },
  },
};

/*
  TODO configure webpack in a way that replaces this file with the correct app specific
  theme file, i.e. in the case that we are building the xplora app we replace this file
  with the xploraTheme file.
*/
export const theme: Theme = extendBaseTheme(xploraOptions);
