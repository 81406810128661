import { API, APIQueryResult, EntityType, useFetchEntityQuery } from "core/api";
import { Invoice } from "core/api/models";

/**
 * @param productId Id of product to generate preview for.
 */
export function useGetProductInvoicePreview(
  productId: number | undefined
): APIQueryResult<Invoice> {
  return useFetchEntityQuery<Invoice>({
    apiUrl: API.productservice.productInvoicePreview,
    entityType: EntityType.INVOICE,
    ids: [productId?.toString() ?? "-1"],
    requestInit: { pathParams: { productId } },
    enabled: productId !== undefined && !isNaN(productId) && productId !== -1,
  });
}
