import { API, APIMutationResult, useAPIMutation } from "core/api";

/**
 * Generate a Kindly Chat-Bot token
 * @param chatId The chat id.
 * @returns Observable for HttpResponse containing the token.
 */
export function useGenerateKindlyToken(): APIMutationResult<string, string> {
  return useAPIMutation<string, string>(
    API.auth.kindlyToken,
    (chatId: string) => ({
      method: "POST",
      credentials: "include",
      mode: "same-origin",
      pathParams: {
        chatId,
      },
    })
  );
}
