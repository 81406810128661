import { Box, BoxProps, Typography } from "common/components";
import { ReactNode } from "react";

export interface FilterGroupProps extends BoxProps {
  /** Filter group label */
  label: string | undefined;
  /** Id of filter group label */
  labelId?: string;
  /** Filter group content */
  children: ReactNode;
}

/** Filter group container for active filters */
export function FilterGroup({
  label,
  labelId,
  children,
  ...props
}: FilterGroupProps) {
  return (
    <Box {...props} padding={(theme) => theme.spacing(0.3, 0)}>
      <Typography id={labelId} variant="caption">
        {label}
      </Typography>
      <Box display="flex" flexWrap="wrap" gap={(theme) => theme.spacing(1)}>
        {children}
      </Box>
    </Box>
  );
}
