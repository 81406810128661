/**
 * The status of a payment can have the values specified by this enum.
 */
export enum PaymentStatus {
  /**
   * Start status set for all imported or registered Payments.
   */
  RECEIVED = "RECEIVED",
  /**
   * Set when a Payment has been matched completely against one or more
   * Invoices.
   */
  MATCHED = "MATCHED",

  /**
   * Set when a Payment is matched against an Invoice but where the remaining
   * Payment amount is greater than zero. Note! This is also the
   * case when the Payment can be matched to an Invoice, but that Invoice is
   * already Closed.
   */
  PARTLY_MATCHED = "PARTLY_MATCHED",

  /**
   * Set when the Payment Match job can’t match the Payment to any Invoice
   * in Billiant.
   */
  NO_MATCH = "NO_MATCH",

  /**
   * Requested, payment is requested through external payment system. Used for Direct Debit
   */
  REQUESTED = "REQUESTED",

  /**
   * Match exception, payment's configuration matches more than one invoice or customer.
   */
  MATCH_EXCEPTION = "MATCH_EXCEPTION",

  /**
   * Reserved, payment is being used by initial invoice and invoice does not exist yet. This is
   * resolved by the invoice job.
   */
  RESERVED = "RESERVED",

  /**
   * Rejected, a payment requested for Direct Debit has been rejected.
   */
  REJECTED = "REJECTED",
}
