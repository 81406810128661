import { PrinterQueueStatus } from "..";

/**
 * Model representing the Billiant PrinterQueueEntry object
 */
export interface PrinterQueueEntry {
  version: number;
  printerQueueEntryId: number;
  printerQueueId: number;
  printerQueueName: string;
  documentId: number;
  documentName: string;
  documentTypeId: number;
  documentTypeName: string;
  customerNo: number;
  customerName: string;
  printerQueueStatus: PrinterQueueStatus;
  insDate: string;
}

export const PrinterQueueEntryDefaults: PrinterQueueEntry = {
  printerQueueEntryId: -1,
  printerQueueId: -1,
  printerQueueName: "",
  documentId: -1,
  documentName: "",
  documentTypeId: -1,
  documentTypeName: "",
  customerNo: -1,
  customerName: "",
  printerQueueStatus: PrinterQueueStatus.TO_PRINT,
  insDate: "",
  version: -1,
};
