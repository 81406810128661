import {
  APIQueryOptions,
  APIQueryResult,
  getAPIQueryData,
  useAPIQuery,
  ValidValue,
} from "core/api";

/**
 * @param valuesUrl API url for valid values
 */
export function useFetchValidValues(
  valuesUrl: string | undefined,
  options?: APIQueryOptions<ValidValue[]>
): APIQueryResult<ValidValue[]> {
  return useAPIQuery<ValidValue[]>(
    createValidValuesQueryKey(valuesUrl),
    valuesUrl ?? "",
    {},
    { ...options, enabled: !!valuesUrl && options?.enabled !== false }
  );
}

export function createValidValuesQueryKey(valuesUrl: string | undefined) {
  return ["useFetchValidValue", valuesUrl];
}

export function getValidValuesQueryData(valuesUrl: string) {
  return getAPIQueryData<ValidValue[]>(createValidValuesQueryKey(valuesUrl));
}
