import { useNavigate } from "common/routing";
import {
  cacheInvalidators,
  FlowResultEffect,
  setPathParameters,
} from "core/api";
import { Messages } from "core/message";

/**
 * Creates resolver function for action effects
 * @returns Resolver function
 */
export function useActionEffectResolver() {
  const navigate = useNavigate();

  return (effects: FlowResultEffect[]) => {
    for (const effect of effects) {
      switch (effect.type) {
        case "NAVIGATE":
          if (effect.path === "-1") {
            // navigates back one step, similar to the back button on the browser
            navigate(-1);
          } else {
            navigate(setPathParameters(effect.path, effect.params));
          }
          break;
        case "MESSAGE":
          Messages().addMessage({
            type: effect.messageType,
            text: effect.message,
          });
          break;
        case "INVALIDATE_CACHE":
          switch (effect.cacheName) {
            case "SCREEN":
              cacheInvalidators.SCREEN({ screenId: effect.screenId });
              break;
            case "ENTITY":
              cacheInvalidators.ENTITY({
                entityRef: effect.entityRef,
                changeType: effect.changeType,
              });
              break;
            default:
              console.error("No matching cache name");
              break;
          }
          break;
        default:
          console.error("No matching effect type");
          break;
      }
    }
  };
}
