import { lazy, LazyExoticComponent } from "react";

/**
 * Dynamically imports widget based on widget type
 * @param widgetType Type details
 * @returns Lazy widget component or fallback component
 */
export function importWidget(widgetType: string) {
  if (widgetType in widgets) {
    return widgets[widgetType];
  }
  return lazy(() => import("screens/widgets/NullWidget"));
}

export const widgets: Record<string, LazyExoticComponent<any>> = {
  CurrentCost: lazy(() => import("screens/widgets/CurrentCostWidget")),
  Container: lazy(() => import("screens/widgets/ContainerWidget")),
  Flow: lazy(() => import("screens/widgets/FlowWidget")),
  Image: lazy(() => import("screens/widgets/ImageWidget")),
  InvoiceList: lazy(() => import("screens/widgets/InvoiceListWidget")),
  Layout: lazy(() => import("screens/widgets/LayoutWidget")),
  Link: lazy(() => import("screens/widgets/LinkWidget")),
  Text: lazy(() => import("screens/widgets/TextWidget")),
  Skeleton: lazy(() => import("screens/widgets/SkeletonWidget")),
};
