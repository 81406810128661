import { useCodeItems } from "core/hooks";
import { CodeObject, Note, NoteStatus } from "core/api";
import { useCallback } from "react";

export function useNoteStatus() {
  const { getCodeName } = useCodeItems();

  const findNoteStatusText = useCallback(
    (noteStatus: NoteStatus) => {
      return getCodeName(CodeObject.NoteStatus, noteStatus);
    },
    [getCodeName]
  );

  const getNoteStatus = useCallback(
    (note: Note) => {
      const noteStatus = note.noteOpen ? NoteStatus.OPEN : NoteStatus.CLOSED;
      return {
        status: noteStatus,
        reasonText: findNoteStatusText(noteStatus),
      };
    },
    [findNoteStatusText]
  );

  return { getNoteStatus };
}
