import { DialogContent, styled } from "common/components";
import { ActionForm } from "../components";

export const StyledActionForm = styled(ActionForm)(({ theme }) => ({
  display: "grid",
  gridTemplateRows: "min-content auto min-content",
  height: "100%",
}));

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  display: "grid",
  gridTemplateRows: "min-content auto",
  gap: theme.spacing(1),
  whiteSpace: "pre-wrap",
}));
