import {
  ChangeEntityStatusFlowData,
  ChangeEntityStatusFlowStep,
  ChangeProductStatusFlowData,
  ChangeProductStatusFlowStep,
} from "core/api";
import { changeEntityStatusDataResolver } from "../ChangeEntityStatus";
import { StepDataResolver } from "../models";

/**
 * Change Product status data resolver
 *
 * Formats order attributes and status reason id based on form field values
 */
export const changeProductStatusDataResolver: StepDataResolver<
  ChangeProductStatusFlowStep,
  ChangeProductStatusFlowData
> = ({ flow, flowStep, data, fieldValues }) => {
  const entityData: ChangeEntityStatusFlowData = {
    ...data,
    uiComponent: "ChangeEntityStatus",
  };
  const entityFlowStep: ChangeEntityStatusFlowStep = {
    ...flowStep,
    uiComponent: "ChangeEntityStatus",
  };
  // Use the 'super' data resolver to resolve the properties of the 'super' component
  const returnProps = changeEntityStatusDataResolver({
    flow,
    flowStep: entityFlowStep,
    data: entityData,
    fieldValues,
  });
  // Return a new set of properties built using returnProps.
  // Returning returnProps directly does not work because not the correct Type.
  return {
    orderAttributes: {
      ...data.orderAttributes,
      approved: returnProps.orderAttributes?.approved ?? false,
      effectiveDate: returnProps.orderAttributes?.effectiveDate ?? "",
    },
    statusReasonId: returnProps.statusReasonId,
  };
};
