/**
 * Enum for field type set by server to specify if a field config is one of the specified types below.
 * <p>
 * see also FieldType.java
 */
export enum FieldType {
  /**
   * The field is a status field.
   */
  ENTITY_STATUS_ID = "ENTITY_STATUS_ID",

  /**
   * The field is a status reason field
   */
  ENTITY_STATUS_REASON_ID = "ENTITY_STATUS_REASON_ID",

  /**
   * The field is the entity type id field
   */
  ENTITY_TYPE_ID = "ENTITY_TYPE_ID",

  /**
   * The field is the entity id field.
   */
  ENTITY_ID = "ENTITY_ID",

  /**
   * The field contains the entity name
   */
  ENTITY_NAME = "ENTITY_NAME",
}
