import { FlowVariant, useFetchActionFlow } from "core/api";
import { LoadingView } from "core/components";
import { ActionProps } from "../models";
import { Form } from "./Form";

export type FlowFormProps = ActionProps;

/**
 * Inline Flow action form
 */
export function FlowForm(props: Readonly<FlowFormProps>) {
  const {
    actionId,
    entityId,
    entityType,
    screenId,
    viewId,
    widgetId,
    invokedFromTable,
  } = props;
  const { data } = useFetchActionFlow({
    actionId,
    entityType,
    entityId,
    params: {
      screenId,
      viewId,
      widgetId,
      invokedFromTable: invokedFromTable ?? false,
    },
  });

  switch (data?.config.variant) {
    case FlowVariant.FORM:
      return <Form {...props} />;
    default:
      return <LoadingView />;
  }
}
