import { useEffect, useRef, useState } from "react";

/**
 * Creates a resize observer to keep track of container width.
 * @returns The element ref and width
 */
export function useContainerSize<
  TElement extends HTMLElement = HTMLDivElement
>() {
  const ref = useRef<TElement>(null);
  const [{ width, height }, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (ref.current) {
      const observer = new ResizeObserver((entries) => {
        const container = entries[0].contentRect;
        setDimensions({ width: container.width, height: container.height });
      });
      observer.observe(ref.current);
      return () => observer.disconnect();
    }
  }, []);

  return { ref, width, height };
}
