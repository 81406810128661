export default {
  nextInvoice: "Next invoice",
  latestInvoice: "Latest invoice",
  invoice: "Invoice",
  invoice_other: "Invoices",
  status: "Status",
  statementType: "Statement Type",
  invoiceId: "Invoice Id",
  creditNoteId: "Credit Note Id",
  name: "Name",
  amount: "Amount",
  invoiceDate: "Invoice Date",
  creditNoteDate: "Credit Note Date",
  dueDate: "Due Date",
  creditDate: "Credit Date",
  creditedBy: "Credited By",
  creditNoteType: "Credit Note Type",
  creditedInvoiceId: "Credited Invoice Id",
  creditReason: "Reason for Credit",
  paymentMethod: "Payment Method",
  referenceNumber: "Reference Number",
  netAmount: "Net amount",
  rounding: "Rounding",
  taxAmount: "Tax",
  totalAmount: "Total amount",
  address: "Address",
  totalAmountTaxIncluded: "Total amount incl. VAT",
  totalAmountTaxExcluded: "Total amount excl. VAT",
  downloadSuccess: "Invoice {{invoiceId}} downloaded",
  noLatestInvoice: "No invoice",
  noInvoices: "No invoices",
  period: "Period",
  costsForUpcomingPeriod:
    "Costs for upcoming period for number {{subscription}}",
  noCostsForUpcomingPeriod: "No costs for upcoming period",
  changeDueDate: {
    dueDate: "New Due Date",
  },

  newDueDate: "New Due Date",
  configureInvoiceDunning: {
    blockReminders: "Block Reminders",
    unblockReminders: "Unblock Reminders",
    blockDebtCollection: "Block Debt Collection",
    unblockDebtCollection: "Unblock Debt Collection",
  },
  remainingAmount: "Remaining Amount",
  payInvoice: {
    paidAmount: "Paid Amount",
    paymentDate: "Payment Date",
    paymentReference: "Payment Reference",
    paymentType: "Payment Type",
  },
  creditInvoice: {
    date: "Credit Note Date",
    invoice: "Credited Invoice",
    credit: "Credit",
    totalInvoicedAmount: "Total Invoiced Amount (incl. tax)",
    creditedAmount: "Total Credited Amount (incl.tax)",
    creditApprove: "Approve Credit Note",
    creditReason: "Reason for Credit",
    negativeOrZeroCreditedAmountWarning:
      "Credited Amount cannot be negative or zero.",
    remainingMaxAmountToCreditWarning:
      "Credited Amount cannot exceed Max amount to credit.",
    maxAmountToCredit: "Maximum Amount to Credit (incl. tax)",
    remainingAmount: "Remaining Amount (incl.tax)",
    revertCredit: "Revert Credit",
    creditInvoiceRow: "Credit Invoice Row",
    remainingAmountToCredit: "Remaining amount to credit",
    amountInclTax: "Amount including tax",
    amountExclTax: "Amount excluding tax",
  },
} as const;
