/* eslint-disable no-fallthrough */
import * as idb from "idb/with-async-ittr";

const DATABASE_NAME = "BILLIANT";
const DATABASE_VERSION = 2;
export const HTTP_CACHE_KEY = "HttpCache";
export const ACTION_LOCK_KEY = "ActionLock";

export interface HttpCacheValue {
  request: string;
  body: any;
  headers: string[][];
  expires: number;
}

export interface ActionLock {
  lockKey: string;
  isLocked: boolean;
}

export interface HttpCacheDB extends idb.DBSchema {
  HttpCache: {
    value: HttpCacheValue;
    key: string;
    indexes: {
      Request: string;
    };
  };
  ActionLock: {
    value: ActionLock;
    key: string;
  };
}

const dbStoreKeys = [HTTP_CACHE_KEY, ACTION_LOCK_KEY] as const;

export type HttpCacheStoreKey = typeof dbStoreKeys[number];

/**
 * Initialize the IndexedDB.
 * see https://developers.google.com/web/ilt/pwa/lab-indexeddb
 * for information as to why we use switch w/o breaks for migrations.
 * add do the database version and add a switch case each time you need to
 * change migrations
 */
function initializeDB() {
  return idb.openDB<HttpCacheDB>(DATABASE_NAME, DATABASE_VERSION, {
    upgrade(database, oldVersion, newVersion, transaction) {
      switch (oldVersion) {
        // @ts-ignore noFallthroughCasesInSwitch
        case 0:
          // execute when the database is first created
          // (oldVersion is 0)
          database.createObjectStore(HTTP_CACHE_KEY, { keyPath: "request" });
          const tx = transaction.objectStore(HTTP_CACHE_KEY);
          tx.createIndex("Request", "request");
        case 1:
          database.createObjectStore(ACTION_LOCK_KEY, { keyPath: "lockKey" });
      }
    },
  });
}

class DBService {
  dbPromise: Promise<idb.IDBPDatabase<HttpCacheDB>> | undefined;

  initializeDB() {
    this.dbPromise = initializeDB();
  }

  get(tablespace: any, key: any) {
    return this.dbPromise
      ?.then((db) => {
        return db.transaction(tablespace).objectStore(tablespace).get(key);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getAll(tablespace: any, indexName: never, index: IDBKeyRange) {
    return this.dbPromise
      ?.then((db) => {
        return db
          .transaction(tablespace)
          .objectStore(tablespace)
          .index(indexName)
          .getAll(index);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  put(
    tablespace: any,
    object: any,
    key?: IDBKeyRange | IDBValidKey | undefined
  ) {
    return this.dbPromise
      ?.then((db) => {
        if (key) {
          return db
            .transaction(tablespace, "readwrite")
            .objectStore(tablespace)
            .put(object, key);
        }
        return db
          .transaction(tablespace, "readwrite")
          .objectStore(tablespace)
          .put(object);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  delete(tablespace: any, key: IDBKeyRange | IDBValidKey) {
    return this.dbPromise
      ?.then((db) => {
        return db
          .transaction(tablespace, "readwrite")
          .objectStore(tablespace)
          .delete(key);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  deleteAll(tablespace: HttpCacheStoreKey) {
    return this.dbPromise
      ?.then((db) => {
        return db
          .transaction(tablespace, "readwrite")
          .objectStore(tablespace)
          .clear();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  createTransaction<TableSpace extends HttpCacheStoreKey = HttpCacheStoreKey>(
    tablespace: TableSpace
  ) {
    return this.dbPromise
      ?.then((db) => {
        return db.transaction(tablespace, "readwrite");
      })
      .catch((error) => {
        console.error(error);
      });
  }
}

export const DB = new DBService();
