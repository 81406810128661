import { AppCode, Severity, useLogEvent } from "core/api";
import { ReactElement } from "react";
import { ErrorBoundary, ErrorBoundaryProps } from "./ErrorBoundary";

export type LoggingErrorBoundaryProps = Omit<ErrorBoundaryProps, "logError">;
/**
 * Wrapper component for error boundary used to access logging hook without violating rules-of-hooks.
 * @returns The component
 */
export function LoggingErrorBoundary({
  children,
  ...props
}: LoggingErrorBoundaryProps): ReactElement {
  const { mutate: logEvent } = useLogEvent();
  const logError = (message: string) => {
    const appPath = window.location.pathname.split("/")[1] as AppCode;
    const app = Object.values(AppCode).includes(appPath)
      ? appPath
      : AppCode.common;
    logEvent(
      { app: app, severity: Severity.ERROR, message: message },
      {
        onError(_error) {
          //Do nothing for now.
        },
      }
    );
  };

  return (
    <ErrorBoundary logError={logError} {...props}>
      {children}
    </ErrorBoundary>
  );
}
