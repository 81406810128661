import { ListItem, ListItemText } from "common/components";
import { convertBooleanToText, formatDate } from "common/utils";
import { FlowData, FlowStep } from "core/api";
import { useTranslation } from "i18n";
import { Fragment } from "react";

export interface AdjustmentPropertiesConfirmationProps {
  step: FlowStep;
  flowData: FlowData;
}

/**
 * Sub component to confirmation. The purpose of this component is
 * to make the main Confirmation component more readable,
 * abstracting some of the logic into smaller sub components.
 *
 * AdjustmentCreateConfirmation is specific to confirmation steps where
 * the flow contains a step with the uiComponent: "AdjustmentCreate".
 *
 * @param flowData data property from the flow object, this is fetched through
 * const flowData = flow.data[step.id]; in the parent component.
 * @returns A JSX element with invoice details from the previous "AdjustmentCreate" step in the flow.
 */
export function AdjustmentPropertiesConfirmation({
  step,
  flowData,
}: AdjustmentPropertiesConfirmationProps): JSX.Element {
  const { t } = useTranslation();
  const {
    priceTypes = null,
    priceTypeId = undefined,
    adjustmentDescription = undefined,
    immediateInvoicingEnabled = undefined,
    earliestInvoiceDate = undefined,
    immediateInvoiceDate = undefined,
  } = flowData.uiComponent === "AdjustmentProperties" ? flowData : {};
  const selectedChargeType = priceTypes?.find(
    (pt) => parseInt(pt.id) === priceTypeId
  )?.name;
  return (
    <Fragment key={step.id}>
      <ListItem>
        <ListItemText
          primary={t("common:chargeType")}
          secondary={selectedChargeType}
          data-cy={"confirmChargeType"}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={t("common:adjustmentDescription")}
          secondary={adjustmentDescription}
          data-cy={"confirmAdjustmentDescription"}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={t("common:enableImmediateInvoicing")}
          secondary={convertBooleanToText(immediateInvoicingEnabled)}
          data-cy={"confirmEnableImmediateInvoicing"}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={t("common:earliestInvoiceDate")}
          secondary={
            immediateInvoicingEnabled
              ? formatDate(immediateInvoiceDate)
              : formatDate(earliestInvoiceDate)
          }
          data-cy={"confirmEarliestInvoiceDate"}
        />
      </ListItem>
    </Fragment>
  );
}
