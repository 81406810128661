import {
  APIQueryOptions,
  CreateFlowParams,
  Flow,
  FlowStep,
  StepEvaluationReason,
  useEvaluateActionFlow,
} from "core/api";
import { useCallback } from "react";
import { FieldValues, UseFormGetValues } from "react-hook-form";
import { insertStepValues } from "./flowUtils";
import { StepperDirection } from "./models";

export interface StepEvaluationSettings {
  /** Action id */
  actionId: number;
  /** Current flow data */
  data: Flow | undefined;
  /** Current step id */
  currentStepId: string | undefined;
  currentSubStepId: string | undefined;
  /** Current step config */
  currentStep: FlowStep | undefined;
  /** Step config for the current step's active sub step */
  currentSubStep: FlowStep | undefined;
  /** Stepper direction */
  direction: StepperDirection;
  /** Getter for flow form values */
  getValues: UseFormGetValues<FieldValues>;
  /** Flow params */
  params: CreateFlowParams;
}

/**
 * Evaluates on entering step and returns evaluate functions
 * @param evaluateSettings
 * @returns Evaluate method and state
 */
export function useStepEvaluation({
  actionId,
  data,
  currentStepId,
  currentSubStepId,
  currentStep,
  currentSubStep,
  direction,
  getValues,
  params,
}: StepEvaluationSettings) {
  const evalOnEnter =
    (currentStep?.evalOnEnter || currentSubStep?.evalOnEnter) ?? false;
  const { mutate, ...evaluateState } = useEvaluateActionFlow({
    actionId,
    currentStepId,
    currentSubStepId,
    direction,
    evalOnEnter,
    values: getValues(),
    flow:
      data && currentStepId && evalOnEnter
        ? insertStepValues(data, getValues(), currentStepId)
        : undefined,
    params,
  });

  const evaluateStep = useCallback(
    (
      reason: StepEvaluationReason,
      data: Flow,
      options?: APIQueryOptions<Flow>
    ) => {
      if (currentStepId) {
        mutate(
          {
            reason,
            data: insertStepValues(data, getValues(), currentStepId),
          },
          options
        );
      }
    },
    [currentStepId, getValues, mutate]
  );

  const evaluateOnChange = useCallback(() => {
    if (data) {
      evaluateStep("CHANGE", data);
    }
  }, [evaluateStep, data]);

  return {
    evaluateStep,
    evaluateOnChange,
    evaluateState,
  };
}
