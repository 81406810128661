import {
  AdjustmentAmountFlowData,
  AdjustmentAmountFlowStep,
  AmountWithVATFlowData,
  AmountWithVATFlowStep,
} from "core/api";
import { StepDataResolver } from "..";
import { amountWithVATDataResolver } from "../AmountWithVAT";

/**
 * This data resolver just invokes {@link amountWithVATDataResolver}.
 *
 * @remark
 * The additional properties handled by the AdjustmentAmount component
 * **generalLedgerInfo** and **taxBundleInfo** are not editable by the
 * user, so they are not returned by this data resolver.
 *
 * @see {@link amountWithVATDataResolver}
 */
export const adjustmentAmountDataResolver: StepDataResolver<
  AdjustmentAmountFlowStep,
  AdjustmentAmountFlowData
> = ({ flow, flowStep, data, fieldValues }) => {
  const amountData: AmountWithVATFlowData = {
    ...data,
    uiComponent: "AmountWithVAT",
  };
  const amountFlowStep: AmountWithVATFlowStep = {
    ...flowStep,
    uiComponent: "AmountWithVAT",
  };

  // Use the 'super' data resolver to resolve the properties of the 'super' component
  const returnProps = amountWithVATDataResolver({
    flow,
    flowStep: amountFlowStep,
    data: amountData,
    fieldValues,
  });

  // Return a new set of properties built using returnProps.
  // Returning returnProps directly does not work because not the correct Type.
  return {
    inputAmountInclVat: returnProps.inputAmountInclVat,
    amount: returnProps.amount,
    // generalLedgerInfo and taxBundleInfo are not returned here, because
    // they are not editable by the user.
  };
};
