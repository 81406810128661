import { addFlowStepPrefix } from "common/utils";
import {
  FieldsFlowData,
  FieldsFlowStep,
  FieldUIComponent,
  FieldValueConfig,
  useFetchFieldConfig,
  useFetchValidValues,
} from "core/api";
import {
  FormField,
  FormFieldProps,
  useCalendarRangeAvailability,
} from "core/forms";
import { FieldValues } from "react-hook-form";
import { FlowStepComponentProps } from "../models";
import { StyledStepContentWrapper } from "../steps.styles";

export type FieldsProps = FlowStepComponentProps<
  FieldsFlowStep,
  FieldsFlowData
>;

/** Fields step component */
export function Fields({ control, data, flowStep }: FieldsProps): JSX.Element {
  const { entityType = undefined, entityTypeId = undefined } =
    data.entityRef ?? flowStep.config ?? {};
  const { data: fieldConfigs } = useFetchFieldConfig(entityType, entityTypeId);

  const fields = flowStep.config.fields;
  const fieldsData = data.fields;
  const gridColumns = `repeat(${flowStep.config.columnCount ?? 1}, 1fr)`;
  return (
    <StyledStepContentWrapper sx={{ gridTemplateColumns: gridColumns }}>
      {fieldConfigs &&
        fields.map(
          ({
            key,
            columnSpan,
            columnStart,
            dependsOn,
            rows,
            disabled,
            evalOnChange,
          }) => {
            const fieldData = fieldsData.find(
              ({ key: dataKey }) =>
                dataKey.key === key.key && dataKey.variant === key.variant
            );
            const fieldConfig =
              fieldData?.config ??
              fieldConfigs.find(
                ({ key: configKey }) =>
                  configKey.key === key.key && configKey.variant === key.variant
              );
            if (!fieldConfig) {
              return null;
            }

            return (
              <Field
                disabled={flowStep.config.disabled || disabled}
                key={key.key + key.variant}
                valuesConfig={fieldConfig.values[0]}
                control={control}
                fieldName={addFlowStepPrefix(flowStep, key.key, key.variant)}
                fieldType={fieldConfig.uiComponent}
                label={fieldConfig.label}
                required={fieldConfig.mandatory}
                defaultValue={
                  fieldConfig.uiComponent === FieldUIComponent.List ||
                  fieldConfig.uiComponent === FieldUIComponent.FieldValueGroup
                    ? fieldData?.values.map((f) => f.value || "")
                    : fieldData?.values.find(({ valueNo }) => valueNo === 0)
                        ?.value
                }
                optionalDefaultValue={
                  fieldData?.values.find(({ valueNo }) => valueNo === 1)?.value
                }
                currencyCode={fieldData?.currencyCode}
                pinCodeLength={
                  fieldConfig.props?.PIN_CODE_LENGTH
                    ? parseInt(fieldConfig.props?.PIN_CODE_LENGTH)
                    : undefined
                }
                pattern={fieldConfig.pattern}
                patternHint={fieldConfig.patternHint}
                minLength={fieldConfig.minLength}
                maxLength={fieldConfig.maxLength}
                socialSecurityNumberLength={
                  fieldConfig.socialSecurityNumberLength
                }
                serverError={fieldData?.error}
                columnSpan={columnSpan}
                columnStart={columnStart}
                dependsOn={dependsOn}
                rows={rows}
                evaluateOnChange={evalOnChange}
                unitType={
                  fieldData?.values.find(({ valueNo }) => valueNo === 0)
                    ?.unitType
                }
              />
            );
          }
        )}
    </StyledStepContentWrapper>
  );
}

interface FieldProps extends FormFieldProps<FieldValues> {
  valuesConfig: FieldValueConfig | undefined;
  rows?: number;
  disabled?: boolean;
}

function Field({
  rows,
  disabled,
  valuesConfig,
  unitType,
  ...props
}: FieldProps) {
  const validValues = useFieldValidValues(valuesConfig);
  const { minDate, maxDate, shouldDisableDate, shouldDisableTime } =
    useCalendarRangeAvailability(props.fieldName, valuesConfig?.calendar);

  return (
    <FormField
      {...props}
      minDate={minDate}
      maxDate={maxDate}
      shouldDisableDate={shouldDisableDate}
      shouldDisableTime={shouldDisableTime}
      rows={rows}
      disabled={disabled}
      unitType={unitType}
      options={validValues?.map(({ id, name }) => ({ label: name, value: id }))}
    />
  );
}

function useFieldValidValues(valuesConfig: FieldValueConfig | undefined) {
  const { data: fetchedValidValues } = useFetchValidValues(
    valuesConfig?.valuesURL,
    {
      enabled: !valuesConfig?.discreteValues,
    }
  );
  return valuesConfig?.discreteValues ?? fetchedValidValues;
}
