export default {
  _404: {
    title: "La page n'a pas été trouvée",
    urlNotFound: "Cette adresse URL n'existe pas",
    notFoundOrNotAvailable:
      "La page à laquelle vous essayez d'accéder n'existe pas ou a été supprimée.",
  },
  _500: {
    fatalError: {
      title: "Une erreur fatale est survenue sur le serveur",
      title_plural: "Plusieurs erreurs fatales sont survenues sur le serveur",
    },
  },
  backToApplications: "Retour vers mes applications",
  error: "erreur",
  error_plural: "erreurs",
  errorWithCount: "{{count}} erreur",
  errorWithCount_plural: "{{count}} erreurs",
  preventNavigationDialog: {
    title: "Modifications non sauvegardées",
    body: "Certaines modifications n'ont pas été sauvergardées. Êtes-vous sûr de vouloir quitter cette page au risque de perdre ces modifications?",
    stayOnPage: "Rester sur cette page",
    leavePage: "Quitter cette page",
  },
  route: {
    noAccess:
      "Vous n'avez pas accès à l'adresse URL {{url}}. Le rôle associé à votre utilisateur n'autorise pas cet accès.",
  },
  fetchError:
    "Le chargement de ce composant a échoué à cause d'une erreur sur le serveur (Code d'erreur {{status}})",
  fields: {
    valueAmountError:
      "Erreur: le champ {{fieldName}} requiers {{expectedAmount}} valeurs, il en a reçu seulement {{actualAmount}}",
  },
  auth: {
    logoutFail:
      "Le serveur n'a pas répondu à la requête de déconnexion. Les données d'identification locales ont été réinitialisées.",
  },
  emptyQuery: "Erreur: La réponse du serveur ne contient aucune valeur",
  clientError: "Erreur au niveau du client web: ",
  validationError: "Erreur de validation",
  screenLoadingError:
    "Une erreur est survenue lors du chargement de la page. Ceci peut être provoqué par une adresse URL invalide ou périmée. Message du serveur: {{serverMessage}} ",
  screenLoadingError_nomessage:
    "Une erreur est survenue lors du chargement de la page. Ceci peut être provoqué par une adresse URL invalide ou périmée.",
  failedToLoadWidget: "Le chargement du composant a échoué",
} as const;
