import { Button, DialogActions } from "@mui/material";
import { PickersActionBarProps } from "@mui/x-date-pickers/PickersActionBar";
import { useTranslation } from "react-i18next";

/** Custom Action bar component used for DatePicker and DateTimePicker */
export function DatePickerActionBar({ onSetToday }: PickersActionBarProps) {
  const { t } = useTranslation(["common"]);
  return (
    <DialogActions>
      <Button onClick={onSetToday} data-cy="todayButton">
        {t("common:searchFilter.today")}
      </Button>
    </DialogActions>
  );
}
