import { styled } from "@mui/material/styles";
import {
  Alert,
  AlertProps,
  Badge,
  Snackbar,
  SnackbarCloseReason,
} from "common/components";
import { useLocationChanges } from "common/routing";
import { useTranslation } from "i18n";
import { ReactElement, useCallback } from "react";
import { ConfirmationDialog } from "./dialogs";
import { ErrorDialog } from "./dialogs/ErrorDialog";
import { Messages } from "./MessageService";
import { useCurrentMessages } from "./useCurrentMessages";

/**
 * Root container for message snackbar and dialog.
 *
 * NOTE: Needs to be a child to the application Router.
 *
 * @returns A message container
 */
export function MessageContainer(): ReactElement | null {
  const { t } = useTranslation("common");

  const { messages, errorDialogMessages, confirmationDialogMessages } =
    useCurrentMessages();

  const handleOnClose = (
    _event: Event | React.SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason
  ) => {
    if (reason !== "clickaway") {
      Messages().removeLatestMessage();
    }
  };

  const closeErrorDialog = useCallback(() => {
    Messages().closeErrorDialog();
  }, []);

  const closeConfirmationDialog = useCallback(() => {
    Messages().closeConfirmationDialog();
  }, []);

  const onLocationChange = useCallback(() => {
    Messages().clearMessages();
    closeErrorDialog();
    closeConfirmationDialog();
  }, [closeErrorDialog, closeConfirmationDialog]);

  useLocationChanges({ onChange: onLocationChange });

  const currentMessage = messages[0];

  return (
    <>
      {currentMessage && (
        <Snackbar
          open={!!currentMessage}
          onClose={handleOnClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          data-cy="snackbarDismiss"
        >
          <div>
            <SnackbarAlert
              messageCount={messages.length}
              severity={currentMessage.type}
              onClose={handleOnClose}
            >
              <span>
                {currentMessage?.text}{" "}
                {currentMessage?.occurrences! > 1 &&
                  t("snackbarMessageOccurredMultipleTimes", {
                    times: currentMessage?.occurrences,
                  })}
              </span>
            </SnackbarAlert>
          </div>
        </Snackbar>
      )}
      {errorDialogMessages.length > 0 && (
        <ErrorDialog
          open={errorDialogMessages.length > 0}
          dialogMessages={errorDialogMessages}
          onClose={closeErrorDialog}
        />
      )}
      {confirmationDialogMessages.length > 0 && (
        <ConfirmationDialog
          message={confirmationDialogMessages[0]}
          open={confirmationDialogMessages.length > 0}
          onClose={closeConfirmationDialog}
        />
      )}
    </>
  );
}

const StyledAlert = styled(Alert)(({ theme }) => ({
  maxWidth: 580,
}));

interface SnackbarAlertProps extends AlertProps {
  messageCount: number;
}

/** Alert component that displays a badge count of additional messages left */
function SnackbarAlert({
  messageCount,
  children,
  ...props
}: SnackbarAlertProps) {
  const count = messageCount - 1;
  return (
    <Badge badgeContent={count ? `+${count}` : 0} color="primary">
      <StyledAlert variant="filled" elevation={6} {...props}>
        {children}
      </StyledAlert>
    </Badge>
  );
}
