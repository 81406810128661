import { MenuItem, Select, SelectProps } from "common/components";
import { formatDataCy } from "common/utils";
import { useTranslation } from "i18n";
import { ForwardedRef, ReactElement, cloneElement, forwardRef } from "react";

export interface SelectOption {
  value: string | number;
  label: string;
  dataCy?: string;
}
export interface UnlabeledSelectProps<
  TOption extends SelectOption = SelectOption
> extends SelectProps {
  dataList?: TOption[];
  includeEmptyOption?: boolean;
  renderOption?(option: TOption, selectedValue: unknown): ReactElement;
}

/** Labeled select field */
export const UnlabeledSelect = forwardRef(
  <TOption extends SelectOption = SelectOption>(
    {
      children,
      dataList,
      includeEmptyOption,
      renderOption = ({ value, label }, selectedValue) => (
        <MenuItem value={value}>{label}</MenuItem>
      ),
      ...props
    }: UnlabeledSelectProps<TOption>,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const { t } = useTranslation(["common"]);
    const noValueOption = {
      value: "",
      label: t("common:noValueSelected"),
    } as TOption;

    const options =
      includeEmptyOption && dataList ? [noValueOption, ...dataList] : dataList;

    const renderOptions = (
      options: TOption[] | undefined,
      selectedValue: unknown
    ) => {
      if (!options) {
        return null;
      }
      return options.map((option) => {
        const renderedOption = renderOption(option, selectedValue);
        return cloneElement(renderedOption, {
          key: option.value,
          "data-cy": option.dataCy ?? formatDataCy(option.label),
        });
      });
    };

    return (
      <Select {...props} ref={ref}>
        {children ? children : renderOptions(options, props.value)}
      </Select>
    );
  }
);
