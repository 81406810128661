export default {
  changePaymentMethod: {
    currentPaymentMethod:
      "Your current payment method is {{currentPaymentMethodName}}",
    enterCreditCard: "Enter another credit card below to use.",
    changePaymentMethod: "Change Payment Method",
  },
  renewProduct: "It is possible to renew the product to get a better price.",
  renewOptions: "Renew Options",
} as const;
