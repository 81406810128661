/**
 * This file contains all full and relative routes in the application, using string constants to
 *  avoid duplication.
 */

const CANCELED = "/canceled";
const CONFIGURATION = "/configuration" as const;
const CREATE = "/create" as const;
const CUSTOMER_CARE = "/customercare" as const;
const CUSTOMERS = "/customers" as const;
const CUSTOMER_CARE_SEARCH = "/search" as const;
const CUSTOMER_NO = "/:customerNo" as const;
const PROVIDER = "/provider/:provider" as const;
const DOCUMENT_TYPES = "/document-types" as const;
const DOCUMENTS = "/documents" as const;
const DOCUMENT_ID = "/:documentId";
const FAILED = "/failed" as const;
const FILTER_ID = "/:filterId" as const;
const FILTERS = "/filters" as const;
const HOME = "/" as const;
const ID = "/:id" as const;
const INVOICES = "/invoices" as const;
const INVOICE_ID = "/:invoiceId" as const;
const INVENTORIES = "/inventories" as const;
const INVENTORY_ID = "/:inventoryId" as const;
const LOGIN = "/login" as const;
const LOGOUT = "/logout" as const;
const MY_ACCOUNT = "/myaccount" as const;
const PAGE_NOT_FOUND = "/page-not-found" as const;
const PREFERENCES = "/preferences" as const;
const PRINTED = "/printed" as const;
const PRINTER_QUEUE = "/printerqueue" as const;
const PRINTING = "/printing" as const;
const PRODUCTS = "/products" as const;
const PRODUCT_ID = "/:productId" as const;
const PROFILE = "/profile" as const;
const QUEUES = "/queues" as const;
const QUEUES_ID = "/:queueId" as const;
const SECURITY = "/security" as const;
const SUBSCRIPTIONS = "/subscriptions" as const;
const SUBSCRIPTION_ID = "/:subscriptionId" as const;
const ORDERS = "/orders" as const;
const ORDER_ID = "/:orderId" as const;
const TASKS = "/tasks" as const;
const TASK_ID = "/:taskId" as const;
const USER_ID = "/:userId" as const;
const TO_PRINT = "/to-print" as const;
const QUEUE_DEFINITIONS = "/queue-definitions" as const;
const QUEUE_ENTRIES = "/queue-entries" as const;
const OVERVIEW = "/overview" as const;
const USERS = "/users" as const;

export const URLS = {
  home: {
    path: HOME,
  },
  login: {
    path: LOGIN,
  },
  login_provider: {
    path: `${LOGIN}${PROVIDER}`,
  },
  logout: {
    path: LOGOUT,
  },
  customercare: {
    path: CUSTOMER_CARE,
    home: {
      path: `${CUSTOMER_CARE}`,
      relative: CUSTOMER_CARE,
    },
    customer: {
      path: `${CUSTOMER_CARE}${CUSTOMERS}${CUSTOMER_NO}`,
      relative: `${CUSTOMERS}${CUSTOMER_NO}`,
      overview: {
        path: `${CUSTOMER_CARE}${CUSTOMERS}${CUSTOMER_NO}${OVERVIEW}`,
      },
      invoices: {
        path: `${CUSTOMER_CARE}${CUSTOMERS}${CUSTOMER_NO}${INVOICES}`,
        relative: `${INVOICES}`,
        invoice: {
          path: `${CUSTOMER_CARE}${CUSTOMERS}${CUSTOMER_NO}${INVOICES}${INVOICE_ID}`,
          relative: `${INVOICE_ID}`,
        },
      },
      inventories: {
        path: `${CUSTOMER_CARE}${CUSTOMERS}${CUSTOMER_NO}${INVENTORIES}`,
        relative: `${INVENTORIES}`,
        inventory: {
          path: `${CUSTOMER_CARE}${CUSTOMERS}${CUSTOMER_NO}${INVENTORIES}${INVENTORY_ID}`,
          relative: `${INVENTORY_ID}`,
        },
      },
      products: {
        path: `${CUSTOMER_CARE}${CUSTOMERS}${CUSTOMER_NO}${PRODUCTS}`,
        product: {
          path: `${CUSTOMER_CARE}${CUSTOMERS}${CUSTOMER_NO}${PRODUCTS}${PRODUCT_ID}`,
        },
      },
      subscriptions: {
        path: `${CUSTOMER_CARE}${CUSTOMERS}${CUSTOMER_NO}${SUBSCRIPTIONS}`,
        subscriptions: {
          path: `${CUSTOMER_CARE}${CUSTOMERS}${CUSTOMER_NO}${SUBSCRIPTIONS}${SUBSCRIPTION_ID}`,
        },
      },
      tasks: {
        path: `${CUSTOMER_CARE}${CUSTOMERS}${CUSTOMER_NO}${TASKS}`,
        task: {
          path: `${CUSTOMER_CARE}${CUSTOMERS}${CUSTOMER_NO}${TASKS}${TASK_ID}`,
        },
      },
      users: {
        user: `${CUSTOMER_CARE}${CUSTOMERS}${CUSTOMER_NO}${USERS}${USER_ID}`,
      },
      documents: {
        document: `${CUSTOMER_CARE}${CUSTOMERS}${CUSTOMER_NO}${DOCUMENTS}${DOCUMENT_ID}`,
      },
    },
    inventories: {
      path: `${CUSTOMER_CARE}${INVENTORIES}`,
      relative: `${INVENTORIES}`,
      inventory: {
        path: `${CUSTOMER_CARE}${INVENTORIES}${INVENTORY_ID}`,
        relative: `${INVENTORY_ID}`,
      },
    },
    search: {
      path: `${CUSTOMER_CARE}${CUSTOMER_CARE_SEARCH}`,
      relative: CUSTOMER_CARE_SEARCH,
    },
  },
  myaccount: {
    path: MY_ACCOUNT,
    profile: {
      path: `${MY_ACCOUNT}${PROFILE}`,
      relative: PROFILE,
    },
    preferences: {
      path: `${MY_ACCOUNT}${PREFERENCES}`,
      relative: PREFERENCES,
    },
    security: {
      path: `${MY_ACCOUNT}${SECURITY}`,
      relative: SECURITY,
    },
  },
  pageNotFound: {
    path: PAGE_NOT_FOUND,
  },
  printerqueue: {
    path: PRINTER_QUEUE,
    queues: {
      path: `${PRINTER_QUEUE}${QUEUES}`,
      relative: QUEUES,
      queueId: {
        path: `${PRINTER_QUEUE}${QUEUES}${QUEUES_ID}`,
        relative: QUEUES_ID,
      },
      queueEntry: {
        path: `${PRINTER_QUEUE}${QUEUES}${QUEUES_ID}${QUEUE_ENTRIES}${ID}`,
        relative: `${QUEUE_ENTRIES}${ID}`,
      },
    },
    queueEntry: {
      path: `${PRINTER_QUEUE}${QUEUE_ENTRIES}${ID}`,
      relative: `${QUEUE_ENTRIES}${ID}`,
    },
    filters: {
      path: `${PRINTER_QUEUE}${FILTERS}`,
      relative: FILTERS,
      filterId: {
        path: `${PRINTER_QUEUE}${FILTERS}${FILTER_ID}`,
        relative: FILTER_ID,
      },
      toPrint: {
        path: `${PRINTER_QUEUE}${FILTERS}${TO_PRINT}`,
        relative: TO_PRINT,
      },
      printing: {
        path: `${PRINTER_QUEUE}${FILTERS}${PRINTING}`,
        relative: PRINTING,
      },
      printed: {
        path: `${PRINTER_QUEUE}${FILTERS}${PRINTED}`,
        relative: PRINTED,
      },
      canceled: {
        path: `${PRINTER_QUEUE}${FILTERS}${CANCELED}`,
        relative: CANCELED,
      },
      failed: {
        path: `${PRINTER_QUEUE}${FILTERS}${FAILED}`,
        relative: FAILED,
      },
      queueEntry: {
        path: `${PRINTER_QUEUE}${FILTERS}${FILTER_ID}${QUEUE_ENTRIES}${ID}`,
        relative: ID,
      },
    },
    configuration: {
      path: `${PRINTER_QUEUE}${CONFIGURATION}`,
      relative: CONFIGURATION,
      documentTypes: {
        path: `${PRINTER_QUEUE}${CONFIGURATION}${DOCUMENT_TYPES}`,
        relative: DOCUMENT_TYPES,
        id: {
          path: `${PRINTER_QUEUE}${CONFIGURATION}${DOCUMENT_TYPES}${ID}`,
          relative: ID,
        },
        create: {
          path: `${PRINTER_QUEUE}${CONFIGURATION}${DOCUMENT_TYPES}${CREATE}`,
          relative: CREATE,
        },
      },
      queueDefinitions: {
        path: `${PRINTER_QUEUE}${CONFIGURATION}${QUEUE_DEFINITIONS}`,
        relative: QUEUE_DEFINITIONS,
        id: {
          path: `${PRINTER_QUEUE}${CONFIGURATION}${QUEUE_DEFINITIONS}${ID}`,
          relative: ID,
        },
        create: {
          path: `${PRINTER_QUEUE}${CONFIGURATION}${QUEUE_DEFINITIONS}${CREATE}`,
          relative: CREATE,
        },
      },
    },
  },
  search: {
    path: ":appPath/search",
    relative: "/search",
  },
} as const;
