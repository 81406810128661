import { ListItem } from "common/components";
import {
  AddressFieldsFlowStep,
  FieldsFlowStep,
  FlowData,
  getFieldConfigQueryData,
} from "core/api";
import { ReadOnlyConfigField } from "core/components";
import { Fragment } from "react";

export interface FieldsConfirmationProps {
  step: FieldsFlowStep | AddressFieldsFlowStep;
  flowData: FlowData;
}

/**
 * Sub component to confirmation. The purpose of this component is
 * to make the main Confirmation component more readable,
 * abstracting some of the logic into smaller sub components.
 *
 * FieldsConfirmation is specific to confirmation steps where
 * the flow contains a step with the uiComponent: "Fields".
 *
 * @param step The current step in the parent components loop.
 * @param flowData data property from the flow object, this is fetched through
 * const flowData = flow.data[step.id]; in the parent component.
 *
 * @returns A JSX element with the fields from a previous step in the flow.
 */
export function FieldsConfirmation({
  step,
  flowData,
}: FieldsConfirmationProps): JSX.Element | null {
  const { entityRef = undefined } =
    flowData.uiComponent === "Fields" ||
    flowData.uiComponent === "AddressFields"
      ? flowData
      : {};

  if (!entityRef) {
    return null;
  }

  const entityFields = getFieldConfigQueryData(
    entityRef?.entityType,
    entityRef?.entityTypeId
  );

  if (!entityFields) {
    return null;
  }

  return (
    <Fragment key={step.id}>
      {step.config.fields.map((field) => {
        const fieldFlowData =
          flowData.uiComponent === "Fields" ||
          flowData.uiComponent === "AddressFields"
            ? flowData
            : undefined;

        const fieldData = fieldFlowData?.fields.find(
          ({ key }) =>
            key.key === field.key.key && key.variant === field.key.variant
        );

        const fieldConfig =
          fieldData?.config ??
          entityFields.find(
            ({ key }) =>
              key.key === field.key.key && key.variant === field.key.variant
          );

        return (
          <ListItem key={field.key.key + field.key.variant}>
            <ReadOnlyConfigField
              fieldKey={field.key}
              config={fieldConfig}
              data={fieldData}
              align="left"
            />
          </ListItem>
        );
      })}
    </Fragment>
  );
}
