import { deepmerge } from "@mui/utils";
import { createTheme, SxProps, Theme, ThemeOptions } from "common/components";
import { filterExistingValues } from "common/utils";
import { baseOptions } from "styles/theme";

/**
 * Combine sx prop values
 * @param sxValues Sx prop values to combine
 * @returns Combined sx prop values
 */
export function combineSx(
  ...sxValues: Array<SxProps<Theme> | undefined>
): SxProps<Theme> {
  return filterExistingValues(sxValues).reduce(
    (allValues, value) => [
      ...(Array.isArray(allValues) ? allValues : [allValues]),
      ...(Array.isArray(value) ? value : [value]),
    ],
    []
  );
}

export function extendBaseTheme(extendOptions: ThemeOptions): Theme {
  return createTheme(deepmerge(baseOptions, extendOptions));
}
