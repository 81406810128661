export default {
  cancelDialog: {
    title: 'Discard action "{{action}}"?',
    body: "Entered data will be lost.",
    cancelButton: "Discard changes",
    closeButton: "Cancel",
  },

  transferProductStep: {
    copyProduct: "Copy Product",
    transferBinds: "Transfer Binds",
    deactivateStatusReasonId: "Deactivation Reason",
    activateStatusReasonId: "Activation Reason",
  },

  findInventoryStep: {
    searchText: "Search text",
    status: "Status",
    location: "Location",
    article: "Article",
    maxInvItems: "Limit #items to",
  },

  findNetworkElementStep: {
    searchText: "Search text",
    status: "Status",
    networkElementType: "Network Element Type",
    maxTableItems: "Limit #items to",
  },
} as const;
