import { styled } from "common/components";
import { NavLink } from "common/routing";

export const HeaderNavLink = styled(NavLink)(({ theme }) => ({
  height: "100%",
  "&.active": {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  "&:hover": {
    color: theme.palette.primary.main,
  },
}));
