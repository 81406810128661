import { StatusIcon } from "common/assets/icons";
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  debounce,
  FilterOptionsState,
  ListItem,
  ListItemIcon,
  TextField,
} from "common/components";
import { Icon } from "core/api";
import { ReactElement, useEffect, useRef, useState } from "react";
import {
  StyledCheckbox,
  StyledChip,
  StyledListItemText,
  StyledSelectContainer,
} from "./MultiSelect.styles";

export interface MultiSelectProps<T> {
  label: string;
  onChange?(
    event: React.SyntheticEvent<Element, Event>,
    value: T[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any> | undefined
  ): void;
  id?: string;
  value: T[];
  options: readonly T[];
  groupBy?(option: T): string;
  getOptionName(option: T): string;
  limitTags?: number;
  /** Function for getting the Icon for that option */
  getIcon?(option: T): Icon | undefined;
  filterOptions?(options: T[], state: FilterOptionsState<T>): T[];
}

/**
 * Multi-selection combobox based on the MUI Autocomplete component
 *
 * @param param
 * @returns
 */
export function MultiSelect<T>({
  label,
  onChange,
  id,
  value,
  options,
  groupBy,
  getOptionName,
  limitTags = 1,
  getIcon,
  filterOptions,
  ...props
}: MultiSelectProps<T>): ReactElement {
  const ref = useRef<HTMLDivElement>(null);
  const [maxChipWidth, setMaxChipWidth] = useState(0);

  useEffect(() => {
    const resetMaxChipWidth = debounce(() => {
      setMaxChipWidth(ref.current ? ref.current.offsetWidth - 50 : 0);
    }, 50);
    window.addEventListener("resize", resetMaxChipWidth);
    return () => window.removeEventListener("resize", resetMaxChipWidth);
  }, []);

  useEffect(() => {
    setMaxChipWidth(ref.current ? ref.current.offsetWidth - 50 : 0);
  }, [ref]);

  return (
    <StyledSelectContainer>
      <Autocomplete
        onChange={onChange}
        disableCloseOnSelect
        clearOnEscape
        multiple
        limitTags={limitTags}
        id={id}
        groupBy={groupBy}
        filterOptions={filterOptions}
        value={value}
        options={options}
        defaultValue={[]}
        handleHomeEndKeys
        clearOnBlur
        size="small"
        getOptionLabel={getOptionName}
        renderOption={(props, option, { selected }) => {
          const optionName = getOptionName(option);
          const icon = getIcon?.(option);

          return (
            <ListItem key={optionName} {...props}>
              <ListItemIcon>
                <StyledCheckbox checked={selected} />
              </ListItemIcon>
              {icon && (
                <StatusIcon
                  iconName={icon.name}
                  iconVariant={icon.variant}
                  sx={{
                    marginRight: 0.5,
                    display: "grid",
                    alignItems: "center",
                  }}
                />
              )}
              <StyledListItemText
                primary={optionName}
                data-cy={optionName?.replace(/ /g, "")}
                primaryTypographyProps={{ variant: "body1" }}
              />
            </ListItem>
          );
        }}
        sx={{ minWidth: 250 }}
        renderInput={(params) => (
          <TextField ref={ref} {...params} label={label} />
        )}
        renderTags={(tags, getTagProps) =>
          tags.map((option, index) => {
            return (
              <StyledChip
                maxWidth={maxChipWidth}
                variant="outlined"
                label={getOptionName(option)}
                size="small"
                {...getTagProps({ index })}
              />
            );
          })
        }
        {...props}
      />
    </StyledSelectContainer>
  );
}
