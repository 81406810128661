import {
  API,
  APIQueryResult,
  EntityType,
  getAPIQueryData,
  useAPIQuery,
} from "core/api";
import { FieldConfig } from "core/api/models/fields";

/**
 * Fetch field configuration from the server endpoint for provided entityType.
 *
 * @param entityType The type of the fields to get config for.
 *
 * @param entityTypeId (Optional) entity type id, used to get dynamic fields
 *   for entity types having such (customer, product, .. but not invoice, payment, ...)
 *
 * @param includeChildFields (Optional) If set to **true**, the fields
 *   configuration data returned by
 *   bss/api/config/entityType/<entityTypeName>/fields will include
 *   all the fields from the child entity.
 *
 * @return A list of field configs.
 *
 */
export function useFetchFieldConfig(
  entityType: EntityType | undefined,
  entityTypeId?: number,
  includeChildFields = false
): APIQueryResult<FieldConfig[]> {
  return useAPIQuery<FieldConfig[]>(
    createFieldConfigQueryKey(entityType, entityTypeId),
    API.config.entityTypeFields,
    {
      pathParams: {
        entityType: entityType ?? "",
      },
      queryParams: includeChildFields
        ? { entityTypeId, includeChildFields }
        : { entityTypeId },
    },
    {
      enabled: entityType ? entityType !== EntityType.NONE : false,
    }
  );
}

export function createFieldConfigQueryKey(
  entityType: EntityType | undefined,
  entityTypeId?: number
) {
  return ["fetchFieldConfig", entityType, entityTypeId];
}

export function getFieldConfigQueryData(
  entityType: EntityType,
  entityTypeId?: number
) {
  return getAPIQueryData<FieldConfig[]>(
    createFieldConfigQueryKey(entityType, entityTypeId)
  );
}
