import {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useEffect,
  useState,
} from "react";

export interface SortStateSettings {
  initOrderBy: string;
  setPage(page: number): void;
  initOrder?: "asc" | "desc";
  requestSortListener?(orderBy: string, ascending: boolean): void;
  onSortChange?(orderBy: string, order: "asc" | "desc"): void;
}

export function useSortState({
  initOrderBy,
  setPage,
  initOrder,
  requestSortListener,
  onSortChange,
}: SortStateSettings): {
  order: "asc" | "desc";
  setOrder: Dispatch<SetStateAction<"asc" | "desc">>;
  orderBy: string;
  setOrderBy: Dispatch<SetStateAction<string>>;
  handleRequestSort: (
    event: MouseEvent<HTMLButtonElement>,
    property: string
  ) => void;
} {
  const [order, setOrder] = useState<"asc" | "desc">(
    initOrder ? initOrder : "asc"
  );
  const [orderBy, setOrderBy] = useState(initOrderBy);

  useEffect(() => {
    if (initOrder) {
      setOrder(initOrder);
    }
  }, [initOrder]);

  useEffect(() => {
    if (initOrderBy) {
      setOrderBy(initOrderBy);
    }
  }, [initOrderBy]);

  useEffect(() => {
    onSortChange?.(orderBy, order);
  }, [order, orderBy, onSortChange]);

  const handleRequestSort = (
    _event: React.MouseEvent<HTMLButtonElement>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setPage(0);

    if (requestSortListener) {
      requestSortListener(property, !isAsc);
    }
  };

  return { order, setOrder, orderBy, setOrderBy, handleRequestSort };
}
