import { FormCheckBox, FormDateTimePicker } from "common/components";
import { addFlowStepPrefix } from "common/utils";
import { OrderAttributesFlowData, OrderAttributesFlowStep } from "core/api";
import { useTranslation } from "i18n";
import { FlowStepComponentProps } from "../models";
import { StyledStepContentWrapper } from "../steps.styles";

export type OrderAttributesProps = FlowStepComponentProps<
  OrderAttributesFlowStep,
  OrderAttributesFlowData
>;

/** Order attributes step component */
export function OrderAttributes({
  control,
  data,
  flowStep,
}: OrderAttributesProps) {
  const { t } = useTranslation(["common"]);

  const { approved, effectiveDate } = data.orderAttributes;

  return (
    <StyledStepContentWrapper>
      <FormDateTimePicker
        control={control}
        label={t("common:effectiveDate")}
        fieldName={addFlowStepPrefix(flowStep, "effectiveDate")}
        defaultValue={effectiveDate}
        required
        inputFormat="L LT"
      />
      <FormCheckBox
        control={control}
        fieldName={addFlowStepPrefix(flowStep, "approved")}
        label={t("common:approveOrders")}
        defaultValue={approved}
        disabled={!flowStep.config.allowedToApprove}
      />
    </StyledStepContentWrapper>
  );
}
