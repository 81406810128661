export default {
  changePaymentMethod: {
    currentPaymentMethod:
      "Din nuvarande betalningsmetod är {{currentPaymentMethodName}}",
    enterCreditCard: "Ange ett annat kreditkort nedan att använda.",
    changePaymentMethod: "Byt betalningsmetod",
  },
  renewProduct:
    "Det är möjligt att förnya produkten för att få ett bättre pris.",
  renewOptions: "Förnya alternativ",
} as const;
