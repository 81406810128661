/**
 * The role of a user can have the values specified by this enum.
 */
export enum UserRole {
  /**
   * Standard user
   */
  STD = "STD",
  /**
   * Admin user
   */
  ADMIN = "ADMIN",
}
