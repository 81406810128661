import { ScreenConfig } from "core/api";
import { useEffect } from "react";
import { filterNavigationScreens } from "./screenUtils";
import { isEqual, omit } from "lodash";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

export interface ScreenNavigationGroup {
  /** Navigation group title */
  title: string;
  /** Main navigation group screen */
  mainScreen?: ScreenConfig;
  /** Navigation group screens */
  screens: ScreenConfig[];
}

interface ScreenNavigationGroupsStore {
  screenNavigationGroups: Record<string, ScreenNavigationGroup[]>;
  addNavigationGroups(
    key: string,
    navigationGroup: ScreenNavigationGroup[]
  ): void;
  removeNavigationGroups(key: string): void;
}

/** Global store for screen related sidebar state */
const useScreenNavigationGroupsStore = create<ScreenNavigationGroupsStore>()(
  devtools((set) => ({
    screenNavigationGroups: {},
    addNavigationGroups: (key, navigationGroups) =>
      set((state) => {
        if (!isEqual(state.screenNavigationGroups[key], navigationGroups)) {
          return {
            ...state,
            screenNavigationGroups: {
              ...state.screenNavigationGroups,
              [key]: navigationGroups,
            },
          };
        }
        return state;
      }),
    removeNavigationGroups: (key) =>
      set((state) => {
        if (key in state.screenNavigationGroups) {
          return {
            ...state,
            screenNavigationGroups: omit(state.screenNavigationGroups, [key]),
          };
        }
        return state;
      }),
  }))
);

/**
 * Subscribes to screen navigation groups state
 * @returns Screen navigation groups
 */
export function useScreenNavigationGroups(): ScreenNavigationGroup[] {
  const screenNavigationGroups = useScreenNavigationGroupsStore(
    (store) => store.screenNavigationGroups
  );
  return Object.entries(screenNavigationGroups)
    .sort(([a], [b]) => a.length - b.length)
    .map(([, group]) => group)
    .flatMap((a) => a);
}

/**
 * Registers a screen navigation group to global store
 * @param navigationGroup Screen navigation group
 */
export function useRegisterScreenNavigationGroups(
  currScreenId?: number,
  navigationGroups?: ScreenNavigationGroup[],
  url?: string
) {
  const { addNavigationGroups, removeNavigationGroups } =
    useScreenNavigationGroupsStore();
  useEffect(() => {
    if (navigationGroups && navigationGroups.length > 0 && url) {
      addNavigationGroups(url, navigationGroups);
    }
  }, [
    url,
    navigationGroups,
    currScreenId,
    addNavigationGroups,
    removeNavigationGroups,
  ]);

  useEffect(() => {
    return () => {
      if (url) {
        removeNavigationGroups(url);
      }
    };
  }, [removeNavigationGroups, url]);
}

/**
 * Formats screen config to screen navigation group
 * @param config Screen config
 * @returns Screen navigation group if configuration is set
 */
export function formatToNavigationGroup(
  config?: ScreenConfig
): ScreenNavigationGroup | undefined {
  if (!config || !config.sidebarConfig) {
    return;
  }
  const { screenName, title } = config.sidebarConfig;
  const mainScreen: ScreenConfig | undefined = screenName
    ? {
        ...config,
        title: screenName,
        screens: [],
      }
    : undefined;
  return {
    ...config,
    mainScreen,
    title: title,
    screens: filterNavigationScreens(config.screens),
  };
}

export function formatToNavigationGroups(
  config?: ScreenConfig
): ScreenNavigationGroup[] | undefined {
  if (!config) {
    return;
  }
  const retList: ScreenNavigationGroup[] = [];
  /*Currently, only add anvigation groups from subscreens if they
    use dynamic navigation items. Otherwise, ignore */
  const formatToNavGroups = config.screens.filter(
    (screen) =>
      screen.sidebarConfig?.dynamicNavigationItemsSearchUrl &&
      screen.sidebarConfig.screenName
  );

  formatToNavGroups.forEach((c) => {
    const navGroup = formatToNavigationGroup(c);

    if (!!navGroup) {
      retList.push(navGroup);
    }
  });

  /**
   * Include navigation group from current screen only if
   *  i) It is not a dynamic navigation items group (will have been loaded previously)
   * ii) It has screens that are currently valid
   */
  const currScreenNavGroup = formatToNavigationGroup(config);
  if (
    currScreenNavGroup &&
    !(
      currScreenNavGroup?.mainScreen &&
      currScreenNavGroup.mainScreen.sidebarConfig
        ?.dynamicNavigationItemsSearchUrl &&
      currScreenNavGroup.mainScreen.sidebarConfig.screenName
    ) &&
    currScreenNavGroup.screens.length > 0
  ) {
    return [currScreenNavGroup, ...retList];
  }

  return retList;
}
