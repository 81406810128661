/**
 * The source app for a logged event.
 */
export enum AppCode {
  // The overall framework, login etc. Not an app.
  common = "common",

  /**
   * The customer care app
   */
  customercare = "customercare",

  /**
   * The printer queue app
   */
  printerqueue = "printerqueue",
}
