import { DecisionFlowData, DecisionFlowStep } from "core/api";
import { StepDataResolver } from "..";

/**
 * This data resolver makes sure that the values input by the user in the
 * form elements of the Decision UI Component are stored
 * in the flow data. If a form element does not have a value,
 * the value already stored in the flow data is returned.
 *
 * @returns An object that contains the following properties (defined in
 * DecisionFlowData):
 *
 * - selectedDecision: the ID of the Decision (Approve or Reject) selected
 *   by the user;
 */
export const decisionDataResolver: StepDataResolver<
  DecisionFlowStep,
  DecisionFlowData
> = ({ data, fieldValues }) => {
  return {
    selectedDecision: fieldValues.decision ?? data.selectedDecision,
  };
};
