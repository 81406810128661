import {
  CardActions,
  CardContent,
  Dialog,
  DialogProps,
  SxProps,
  Theme,
} from "common/components";
import { ReactElement, ReactNode } from "react";
import { StyledCardHeader } from "./CommonDialog.styles";

export interface CommonDialogProps extends DialogProps {
  /** Subheader text */
  subHeader?: string;
  /** Header avatar */
  avatar?: ReactNode;
  actions?: ReactNode;
  /** Data-cy string */
  dataCy?: string;
  /** sx for header */
  sxHeader?: SxProps<Theme>;
  /** sx for content */
  sxContent?: SxProps<Theme>;
}

/** Dialog with header avatar */
export function CommonDialog({
  title,
  subHeader,
  children,
  actions,
  avatar,
  onClose,
  open,
  dataCy,
  sxHeader,
  sxContent,
  ...props
}: CommonDialogProps): ReactElement {
  return (
    <Dialog onClose={onClose} open={open} data-cy={dataCy} {...props}>
      <StyledCardHeader
        avatar={avatar}
        title={title}
        subheader={subHeader}
        sx={sxHeader}
      />
      <CardContent sx={sxContent}>{children}</CardContent>
      {actions && <CardActions>{actions}</CardActions>}
    </Dialog>
  );
}
