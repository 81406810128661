import { CodeObject, useFetchEnums } from "core/api";
import { useCallback } from "react";

export function useCodeItems() {
  const { data: enumCodeItems } = useFetchEnums();

  const getCodeItem = useCallback(
    (className: CodeObject, id: string) => {
      if (enumCodeItems) {
        for (const codeItem of enumCodeItems[className]) {
          if (codeItem.enumValue === id || codeItem.id === id) {
            return codeItem;
          }
        }
      }
      return {
        id: id,
        enumValue: id,
        className: className,
        name: "",
        description: "",
      };
    },
    [enumCodeItems]
  );

  const getCodeName = useCallback(
    (className: CodeObject, id: string) => {
      return getCodeItem(className, id).name;
    },
    [getCodeItem]
  );

  function getCodeDescription(className: CodeObject, id: string): string {
    return getCodeItem(className, id).description;
  }

  const getCodeItems = useCallback(
    (className: CodeObject) => {
      if (enumCodeItems) {
        return enumCodeItems[className];
      }
      return [];
    },
    [enumCodeItems]
  );

  return {
    getCodeItem,
    getCodeDescription,
    getCodeName,
    getCodeItems,
  };
}
