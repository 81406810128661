import { AddCircle, Clear } from "common/assets/icons";
import { Box, IconButton, TextField, TextFieldProps } from "common/components";
import { DEFAULT_PIN_CODE_LENGTH, generatePINCode } from "common/utils";
import { useTranslation } from "i18n";
import { forwardRef } from "react";

export interface PINCodeFieldProps extends Omit<TextFieldProps, "onChange"> {
  /** Sets value on change and when generating a new GUID */
  setValue(pinCode: string): void;
  /** PIN code length */
  pinCodeLength?: number;
}

/** PIN code field with auto-generation */
export const PINCodeField = forwardRef<HTMLInputElement, PINCodeFieldProps>(
  (
    { setValue, disabled, pinCodeLength = DEFAULT_PIN_CODE_LENGTH, ...props },
    ref
  ) => {
    const { t } = useTranslation(["common"]);
    const hasValue = !!(props?.value as string)?.length;

    const generatePIN = () => {
      const pinCode = generatePINCode(pinCodeLength);
      setValue(pinCode);
    };

    return (
      <Box
        display="grid"
        gridTemplateColumns="1fr repeat(2, min-content)"
        alignItems="center"
        gap={(theme) => theme.spacing(0.2)}
      >
        <TextField
          {...props}
          ref={ref}
          disabled={disabled}
          onChange={(event) =>
            !isNaN(event.target.value as unknown as number) &&
            setValue(event.target.value)
          }
          inputProps={{
            ...props.inputProps,
            pattern: `[0-9]{${pinCodeLength}}`,
            maxLength: pinCodeLength,
          }}
          InputLabelProps={{
            required: props.required,
            ...props.InputLabelProps,
          }}
        />
        <IconButton
          size="small"
          color="primary"
          aria-label={t("common:buttons.generatePINCode")}
          onClick={generatePIN}
          disabled={disabled}
        >
          <AddCircle fontSize="small" />
        </IconButton>
        <IconButton
          size="small"
          aria-label={t("common:buttons.clearPINCode")}
          onClick={() => setValue("")}
          disabled={disabled || !hasValue}
        >
          <Clear fontSize="small" />
        </IconButton>
      </Box>
    );
  }
);
