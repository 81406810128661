import {
  FormCheckBox,
  FormControlledFieldProps,
  FormCurrencyField,
  FormDatePicker,
  FormDateTimePicker,
  FormDateTimePickerProps,
  FormGUIDField,
  FormMultipleSelect,
  FormNumberField,
  FormNumberFieldProps,
  FormOptionalFieldWrapper,
  FormPasswordField,
  FormPINCodeField,
  FormSelect,
  FormSocialSecurityNumberField,
  FormTextField,
  FormTimePicker,
  InputAdornment,
  SelectOption,
} from "common/components";
import {
  createUnitLabel,
  getCurrencySymbol,
  isPrefixSymbol,
  removeFlowStepPrefix,
} from "common/utils";
import { FieldLayoutConfig, FieldUIComponent } from "core/api";
import { Control, FieldValues } from "react-hook-form";

// Default to empty when unspecified
const EMPTY_CURRENCY_CODE = "";

export interface FormFieldProps<TValues extends FieldValues>
  extends FieldLayoutConfig {
  /** Field type */
  fieldType: FieldUIComponent;
  /** Form control */
  control: Control<TValues>;
  /** Field label */
  label: string;
  /** Field name. Renders as the 'name' property of the field. */
  fieldName: string;
  /** If the field is required */
  required?: boolean;
  /** RegEx validation pattern for text fields */
  pattern?: string;
  /** Server side generated pattern hint */
  patternHint?: string;
  /** Minimum length for text fields */
  minLength?: number;
  /** Maximum length for text fields */
  maxLength?: number;
  /** Field value placeholder */
  placeholder?: string;
  /** Default value */
  defaultValue?: string | string[] | number[];
  /** Default value of checkbox in optional fields */
  optionalDefaultValue?: string | "true" | "false";
  /** Options for select fields */
  options?: SelectOption[];
  /** Currency code. Only used for amounts. */
  currencyCode?: string;
  /** Length of PIN code. Only used for the PIN code field. */
  pinCodeLength?: number;
  /** Server error message */
  serverError?: string;
  /** Length of the social security number */
  socialSecurityNumberLength?: number;
  /** If the field should be disabled */
  disabled?: boolean;
  /** Number of rows for a TextArea  */
  rows?: number;
  /** Column span for the field  */
  columnSpan?: number;
  /** What column the field starts from  */
  columnStart?: number;
  minDate?: FormDateTimePickerProps["minDate"];
  maxDate?: FormDateTimePickerProps["maxDate"];
  evaluateOnChange?: boolean;
  shouldDisableDate?: FormDateTimePickerProps["shouldDisableDate"];
  shouldDisableTime?: FormDateTimePickerProps["shouldDisableTime"];

  /** The Billiant core UnitType enum value */
  unitType?: string;
}

export function getOptionalFieldName(fieldName: string): string {
  return "include_" + fieldName;
}

/**
 * Form field component which renders a form controlled field based
 * on field type.
 * @param FormFieldProps
 * @returns Form field
 */
export function FormField<TValues extends FieldValues>({
  fieldType,
  fieldName,
  label,
  control,
  required = false,
  pattern,
  patternHint,
  minLength,
  maxLength,
  socialSecurityNumberLength,
  placeholder,
  defaultValue,
  optionalDefaultValue,
  options = [],
  currencyCode,
  pinCodeLength,
  serverError,
  disabled,
  columnSpan = 1,
  columnStart,
  dependsOn,
  minDate,
  maxDate,
  rows,
  evaluateOnChange,
  shouldDisableDate,
  shouldDisableTime,
  unitType,
}: FormFieldProps<TValues>): JSX.Element | null {
  const DEFAULT_ROWS = 1;
  const fieldProps: FormControlledFieldProps = {
    fieldName,
    control,
    label,
    serverError,
    socialSecurityNumberLength,
    disabled,
    required,
    pattern,
    patternHint,
    minLength,
    maxLength,
    columnSpan,
    columnStart,
    dependsOn,
    evaluateOnChange,
    "data-cy": removeFlowStepPrefix(fieldName),
  };

  switch (fieldType) {
    case "Text":
    case "TextArea":
      return (
        <FormTextField
          placeholder={placeholder}
          defaultValue={defaultValue}
          multiline={fieldType === "TextArea"}
          rows={rows ?? DEFAULT_ROWS}
          {...fieldProps}
        />
      );
    case "SocialSecurityNumber":
      const updatedFieldProps = {
        ...fieldProps,
        evaluateOnChange: true,
      };
      return (
        <FormSocialSecurityNumberField
          defaultValue={defaultValue}
          control={control}
          {...updatedFieldProps}
        />
      );

    case "Password":
      return (
        <FormPasswordField
          autoComplete="new-password" // To prevent autocompletion, see https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion
          placeholder={placeholder}
          defaultValue={defaultValue}
          {...fieldProps}
        />
      );
    case "Amount":
      return (
        <FormCurrencyField
          defaultValue={defaultValue as string}
          currencyCode={currencyCode || EMPTY_CURRENCY_CODE}
          {...getPrefixSuffixProps(currencyCode)}
          {...fieldProps}
        />
      );
    case "Number":
    case "Percent": {
      const unitLabel = unitType && createUnitLabel(unitType);

      const inputProps = unitLabel
        ? {
            endAdornment: (
              <InputAdornment position="end">{unitLabel}</InputAdornment>
            ),
          }
        : {};

      return (
        <FormNumberField
          defaultValue={defaultValue as string}
          suffix={fieldType === "Percent" ? "%" : undefined}
          {...fieldProps}
          InputProps={inputProps}
        />
      );
    }
    case "NetworkElement":
    case "Select":
      return (
        <FormSelect
          dataList={options}
          includeEmptyOption={!fieldProps.required}
          defaultValue={defaultValue}
          {...fieldProps}
        />
      );
    case "List":
      return (
        <FormMultipleSelect
          dataList={options}
          defaultValue={defaultValue}
          {...fieldProps}
        />
      );
    case "Toggle":
      return (
        <FormCheckBox defaultValue={defaultValue === "true"} {...fieldProps} />
      );
    case "Date":
      return (
        <FormDatePicker
          defaultValue={defaultValue ?? ""}
          inputFormat="L"
          {...fieldProps}
        />
      );
    case "Time":
      return (
        <FormTimePicker
          defaultValue={defaultValue as string}
          inputFormat="LTS"
          {...fieldProps}
        />
      );
    case "DateTime":
      return (
        <FormDateTimePicker
          defaultValue={defaultValue ?? ""}
          inputFormat="L LTS"
          minDate={minDate}
          maxDate={maxDate}
          shouldDisableDate={shouldDisableDate}
          shouldDisableTime={shouldDisableTime}
          {...fieldProps}
        />
      );
    case "GUID":
      return <FormGUIDField defaultValue={defaultValue} {...fieldProps} />;
    case "PINCode":
      return (
        <FormPINCodeField
          defaultValue={defaultValue}
          pinCodeLength={pinCodeLength}
          {...fieldProps}
        />
      );
    case "OptionalSelect":
      return (
        <FormOptionalFieldWrapper
          control={control}
          fieldName={getOptionalFieldName(fieldName)}
          defaultChecked={optionalDefaultValue === "true"}
          renderField={(checked, _disabled) => (
            <FormSelect
              dataList={options}
              includeEmptyOption
              defaultValue={defaultValue}
              {...fieldProps}
              disabled={!checked || _disabled}
            />
          )}
        />
      );
    case "OptionalDate":
      return (
        <FormOptionalFieldWrapper
          control={control}
          fieldName={getOptionalFieldName(fieldName)}
          defaultChecked={optionalDefaultValue === "true"}
          renderField={(checked, _disabled) => (
            <FormDatePicker
              defaultValue={defaultValue as string}
              inputFormat="L"
              {...fieldProps}
              disabled={!checked || _disabled}
            />
          )}
        />
      );
    case "OptionalText":
      return (
        <FormOptionalFieldWrapper
          control={control}
          fieldName={getOptionalFieldName(fieldName)}
          defaultChecked={optionalDefaultValue === "true"}
          renderField={(checked, _disabled) => (
            <FormTextField
              placeholder={placeholder}
              defaultValue={defaultValue}
              {...fieldProps}
              disabled={!checked || _disabled}
              required={checked}
            />
          )}
        />
      );
    case "OptionalAmount":
      return (
        <FormOptionalFieldWrapper
          control={control}
          fieldName={getOptionalFieldName(fieldName)}
          defaultChecked={optionalDefaultValue === "true"}
          renderField={(checked, _disabled) => (
            <FormNumberField
              defaultValue={defaultValue as string}
              decimalScale={2}
              fixedDecimalScale
              {...fieldProps}
              disabled={!checked || _disabled}
              {...getPrefixSuffixProps(currencyCode)}
            />
          )}
        />
      );
    default:
      return null;
  }
}

function getPrefixSuffixProps(
  currencyCode: string | undefined
): Pick<FormNumberFieldProps, "prefix" | "suffix"> | undefined {
  if (!currencyCode) {
    return undefined;
  }
  const symbol = getCurrencySymbol(currencyCode);
  const isPrefix = isPrefixSymbol(currencyCode);
  return {
    prefix: isPrefix ? symbol : undefined,
    suffix: !isPrefix ? symbol : undefined,
  };
}
