import { isEqual } from "lodash";
import { useCallback, useEffect } from "react";
import { Mutate, State, StateCreator, StoreApi, create } from "zustand";
import {
  StateStorage,
  createJSONStorage,
  devtools,
  persist,
} from "zustand/middleware";

export type TDataFunction<TData> = (currentState: TData) => TData;

export interface Store<TData> {
  /** Current store state */
  state: TData;
  /**
   * Update store state
   * @param newState New state or callback function to create new state
   */
  updateState(newState: TData | TDataFunction<TData>): void;
  /** Reset store state */
  resetStore(): void;
}

export interface StoreSettings<TData> {
  /** Local/Session storage key. Persists data if provided. */
  name?: string;
  /** Storage object. Defaults to localStorage. */
  storage?: StateStorage;
  /** Initial store state */
  initialState: TData;
}

type StoreStateCreator<TData> = StateCreator<
  Store<TData>,
  [],
  [],
  Store<TData>
>;

type StoreWithPersist = Mutate<StoreApi<State>, [["zustand/persist", unknown]]>;

/**
 * Registers rehydration of store state on storage event
 * @param store Store with persist middleware
 * @returns Event listener cleanup function
 */
export const withStorageDOMEvents = (store: StoreWithPersist) => {
  const storageEventCallback = (e: StorageEvent) => {
    if (e.key === store.persist.getOptions().name && e.newValue) {
      store.persist.rehydrate();
    }
  };

  window.addEventListener("storage", storageEventCallback);

  return () => {
    window.removeEventListener("storage", storageEventCallback);
  };
};

/**
 * Internal constructor function for creating a simple store with, or without, persist middleware
 * @param settings Store settings
 * @returns Store
 */
const storeConstructor = <TData>({
  name,
  storage = localStorage,
  initialState,
}: StoreSettings<TData>) => {
  const storeSettings: StoreStateCreator<TData> = (set) => ({
    state: initialState,
    updateState: (newState) => {
      set((store: Store<TData>) => {
        const _newState =
          newState instanceof Function ? newState(store.state) : newState;
        if (!isEqual(store.state, _newState)) {
          return { ...store, state: _newState };
        }
        return store;
      });
    },
    resetStore: () => {
      set((store) => ({ ...store, state: initialState }));
    },
  });
  if (name) {
    return create<Store<TData>>()(
      devtools(
        persist(storeSettings, {
          name,
          storage: createJSONStorage(() => storage),
          partialize: (state) => state.state,
          merge: (persistedState, currentState) => ({
            ...currentState,
            state: (persistedState as TData) ?? currentState.state,
          }),
        })
      )
    );
  }
  return create<Store<TData>>()(devtools(storeSettings));
};

/**
 * Creates a simple store with, or without, persist middleware.
 * Registers storage event handlers for rehydration of state.
 *
 * ```
 * const useMyStore = createStore({ name: "storageKey", initialValue: -1 });
 *
 * function MyComponent() {
 *   const { state, updateState } = useMyStore();
 *
 *   return null;
 * }
 * ```
 *
 * @param settings Store settings
 * @returns Store
 */
export const createStore: typeof storeConstructor = (settings) => {
  const useStore = storeConstructor(settings);
  if ("persist" in useStore) {
    withStorageDOMEvents(useStore as StoreWithPersist);
  }
  return useStore;
};

type RecordStoreSettings<TData> = Omit<StoreSettings<TData>, "initialState">;

/**
 * Creates a record store with, or without, persist middleware.
 * Registers storage event handlers for rehydration of state.
 *
 * Use a record store when storing data for more than one instance
 * based on a key.
 *
 * ```
 * const useMyStore = createRecordStore({ name: "storageKey" });
 *
 * function MyComponent() {
 *   const { state, updateState } = useMyStore(key, {});
 *
 *   return null;
 * }
 * ```
 *
 * @param settings Record Store settings
 * @returns Record Store
 */
export function createRecordStore<TData>(settings: RecordStoreSettings<TData>) {
  const useStore = createStore<Record<string, TData>>({
    ...settings,
    initialState: {},
  });

  return (key: string | undefined, initialState: TData) => {
    const { state, updateState: updateRecord } = useStore(
      ({ state, updateState }) => ({
        state: key ? state[key] : undefined,
        updateState,
      })
    );

    // Registers initial state if key is not present in record state.
    useEffect(() => {
      if (key) {
        updateRecord((state) => {
          if (!(key in state)) {
            return {
              ...state,
              [key]: initialState,
            };
          }
          return state;
        });
      }
    }, [key, initialState, updateRecord]);

    /**
     * Updates record state
     * @param newState New state or callback function to create new state
     */
    const updateState = useCallback(
      (newState: TData | TDataFunction<TData>) => {
        if (key) {
          updateRecord((state) => {
            const _newState =
              newState instanceof Function ? newState(state[key]) : newState;
            if (!isEqual(_newState, state[key])) {
              return {
                ...state,
                [key]: _newState,
              };
            }
            return state;
          });
        }
      },
      [updateRecord, key]
    );

    /* 
      Returns initial state if state is undefined or null to ensure
      a valid state is returned before the initial state is updated
      in the useEffect handler.
    */
    return { state: state ?? initialState, updateState };
  };
}
