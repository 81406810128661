import { URLS, useNavigate } from "common/routing";
import { useCallback } from "react";
import { StorageKey } from "../../appSettings/keys";

/**
 * The logged out url is the url that you
 * redirects to when pressing the go to login page
 * @returns A path
 */
export function getLoggedOutUrl(): string {
  const url = localStorage.getItem(StorageKey.LOGGED_OUT_URL_KEY);
  const idProvider = localStorage.getItem(StorageKey.PREDEFINED_ID_PROVIDER);
  const loginUrl = url || URLS.login.path;
  return idProvider ? `${loginUrl}?idProvider=${idProvider}` : loginUrl;
}

/**
 * @param url The url
 * @returns true if url starts with http/https; otherwise false
 */
export function isExternalUrl(url: string): boolean {
  return url.startsWith("http") || url.startsWith("https");
}

/**
 * Set the logged out URL
 * @param logoutURL The logged out URL
 */
export function setLoggedOutURL(loggedURL: string) {
  localStorage.setItem(StorageKey.LOGGED_OUT_URL_KEY, loggedURL);
}

/**
 * @returns the redirect url
 */
export function getRedirectUrl(): string | null {
  return localStorage.getItem(StorageKey.REDIRECT_URL_KEY);
}

/**
 * See the redirect url
 * @param url The redirect url
 */
export function setRedirectURL(url?: string) {
  const redirectURL = url ?? window.location.pathname;
  localStorage.setItem(StorageKey.REDIRECT_URL_KEY, redirectURL);
}

/**
 * use redirect hook
 * @returns redirect functions
 */
export function useRedirect() {
  const navigate = useNavigate();

  const navigateToScreen = () => {
    const redirectURL = getRedirectUrl();
    if (redirectURL) {
      localStorage.removeItem(StorageKey.REDIRECT_URL_KEY);
    }
    navigate(redirectURL ?? URLS.home.path, { replace: true });
  };

  const redirectToLoginPage = useCallback(() => {
    if (window.location.pathname !== URLS.login.path) {
      setRedirectURL();

      navigate(URLS.login.path, { replace: true });
    }
  }, [navigate, setRedirectURL]);

  const redirectToLogoutPage = useCallback(() => {
    navigate(URLS.logout.path, { replace: true });
  }, [navigate]);

  return {
    navigateToScreen,
    redirectToLoginPage,
    redirectToLogoutPage,
  };
}
