import { Box, styled } from "common/components";
import { Document } from "react-pdf";

export const StyledViewerContainer = styled(Box)(({ theme }) => ({
  overflow: "hidden",
  maxWidth: "100%",
  height: "100%",
  display: "grid",
}));

export const StyledDocument = styled(Document)(({ theme }) => ({
  top: "65px",
  overflow: "scroll",
  width: "100%",
  display: "grid",
  gap: theme.spacing(2),
  justifyItems: "center",
  padding: theme.spacing(2, 0),
  backgroundColor: theme.palette.grey[50],
}));
