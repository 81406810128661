import { Box, Divider, FormSelect } from "common/components";
import { addFlowStepPrefix } from "common/utils";
import { SelectStepFlowData, SelectStepFlowStep } from "core/api";
import { useTranslation } from "i18n";
import { useEffect } from "react";
import { useController } from "react-hook-form";
import { EditGeneral, IdType } from "../../../../components";
import { FlowStepComponentProps } from "../models";
import { StyledStepContentWrapper } from "../steps.styles";
import { getStepComponent } from "../stepUtils";

export type SelectStepProps = FlowStepComponentProps<
  SelectStepFlowStep,
  SelectStepFlowData
>;

/** SelectStep step component */
export function SelectStep({
  control,
  flow,
  data,
  flowStep,
  setValue,
  setCurrentSubStepId,
  ...props
}: SelectStepProps) {
  const selectedStepIdKey = addFlowStepPrefix(flowStep, "selectedStepId");
  const { field } = useController({
    control,
    name: selectedStepIdKey,
    defaultValue: data.selectedStepId,
  });

  const { t } = useTranslation(["common"]);
  const {
    steps = [],
    addEmptySelection,
    sortInAlphabeticalOrder,
  } = flowStep.config;

  const selectedStep = steps.find((step) => field.value === step.id);

  const StepComponent = getStepComponent(selectedStep?.uiComponent);

  useEffect(() => {
    setCurrentSubStepId?.(field.value);
  }, [field.value, setCurrentSubStepId]);

  const dataList = addEmptySelection
    ? [
        { label: t("common:noValueSelected"), value: "-1" },
        ...steps.map(({ name, id }) => ({ label: name, value: id })),
      ]
    : steps.map(({ name, id }) => ({ label: name, value: id }));

  if (sortInAlphabeticalOrder) {
    dataList.sort((a, b) => a.label.localeCompare(b.label));
  }

  return (
    <StyledStepContentWrapper
      dialogSize={flow.config.dialogSize}
      sx={{ padding: 0 }}
    >
      <EditGeneral idType={IdType.Step} id={selectedStep?.stepId} />
      <FormSelect
        control={control}
        fieldName={selectedStepIdKey}
        dataList={dataList}
      />
      <Divider />
      <Box sx={{ overflow: "auto" }}>
        {selectedStep && (
          <StepComponent
            control={control}
            flow={flow}
            data={flow.data[selectedStep.id]}
            flowStep={selectedStep}
            setValue={setValue}
            {...props}
          />
        )}
      </Box>
    </StyledStepContentWrapper>
  );
}
