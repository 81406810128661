import { SxProps, Theme } from "common/components";
import { ActionButtonsConfig, EntityData, EntityType, Widget } from "core/api";
import { GridItem } from "core/dashboard";
import {
  LazyExoticComponent,
  Suspense,
  SuspenseProps,
  createContext,
  useContext,
  useState,
} from "react";
import { ViewContextState, useViewContext } from "../DynamicView";
import { LoadingView } from "../DynamicView/LoadingView";
import { LayoutContextProvider } from "../LayoutContext";

export interface WidgetContextState extends ViewContextState {
  /** Widget title */
  title: string | undefined;
  /** Widget description */
  description?: string;
  /** Widget name */
  name?: string;
  /** Widget id */
  widgetId: Widget["widgetId"];
  /** Widget type */
  widgetType: Widget["widgetType"];
  /** Widget grid item config */
  gridItem: GridItem | undefined;
  /** Entity type */
  entityType?: EntityType;
  /** Entity type id */
  entityTypeId?: number;
  /** Boolean for disabling widget border and background */
  transparent?: boolean;
  subWidgets?: Widget[];
  collapsible: boolean;
  /** Boolean flag indicating whether the container widget is collapsed. */
  containerWidgetCollapsed?: boolean;
  isContainerWidget?: boolean;
  /** Grid properties from the parent ContainerWidget, if applicable */
  containerGrid?: GridItem;
  updateContext: (actionsConfig: ActionButtonsConfig | undefined) => void;
}

export const WidgetContext = createContext<WidgetContextState | null>(null);

/** Use widget context state */
export function useWidgetContext() {
  const context = useContext(WidgetContext);

  if (!context) {
    console.error("WidgetContext needs to be used within a Provider");
  }
  return context as WidgetContextState;
}

export interface DynamicWidgetProps {
  /** Widget definition from the endpoint */
  widgetDef: Widget;
  screenEntity?: EntityData;
  gridItem?: GridItem;
  /** Widget description */
  description?: string;
  /** Widget name */
  name?: string;
  /** Entity type id */
  entityTypeId?: number;
  /** Boolean for disabling widget border and background */
  transparent?: boolean;
  /** Style properties */
  sx?: SxProps<Theme>;
  importWidget(widgetType: string): LazyExoticComponent<any>;
  fallback?: SuspenseProps["fallback"];
  /** If the title should be displayed */
  showWidgetTitle?: boolean;
  containerWidgetCollapsed?: boolean;
  /** Grid properties from the parent ContainerWidget, if applicable */
  containerGrid?: GridItem;
}

/** Dynamically loads a widget component */
export function DynamicWidget({
  widgetDef,
  gridItem,
  transparent,
  sx,
  entityTypeId,
  showWidgetTitle,
  importWidget,
  fallback,
  containerWidgetCollapsed,
  containerGrid,
}: DynamicWidgetProps) {
  const viewContext = useViewContext();
  const Widget = importWidget(widgetDef.widgetType);
  const showTitle = showWidgetTitle ?? widgetDef.config.showTitle;

  const [actionsConfig, setActionsConfig] = useState(widgetDef.actionsConfig);

  const isContainerWidget = widgetDef.widgetType === "Container";

  const updateContext = (actionsConfig: ActionButtonsConfig | undefined) => {
    if (actionsConfig) {
      setActionsConfig(actionsConfig);
    }
  };

  return (
    <WidgetContext.Provider
      value={{
        ...viewContext,
        title: showTitle ? widgetDef.name : "",
        description: widgetDef.description,
        name: widgetDef.name,
        widgetId: widgetDef.widgetId,
        widgetType: widgetDef.widgetType,
        actionsConfig,
        subWidgets: widgetDef.subWidgets,
        gridItem,
        transparent,
        entityType: widgetDef.entityType,
        entityTypeId,
        collapsible: widgetDef.config.collapsible,
        containerWidgetCollapsed,
        isContainerWidget,
        containerGrid,
        updateContext,
      }}
    >
      <LayoutContextProvider
        layoutId={`widget_${widgetDef.widgetId}`}
        grid={gridItem ?? containerGrid}
        collapsible={containerWidgetCollapsed ?? widgetDef.config.collapsible}
        hideDisabledActions={widgetDef.config.hideDisabledActions}
        showTitle={showTitle}
      >
        <Suspense fallback={fallback ?? <LoadingView />}>
          <Widget
            {...widgetDef.config}
            title={showTitle ? widgetDef.name : ""}
            sx={sx}
          />
        </Suspense>
      </LayoutContextProvider>
    </WidgetContext.Provider>
  );
}
