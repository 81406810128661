import { useFetchView } from "core/api";
import { ReactElement } from "react";
import { FooterLoader } from "./FooterLoader";

export interface FooterViewProps {
  /** The id for the footer view from web_views table */
  viewId: number;
}

export function FooterView({ viewId }: FooterViewProps): ReactElement {
  const { data } = useFetchView(viewId);

  return (
    data?.config && (
      <FooterLoader
        uiComponent={data?.config?.uiComponent}
        config={data?.config?.footerConfig}
      />
    )
  );
}
