import { Backdrop, CircularProgress } from "common/components";
import {
  EntityType,
  FlowVariant,
  useCancelActionFlow,
  useFetchActionFlow,
} from "core/api";
import { useScreenContext } from "core/components";
import { LoggingErrorBoundary } from "error/components";
import { lazy, ReactNode, Suspense } from "react";
import { ActionDialogProps } from "../models";
import { useActionDialog } from "./useActionDialog";

const StepperDialog = lazy(() => import("./StepperDialog/StepperDialog"));
const DetailedStepperDialog = lazy(
  () => import("./DetailedStepperDialog/DetailedStepperDialog")
);

/**
 * Action dialog that is the main entry point for action flows.
 *
 * @remarks
 * This entry point decides if to load a stepper or a detailed stepper dialog.
 *
 * @returns An instance of the <ActionDialog> React component
 */
export function ActionDialog(): JSX.Element | null {
  const { state, closeDialog } = useActionDialog();
  const { screenConfig } = useScreenContext();
  const { entityType, entityId, actionId, viewId, widgetId, invokedFromTable } =
    state ?? {};

  const dialogProps: ActionDialogProps = {
    actionId: actionId ?? -1,
    entityType: entityType as EntityType,
    entityId: entityId ?? "-1",
    screenId: screenConfig?.screenId ?? -1,
    viewId: viewId,
    widgetId: widgetId,
    invokedFromTable: invokedFromTable ?? false,
    closeDialog,
  };

  const { data, fetchStatus, isLoading, isError } = useFetchActionFlow({
    actionId: dialogProps.actionId,
    entityType: dialogProps.entityType,
    entityId: dialogProps.entityId,
    params: {
      screenId: dialogProps.screenId,
      viewId: dialogProps.viewId,
      widgetId: dialogProps.widgetId,
      invokedFromTable: dialogProps.invokedFromTable,
    },
  });

  const { mutate: cancel } = useCancelActionFlow({ actionId: actionId ?? -1 });

  const onUIError = () => {
    if (data) {
      cancel(data);
    }
    closeDialog();
  };

  if (fetchStatus === "fetching" && isLoading) {
    return <ActionDialogBackdrop />;
  }

  if (isError) {
    closeDialog();
  }

  switch (data?.config.variant) {
    case FlowVariant.STEPPER:
      return (
        <DialogWrapper onUIError={onUIError}>
          <StepperDialog {...dialogProps} />
        </DialogWrapper>
      );
    case FlowVariant.DETAILED_STEPPER:
      return (
        <DialogWrapper onUIError={onUIError}>
          <DetailedStepperDialog {...dialogProps} />
        </DialogWrapper>
      );
    default:
      return null;
  }
}

interface DialogWrapperProps {
  children: ReactNode;
  onUIError(): void;
}

function DialogWrapper({ children, onUIError }: Readonly<DialogWrapperProps>) {
  return (
    <LoggingErrorBoundary onError={onUIError}>
      <Suspense fallback={<ActionDialogBackdrop />}>{children}</Suspense>
    </LoggingErrorBoundary>
  );
}

function ActionDialogBackdrop() {
  return (
    <Backdrop
      open
      mountOnEnter
      unmountOnExit
      sx={{
        color: (theme) => theme.palette.common.white,
        zIndex: (theme) => theme.zIndex.modal,
      }}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
