import { API, EntityAlert, EntityType, useAPIQuery } from "core/api";

/**
 * Fetch Alerts for a given entity
 * @param entityType
 * @param entityId
 * @returns
 */
export function useFetchEntityAlerts(entityType: EntityType, entityId: string) {
  return useAPIQuery<EntityAlert[]>(
    ["alerts", entityId, entityType],
    API.alertService.entityAlerts,
    {
      pathParams: {
        entityType: entityType,
        entityId: entityId,
      },
    },
    { enabled: entityId !== "-1" }
  );
}
