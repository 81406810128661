import { Flow } from "../actions";

export enum NotificationDirection {
  FROM_ME = "FROM_ME",
  TO_ME = "TO_ME",
}

export interface Notification {
  direction: NotificationDirection;
  to: string;
  from: string;
  title: string;
  tags: string[];
  subject: string;
  issuedAt: string;
  icon: string;
  flow: Flow;
  url?: string;
}
