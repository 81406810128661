import { getField } from "common/utils";
import { FieldVariant, setCurrentUserData } from "core/api";
import { StorageKey } from "core/appSettings";
import {
  AuthenticationSettings,
  OnAuthenticatedFunction,
  SelfcareUserResponse,
} from "core/auth";
import { changeLanguage } from "i18n/init";

export const onAuthenticated: OnAuthenticatedFunction<SelfcareUserResponse> = (
  user,
  withDefaultCustomerGroup,
  dispatch
) => {
  const localStorageLanguage = localStorage.getItem(
    StorageKey.SELFCARE_LANGUAGE
  );
  const environmentLanguage = process.env.REACT_APP_SELFCARE_LANGUAGE;
  const userLanguage = getField(
    { key: "Language_Code", variant: FieldVariant.PIGGYBACK },
    user.user.fields ?? []
  )?.values[0]?.value;

  const selectedLanguage =
    localStorageLanguage || userLanguage || environmentLanguage || "en";
  changeLanguage(selectedLanguage);

  setCurrentUserData(user.user);
  dispatch();
};

export const authSettings: AuthenticationSettings<SelfcareUserResponse> = {
  onAuthenticated,
};
