import { Box, Typography } from "common/components";
import { useViewContext } from "core/components";
import { ReactNode } from "react";

export interface ViewContainerProps {
  children: ReactNode;
  showTitle: boolean;
}

export function ViewContainer({ children, showTitle }: ViewContainerProps) {
  const { title } = useViewContext();
  return (
    <Box display="grid" gap={2} paddingY={2} marginY={2} marginX={2}>
      {title && showTitle && (
        <Typography variant="h3" component="h2">
          {title}
        </Typography>
      )}
      <div>{children}</div>
    </Box>
  );
}
