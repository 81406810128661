import { Description } from "common/assets/icons";
import {
  Box,
  BoxProps,
  CircularProgress,
  Paper,
  Typography,
  useTheme,
} from "common/components";
import { useContainerSize } from "common/hooks";
import { useAPIQuery } from "core/api";
import { useTranslation } from "i18n";
import { useState } from "react";
/*
  Import from "react-pdf/dist/esm/entry.webpack" instead when
  issues in Create React App 5 related to this are fixed:
  https://github.com/wojtekmaj/react-pdf/issues/912
*/
import { DocumentProps, Page, PageProps, pdfjs } from "react-pdf";
import { PDFToolbar } from "./PDFToolbar/PDFToolbar";
import { StyledDocument, StyledViewerContainer } from "./PDFViewer.styles";
import { usePageScroll } from "./usePageScroll";

// Remove this after updated import
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

/**
 * Creates a list of page numbers
 * @param pageCount Number of pages
 * @returns A list of page numbers
 */
function getPageNumbers(pageCount: number) {
  return Array.from(new Array(pageCount), (_, index) => index + 1);
}

/**
 * Queries for pdf file
 * @param pdfUrl Url to pdf file
 * @returns Query response with downloaded file and filename
 */
export function usePDFFile(pdfUrl: string) {
  return useAPIQuery(["pdf", pdfUrl], pdfUrl, {}, { isDownload: true });
}

export interface PDFViewerProps {
  /** PDF file */
  file: Blob | undefined;
  /** File name */
  fileName?: string | undefined;
  /** If file name should be displayed in toolbar */
  showFileName?: boolean;
  /** If the file is not available yet */
  loading?: boolean;
  /** Message when file could not be loaded */
  errorMessage?: string;
  /** Viewer display variant */
  variant?: "all" | "paginated";
  /** Maximum view height - used for correct scrolling behavior */
  maxViewHeight?: string;
}

/** PDF viewer with display variants and scaling */
export function PDFViewer({
  file,
  fileName,
  showFileName,
  variant,
  loading,
  errorMessage,
  maxViewHeight = "max(calc(100vh - 180px), 900px)",
}: PDFViewerProps) {
  const { t } = useTranslation(["common"]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [scale, setScale] = useState(1);
  const { ref, width } = useContainerSize();
  const { pagesContainerRef, scrollToPage } = usePageScroll({
    activeObserver: variant === "all",
    pageNumberAttribute: "data-page-number",
    pageCount,
    setCurrentPage,
  });

  const onDocumentLoadSuccess: DocumentProps["onLoadSuccess"] = ({
    numPages: nextPageCount,
  }) => {
    setPageCount(nextPageCount);
  };

  const onPageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    if (variant === "all") {
      scrollToPage(pageNumber);
    }
  };

  const nextPage = () => {
    if (currentPage < pageCount) {
      onPageChange(currentPage + 1);
    }
  };

  const previousPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  return (
    <StyledViewerContainer ref={ref} height={maxViewHeight}>
      {loading ? (
        <LoadingPDF message={t("common:downloadingDocument")} />
      ) : (
        <>
          <PDFToolbar
            file={file}
            fileName={fileName}
            currentPage={currentPage}
            nextPage={nextPage}
            previousPage={previousPage}
            onPageChange={onPageChange}
            scale={scale}
            setScale={setScale}
            pageCount={pageCount}
            showFileName={showFileName}
            sx={{
              top: 0,
              zIndex: 1,
              width: "100%",
            }}
          />
          <StyledDocument
            inputRef={pagesContainerRef}
            file={file}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={<LoadingPDF message={t("common:loadingDocument")} />}
            noData={<NoAvailablePDF errorMessage={errorMessage} />}
          >
            {variant === "paginated" ? (
              <PDFPage pageNumber={currentPage} width={width} scale={scale} />
            ) : (
              getPageNumbers(pageCount).map((pageNumber) => (
                <PDFPage
                  key={pageNumber}
                  pageNumber={pageNumber}
                  width={width || undefined}
                  scale={scale}
                />
              ))
            )}
          </StyledDocument>
        </>
      )}
    </StyledViewerContainer>
  );
}

function PDFPage({ width = 0, ...props }: PageProps) {
  const theme = useTheme();
  return (
    <Paper elevation={2} data-page-number={props.pageNumber}>
      <Page
        width={
          Math.min(Math.max(width - parseInt(theme.spacing(2)), 0), 1e3) ||
          undefined
        }
        {...props}
      />
    </Paper>
  );
}

function EmptyStateContainer(props: BoxProps) {
  return (
    <Box
      display="grid"
      gap={(theme) => theme.spacing(2)}
      alignContent="center"
      justifyItems="center"
      minHeight={300}
      {...props}
    />
  );
}

interface LoadingPDFProps {
  message: string;
}

function LoadingPDF({ message }: LoadingPDFProps) {
  return (
    <EmptyStateContainer>
      <CircularProgress />
      <Typography>{message}</Typography>
    </EmptyStateContainer>
  );
}

interface NoAvailablePDFProps {
  errorMessage?: string;
}

function NoAvailablePDF({ errorMessage }: NoAvailablePDFProps) {
  const { t } = useTranslation(["common"]);
  return (
    <EmptyStateContainer>
      <Description sx={{ fontSize: "4rem" }} color="disabled" aria-hidden />
      <Typography variant="body2" textAlign="center" maxWidth={300}>
        {errorMessage ?? t("common:noDocumentAvailable")}
      </Typography>
    </EmptyStateContainer>
  );
}
