import { isEqual } from "lodash";

/**
 * Find index of object in a given array
 * @param an Object to find index of
 * @param array Array to search
 * @returns -1 if a not in array, positive integer otherwise
 */
export function indexOf<T>(a: T, array: T[]): number {
  return customIndexOf(a, array, isEqual);
}

export function customIndexOf<T>(
  a: T,
  array: T[] | undefined,
  equal: (a: T, b: T) => boolean
) {
  if (array) {
    return array.findIndex((t) => equal(a, t));
  }
  return -1;
}

type ExistingValue =
  | string
  | number
  | boolean
  | Date
  | Array<any>
  | Set<any>
  | Map<any, any>
  | Record<string, any>;

/**
 * Removes null and undefined values from list
 * @param values Value list to filter
 * @returns Filtered value list
 */
export function filterExistingValues<TValue extends ExistingValue>(
  values: Array<TValue | undefined | null>
): TValue[] {
  const isExisting = (value: TValue | undefined | null): value is TValue =>
    value !== undefined && value !== null;
  return values.filter(isExisting);
}
