import {
  Product,
  Subscription,
  useFetchSubscriptionsStatusReasons,
} from "core/api";
import { useCallback } from "react";

export function useProductStatus() {
  const { data: subscriptionsStatusReasons } =
    useFetchSubscriptionsStatusReasons();

  const findSubscriptionStatusReasonText = useCallback(
    (statusId: number) => {
      if (!subscriptionsStatusReasons) {
        return "";
      }
      const statusReason = subscriptionsStatusReasons.find(
        ({ statusReasonId }) => statusReasonId === statusId
      );
      return statusReason?.name ?? "";
    },
    [subscriptionsStatusReasons]
  );

  const getSubscriptionStatus = useCallback(
    (product: Product | Subscription) => {
      return {
        status: product?.status,
        reasonText: findSubscriptionStatusReasonText(product.statusReasonId),
      };
    },
    [findSubscriptionStatusReasonText]
  );

  return { getSubscriptionStatus };
}
