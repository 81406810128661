import {
  API,
  createApiUrl,
  InfiniteAPIQueryContext,
  InfiniteAPIQueryResult,
  invalidateAPIQuery,
  useInfiniteAPIQuery,
} from "core/api";
import { RequestInitWithParams } from "core/api/interfaces/RequestInitWithParams";
import { Invoice, Page } from "core/api/models";
import { Cache } from "core/idb";

export interface FindUserInvoicesOptions {
  /** Directive for useQuery. */
  enabled?: boolean;
  /** Optional paging parameter */
  pageNo?: number;
  /** Optional paging parameter */
  pageSize?: number;
}

/**
 *
 * @param Hook to find invoices and credit notes for selfcare.
 * @returns
 */
export function useFindUserInvoices({
  enabled = true,
  ...options
}: FindUserInvoicesOptions): InfiniteAPIQueryResult<Page<Invoice>> {
  function requestInitFun(
    context: InfiniteAPIQueryContext<FindUserInvoicesOptions>
  ): RequestInitWithParams {
    return {
      queryParams: {
        pageNo: context.pageParam
          ? context.pageParam?.pageNo
          : options.pageNo ?? 0,
        pageSize: options.pageSize,
      },
    };
  }

  function nextPage(
    lastPage: FindUserInvoicesOptions,
    allPages: FindUserInvoicesOptions[]
  ) {
    const defObj = lastPage ? lastPage : options;
    return {
      pageNo: typeof defObj.pageNo === "number" ? defObj.pageNo + 1 : undefined,
    };
  }

  function previousPage(
    lastPage: FindUserInvoicesOptions,
    allPages: FindUserInvoicesOptions[]
  ) {
    if (lastPage.pageNo && lastPage.pageNo > 1) {
      return {
        pageNo: lastPage.pageNo && lastPage.pageNo - 1,
      };
    }

    return undefined;
  }

  return useInfiniteAPIQuery<Page<Invoice>, FindUserInvoicesOptions>(
    ["findUserInvoices", options],
    API.userservice.userInvoices,
    requestInitFun,
    {
      enabled,
      getNextPageParam: nextPage,
      getPreviousPageParam: previousPage,
    }
  );
}

export function invalidateFindUserInvoicesCache() {
  const url = createApiUrl(API.userservice.userInvoices);
  Cache.clearResponse("GET", url);
  invalidateAPIQuery(["findUserInvoices"], false);
}
