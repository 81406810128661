import { useCodeItems } from "core/hooks";
import { CodeObject, Payment, PaymentStatus } from "core/api";
import { useCallback } from "react";

export function usePaymentStatus() {
  const { getCodeName } = useCodeItems();

  const findPaymentStatusText = useCallback(
    (paymentStatus: PaymentStatus) => {
      return getCodeName(CodeObject.PaymentStatus, paymentStatus);
    },
    [getCodeName]
  );

  const getPaymentStatus = useCallback(
    (payment: Payment) => {
      return {
        status: payment?.status,
        reasonText: findPaymentStatusText(payment?.status),
      };
    },
    [findPaymentStatusText]
  );

  return { getPaymentStatus };
}
