import { Error, FontIcon, Info, Warning } from "common/assets/icons";
import {
  Alert,
  AlertProps,
  AlertTitle,
  Box,
  Typography,
} from "common/components";
import { Severity, StepMessage } from "core/api";

export interface StepDetailsProps {
  /** Details title */
  title: string;
  /** Details icon */
  icon: string;
  /** Details hint */
  hint: string;
  /** Details description */
  description: string;
  /** Optional messages from server */
  messages?: StepMessage[];
  /** API error message */
  apiError?: string;
  /** Show or hide the initial info box */
  showStepTitle: boolean | undefined;
}

/** Action dialog step details */
export function StepDetails({
  title,
  description,
  icon,
  messages,
  apiError,
  showStepTitle,
}: StepDetailsProps) {
  const errors = [
    ...(apiError?.split("\n") ?? []),
    ...filterBySeverity(messages, Severity.ERROR),
  ];

  const warnings = filterBySeverity(messages, Severity.WARNING);

  const infos = filterBySeverity(messages, Severity.INFO);

  return (
    <Box display="grid" gap={0.5}>
      {(showStepTitle || showStepTitle === undefined) && (
        <Alert
          sx={{
            bgcolor: (theme) => theme.palette.primary.light,
            color: (theme) => theme.typography.caption,
          }}
          icon={
            <FontIcon
              icon={icon}
              sx={{ color: (theme) => theme.palette.primary.main }}
            />
          }
        >
          <AlertTitle data-cy="stepTitle">{title}</AlertTitle>
          <Typography variant="caption" whiteSpace={"pre-wrap"}>
            {description.replaceAll("\\n", String.fromCharCode(13, 10))}
          </Typography>
        </Alert>
      )}
      {infos.length > 0 && (
        <StepAlert color="info" icon={<Info />} messages={infos} />
      )}
      {errors.length > 0 && (
        <StepAlert color="error" icon={<Error />} messages={errors} />
      )}
      {warnings.length > 0 && (
        <StepAlert color="warning" icon={<Warning />} messages={warnings} />
      )}
    </Box>
  );
}

export interface StepAlertProps extends Pick<AlertProps, "color" | "icon"> {
  messages: string[];
}

export function StepAlert({ color, icon, messages }: StepAlertProps) {
  return (
    <Alert color={color} icon={icon} data-cy="stepMessages">
      {messages.map((message, index) => (
        <Typography key={index} variant="body2">
          {message}
        </Typography>
      ))}
    </Alert>
  );
}

function filterBySeverity(
  messages: StepMessage[] | undefined,
  severity: Severity
): string[] {
  return (
    messages
      ?.filter((message) => message.severity === severity)
      .map(({ text }) => text) ?? []
  );
}
