export enum FieldUIComponent {
  // Simple values
  Amount = "Amount",
  Date = "Date",
  DateTime = "DateTime",
  GUID = "GUID",
  List = "List",
  Number = "Number",
  PINCode = "PINCode",
  Password = "Password",
  Percent = "Percent",
  OptionalAmount = "OptionalAmount",
  OptionalSelect = "OptionalSelect",
  OptionalDate = "OptionalDate",
  OptionalText = "OptionalText",
  Select = "Select",
  SocialSecurityNumber = "SocialSecurityNumber",
  Text = "Text",
  TextArea = "TextArea",
  Time = "Time",
  Toggle = "Toggle",

  // Connected to an entitiy id
  Article = "Article",
  Address = "Address",
  Document = "Document",
  Inventory = "Inventory",
  NetworkElement = "NetworkElement",
  FieldValueGroup = "FieldValueGroup",
}
