/**
 * Checks if user is using a Mac
 * @returns If user OS is MacOS
 */
export function isMacOS() {
  return navigator.userAgent.includes("Mac");
}

/**
 * Checks if key is the main modifier key based on user OS
 * @param key Keyboard key
 * @returns If key is the main modifier key
 */
export function isOSMetaKey(key: string): boolean {
  return (isMacOS() && key === "Meta") || (!isMacOS() && key === "Control");
}
