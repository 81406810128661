import { Checkbox, Chip, ListItemText, styled } from "common/components";

export interface StyledChipProps {
  maxWidth: number;
}

export const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== "maxWidth",
})<StyledChipProps>(({ theme, maxWidth }) => ({
  maxWidth: maxWidth + "px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  margin: "3px",
}));

export const StyledSelectContainer = styled("div")(({ theme }) => ({
  minWidth: 250,
  maxWidth: 800,
}));

export const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  height: 24,
  width: 24,
}));

export const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  paddingRight: theme.spacing(1),
  "& > span": {
    width: "calc(100%)",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
}));
