export default {
  form: {
    title: "Logga in på Xplora",
    username: "Förälders telefonnummer",
    password: "Skriv in koden som du har mottagit på SMS.",
    language: "Språk",
    submit: "Logga in",
    continue: "Nästa",
    usernameValidationMessage: "Ange förälders telefonnummer",
    otpHint: "",
  },
  logoutDialog: {
    title: "Inloggad som",
  },
  loginInfo: {
    title: "Har du ett abonnemang från Xplora?",
    content:
      "OBS: Om prenumerationen gäller en Xplora-klocka, ange föräldrarnas mobilnummer, inte smartklockans. \n \n Observera att det kan ta upp till 30 minuter från beställning tills du kan logga in.",
  },
} as const;
