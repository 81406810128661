import { FormTextField } from "common/components";
import { MissingConfigFlowData, MissingConfigFlowStep } from "core/api";
import { FlowStepComponentProps } from "../models";
import { StyledStepContentWrapper } from "../steps.styles";

export type MissingConfigProps = FlowStepComponentProps<
  MissingConfigFlowStep,
  MissingConfigFlowData
>;

/**
 * Missing Config step component to just be able to inform about missing flow configuration.
 * <p>
 * This is intended to be enhanced in the future to show more information. At the moment it is
 * just an empty component that sets an error in the form, this to be able to prevent displaying a
 * spinner and to disable the next button in the wizard.
 */
export function MissingConfig({ control }: MissingConfigProps): JSX.Element {
  // Not sure this is the best way, but at least it makes the form invalid
  // see https://react-hook-form.com/docs/useform/seterror

  /* Does not work as expected, triggered but for still valid:
  useEffect(() => {
    setError("root.random", {
      type: "random",
    });
    console.log("Triggering seterror");
  }, [setError]);
*/

  // Workaround, hidden required field with no value - ugly but it works, the form is not valid and the Next button is by that disabled. //JSO
  return (
    <StyledStepContentWrapper>
      <FormTextField
        fieldName={"hidden"}
        required={true}
        control={control}
        disabled={true}
        InputLabelProps={{ required: false }}
        sx={{ visibility: "hidden" }}
      />
    </StyledStepContentWrapper>
  );
}
