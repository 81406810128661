import { useParams } from "common/routing";
import {
  API,
  DetailCardConfig,
  EntityType,
  invalidateAPIQuery,
  ScreenConfig,
  useAPIQuery,
} from "core/api";
import { formatEntityUrl } from "core/components";

interface WebViewResponse {
  config: string;
}
interface WebScreenResponse {
  actionsConfig: WebActionsConfigResponse;
  views: WebViewResponse[];
  routeProps: {
    path: string;
  };
  screens: WebScreenResponse[];
  bookmarkApiEntry: DetailCardConfig | null;
  entityType: EntityType; // temporary values before backend sends bookmark data
  icon: string; // temporary values before backend sends bookmark data
}

interface WebActionConfigResponse {
  config: string;
}
interface WebActionsConfigResponse {
  actions: WebActionConfigResponse[];
}

export type EntitiesMap = Record<EntityType, Record<string, any>[]>;

export const SCREEN_QUERY_KEY = "screens";

/**
 * Transformer to parse stringified JSON config
 * @param data
 * @returns
 */
const dbScreenConfigTransformer =
  (params: Record<string, any>, formatUrls?: boolean) =>
  (data: WebScreenResponse) => {
    const {
      routeProps: { path },
      screens,
      ...rest
    } = data;
    return {
      routeProps: {
        path: formatUrls ? formatEntityUrl(params, path) : path,
      },
      screens: formatUrls
        ? screens.map((screen) => {
            const { routeProps, ...rest } = screen;
            return {
              routeProps: {
                path: formatEntityUrl(params, routeProps.path),
              },
              ...rest,
            };
          })
        : screens,
      ...rest,
    };
  };

/**
 * Fetch App screen configuration
 * @param id
 * @param formatUrls
 * @returns
 */
export function useFetchScreen(
  id: number | undefined,
  ignoreError?: boolean | number[],
  entities?: EntitiesMap,
  formatUrls?: boolean
) {
  const _params = useParams<Record<string, string>>();
  // The * param contains the wildcard part of current route and will
  // change value on every navigation. To prevent triggering all fetch
  // screen queries when that happens it needs too be excluded or have
  // the same value between navigations. Which is why it's manually set
  // as 'undefined'.
  const params = { ..._params, "*": undefined };
  const loadedEntities = JSON.stringify(formatLoadedEntities(entities));

  return useAPIQuery<ScreenConfig>(
    createFetchScreenQueryKey(id, params, loadedEntities),
    API.config.screen,
    {
      pathParams: {
        screenId: id,
      },
      queryParams: {
        screenParameters: Object.entries(params)
          .filter(([, value]) => value !== undefined)
          .map(([key, value]) => key + "=" + value),
        loadedEntities,
      },
      transformer: dbScreenConfigTransformer(params, formatUrls),
    },
    { ignoreError, enabled: Number.isInteger(id) }
  );
}

function formatLoadedEntities(entities: EntitiesMap | undefined) {
  return entities
    ? Object.values(EntityType)
        .map((type) =>
          !entities[type]
            ? []
            : entities[type]?.map((entity: Record<string, any>) => {
                return {
                  entityId: entity.entityId,
                  entityType: type as EntityType,
                  entityTypeId: entity.entityTypeId,
                };
              })
        )
        .flatMap((e) => e)
    : undefined;
}

function createFetchScreenQueryKey(
  id: number | undefined,
  params: Record<string, string | undefined>,
  entities?: string
) {
  return [SCREEN_QUERY_KEY, id, params, entities];
}

interface FetchScreenCacheParams {
  id: number;
  params: Record<string, string>;
  entities?: string;
}

export function invalidateFetchScreenCache({
  id,
  params,
  entities,
}: FetchScreenCacheParams) {
  const queryKey = createFetchScreenQueryKey(id, params, entities);
  invalidateAPIQuery(queryKey);
}
