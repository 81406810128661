import { SelectOption } from "common/components";
import { addFlowStepPrefix } from "common/utils";
import { Flow, FlowData, FlowDataMap, FlowStep, ValidValue } from "core/api";
import { FieldValues } from "react-hook-form";
import { stepDataResolver } from "./steps";

/**
 * Gets current step config
 * @param flow Current flow
 * @param currentStepId Current step id
 * @returns Current step config
 */
export function getCurrentStep(
  flow: Flow | undefined,
  currentStepId?: string
): FlowStep | undefined {
  if (!flow) {
    return undefined;
  }
  return flow.steps.find(
    ({ id }) => id === (currentStepId ?? flow.currentStepId)
  );
}

/**
 * Gets current sub step config
 * @param flow Current flow
 * @param currentStepId Current step id
 * @param currentSubStepId Current sub step id
 * @returns Current sub step config
 */
export function getCurrentSubStep(
  flow: Flow | undefined,
  currentStepId?: string,
  currentSubStepId?: string
): FlowStep | undefined {
  const currentStep = getCurrentStep(flow, currentStepId);
  if (currentStep?.uiComponent !== "SelectStep" || !flow) {
    return undefined;
  }
  const currentStepData = flow.data[currentStep.id];
  const _currentSubStepId =
    currentStepData.uiComponent === "SelectStep"
      ? currentStepData.selectedStepId
      : undefined;
  if (!_currentSubStepId) {
    return undefined;
  }
  return currentStep.config.steps.find(
    (step) => step.id === (currentSubStepId ?? _currentSubStepId)
  );
}

/**
 * Gets data for current/target step
 * @param flow Current flow
 * @param currentStepId Current step id
 * @returns Flow step data
 */
export function getCurrentData(
  flow: Flow | undefined,
  currentStepId?: string
): FlowData | undefined {
  if (!flow) {
    return undefined;
  }
  return flow.data[currentStepId ?? flow.currentStepId];
}

/**
 * Creates new flow data with inserted field values
 * @param flow Flow data
 * @param fieldValues Field values
 * @param currentStepId Current step id
 * @returns Flow data with inserted field values
 */
export function insertStepValues(
  flow: Flow,
  fieldValues: FieldValues,
  currentStepId: string | undefined
): Flow {
  // Flatten list of steps to include sub steps
  const steps = flow.steps.flatMap((step) =>
    "steps" in step.config ? [step, ...(step.config.steps ?? [])] : [step]
  );

  const flowStep = steps.find((step) => step.id === currentStepId);
  const stepData = flow.data[currentStepId || ""];

  // Must also ensure resolver to the selected step is applied.
  const selectedStepId =
    flowStep?.uiComponent === "SelectStep"
      ? fieldValues[addFlowStepPrefix(flowStep, "selectedStepId")]
      : undefined;

  const _flow = selectedStepId
    ? { ...insertStepValues(flow, fieldValues, selectedStepId) }
    : flow;

  if (currentStepId && flowStep && stepData) {
    const data = {
      ..._flow.data,
      [currentStepId]: {
        ...stepData,
        // Only updates returned values from resolver
        ...stepDataResolver({
          flow,
          flowStep,
          data: stepData,
          fieldValues,
        }),
      },
    } as FlowDataMap;

    return {
      ..._flow,
      currentStepId: currentStepId ?? flow.currentStepId,
      data,
    };
  } else {
    return flow;
  }
}

/**
 * Convert a list of ValidValues to a list of SelectOptions
 *
 * @param values the ValidValues to convert
 * @returns  a list of SelectOptions
 */
export function toSelectOption(values: ValidValue[]): SelectOption[] {
  return values?.map(({ id, name }) => ({
    value: id,
    label: name,
  }));
}
