import { EntityData, EntityType, useAPIQuery } from "core/api";

export const STATIC_TABLE_ITEMS_KEY = "staticTableItems";

export interface StaticTableItemsOptions {
  /** API url */
  apiUrl: string | undefined;
  /** Data entity type */
  entityType: EntityType | undefined;
  enabled: boolean;
}

/**
 * Fetches static table data
 * @param options StaticTableItemsOptions
 * @returns Query result
 */
export function useStaticTableItems<TData extends EntityData>({
  apiUrl,
  entityType,
  enabled,
}: StaticTableItemsOptions) {
  const queryResult = useAPIQuery<TData[]>(
    [STATIC_TABLE_ITEMS_KEY, entityType, apiUrl],
    apiUrl ?? "",
    {},
    { enabled: Number(apiUrl?.length) > 0 && !!entityType && enabled }
  );

  return queryResult;
}
