import { Dialpad, HelpRounded, QuestionAnswer } from "common/assets/icons";
import {
  Box,
  ListItemIcon,
  ListItemText,
  Skeleton,
  SvgIconProps,
  Tooltip,
} from "common/components";
import {
  CustomerStatus,
  DocumentStatus,
  EntityType,
  InvoicePaymentStatus,
  InvoiceStatus,
  NoteStatus,
  PaymentStatus,
  SubscriptionStatus,
  TaskListStatus,
  TaskStatus,
  UserStatus,
} from "core/api";
import { ForwardedRef, ReactElement, Suspense, forwardRef, lazy } from "react";

const CheckCircle = lazy(() => import("@mui/icons-material/CheckCircle"));
const StopRounded = lazy(() => import("@mui/icons-material/StopRounded"));
const AssignmentInd = lazy(() => import("@mui/icons-material/AssignmentInd"));
const Cancel = lazy(() => import("@mui/icons-material/Cancel"));
const Flaky = lazy(() => import("@mui/icons-material/Flaky"));
const Forward = lazy(() => import("@mui/icons-material/Forward"));
const Pending = lazy(() => import("@mui/icons-material/Pending"));
const Unpublished = lazy(() => import("@mui/icons-material/Unpublished"));
const Error = lazy(() => import("@mui/icons-material/Error"));
const Block = lazy(() => import("@mui/icons-material/Block"));
const StopCircle = lazy(() => import("@mui/icons-material/StopCircle"));
const PauseCircleOutline = lazy(
  () => import("@mui/icons-material/PauseCircleOutline")
);

export interface EntityStatusIconProps extends EntityIconProps {
  /** Current entity type */
  entityType: EntityType;
  /** Status description for tooltip */
  description: string;
  withLabel?: boolean;
  /** Turn tooltip on/off  */
  tooltip?: boolean;
}

export interface EntityIconProps extends SvgIconProps {
  /** Status enum */
  status: string;
}

/**
 * Returns icon based on entity type and the status of the object
 *
 * @para entityType The entity type
 * @param status Entity status
 * @param description Status description
 * @param withLabel IF true display label
 * @param tooltip Turn tooltip on/off. Default to on
 * @param props ...props to provide to the icon such as size.
 *
 * @returns A suitable icon
 *
 */
export function EntityStatusIcon({
  entityType,
  status,
  description,
  withLabel,
  tooltip = true,
  ...props
}: EntityStatusIconProps): ReactElement {
  const icon = (
    <EntityStatusIconHelper
      entityType={entityType}
      status={status}
      aria-label={description}
      {...props}
    />
  );
  return (
    <Suspense
      fallback={
        <Tooltip title={description}>
          <Skeleton variant="circular" width={18} height={18} />
        </Tooltip>
      }
    >
      <Tooltip title={tooltip ? description : ""}>
        {withLabel ? (
          <Box
            display="grid"
            gridAutoFlow="column"
            alignContent="center"
            justifyContent="start"
          >
            <ListItemIcon sx={{ display: "grid", placeContent: "center" }}>
              {icon}
            </ListItemIcon>
            <ListItemText
              primary={description}
              disableTypography
              sx={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            />
          </Box>
        ) : (
          icon
        )}
      </Tooltip>
    </Suspense>
  );
}

const colors = {
  success: "green !important",
  info: "blue !important",
  warning: "orange !important",
  error: "red !important",
  grey: "gray !important",
  darkgrey: "darkgray !important",
};

/**
 * Inner helper component to modularize code a bit.
 */
const EntityStatusIconHelper = forwardRef(
  (
    {
      entityType,
      status,
      ...props
    }: Omit<EntityStatusIconProps, "description">,
    ref: ForwardedRef<SVGSVGElement>
  ): ReactElement => {
    const iconProps: EntityIconProps = {
      ref,
      status,
    };
    /*
     * These enum and icon combinations should probably be fetched
     * from the server instead to make the frontend less dependent
     * on knowing the specific status types that are available.
     */
    switch (entityType) {
      case EntityType.CUSTOMER:
        return <CustomerIcon {...iconProps} {...props} />;
      case EntityType.INVOICE:
        return <InvoiceStatusIcon {...iconProps} {...props} />;
      case EntityType.NOTE:
        return <NoteIcon {...iconProps} {...props} />;
      case EntityType.PAYMENT:
        return <PaymentIcon {...iconProps} {...props} />;
      case EntityType.PRODUCT:
        return <SubscriptionIcon {...iconProps} {...props} />;
      case EntityType.TASK:
        return <TaskIcon {...iconProps} {...props} />;
      case EntityType.TASK_LIST:
        return <TaskListIcon {...iconProps} {...props} />;
      case EntityType.USER:
        return <UserIcon {...iconProps} {...props} />;
      case EntityType.NRDB_NUMBER:
        return <Dialpad {...iconProps} {...props} />;
      case EntityType.NRDB_MESSAGE:
        return <QuestionAnswer {...iconProps} {...props} />;
      case EntityType.DOCUMENT:
        return <DocumentIcon {...iconProps} {...props} />;

      default:
        return <DefaultIcon {...iconProps} {...props} />;
    }
  }
);

const CustomerIcon = forwardRef(
  (
    { status, sx, ...props }: EntityIconProps,
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    switch (status) {
      case CustomerStatus.ACTIVE:
        return (
          <CheckCircle
            sx={{ color: colors.success, ...sx }}
            {...props}
            ref={ref}
          />
        );
      case CustomerStatus.CANCELLED:
        return (
          <Cancel sx={{ color: colors.darkgrey, ...sx }} {...props} ref={ref} />
        );
      case CustomerStatus.CLOSED:
        return (
          <StopRounded
            sx={{ color: colors.grey, ...sx }}
            {...props}
            ref={ref}
          />
        );
      case CustomerStatus.CLOSING:
        return (
          <Unpublished
            sx={{ color: colors.warning, ...sx }}
            {...props}
            ref={ref}
          />
        );
      case CustomerStatus.PENDING:
        return (
          <Pending sx={{ color: colors.warning, ...sx }} {...props} ref={ref} />
        );
      case CustomerStatus.REGISTERED:
        return (
          <Flaky sx={{ color: colors.warning, ...sx }} {...props} ref={ref} />
        );
      default:
        return <DefaultIcon status={status} {...props} />;
    }
  }
);

const DocumentIcon = forwardRef(
  (
    { status, sx, ...props }: EntityIconProps,
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    switch (status) {
      case DocumentStatus.APPROVED:
        return (
          <CheckCircle
            sx={{ color: colors.success, ...sx }}
            {...props}
            ref={ref}
          />
        );
      case DocumentStatus.DRAFT:
        return (
          <Flaky sx={{ color: colors.warning, ...sx }} {...props} ref={ref} />
        );
      default:
        return <DefaultIcon status={status} {...props} />;
    }
  }
);

// Note, the invoice status is a mix of invoice payment status and invoice status, for credit notes we need the invoice status.
const InvoiceStatusIcon = forwardRef(
  (
    { status, sx, ...props }: EntityIconProps,
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    switch (status) {
      case InvoicePaymentStatus.OVERPAID:
        return (
          <Error sx={{ color: colors.success, ...sx }} {...props} ref={ref} />
        );

      case InvoiceStatus.CREDIT_NOTE_APPROVED:
      case InvoicePaymentStatus.NOT_PAID:
        return (
          <CheckCircle
            sx={{ color: colors.warning, ...sx }}
            {...props}
            ref={ref}
          />
        );

      case InvoiceStatus.CREDIT_NOTE_CREATED:
      case InvoicePaymentStatus.PAID:
        return (
          <CheckCircle
            sx={{ color: colors.success, ...sx }}
            {...props}
            ref={ref}
          />
        );
      case InvoicePaymentStatus.PARTLY_PAID:
        return (
          <Pending sx={{ color: colors.warning, ...sx }} {...props} ref={ref} />
        );
      case InvoicePaymentStatus.WRITTEN_OFF:
        return (
          <Cancel sx={{ color: colors.darkgrey, ...sx }} {...props} ref={ref} />
        );

      case InvoiceStatus.CREDIT_NOTE_REGISTERED:
        return (
          <Flaky sx={{ color: colors.warning, ...sx }} {...props} ref={ref} />
        );

      case InvoiceStatus.CREDIT_NOTE_SENT:
        return (
          <Forward sx={{ color: colors.success, ...sx }} {...props} ref={ref} />
        );

      case InvoiceStatus.CREDIT_NOTE_REJECTED:
        return (
          <Block sx={{ color: colors.error, ...sx }} {...props} ref={ref} />
        );

      default:
        return <DefaultIcon status={status} {...props} />;
    }
  }
);

const NoteIcon = forwardRef(
  (
    { status, sx, ...props }: EntityIconProps,
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    switch (status) {
      case NoteStatus.CLOSED:
        return (
          <CheckCircle
            sx={{ color: colors.success, ...sx }}
            {...props}
            ref={ref}
          />
        );
      case NoteStatus.OPEN:
        return (
          <Error sx={{ color: colors.error, ...sx }} {...props} ref={ref} />
        );
      default:
        return <DefaultIcon status={status} {...props} />;
    }
  }
);

const PaymentIcon = forwardRef(
  (
    { status, sx, ...props }: EntityIconProps,
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    switch (status) {
      case PaymentStatus.MATCHED:
      case PaymentStatus.PARTLY_MATCHED:
        return (
          <CheckCircle
            sx={{ color: colors.success, ...sx }}
            {...props}
            ref={ref}
          />
        );
      case PaymentStatus.MATCH_EXCEPTION:
      case PaymentStatus.REJECTED:
        return (
          <Error sx={{ color: colors.error, ...sx }} {...props} ref={ref} />
        );
      case PaymentStatus.NO_MATCH:
        return (
          <StopCircle sx={{ color: colors.grey, ...sx }} {...props} ref={ref} />
        );
      case PaymentStatus.RECEIVED:
        return (
          <Flaky sx={{ color: colors.warning, ...sx }} {...props} ref={ref} />
        );
      case PaymentStatus.REQUESTED:
      case PaymentStatus.RESERVED:
        return (
          <Pending sx={{ color: colors.warning, ...sx }} {...props} ref={ref} />
        );
      default:
        return <DefaultIcon status={status} {...props} />;
    }
  }
);

const SubscriptionIcon = forwardRef(
  (
    { status, sx, ...props }: EntityIconProps,
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    switch (status) {
      case SubscriptionStatus.ACTIVE:
        return (
          <CheckCircle
            sx={{ color: colors.success, ...sx }}
            {...props}
            ref={ref}
          />
        );
      case SubscriptionStatus.BLOCKED:
        return (
          <Block sx={{ color: colors.error, ...sx }} {...props} ref={ref} />
        );
      case SubscriptionStatus.CANCELED:
        return (
          <Cancel sx={{ color: colors.darkgrey, ...sx }} {...props} ref={ref} />
        );
      case SubscriptionStatus.INACTIVE:
        return (
          <StopCircle sx={{ color: colors.grey, ...sx }} {...props} ref={ref} />
        );
      case SubscriptionStatus.PENDING:
        return (
          <Pending sx={{ color: colors.warning, ...sx }} {...props} ref={ref} />
        );
      case SubscriptionStatus.SUSPENDED:
        return (
          <PauseCircleOutline
            sx={{ color: colors.info, ...sx }}
            {...props}
            ref={ref}
          />
        );
      default:
        return <DefaultIcon status={status} {...props} />;
    }
  }
);

const TaskIcon = forwardRef(
  (
    { status, sx, ...props }: EntityIconProps,
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    switch (status) {
      case TaskStatus.ASSIGNED:
        return (
          <AssignmentInd
            sx={{ color: colors.warning, ...sx }}
            {...props}
            ref={ref}
          />
        );
      case TaskStatus.CLOSED:
        return (
          <CheckCircle
            sx={{ color: colors.success, ...sx }}
            {...props}
            ref={ref}
          />
        );
      case TaskStatus.NOT_SET:
        return (
          <Block sx={{ color: colors.error, ...sx }} {...props} ref={ref} />
        );
      case TaskStatus.REGISTERED:
        return (
          <Flaky sx={{ color: colors.warning, ...sx }} {...props} ref={ref} />
        );
      default:
        return <DefaultIcon status={status} {...props} />;
    }
  }
);

const TaskListIcon = forwardRef(
  (
    { status, sx, ...props }: EntityIconProps,
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    switch (status) {
      case TaskListStatus.IN_USE:
        return (
          <CheckCircle
            sx={{ color: colors.success, ...sx }}
            {...props}
            ref={ref}
          />
        );
      case TaskListStatus.PLANNED:
        return (
          <Flaky sx={{ color: colors.warning, ...sx }} {...props} ref={ref} />
        );
      case TaskListStatus.CLOSED:
        return (
          <StopRounded
            sx={{ color: colors.grey, ...sx }}
            {...props}
            ref={ref}
          />
        );
      default:
        return <DefaultIcon status={status} {...props} />;
    }
  }
);

const UserIcon = forwardRef(
  (
    { status, sx, ...props }: EntityIconProps,
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    switch (status) {
      case UserStatus.ACTIVE:
        return (
          <CheckCircle
            sx={{ color: colors.success, ...sx }}
            {...props}
            ref={ref}
          />
        );
      case UserStatus.CANCELED:
        return (
          <Cancel sx={{ color: colors.darkgrey, ...sx }} {...props} ref={ref} />
        );
      case UserStatus.INACTIVE:
        return (
          <StopCircle sx={{ color: colors.grey, ...sx }} {...props} ref={ref} />
        );
      case UserStatus.PENDING:
        return (
          <Pending sx={{ color: colors.warning, ...sx }} {...props} ref={ref} />
        );
      default:
        return <DefaultIcon status={status} {...props} />;
    }
  }
);

const DefaultIcon = forwardRef(
  (
    { status, sx, ...props }: EntityIconProps,
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    return (
      <HelpRounded sx={{ color: colors.error, ...sx }} {...props} ref={ref} />
    );
  }
);
