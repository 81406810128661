import {
  Breadcrumbs,
  Card,
  CardHeader,
  ListSubheader,
  styled,
} from "common/components";
import { Link } from "common/routing";

export const StyledBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
  "& > ol > li": {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: 180,
  },
}));

export const StyledCard = styled(Card)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.grey[100],
  },
}));

export const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  "& .MuiCardHeader-title": {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: 400,
  },
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  display: "flex",
  width: "100%",
}));

export const StyledListCategory = styled(ListSubheader)(({ theme }) => ({
  paddingTop: theme.spacing(0.25),
  lineHeight: theme.typography.h6.lineHeight,
  fontSize: theme.typography.h6.fontSize,
  textTransform: "uppercase",
  color: theme.palette.grey[700],
}));
