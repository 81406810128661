import {
  FieldValue,
  FlowDataField,
  SetResellerFlowData,
  SetResellerFlowStep,
} from "core/api";
import { FieldValues } from "react-hook-form";
import { StepDataResolver } from "../models";

/**
 * Reseller data resolver, updates the values in the reseller field.
 *
 * This implementation is similar to the fieldsDataResolver, but just one field and special treatment per valueNo.
 */
export const setResellerDataResolver: StepDataResolver<
  SetResellerFlowStep,
  SetResellerFlowData
> = ({ data, fieldValues }) => {
  const { reseller } = data;

  return {
    reseller: {
      ...reseller,
      values: createFieldValues(fieldValues, data.reseller),
    },
  };
};

/**
 *
 * Return updated values based on form input.
 *
 * @param fieldValues
 * @param field
 * @returns
 */
function createFieldValues(
  fieldValues: FieldValues,
  field: FlowDataField
): FieldValue[] {
  const { values } = field;
  return values.map((value) => ({
    ...value,
    value: getFormValue(fieldValues, value),
  }));
}

/**
 * Return the form value for the specified value, based on valueNo.
 *
 * @param fieldValues
 * @param value
 * @returns
 */
function getFormValue(
  fieldValues: FieldValues,
  value: FieldValue
): string | undefined {
  switch (value.valueNo) {
    case 0:
      return fieldValues.resellerId ?? value.value;
    case 1:
      // To preserve data if field is not there (hidden)
      // Can't use fieldValues.agent ?? value.value here since agent can be an empty string (not truthy but should be set anyway)
      return fieldValues.agent !== undefined ? fieldValues.agent : value.value;
    default:
      return value.value;
  }
}
