/**
 * Used to abort API query requests
 */
class QueryAborter {
  private controller: AbortController;

  constructor() {
    this.controller = new AbortController();
    this.createNewController.bind(this);
    this.getSignal.bind(this);
    this.abortAll.bind(this);
  }

  /**
   * Replaces the current controller with a new one.
   * Use to reset signal for future promises.
   */
  createNewController() {
    this.controller = new AbortController();
  }

  /** Gets current signal */
  getSignal() {
    return this.controller.signal;
  }

  /** Aborts, and prevents, all promises using current signal */
  abortAll() {
    this.controller.abort();
  }
}

export const queryAborter = new QueryAborter();
