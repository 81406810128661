import { AvailabilityStatus } from "..";

/**
 * Model representing the Billiant PrinterQueue object
 */
export interface PrinterQueue {
  version: number;
  printerQueueId: number;
  name: string;
  description: string;
  availabilityStatus: AvailabilityStatus;
}

export const PrinterQueueDefaults: PrinterQueue = {
  version: -1,
  printerQueueId: -1,
  name: "",
  description: "",
  availabilityStatus: AvailabilityStatus.PLANNED,
};
