import { Download } from "common/assets/icons";
import {
  Button,
  ButtonProps,
  DownloadBackdrop,
  IconButton,
  SxProps,
  Theme,
  Tooltip,
} from "common/components";
import { downloadInBrowser, useFileDownload } from "core/api";
import { useTranslation } from "i18n";
import { ReactNode } from "react";

export interface DownloadResourceButtonProps {
  /** File name */
  fileName: string;
  /** Url to resource */
  resourceUrl: string;
  /** Button size */
  size?: "small" | "medium" | "large" | undefined;
  /** Button type */
  type?: "regular" | "icon";
  color?: ButtonProps["color"];
  variant?: ButtonProps["variant"];
  icon?: ReactNode;
  label?: string;
  sx?: SxProps<Theme>;
}

export function DownloadResourceButton({
  fileName,
  resourceUrl,
  label: _label,
  size,
  type = "regular",
  color,
  variant,
  icon,
  sx,
}: DownloadResourceButtonProps) {
  const { mutate, isLoading } = useFileDownload();
  const { t } = useTranslation(["common"]);

  const downloadFile = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();

    mutate(
      { relativeUrl: resourceUrl },
      {
        onSuccess({ attachmentFileName, blob }) {
          downloadInBrowser(blob, attachmentFileName ?? fileName);
        },
      }
    );
  };

  const label = _label || t("common:downloadFile", { fileName });

  return (
    <>
      {type === "icon" ? (
        <Tooltip title={label}>
          <IconButton
            color={color}
            size={size}
            aria-label={label}
            onClick={downloadFile}
            data-cy="downloadButton"
            sx={sx}
          >
            {icon ?? <Download fontSize={size} />}
          </IconButton>
        </Tooltip>
      ) : (
        <Button
          startIcon={icon ?? <Download fontSize={size} />}
          color={color}
          variant={variant}
          size={size}
          onClick={downloadFile}
          data-cy="downloadButton"
          sx={sx}
        >
          {label}
        </Button>
      )}
      <DownloadBackdrop
        open={isLoading}
        title={t("common:downloadingDocument", { fileName })}
      />
    </>
  );
}
