import { StatusIcon, Visibility, VisibilityOff } from "common/assets/icons";
import { IconButton } from "common/components";
import { useToggle } from "common/hooks";
import { DisplayValueOptions, formatDisplayValue } from "common/utils";
import { FieldUIComponent, Icon } from "core/api";
import { useTranslation } from "react-i18next";
import {
  StyledText,
  StyledTextProps,
  StyledValueContainer,
} from "./FieldValue.styles";

export interface FieldValueProps extends DisplayValueOptions {
  /** Field UI component */
  uiComponent: FieldUIComponent;
  /** Field display value */
  displayValue: string | string[] | undefined;
  /** If field value is secret */
  secret: boolean;
  /** If there's no value set */
  noValue: boolean;
  /** Horizontal alignment of the field text */
  align: string;
  /** Field icon */
  icon?: Icon;
  /** If displaying an icon  */
  showIcon?: boolean;

  /** if there is an error text */
  error?: string;
}

/** Displays read-only field value */
export function FieldValue({
  uiComponent,
  displayValue,
  secret,
  noValue,
  currencyCode,
  startAdornment,
  endAdornment,
  align,
  icon,
  showIcon,
  unitType,
  error,
}: FieldValueProps): JSX.Element {
  const [isSecret, toggleIsSecret] = useToggle(true);
  const { t } = useTranslation(["common"]);

  const formattedDisplayValue = formatDisplayValue(uiComponent, {
    displayValue,
    currencyCode,
    startAdornment,
    endAdornment,
    unitType,
  });

  const textProps: StyledTextProps = {
    noValue,
    align:
      (align as "right" | "left" | "center") ||
      noValue ||
      (uiComponent === FieldUIComponent.Number &&
        isNaN(parseFloat(displayValue as string))),
    tabIndex: !noValue ? 0 : undefined,
    onFocus: (event) =>
      window.getSelection()?.selectAllChildren(event.currentTarget),
    onBlur: () => window.getSelection()?.empty(),
    onCopy: () => navigator.clipboard.writeText(formattedDisplayValue),
  };

  const visibleValue = noValue
    ? t("common:noValue")
    : secret && isSecret
    ? "•".repeat(formattedDisplayValue.length)
    : formattedDisplayValue;

  const VisibilityIcon = !isSecret ? Visibility : VisibilityOff;

  return (
    <StyledValueContainer uiComponent={uiComponent}>
      {icon?.name && showIcon && (
        <StatusIcon
          iconName={icon.name}
          iconVariant={icon.variant}
          displayValue={error}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        />
      )}
      <StyledText {...textProps}>{visibleValue}</StyledText>

      {secret && !noValue && (
        <IconButton
          aria-label={t("common:buttons.toggleVisibility")}
          onClick={toggleIsSecret}
          edge="end"
          size="small"
        >
          <VisibilityIcon fontSize="small" />
        </IconButton>
      )}
    </StyledValueContainer>
  );
}
