import { Checkbox, FormControlLabel } from "common/components";
import { FilterValueState, FilterValueStateBoolean } from "core/api";
import { ChangeEvent, ReactElement } from "react";

interface BooleanFilterFieldProps {
  /** The filter's state */
  state: FilterValueStateBoolean;
  /** Label for the field */
  displayName: string;
  /** Gets filter on change function by filter id */
  onChange: (event: FilterValueState) => void;
}

export function BooleanFilterField({
  state,
  displayName,
  onChange,
}: BooleanFilterFieldProps): ReactElement {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange({ ...state, [event.target.name]: event.target.checked });
  };
  return (
    <FormControlLabel
      control={
        <Checkbox
          data-cy={`filter-${state.filterId}`}
          name="value"
          checked={!!state?.value}
          onChange={handleChange}
        />
      }
      label={displayName}
      sx={{ paddingLeft: (theme) => theme.spacing(0.5) }}
    />
  );
}
