import {
  FormCurrencyField,
  FormDatePicker,
  FormSelect,
  FormTextField,
} from "common/components";
import { addFlowStepPrefix } from "common/utils";
import { toSelectOption } from "core/actions/flows/flowUtils";
import { CreatePaymentFlowData, CreatePaymentFlowStep } from "core/api";
import { useTranslation } from "i18n";
import { ReactElement } from "react";
import { FlowStepComponentProps } from "../models";
import { StyledStepContentWrapper } from "../steps.styles";

export type CreatePaymentProps = FlowStepComponentProps<
  CreatePaymentFlowStep,
  CreatePaymentFlowData
>;

/** Create payment step component to add a payment without an invoice. */
export function CreatePayment({
  control,
  data,
  flowStep,
}: CreatePaymentProps): ReactElement {
  const { t } = useTranslation(["invoice"]);
  const {
    paymentDate,
    paidAmount,
    paymentReference,
    currencyCode,
    paymentType,
    applicablePaymentTypes,
  } = data;

  return (
    <StyledStepContentWrapper sx={{ gridTemplateColumns: "repeat(2, 1fr)" }}>
      <FormCurrencyField
        fieldName={addFlowStepPrefix(flowStep, "paidAmount")}
        defaultValue={paidAmount}
        control={control}
        label={t("invoice:payInvoice:paidAmount")}
        currencyCode={currencyCode}
        required
        allowNegative={false}
        sx={{ gridColumn: "1 / span 1" }}
      />
      <FormDatePicker
        fieldName={addFlowStepPrefix(flowStep, "paymentDate")}
        defaultValue={paymentDate}
        control={control}
        label={t("invoice:payInvoice:paymentDate")}
        required
      />
      <FormSelect
        fieldName={addFlowStepPrefix(flowStep, "paymentType")}
        control={control}
        label={t("invoice:payInvoice:paymentType")}
        required
        defaultValue={paymentType.id}
        dataList={toSelectOption(applicablePaymentTypes) ?? []}
      />
      <FormTextField
        fieldName={addFlowStepPrefix(flowStep, "paymentReference")}
        defaultValue={paymentReference}
        control={control}
        label={t("invoice:payInvoice:paymentReference")}
      />
    </StyledStepContentWrapper>
  );
}
