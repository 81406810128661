/**
 * Get index of element in parent
 * @param element HTML element
 * @returns Index of element
 */
export function indexOfElement(element: Element): number {
  const { parentNode } = element;
  if (!parentNode) {
    return -1;
  }
  return Array.from(parentNode.children).findIndex(
    (currentElement) => currentElement === element
  );
}

export function isMobileDevice(): boolean {
  return (
    typeof navigator !== "undefined" &&
    /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)
  );
}

export function isAppleMobileDevice(): boolean {
  return (
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent)
  );
}
