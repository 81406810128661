import { formatFormDatePickerValue } from "common/components";
import {
  AdjustmentPropertiesFlowData,
  AdjustmentPropertiesFlowStep,
} from "core/api";
import { StepDataResolver } from "..";

/**
 * This data resolver makes sure that the values input by the user in the
 * form elements of the AdjustmentProperties UI Component are stored in the flow data.
 * If a form element does not have a value, the value already stored in the
 * flow data is returned.
 *
 * @returns An object that contains the following properties (defined in
 * AdjustmentPropertiesFlowData):
 *
 * - priceTypeId: the ID of the PriceType selected by the user;
 *
 * - adjustmentDescription: the description text selected/edited by the user;
 *
 * - immediateInvoicingEnabled: alse (default) if the user left the
 *   'enable immediate invoicing' check box unchecked, or true if the user
 *   checked that option;
 *
 * - earliestInvoiceDate: the invoice date selected by the user from the
 *   drop-down list. This property is only relevant if
 *   immediateInvoicingEnabled == false;
 *
 * - immediateInvoiceDate: the invoice date selected by the user using the date
 *   picker. This property is only relevant if immediateInvoicingEnabled == true.
 */
export const adjustmentPropertiesDataResolver: StepDataResolver<
  AdjustmentPropertiesFlowStep,
  AdjustmentPropertiesFlowData
> = ({ data, fieldValues }) => {
  return {
    priceTypeId: fieldValues.chargeType ?? data.priceTypeId,
    adjustmentDescription:
      fieldValues.adjustmentDescription ?? data.adjustmentDescription,
    immediateInvoicingEnabled:
      fieldValues.enableImmediateInvoicing ?? data.immediateInvoicingEnabled,
    earliestInvoiceDate:
      fieldValues.earliestInvoiceDate ?? data.earliestInvoiceDate,
    immediateInvoiceDate: fieldValues.immediateInvoiceDate
      ? formatFormDatePickerValue(fieldValues.immediateInvoiceDate)
      : data.immediateInvoiceDate,
  };
};
