import { Paper, styled } from "common/components";

export const StyledPaper = styled(Paper)(() => ({
  boxShadow: "none",
  justifyContent: "left",
  display: "flex",
  alignItems: "center",
  whiteSpace: "nowrap",
}));

export interface StyledContainerProps {
  isCreditNote: boolean;
}

export const StyledContainer = styled("div", {
  shouldForwardProp: (propName) => propName !== "isCreditNote",
})<StyledContainerProps>(({ theme, isCreditNote }) => ({
  display: "grid",
  gap: theme.spacing(1),
  gridTemplateAreas: isCreditNote
    ? `
    "icon invoice_id invoice_date invoice_type invoice_type tax amount amount"
    "icon statementType name address address rounding toggle toggle"
    "icon credited_invoice_id . credit_reason credit_reason . . . "
  `
    : `
    "icon invoice_id invoice_date due_date ref_nr tax amount amount" 
    "icon statementType name address address rounding toggle toggle"
    "icon . . . . . . ."
  `,
  gridTemplateColumns: "max-content repeat(7, 1fr)",
  gridTemplateRows: "repeat(1, 1fr) min-content",
  minWidth: 900,
  overflow: "auto",
}));

export type InvoiceGridAreas =
  | "address"
  | "amount"
  | "credited_invoice_id"
  | "credit_reason"
  | "due_date"
  | "icon"
  | "invoice_id"
  | "invoice_date"
  | "invoice_type"
  | "name"
  | "ref_nr"
  | "rounding"
  | "statementType"
  | "tax"
  | "toggle"
  | undefined;
export interface StyledGridAreaProps {
  gridArea: InvoiceGridAreas;
}

export const StyledGridArea = styled("div", {
  shouldForwardProp: (prop) => prop !== "gridArea",
})<StyledGridAreaProps>(({ gridArea }) => ({
  gridArea,
  alignContent: "start",
  display: "grid",
  alignItems: "center",
}));

export const StyledDiv = styled("div")(() => ({
  display: "grid",
  justifyItems: "center",
}));
