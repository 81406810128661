export default {
  nextInvoice: "Nächste Rechnung",
  latestInvoice: "Letzte Rechnung",
  invoice: "Rechnung",
  invoice_other: "Rechnungen",
  status: "Status",
  statementType: "Art des Buchungsbelegs",
  invoiceId: "Rechnungsnummer",
  creditNoteId: "Gutschriftsnummer",
  name: "Name",
  amount: "Betrag",
  invoiceDate: "Rechnungsdatum",
  creditNoteDate: "Gutschriftsdatum",
  dueDate: "Fälligkeitsdatum",
  creditDate: "Gutschriftdatum",
  creditedBy: "Gutschriftsnummer",
  creditNoteType: "Gutschriftstyp",
  creditedInvoiceId: "Gutgeschriebene Rechnungsnummer",
  creditReason: "Grund fürs Gutschreiben",
  paymentMethod: "Zahlungsweise",
  referenceNumber: "Referenznummer",
  netAmount: "Nettobetrag",
  rounding: "Rundung",
  taxAmount: "Steuerbetrag",
  totalAmount: "Gesamtbetrag",
  address: "Adresse",
  totalAmountTaxIncluded: "Gesamtbetrag inkl. MwSt.",
  totalAmountTaxExcluded: "Gesamtbetrag ohne MwSt.",
  downloadSuccess: "Rechnung {{invoiceId}} wurde heruntergeladen",
  noLatestInvoice: "Keine Rechnung",
  noInvoices: "Keine Rechnungen",
  period: "Zeitraum",
  costsForUpcomingPeriod: "Kosten für den kommenden Zeitraum {{subscription}}",
  noCostsForUpcomingPeriod: "Keine Kosten im kommende Zeitraum",
  changeDueDate: {
    dueDate: "Neues Fälligkeitsdatum",
  },

  newDueDate: "Neues Fälligkeitsdatum",
  configureInvoiceDunning: {
    blockReminders: "Mahnungen sperren",
    unblockReminders: "Mahnungen entsperren",
    blockDebtCollection: "Inkasso sperren",
    unblockDebtCollection: "Inkasso entsperren",
  },
  remainingAmount: "Restlicher Betrag",
  payInvoice: {
    paidAmount: "Eingezahlter Betrag",
    paymentDate: "Zahlungsdatum",
    paymentReference: "Zahlungsreferenz",
    paymentType: "Zahlungsart",
  },
  creditInvoice: {
    date: "Gutschriftsdatum",
    invoice: "Gutgeschriebene Rechnung",
    credit: "Gutschreiben",
    totalInvoicedAmount: "Gesamtrechnungsbetrag",
    creditedAmount: "Gesamtbetrag der Gutschrift",
    creditApprove: "Gutschrift genehmigen",
    creditReason: "Grund fürs Gutschreiben",
    negativeOrZeroCreditedAmountWarning:
      "Der gutgeschriebene Betrag kann nicht negativ oder null sein.",
    remainingMaxAmountToCreditWarning:
      "Der gutgeschriebene Betrag kann den Maximalbetrag nicht überschreiten",
    maxAmountToCredit: "Maximalbetrag, der gutgeschrieben werden kann",
    remainingAmount: "Restlicher Betrag (inkl. MwSt.)",
    revertCredit: "Gutschrift stornieren",
    creditInvoiceRow: "Rechnungszeile gutschreiben",
    remainingAmountToCredit: "Verfügbarer Betrag zur Gutschrift",
    amountInclTax: "Betrag inklusive MwSt.",
    amountExclTax: "Betrag ohne MwSt.",
  },
} as const;
