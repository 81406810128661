import { Box } from "common/components";
import { ReactElement } from "react";

interface LoginContentContainerProps {
  children: ReactElement;
}

/**
 * A styled container, recommended to be used as a wrapper for
 * content on the login page.
 * @param children Other elements to render inside this container
 * @returns
 */
export function LoginContentContainer({
  children,
}: LoginContentContainerProps): ReactElement {
  return (
    <Box
      sx={{
        padding: (theme) => theme.spacing(2),
        width: "48%",
        flex: "1 1 49%",
        maxWidth: "600px",
      }}
    >
      {children}
    </Box>
  );
}
