import { merge } from "lodash";
import { ReactNode } from "react";
import { ViewContext, ViewContextState } from "../DynamicView";
import { WidgetContext, WidgetContextState } from "../DynamicWidget";

type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

const defaultViewConfig: ViewContextState = {
  actionsConfig: { actions: [] },
  screenEntities: {},
  screenEntity: undefined,
  screenEntityId: undefined,
  screenEntityType: undefined,
  title: "Widget title",
  viewId: -1,
  viewType: "Dashboard",
  widgets: [],
};
const defaultWidgetConfig: WidgetContextState = {
  ...defaultViewConfig,
  gridItem: {
    i: "gridItemKey",
    minH: 1,
    minW: 1,
    widgetContent: () => null,
    w: 12,
  },
  widgetId: -1,
  widgetType: "Layout",
};

export interface DevContextProps {
  /** Override for view config properties. */
  viewConfig?: RecursivePartial<ViewContextState>;
  /** Override for widget config properties. */
  widgetConfig?: RecursivePartial<WidgetContextState>;
  children?: ReactNode;
}

/**
 * Helper component for development of views and widgets.
 *
 * Wrap a view or widget in this to test it without setting up config in db. Sets default context
 * values for view and widget where any individual property can be modified without affecting other
 * properties.
 *
 * Designed to be used in early development of views and widgets, not for production.
 */
export function DevContext({
  viewConfig,
  widgetConfig,
  children,
}: DevContextProps) {
  return (
    <ViewContext.Provider value={merge(defaultViewConfig, viewConfig)}>
      <WidgetContext.Provider value={merge(defaultWidgetConfig, widgetConfig)}>
        {children}
      </WidgetContext.Provider>
    </ViewContext.Provider>
  );
}
