import { Alert, DialogTitle, styled } from "common/components";

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  paddingTop: 0,
  paddingLeft: 0,
  paddingRight: 0,
}));

export const StyledAlert = styled(Alert)(({ theme }) => ({
  borderRadius: 0,
}));
