import { useCodeItems } from "core/hooks";
import { Address, AddressStatus, CodeObject } from "core/api";
import { useCallback } from "react";

/**
 *
 * @returns Function to get document status
 */
export function useAddressStatus() {
  const { getCodeName } = useCodeItems();

  const findAddressStatusText = useCallback(
    (addressStatus: AddressStatus) => {
      return getCodeName(CodeObject.AddressStatus, addressStatus);
    },
    [getCodeName]
  );

  const getAddressStatus = useCallback(
    (address: Address) => {
      const addressStatus = address.status;
      return {
        status: addressStatus,
        reasonText: findAddressStatusText(addressStatus),
      };
    },
    [findAddressStatusText]
  );

  return { getAddressStatus };
}
