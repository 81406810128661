import { useCodeItems } from "core/hooks";
import { CodeObject, Document, DocumentStatus } from "core/api";
import { useCallback } from "react";

/**
 *
 * @returns Function to get document status
 */
export function useDocumentStatus() {
  const { getCodeName } = useCodeItems();

  const findDocumentStatusText = useCallback(
    (documentStatus: DocumentStatus) => {
      return getCodeName(CodeObject.DocumentStatus, documentStatus);
    },
    [getCodeName]
  );

  const getDocumentStatus = useCallback(
    (document: Document) => {
      const documentStatus = document.status;
      return {
        status: documentStatus,
        reasonText: findDocumentStatusText(documentStatus),
      };
    },
    [findDocumentStatusText]
  );

  return { getDocumentStatus };
}
