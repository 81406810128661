import {
  ConfirmationDialogMessage,
  ErrorDialogMessage,
  Message,
} from "./models";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

/** Timeout for messages in milliseconds */
const MESSAGE_TIMEOUT = 4000;

export interface MessageStore {
  /** Snackbar messages */
  messages: Message[];
  /** Error dialog messages */
  errorDialogMessages: ErrorDialogMessage[];
  confirmationDialogMessages: ConfirmationDialogMessage[];
  /**
   * Add a message to the message service
   * A new message with the same text and type as the last added message will not be added,
   * a text will be added to the last message text instead "(occurred X times)"
   *
   * Timeout messages will all be cleared relative in time of the latest incomming message
   * if more than one message is added in a row before the the timeout has come into effect.
   *
   * @param message The message to add
   */
  addMessage(message: Message): void;
  /** Clear messages with timeout */
  clearTimeoutMessages(): void;
  /** Removes the latest active message */
  removeLatestMessage(): void;
  /** Clear all (non-persistent) messages */
  clearMessages(): void;
  /**
   * Adds a message to dialog.
   * @param dialogMessage Message to display in dialog
   */
  openErrorDialog(dialogMessage: ErrorDialogMessage): void;
  /**
   * Clear all dialog messages to close dialog.
   */
  closeErrorDialog(): void;
  /** Opens the confiramation dialog */
  openConfirmationDialog(dialogMessage: ConfirmationDialogMessage): void;
  /** Closes the confirmation dialog */
  closeConfirmationDialog(): void;
}

let clearTimeoutId: NodeJS.Timeout | null = null;

/**
 * Service to manage a global state for messages. Should only exist one
 * instance in the appication.
 */
export const useMessageStore = create<MessageStore>()(
  devtools((set) => ({
    messages: [],
    errorDialogMessages: [],
    confirmationDialogMessages: [],
    addMessage: (message) => {
      if (!message.noTimeout) {
        if (clearTimeoutId !== null) {
          clearTimeout(clearTimeoutId);
        }
        clearTimeoutId = setTimeout(() => {
          useMessageStore.getState().clearTimeoutMessages();
          clearTimeoutId = null;
        }, MESSAGE_TIMEOUT);
      }
      set((state) => {
        let matchFound = false;
        state.messages.forEach((queuedMessage) => {
          if (
            queuedMessage.text === message.text &&
            queuedMessage.type === message.type
          ) {
            queuedMessage.occurrences!++;
            matchFound = true;
          }
        });

        if (!matchFound) {
          message.occurrences = 1;
          state.messages.push(message);
        }
        return { ...state };
      });
    },
    clearTimeoutMessages: () => {
      set((state) => ({
        ...state,
        messages: state.messages.filter(({ noTimeout }) => noTimeout),
      }));
    },
    removeLatestMessage: () => {
      set((state) => {
        state.messages.pop();
        return { ...state };
      });
    },
    clearMessages: () => {
      set((state) => ({
        ...state,
        messages: state.messages
          .filter(({ persistNavigation }) => persistNavigation)
          .map((message) => ({ ...message, persistNavigation: false })),
      }));
    },
    openErrorDialog: (dialogMessage) => {
      set((state) => {
        const messageExists =
          state.errorDialogMessages.find(
            (message) => message.details === dialogMessage.details
          ) !== undefined;
        if (!messageExists) {
          state.errorDialogMessages.push(dialogMessage);
          return { ...state };
        }
        return state;
      });
    },
    closeErrorDialog: () => {
      set((state) => ({
        ...state,
        errorDialogMessages: [],
      }));
    },
    openConfirmationDialog: (dialogMessage) => {
      set((state) => ({
        ...state,
        confirmationDialogMessages: [
          ...state.confirmationDialogMessages,
          dialogMessage,
        ],
      }));
    },
    closeConfirmationDialog: () => {
      set((state) => ({
        ...state,
        confirmationDialogMessages: state.confirmationDialogMessages.slice(1),
      }));
    },
  }))
);

/** Instance of MessageService */
export const Messages = useMessageStore.getState;
