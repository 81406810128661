import { StorageKey } from "core/appSettings";
import { GridData } from "core/dashboard";
import { createRecordStore } from "core/store";
import { createContext, ReactNode, useCallback, useContext } from "react";

export interface LayoutContextState {
  /** Layout identifier */
  layoutId?: string;
  /** If layout should be open/expanded */
  open: boolean;
  /** Toggle open state */
  toggleOpen(): void;
  /** Grid configuration */
  grid: GridData | undefined;
  /** If the layout should be collapsible */
  collapsible: boolean;
  /** If actions should be hidden if disabled */
  hideDisabledActions: boolean;
  /** If title should be displayed */
  showTitle: boolean;
}

const LayoutContext = createContext<LayoutContextState | null>(null);

export function useLayoutContext() {
  const context = useContext(LayoutContext);
  if (!context) {
    throw new Error("LayoutContext needs a provider");
  }
  return context;
}

export interface LayoutContextProviderProps {
  /** Layout identifier */
  layoutId?: string;
  /** Grid configuration */
  grid?: GridData;
  /** If the layout should be collapsible */
  collapsible?: boolean;
  /** If actions should be hidden if disabled */
  hideDisabledActions?: boolean;
  /** Children */
  children: ReactNode;
  /** If title should be displayed */
  showTitle: boolean;
}

export function LayoutContextProvider({
  children,
  layoutId,
  grid,
  collapsible = false,
  hideDisabledActions = false,
  showTitle = false,
}: LayoutContextProviderProps) {
  const { state, updateState } = useExpandedLayoutStore(layoutId, true);

  const toggleOpen = useCallback(() => {
    if (layoutId !== undefined && collapsible) {
      updateState((open) => !open);
    }
  }, [updateState, layoutId, collapsible]);

  return (
    <LayoutContext.Provider
      value={{
        layoutId,
        open: state,
        toggleOpen,
        grid,
        collapsible,
        hideDisabledActions,
        showTitle,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
}

const useExpandedLayoutStore = createRecordStore<boolean>({
  name: StorageKey.LAYOUTS_COLLAPSED,
});
