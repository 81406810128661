import { styled } from "common/components";

export const ShortcutContainer = styled("span")(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  padding: theme.spacing(0.2, 0.8),
  whiteSpace: "nowrap",
  overflow: "visible",
  display: "inline-flex",
  flexWrap: "nowrap",
  alignItems: "center",
  gap: theme.spacing(0.5),
}));
