import { QueryClient } from "@tanstack/react-query";

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 0,
    },
  },
});
