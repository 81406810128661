import { useCallback, useState } from "react";

export interface TableSortingOptions {
  /** Default order by value */
  defaultOrderBy?: string;
}

/** Manages table sorting state */
export function useTableSorting({ defaultOrderBy }: TableSortingOptions = {}) {
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  const onSortChange = useCallback(
    (orderBy: string, order: "asc" | "desc") => {
      setOrder(order);
      setOrderBy(orderBy);
    },
    [setOrder, setOrderBy]
  );

  return {
    order,
    orderBy,
    page,
    pageSize,
    setOrder,
    setOrderBy,
    setPage,
    setPageSize,
    onSortChange,
  };
}
