import { Search } from "common/assets/icons";
import {
  Box,
  Button,
  FormControllerContext,
  FormSelect,
  FormTextField,
} from "common/components";
import { addFlowStepPrefix } from "common/utils";
import {
  FindNetworkElementFlowData,
  FindNetworkElementFlowStep,
  TableFlowStep,
} from "core/api";
import { useTranslation } from "i18n";
import { ReactElement, useContext, useState } from "react";
import { useFormContext } from "react-hook-form";
import { theme } from "styles/theme";
import { toSelectOption } from "../../flowUtils";
import { Table } from "../Table";
import { FlowStepComponentProps } from "../models";
import { StyledStepContentWrapper } from "../steps.styles";

export type FindNetworkElementProps = FlowStepComponentProps<
  FindNetworkElementFlowStep,
  FindNetworkElementFlowData
>;

/** Finds Inventory step component that allows you to find inventories to assign to a product. */
export function FindNetworkElement({
  control,
  flow,
  data,
  flowStep,
  setValue,
  ...props
}: FindNetworkElementProps): ReactElement {
  const { t } = useTranslation(["common", "action"]);

  const { tableStepConfig, networkElementTypes } = flowStep.config;

  const mergedTableStep = {
    ...flowStep,
    ...data.tableStep,
    config: { ...tableStepConfig },
  } as unknown as TableFlowStep;

  const context = useFormContext();
  const searchTextFieldName = addFlowStepPrefix(flowStep, "searchText");

  const enteredText: string = context?.watch(searchTextFieldName, "");

  const { evaluateOnChange } = useContext(FormControllerContext);
  const [isLoading, setIsLoading] = useState(false); //

  const handleClick = () => {
    setIsLoading(true);
    evaluateOnChange?.(() => {
      setIsLoading(false);
    });
  };

  return (
    <>
      <StyledStepContentWrapper>
        <Box
          style={{
            display: "grid",
            gridTemplateColumns: "3fr 2fr 2fr 2fr 1fr min-content",
            alignItems: "center",
            gap: theme.spacing(1),
          }}
          data-cy="searchFields"
        >
          <FormTextField
            fieldName={searchTextFieldName}
            control={control}
            label={t("action:findNetworkElementStep.searchText")}
            data-cy="searchText"
            defaultValue={data.searchText}
            onKeyDown={(event) => {
              if (event.key === "Enter" && enteredText !== "") {
                evaluateOnChange?.();
              }
            }}
          />
          <FormSelect
            dataList={toSelectOption(networkElementTypes)}
            includeEmptyOption={true}
            fieldName={addFlowStepPrefix(flowStep, "networkElementTypeId")}
            required={false}
            control={control}
            defaultValue={data.networkElementTypeId}
            label={t("action:findNetworkElementStep.networkElementType")}
            data-cy="networkElementType"
            disabled={!data.networkElementTypeSelectionEnabled}
          />
          <FormTextField
            fieldName={addFlowStepPrefix(flowStep, "maxTableItems")}
            control={control}
            label={t("action:findNetworkElementStep.maxTableItems")}
            data-cy="maxTableItems"
            defaultValue={data.maxTableItems}
            disabled={!data.maxTableItemsEnabled}
          />
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={handleClick}
            disabled={isLoading || enteredText === ""}
            loading={isLoading}
            data-cy="searchButton"
            aria-label={t("common:buttons.search")}
          >
            <Search />
          </Button>
        </Box>
        {data.tableStep && (
          <Table
            control={control}
            data={data.tableStep}
            flowStep={mergedTableStep}
            flow={flow}
            setValue={setValue}
            {...props}
          ></Table>
        )}
      </StyledStepContentWrapper>
    </>
  );
}
