import { styled } from "@mui/material/styles";

export const StyledActionButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "nowrap",
  justifyContent: "flex-end",
  gap: theme.spacing(1),
  maxWidth: "100%",
  flex: 1,
}));
