import { StorageKey } from "core/appSettings";
import { createStore } from "core/store";
import { useCallback } from "react";
import { ActionParams } from "../models";

export interface ActionDialogState extends ActionParams {
  /** Action id */
  actionId: number;
}

/** Handles dialog settings state */
export function useActionDialog() {
  const { state, updateState, resetStore } = useActionDialogStore();

  const openDialog = useCallback(
    (params: ActionDialogState) => updateState(params),
    [updateState]
  );

  /** Resets the internal state by using the resetStore function */
  const closeDialog = useCallback(() => {
    resetStore();
  }, [resetStore]);

  return { state, openDialog, closeDialog };
}

const useActionDialogStore = createStore<ActionDialogState | undefined>({
  name: StorageKey.ACTION_DIALOG,
  storage: sessionStorage,
  initialState: undefined,
});
