import { Location } from "history";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export interface LocationChangesOptions {
  /** Called on history change */
  onChange(location: Location): void;
}

/** Listen to history changes */
export function useLocationChanges({ onChange }: LocationChangesOptions) {
  const location = useLocation();

  useEffect(() => {
    return () => onChange(location);
  }, [location, onChange]);
}
