import {
  getTableConfigQueryData,
  TableFlowData,
  TableFlowStep,
  TaggedTableData,
} from "core/api";
import { StepDataResolver } from "../models";

/**
 * Table data resolver
 *
 * Formats table selections based on form field values
 */
export const tableDataResolver: StepDataResolver<
  TableFlowStep,
  TableFlowData
> = ({ flowStep, fieldValues }) => {
  const tableConfig = getTableConfigQueryData(flowStep.config.tableId);
  const config = tableConfig?.optionsConfig[0];

  if (!config) {
    return {};
  }

  const selectedRows: TaggedTableData[] = fieldValues["tableStep"];

  if (!selectedRows) {
    return {};
  }

  return {
    selectedIds: selectedRows
      .map((row) =>
        ["number", "string"].includes(typeof row) ? row : row[config.uniqueKey]
      )
      .filter((id) => ![undefined, null].includes(id)),
    selectedNames: selectedRows.map((row) => row.name || ""),
  };
};
