import { NumberField, NumberFieldProps } from "common/components";
import { ReactElement, useEffect } from "react";
import { removeFlowStepPrefix } from "../../../../utils";
import {
  FormControlledFieldProps,
  withFormController,
} from "../FormController";

export interface FormNumberFieldProps
  extends FormControlledFieldProps,
    Omit<NumberFieldProps, "label" | "defaultValue"> {
  customValidation?: () => boolean | string;
}

/**
 * Adds form handling for NumberField.
 *
 * @param fieldName Static string used for the unique ids of the inner components.
 * @param control Form control.
 * @param mandatoryLabel Add own label for mandatary fields (default value is fieldname + "(mandatory)").
 * @param validationRules Optional validation rule object, se below for example.
 *                        If triggered the helper text is replaced with active validation rule message.
 * @param customValidation A function that can be used to perform custom validation
 *                         on this field.
 * @param props Any remaining properties.
 */
export const FormNumberField = withFormController<FormNumberFieldProps>(
  ({
    fieldName,
    label,
    helperText,
    renderState,
    customValidation,
    ...props
  }): ReactElement => {
    const {
      field,
      fieldState: { error },
      gridColumn,
      isDisabled,
      setExtendedRules,
    } = renderState;

    useEffect(() => {
      setExtendedRules({
        validate: {
          // N.B. externalValidation is just an arbitrary property name
          // It is not a reference to anything already existing
          externalValidation: () => {
            if (customValidation) {
              return customValidation();
            } else {
              return true;
            }
          },
        },
      });
    }, [setExtendedRules, customValidation]);

    return (
      <NumberField
        {...field}
        label={String(label)}
        fieldName={fieldName}
        data-cy={removeFlowStepPrefix(fieldName)}
        {...props}
        error={!!error}
        helperText={error?.message ?? helperText}
        disabled={isDisabled}
        sx={[
          { gridColumn },
          ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
        ]}
      />
    );
  }
);
