import { Chip } from "common/components";
import { useConfigMode } from "core/auth";
import { MouseEvent, ReactElement, useCallback } from "react";

export enum IdType {
  Screen = "Screen",
  View = "View",
  Table = "Table",
  TableOption = "Table Option",
  Step = "Step",
  Flow = "Flow",
  Application = "Application",
  Config = "Config",
}

export interface EditGeneralProps {
  /** Determines label and styling */
  idType: IdType;
  /** Id to display */
  id: number | undefined;
}

/**
 * Generates a chip with correct styling and placement for showing Id
 * while configuration mode is active.
 *
 * Right click the chip to copy its displayed Id.
 *
 * Any future edits made to components based on a specific configuration mode
 * should be made in this component.
 */
export function EditGeneral({
  idType,
  id,
}: EditGeneralProps): ReactElement | null {
  const { state: configModeEnabled } = useConfigMode();

  const handleRightClick = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      navigator.clipboard.writeText("" + id);
    },
    [id]
  );

  let chipColor = "";
  let chipTextColor = "";

  switch (idType) {
    case IdType.Application:
    case IdType.View:
    case IdType.Flow:
      chipColor = "hsl(198, 70%, 70%, 1)";
      chipTextColor = "hsl(0, 0%, 0%)";
      break;

    case IdType.Config:
    case IdType.Step:
      chipColor = "hsl(198, 70%, 70%, 0.5)";
      chipTextColor = "hsl(0, 0%, 0%)";
      break;

    case IdType.Table:
    case IdType.TableOption:
    case IdType.Screen:
      chipColor = "hsl(198, 86%, 95%)";
      chipTextColor = "hsl(0, 0%, 0%)";
      break;
  }

  if (configModeEnabled) {
    return (
      <Chip
        label={idType + " Id: " + id}
        variant="filled"
        clickable={true}
        onContextMenu={handleRightClick}
        data-cy={"edit" + idType + id}
        sx={{
          position: "relative",
          color: chipTextColor,
          backgroundColor: chipColor,
          marginBottom: "2px",
          marginTop: "2px,",
          height: "fit-content",
          width: "100%",
        }}
      />
    );
  } else return null;
}
