import { ListItem, ListItemText } from "common/components";
import { formatDate } from "common/utils";
import { ChangeDueDateFlowStep, FlowData } from "core/api";
import { useTranslation } from "i18n";
import { Fragment } from "react";

export interface ChangeDueDateConfirmationProps {
  step: ChangeDueDateFlowStep;
  flowData: FlowData;
}

/**
 * Sub component to confirmation. The purpose of this component is
 * to make the main Confirmation component more readable,
 * abstracting some of the logic into smaller sub components.
 *
 * ChangeDueDateConfirmation is specific to confirmation steps where
 * the flow contains a step with the uiComponent: "ChangeDueDate".
 *
 * @param step The current step in the parent components loop.
 * @param flowData data property from the flow object, this is fetched through
 * const flowData = flow.data[step.id]; in the parent component.
 *
 * @returns A JSX element with invoice details from the previous "ChangeDueDate" step in the flow.
 */
export function ChangeDueDateConfirmation({
  step,
  flowData,
}: ChangeDueDateConfirmationProps): JSX.Element {
  const { t } = useTranslation(["common"]);
  const fieldFlowData =
    flowData.uiComponent === "ChangeDueDate" ? flowData : undefined;

  return (
    <Fragment key={step.id}>
      <ListItem>
        <ListItemText
          primary={t("invoice:invoice")}
          secondary={fieldFlowData?.externalInvoiceId}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={t("invoice:changeDueDate:dueDate")}
          secondary={formatDate(fieldFlowData?.dueDate)}
        />
      </ListItem>
    </Fragment>
  );
}
