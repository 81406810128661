import { CheckboxIcon, StatusIcon } from "common/assets/icons";
import {
  createBookmark,
  fieldKeyToString,
  getFieldDisplayValue,
  getFieldIcon,
  mapFields,
} from "common/utils";
import {
  BookmarkEntryType,
  EntityData,
  EntityType,
  FieldConfig,
  FieldKey,
  ValueConfig,
  useFetchFieldConfig,
} from "core/api";
import {
  BookmarkIconButton,
  DownloadResourceButton,
  EntityStatusIcon,
  TableSkeleton,
  formatEntityUrl,
  formatValue,
  useEntityStatus,
} from "core/components";
import { useBookmarks } from "core/hooks";
import { useMemo } from "react";

interface BookmarkIconProps {
  /** Entity data */
  entity: EntityData;
}

interface FormattedFieldDisplayValueProps {
  fieldConfigList?: FieldConfig[];
  entityType: EntityType;
  entity?: EntityData;
  fieldKey: FieldKey;
}

function FormattedFieldDisplayValue({
  fieldConfigList,
  entityType,
  entity,
  fieldKey,
}: FormattedFieldDisplayValueProps) {
  const icon = getFieldIcon(fieldKey, entity?.fields ?? []);

  const fieldConfig = mapFields(fieldConfigList)[fieldKeyToString(fieldKey)];
  const displayValue = fieldConfig
    ? getFieldDisplayValue(
        fieldKey,
        fieldConfig.uiComponent,
        entity?.fields ?? []
      )
    : "";
  return entity && fieldConfig ? (
    <>
      {icon.name ? (
        <StatusIcon
          iconName={icon.name}
          iconVariant={icon.variant}
          description={displayValue}
          withLabel
          tooltip={false}
          sx={{ display: "grid" }}
        />
      ) : (
        displayValue
      )}
    </>
  ) : (
    <TableSkeleton fullColumn />
  );
}

/**
 * Defines column content creator function
 *
 * @param entityType The type of entity whose data is displayed in the table
 *
 * @param includeChildFields (optional) If set to **true**, the fields
 * configuration data returned by
 * bss/api/config/entityType/<entityTypeName>/fields will include
 * all the fields from the child entity.
 *
 * @returns Column content creator function
 */
export function useTableColumnContent(
  entityType: EntityType,
  includeChildFields = false
) {
  const { getStatus } = useEntityStatus();
  const { bookmarks } = useBookmarks(undefined, BookmarkEntryType.BOOKMARK);
  const { data: fieldConfigList } = useFetchFieldConfig(
    entityType,
    undefined,
    includeChildFields
  );

  return useMemo(
    () => ({
      createColumnContent(
        column: ValueConfig,
        entityType: EntityType,
        getEntityUrl?: (entity: EntityData) => string | undefined
      ) {
        function BookmarkIcon({ entity }: BookmarkIconProps) {
          const bookmark = createBookmark(
            entityType,
            entity.entityId,
            entity as any,
            bookmarks
          );
          const functionUrl = getEntityUrl ? getEntityUrl(entity) : undefined;
          return (
            <BookmarkIconButton
              filterEntityType={entityType}
              bookmark={{
                ...bookmark,
                url: functionUrl ?? bookmark.url,
              }}
              size="small"
              searchResults
            />
          );
        }

        return function ColumnContent(entry: EntityData) {
          switch (column.type) {
            case "CHECKBOX":
              return (
                <CheckboxIcon
                  checked={entry[column.valueKey] === column.expectedValue}
                />
              );
            case "ENTITY_STATUS":
              const { status, reasonText: statusReason } = getStatus(
                entityType,
                entry
              );
              return status && statusReason ? (
                <EntityStatusIcon
                  entityType={entityType}
                  fontSize="small"
                  status={status}
                  description={statusReason}
                  tooltip={false}
                  withLabel
                />
              ) : null;
            case "FIELD_KEY":
              return (
                <FormattedFieldDisplayValue
                  fieldConfigList={fieldConfigList}
                  fieldKey={column.key}
                  entityType={entityType}
                  entity={entry}
                />
              );

            case "BOOKMARK":
              return <BookmarkIcon entity={entry} />;
            case "DOWNLOAD":
              const url = formatEntityUrl(
                entry,
                column.resourcePath,
                column.pathParams
              );
              const fileName =
                formatValue(
                  {
                    type: "TEXT",
                    keys: column.fileNameKeys,
                    pattern: column.fileNamePattern,
                  },
                  entry
                ) + column.fileExtension;
              return (
                <DownloadResourceButton
                  type="icon"
                  size="small"
                  fileName={fileName}
                  resourceUrl={url}
                />
              );
            default:
              return <>{formatValue(column, entry)}</>;
          }
        };
      },
    }),
    [bookmarks, getStatus, fieldConfigList]
  );
}
