import {
  API,
  APIQueryOptions,
  APIQueryResult,
  EntityType,
  useAPIQuery,
} from "core/api";
import { SearchFilter } from "core/api/models";

/**
 * Hook for retrieving filters for a given entity type.
 * @param entityType the entity type to get filters for
 */
export function useFetchFilters(
  entityType: EntityType,
  tableOptionId?: number,
  options?: APIQueryOptions<SearchFilter[]>
): APIQueryResult<SearchFilter[]> {
  return useAPIQuery<SearchFilter[]>(
    ["fetchFilters", entityType, tableOptionId],
    API.config.filters,
    {
      queryParams: {
        entityType,
        tableOptionId: tableOptionId || -1,
      },
    },
    { ...options, enabled: options?.enabled && entityType !== undefined }
  );
}
