import { GUIDField, GUIDFieldProps } from "common/components";
import { ReactElement } from "react";
import {
  FormControlledFieldProps,
  withFormController,
} from "../FormController";

export interface FormGUIDFieldProps
  extends FormControlledFieldProps,
    Omit<GUIDFieldProps, "setValue" | "value" | "defaultValue" | "label"> {}

/** Form GUID field */
export const FormGUIDField = withFormController<FormGUIDFieldProps>(
  ({ helperText, renderState, ...props }): ReactElement => {
    const {
      field: { onChange, ...field },
      fieldState: { error },
      gridColumn,
      isDisabled,
    } = renderState;
    return (
      <GUIDField
        {...props}
        {...field}
        setValue={onChange}
        error={!!error}
        helperText={error?.message ?? helperText}
        disabled={isDisabled}
        sx={[
          { gridColumn },
          ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
        ]}
      />
    );
  }
);
