import {
  FindInventoryFlowData,
  FindInventoryFlowStep,
  TableFlowStep,
} from "core/api";
import { StepDataResolver } from "..";
import { tableDataResolver } from "../Table";

/**
 * Resolve the find fields and extract the table part and use the table step resolver.
 */
export const findInventoryDataResolver: StepDataResolver<
  FindInventoryFlowStep,
  FindInventoryFlowData
> = ({ flow, flowStep, data, fieldValues }) => {
  // Convert the 'data' object to type FieldsFlowData to be able to
  // pass it to the fieldsDataResolver
  // eslint-disable-next-line
  const {
    searchText,
    locationId,
    statusId,
    articleId,
    maxInvItems,
    tableStep,
  } = data;

  const { tableStepConfig } = flowStep.config;

  const mergedTableStep = {
    ...flowStep,
    ...tableStep,
    config: { ...tableStepConfig },
  } as unknown as TableFlowStep;

  // Resolve encapsulated table step
  const returnProps = tableDataResolver({
    flow,
    flowStep: mergedTableStep,
    data: tableStep,
    fieldValues,
  });

  //
  return {
    tableStep: { ...tableStep, ...returnProps },
    searchText: fieldValues.searchText ?? searchText,
    statusId: fieldValues.statusId ?? statusId,
    locationId: fieldValues.locationId ?? locationId,
    articleId: fieldValues.articleId ?? articleId,
    maxInvItems: fieldValues.maxInvItems ?? maxInvItems,
  } as FindInventoryFlowData;
};
