import { ArrowBackIosNew } from "common/assets/icons";
import { IconButton } from "common/components";
import { useNavigate } from "common/routing";

export function GoBackButton() {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  return (
    <IconButton onClick={goBack} sx={{ justifySelf: "start" }}>
      <ArrowBackIosNew />
    </IconButton>
  );
}
