import { styled } from "common/components";
import { FlowDialogSize } from "../../../api";

interface StyledStepContentWrapperProps {
  /** Flow dialog size enum */
  dialogSize?: FlowDialogSize;
}

const dialogSizeMapping = {
  [FlowDialogSize.NORMAL]: 500,
  [FlowDialogSize.LARGE]: 800,
};

const getMaxHeightValue = (dialogSize?: FlowDialogSize) => {
  if (dialogSize === FlowDialogSize.FULLSCREEN) {
    return `calc(100vh - 230px)`;
  } else if (dialogSize) {
    return `calc(${dialogSizeMapping[dialogSize]}px - 235px)`;
  }
  return "100%";
};

export const StyledStepComponentWrapper = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateRows: "min-content auto",
  alignContent: "start",
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(0.5),
  overflow: "auto",
  height: "100%",
}));

export const StyledStepContentWrapper = styled(
  "div"
)<StyledStepContentWrapperProps>(({ theme, dialogSize }) => ({
  display: "grid",
  gap: theme.spacing(1.5),
  alignContent: "start",
  overflow: "auto",
  padding: theme.spacing(1, 0),
  height: "100%",
  maxHeight: getMaxHeightValue(dialogSize),
}));

export const StyledStepIndentContentWrapper = styled("div")(({ theme }) => ({
  display: "grid",
  gap: theme.spacing(1.5),
  alignContent: "start",
  overflow: "auto",
  padding: theme.spacing(1, 5),
  height: "100%",
}));
