import { List, Paper, styled } from "common/components";

export const StyledQuickSearchContainer = styled("div")(({ theme }) => ({
  position: "relative",
}));

export const StyledOverlay = styled(Paper)(({ theme }) => ({
  boxSizing: "border-box",
  position: "absolute",
  top: theme.spacing(6),
  right: theme.spacing(-6),
  minWidth: `calc(100% + ${theme.spacing(12)})`,
  width: "max-content",
  padding: theme.spacing(2),
  maxWidth: `calc(100vw - ${theme.spacing(12)})`,
  [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
    maxWidth: 650,
  },
}));

export const StyledResultsContainer = styled("div")(({ theme }) => ({
  display: "grid",
  alignItems: "center",
  gridTemplateColumns: "1fr max-content",
  gridTemplateAreas: `
      "results results"
      "resultsLabel button"
    `,
  rowGap: theme.spacing(0.4),
  columnGap: theme.spacing(2),
  width: "100%",
  padding: theme.spacing(1, 0),
}));

export const StyledResultsList = styled(List)(({ theme }) => ({
  gridArea: "results",
  "& > *": {
    marginBottom: theme.spacing(0.4),
    paddingRight: theme.spacing(0),
  },
  maxHeight: "70vh",
  overflowY: "auto",
}));

export const StyledTabList = styled(List)(({ theme }) => ({
  padding: theme.spacing(1, 0),
  "& > *": {
    marginBottom: theme.spacing(0.4),
    paddingRight: theme.spacing(0),
  },
}));
