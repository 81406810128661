import { useMutation } from "@tanstack/react-query";
import {
  APIError,
  APIMutationOptions,
  APIMutationResult,
  getAPIRequestFunction,
  RequestInitFn,
} from ".";

/**
 * Returns a APIMutationResult, a type based on UseMutationResult(see react-query docs), which is
 * used to send queries to the REST API.
 *
 * Should only be used in places that do not have the auth context loaded.
 *
 * Can also be used for GET requests in specific circumstances. For instance, used
 * to fetch documents from the server and wait for the result.
 *
 * @param relativeUrl Relative URL to request in REST API
 * @param requestInit Initializtion parameters
 * @param options Options to customize query
 * @returns APIMutationResult, type based on UseMutationResult from react-query, see separate docs
 */
export function useAPIMutationNoAuthContext<
  TData,
  TMutationParameters extends Object
>(
  relativeUrl: string,
  requestInit?: RequestInitFn<TMutationParameters>,
  options?: APIMutationOptions<TData, TMutationParameters>
): APIMutationResult<TData, TMutationParameters> {
  const requestAPI = getAPIRequestFunction(true, undefined);
  return useMutation<TData, APIError, TMutationParameters, void>(
    (params: TMutationParameters) =>
      requestAPI(relativeUrl, {
        method: "POST",
        credentials: "include",
        mode: "same-origin",
        ...requestInit?.(params),
      }),
    options
  );
}
