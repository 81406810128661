import { CreditCheckFlowData, CreditCheckFlowStep } from "core/api";
import { StepDataResolver } from "../models";

/**
 * Credit rating data resolver. Only identifier should be
 * sent from the UX to the server.
 */
export const creditCheckDataResolver: StepDataResolver<
  CreditCheckFlowStep,
  CreditCheckFlowData
> = ({ data, fieldValues }) => {
  const ratingStatusValue = data.creditRating?.ratingStatusValue;

  return {
    creditRating: ratingStatusValue
      ? {
          ...data.creditRating,
          score: fieldValues.creditScore ?? data.creditRating.score,
          ratingStatusValue: {
            ...ratingStatusValue,
            name: fieldValues.creditStatus ?? ratingStatusValue.name,
          },
        }
      : data.creditRating,
    identifier: fieldValues.identifier ?? data.identifier,
    overrideSelected: fieldValues.overrideSelected ?? data.overrideSelected,
  };
};
