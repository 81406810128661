import { API, Flow, setPathParameters, useAPIMutation } from "core/api";

export interface CancelActionFlowSettings {
  actionId: number;
}

export function useCancelActionFlow({ actionId }: CancelActionFlowSettings) {
  return useAPIMutation<void, Flow>(
    setPathParameters(API.actions.flows.cancel, {
      actionId,
    }),
    (flow) => ({
      body: JSON.stringify(flow),
      expectedType: "Text",
    })
  );
}
