import { ListItem, ListItemText, styled } from "common/components";

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "180px min-content",
  columnGap: theme.spacing(1),
}));

export const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  textAlign: "right",
}));
