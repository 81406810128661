import { createContext, ReactElement, ReactNode, useContext } from "react";
import { AuthUserResponse } from "../models";
import {
  Authentication,
  AuthenticationSettings,
  useAuthentication,
} from "./useAuthentication";

const AuthenticationContext = createContext({});

export interface AuthenticationContextProviderProps<
  TUserResponse extends AuthUserResponse
> {
  children: ReactNode;
  settings?: AuthenticationSettings<TUserResponse>;
}

export function useAuthenticationContext() {
  return useContext(AuthenticationContext) as Authentication;
}

export function AuthenticationContextProvider<
  TUserResponse extends AuthUserResponse
>({
  children,
  settings,
}: AuthenticationContextProviderProps<TUserResponse>): ReactElement {
  const state = useAuthentication(settings);
  return (
    <AuthenticationContext.Provider value={state}>
      {children}
    </AuthenticationContext.Provider>
  );
}
