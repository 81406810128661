import { ListItem, ListItemText } from "common/components";
import { convertBooleanToText, formatTimestamp } from "common/utils";
import { FlowData, OrderAttributesFlowStep } from "core/api";
import { useTranslation } from "i18n";
import { Fragment } from "react";

export interface OrderAttributesConfirmationProps {
  step: OrderAttributesFlowStep;
  flowData: FlowData;
}

/**
 * Sub component to confirmation. The purpose of this component is
 * to make the main Confirmation component more readable,
 * abstracting some of the logic into smaller sub components.
 *
 * OrderAttributesConfirmation is specific to confirmation steps where
 * the flow contains a step with the uiComponent: "OrderAttributes" or "ChangeEntityStatus".
 *
 * @param getValues A helper function from react-hook-form. The function is passed
 * into the parent Confirmation component and in turn passed here.
 *
 * @returns A JSX element with with effective date and a checkbox for approving the order.
 */
export function OrderAttributesConfirmation({
  step,
  flowData,
}: OrderAttributesConfirmationProps): JSX.Element | null {
  const { t } = useTranslation(["common"]);
  const fieldFlowData =
    flowData.uiComponent === "OrderAttributes" ? flowData : undefined;

  return (
    <Fragment key={step.id}>
      <ListItem>
        <ListItemText
          primary={t("common:effectiveDate")}
          secondary={formatTimestamp(
            fieldFlowData?.orderAttributes.effectiveDate
          )}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={t("common:approveOrders")}
          secondary={convertBooleanToText(
            fieldFlowData?.orderAttributes.approved
          )}
        />
      </ListItem>
    </Fragment>
  );
}
