export enum AccessLevel {
  /**
   * No access at all, if this is a GUI component,
   * then it should be hidden.
   */
  NO_ACCESS = 0,
  /** Read access, data and GUI visible. */
  READ = 1,
  /** Read and write, but not delete access. */
  WRITE = 2,
  /** Full access (read+write+delete). */
  FULL = 3,
}
