import { Customer, useFetchCustomerStatusReasons } from "core/api";
import { useCallback } from "react";

export function useCustomerStatus() {
  const { data: customerStatusReasons } = useFetchCustomerStatusReasons();

  const findCustomerStatusReasonText = useCallback(
    (statusId: number) => {
      if (!customerStatusReasons) {
        return "";
      }
      const statusReason = customerStatusReasons.find(
        ({ statusReasonId }) => statusReasonId === statusId
      );
      return statusReason?.name ?? "";
    },
    [customerStatusReasons]
  );

  const getCustomerStatus = useCallback(
    (customer: Customer) => {
      return {
        status: customer?.status,
        reasonText: findCustomerStatusReasonText(customer?.statusReasonId),
      };
    },
    [findCustomerStatusReasonText]
  );

  return { getCustomerStatus };
}
