import {
  FindNetworkElementFlowData,
  FindNetworkElementFlowStep,
  TableFlowStep,
} from "core/api";
import { StepDataResolver } from "..";
import { tableDataResolver } from "../Table";

/**
 * Resolve the find fields and extract the table part and use the table step resolver.
 */
export const findNetworkElementDataResolver: StepDataResolver<
  FindNetworkElementFlowStep,
  FindNetworkElementFlowData
> = ({ flow, flowStep, data, fieldValues }) => {
  const { tableStepConfig } = flowStep.config;

  const mergedTableStep = {
    ...flowStep,
    ...data.tableStep,
    config: { ...tableStepConfig },
  } as unknown as TableFlowStep;

  // Resolve encapsulated table step
  const returnProps = tableDataResolver({
    flow,
    flowStep: mergedTableStep,
    data: data.tableStep,
    fieldValues,
  });

  return {
    tableStep: { ...data.tableStep, ...returnProps },
    searchText: fieldValues.searchText ?? data.searchText,
    networkElementTypeId:
      fieldValues.networkElementTypeId ?? data.networkElementTypeId,
    maxTableItems: fieldValues.maxTableItems ?? data.maxTableItems,
  } as FindNetworkElementFlowData;
};
