import {
  Address,
  Customer,
  Document,
  EntityData,
  EntityType,
  Invoice,
  Note,
  Payment,
  Product,
  Subscription,
  Task,
  User,
} from "core/api";
import { useCallback } from "react";
import {
  useAddressStatus,
  useCustomerStatus,
  useDocumentStatus,
  useInvoiceStatus,
  useNoteStatus,
  usePaymentStatus,
  useProductStatus,
  useTaskStatus,
  useUserStatus,
} from "./statusHelpers";

/**
 * Provides a function for getting status and description for an entity
 * based on entity type.
 *
 * @returns Function for getting status and description
 */
export function useEntityStatus() {
  const { getCustomerStatus } = useCustomerStatus();
  const { getInvoiceStatus } = useInvoiceStatus();
  const { getNoteStatus } = useNoteStatus();
  const { getPaymentStatus } = usePaymentStatus();
  const { getSubscriptionStatus } = useProductStatus();
  const { getTaskStatus } = useTaskStatus();
  const { getUserStatus } = useUserStatus();
  const { getDocumentStatus } = useDocumentStatus();

  const { getAddressStatus } = useAddressStatus();

  const getStatus = useCallback(
    (entityType: EntityType, entity: EntityData | undefined) => {
      if (!entity) {
        return { status: undefined, reasonText: undefined };
      }
      switch (entityType) {
        case EntityType.CUSTOMER:
          return getCustomerStatus(entity as Customer);
        case EntityType.INVOICE:
          return getInvoiceStatus(entity as Invoice);
        case EntityType.NOTE:
          return getNoteStatus(entity as Note);
        case EntityType.PAYMENT:
          return getPaymentStatus(entity as Payment);
        case EntityType.PRODUCT:
          return getSubscriptionStatus(entity as Product);
        case EntityType.SUBSCRIPTION:
          return getSubscriptionStatus(entity as Subscription);
        case EntityType.TASK:
          return getTaskStatus(entity as Task);
        case EntityType.USER:
          return getUserStatus(entity as User);
        case EntityType.DOCUMENT:
          return getDocumentStatus(entity as Document);
        case EntityType.ADDRESS:
          return getAddressStatus(entity as Address);

        case EntityType.MESSAGE:
        case EntityType.NP_CASE:
        case EntityType.INVENTORY:
        case EntityType.PAYMENT_PROVIDER_HISTORY:
        case EntityType.ADJUSTMENT:
          // Compability only until completely removed and only field data is used.
          return { status: undefined, reasonText: undefined };
        default:
          console.error(`No matching status reason text for: ${entityType}`);
          return { status: undefined, reasonText: undefined };
      }
    },
    [
      getCustomerStatus,
      getDocumentStatus,
      getInvoiceStatus,

      getNoteStatus,
      getPaymentStatus,
      getSubscriptionStatus,
      getTaskStatus,
      getUserStatus,
      getAddressStatus,
    ]
  );

  return { getStatus };
}
