import { SxProps, Theme } from "common/components";
import { Navigate, Route, Routes, useParams } from "common/routing";
import {
  EntitiesMap,
  EntityData,
  EntityType,
  Icon,
  ScreenConfig,
  useFetchScreen,
} from "core/api";
import { EditGeneral, Head, IdType } from "core/components";
import { Messages } from "core/message";
import {
  RegisterScreenEntities,
  ScreenEntities,
  useRegisterParams,
} from "core/store";
import { LoggingErrorBoundary } from "error/components";
import { useTranslation } from "i18n";
import { ComponentType, useEffect, useMemo } from "react";
import { DynamicView, DynamicViewProps } from "./DynamicView";
import { LoadingScreen } from "./LoadingScreen";
import {
  formatToNavigationGroups,
  useRegisterScreenNavigationGroups,
} from "./NavigationGroupsService";
import { ViewsContainer } from "./Screen.styles";
import { ScreenContextProvider } from "./ScreenContext";
import {
  buildLoadedEntities,
  createNavigationGroupScreen,
  getScreenRedirectUrl,
} from "./appScreenUtils";
import { useScreenEntity, useScreenTitle } from "./hooks";
import { addToScreenEntities, formatEntityUrl } from "./screenUtils";

export interface ScreenViewProps {}

export interface ScreenContentProps {
  config: ScreenConfig | undefined;
  statusIcon?: Icon;
  title: string;
  breadcrumbTitle: string;
  screenEntity?: EntityData;
  screenEntities: ScreenEntities;
}

export type ScreenContentComponent = ComponentType<ScreenContentProps>;

export interface ScreenProps {
  screenId: number;
  loadedEntities?: EntitiesMap;
  screenEntities?: ScreenEntities;
  importView: DynamicViewProps["importView"];
  contentComponent?: ComponentType<ScreenContentProps>;
  useScreenData?(data: ScreenContentProps): void;
  pageNotFoundComponent: ComponentType;
  viewsContainerSx?: SxProps<Theme>;
}

/**
 * Recursively renders AppScreen based on screen config.
 * @param ScreenProps
 * @returns AppScreen
 */

export function Screen({
  screenId,
  loadedEntities,
  screenEntities = {},
  contentComponent: ContentComponent,
  pageNotFoundComponent: PageNotFound,
  useScreenData,
  importView,
  viewsContainerSx,
}: ScreenProps) {
  const params = useParams<Record<string, any>>();
  const { t } = useTranslation(["error"]);

  useRegisterParams();
  const { data, isLoading, fetchStatus, isSuccess, isError, error } =
    useFetchScreen(screenId, true, loadedEntities);

  const {
    data: screenEntity,
    isError: isScreenEntityError,
    error: screenEntityError,
  } = useScreenEntity(data, params);

  //Display error if screen failed to load
  useEffect(() => {
    if (error || screenEntityError) {
      const configError = error?.userMessage || error?.developerMessage;
      const entityError =
        screenEntityError?.userMessage || screenEntityError?.developerMessage;
      const errorMessage = configError || entityError;

      Messages().openErrorDialog({
        details: t(
          !!errorMessage
            ? "error:screenLoadingError"
            : "error:screenLoadingError_nomessage",
          {
            serverMessage: errorMessage,
          }
        ),
      });
    }
  }, [error, screenEntityError, t]);

  const {
    title,
    breadcrumbTitle,
    icon: statusIcon,
  } = useScreenTitle(data, screenEntity);

  const entityType = data?.entityType ?? EntityType.NONE;
  const entityData = screenEntity;

  const _screenEntities: ScreenEntities = useMemo(
    () => addToScreenEntities(screenEntities, entityType, entityData),
    [screenEntities, entityType, entityData]
  );

  const navigationGroupScreen = useMemo(
    () => createNavigationGroupScreen(data, params),
    [params, data]
  );

  useRegisterScreenNavigationGroups(
    data?.screenId,
    formatToNavigationGroups(navigationGroupScreen),
    data &&
      formatEntityUrl(screenEntity, data?.routeProps.path, Object.keys(params))
  );

  /** Memoized views to prevent rerenders triggered by parent context providers */
  const views = useMemo(() => {
    if (!data || (data.entityFetchUrl && !screenEntity)) {
      return [];
    }
    return data?.views?.map(
      ({ viewId: id, type, config, name, actionsConfig, widgets }) => (
        <LoggingErrorBoundary key={id}>
          <DynamicView
            importView={importView}
            title={name}
            actionsConfig={actionsConfig}
            viewId={id}
            viewType={type}
            viewProps={config}
            screenEntity={screenEntity}
            screenEntityType={data?.entityType}
            screenEntities={_screenEntities}
            widgets={widgets}
            screenEntityId={screenEntity?.entityId}
          />
        </LoggingErrorBoundary>
      )
    );
  }, [data, screenEntity, _screenEntities]);

  useScreenData?.({
    config: data,
    title,
    breadcrumbTitle,
    screenEntities: _screenEntities,
    screenEntity,
  });

  if ((!isLoading && !isSuccess) || isError || isScreenEntityError) {
    return null;
  }

  if (isLoading || !data || (data?.entityFetchUrl && !screenEntity)) {
    return <LoadingScreen />;
  }

  return (
    <Routes>
      <Route
        index
        element={
          data?.redirectOnLoad ? (
            <Navigate to={getScreenRedirectUrl(data, params)} replace />
          ) : (
            <LoggingErrorBoundary>
              <Head>
                <title>
                  {data?.showTitle === undefined || data?.showTitle
                    ? title
                    : ""}
                </title>
              </Head>
              <RegisterScreenEntities entities={_screenEntities} />
              <ScreenContextProvider
                screenConfig={data}
                entities={_screenEntities}
              >
                <EditGeneral idType={IdType.Screen} id={screenId} />

                {ContentComponent && (
                  <ContentComponent
                    config={data}
                    title={
                      data?.showTitle === undefined || data?.showTitle
                        ? title
                        : ""
                    }
                    statusIcon={statusIcon}
                    breadcrumbTitle={breadcrumbTitle}
                    screenEntity={screenEntity}
                    screenEntities={_screenEntities}
                  />
                )}
                <ViewsContainer sx={viewsContainerSx}>{views}</ViewsContainer>
              </ScreenContextProvider>
            </LoggingErrorBoundary>
          )
        }
      />
      {data?.screens?.map(({ screenId: id, routeProps }) => {
        return (
          <Route
            key={id}
            path={routeProps.relativePath + "/*"}
            element={
              <Screen
                key={id}
                screenId={id}
                loadedEntities={buildLoadedEntities(
                  data,
                  loadedEntities,
                  screenEntity
                )}
                screenEntities={_screenEntities}
                contentComponent={ContentComponent}
                pageNotFoundComponent={PageNotFound}
                importView={importView}
                useScreenData={useScreenData}
                viewsContainerSx={viewsContainerSx}
              />
            }
          />
        );
      })}
      {isSuccess && (
        <Route
          path="*"
          element={
            fetchStatus !== "fetching" ? <PageNotFound /> : <LoadingScreen />
          }
        />
      )}
    </Routes>
  );
}
