import {
  LocalizationProvider,
  TimePicker,
  TimePickerProps,
} from "@mui/x-date-pickers";
import { TextField, TextFieldProps } from "common/components";
import { DateAdapter } from "common/utils";
import { useTranslation } from "i18n";
import { useEffect } from "react";
import {
  FormControlledFieldProps,
  withFormController,
} from "../FormController";
import {
  getFormDatePickerOnChange,
  getKeyboardInputDateValidator,
  insertDateErrorHint,
} from "../formDateUtils";

export interface FormTimePickerProps
  extends Omit<
      TimePickerProps<any, any>,
      "value" | "onChange" | "renderInput" | "label"
    >,
    FormControlledFieldProps {
  helperText?: string;
  "data-cy"?: string;
}

/** Form time picker */
export const FormTimePicker = withFormController<FormTimePickerProps>(
  ({
    fieldName,
    helperText,
    inputFormat = "LTS",
    renderState,
    "data-cy": dataCy,
    ...props
  }) => {
    const { t } = useTranslation(["common"]);
    const {
      field: { value, onChange, ...field },
      fieldState: { error },
      gridColumn,
      isDisabled,
      setExtendedRules,
    } = renderState;

    useEffect(() => {
      setExtendedRules({
        validate: {
          checkTime: getKeyboardInputDateValidator(
            inputFormat,
            t("common:forms.date.timeError"),
            props.required,
            t("common:forms.fieldIsMandatory")
          ),
        },
      });
    }, [setExtendedRules, t, inputFormat, props.required]);

    return (
      <LocalizationProvider dateAdapter={DateAdapter}>
        <TimePicker
          {...props}
          {...field}
          inputFormat={inputFormat}
          value={value?.event ?? value}
          onChange={getFormDatePickerOnChange(onChange)}
          disabled={isDisabled}
          renderInput={(params: TextFieldProps) => (
            <TextField
              data-cy={dataCy}
              name={fieldName}
              sx={{ minWidth: "190px", gridColumn }}
              {...params}
              error={!!error}
              helperText={insertDateErrorHint(
                error,
                helperText,
                params.inputProps?.placeholder
              )}
              InputLabelProps={{
                required: props.required,
              }}
            />
          )}
        />
      </LocalizationProvider>
    );
  }
);
