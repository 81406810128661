import { FormCheckBox, FormTextField } from "common/components";
import { addFlowStepPrefix } from "common/utils";
import {
  ConfigureInvoiceDunningFlowData,
  ConfigureInvoiceDunningFlowStepConfig
} from "core/api";
import { useTranslation } from "i18n";
import { FlowStepComponentProps } from "../models";
import { StyledStepContentWrapper } from "../steps.styles";

export type ConfigureInvoiceDunningProps = FlowStepComponentProps<
  ConfigureInvoiceDunningFlowStepConfig,
  ConfigureInvoiceDunningFlowData
>;

/** Configure Invoice step component */
export function ConfigureInvoiceDunning({
  control,
  data,
  flowStep,
}: ConfigureInvoiceDunningProps): JSX.Element {
  const { t } = useTranslation(["invoice"]);
  const { block, dunningMeasure } = flowStep.config;

  const { externalInvoiceId } = data;
  let label: string;

  switch (dunningMeasure) {
    case "REMINDERS":
      label = block
        ? t("invoice:configureInvoiceDunning:blockReminders")
        : t("invoice:configureInvoiceDunning:unblockReminders");
      break;
    case "DEBT_COLLECTION":
      label = block
        ? t("invoice:configureInvoiceDunning:blockDebtCollection")
        : t("invoice:configureInvoiceDunning:unblockDebtCollection");
      break;
    default:
      label = "ERROR: Unknown dunningMeasure value";
  }

  return (
    <StyledStepContentWrapper sx={{ gridTemplateColumns: "repeat(1, 1fr)" }}>
      <FormTextField
        fieldName={addFlowStepPrefix(flowStep, "invoiceId")}
        defaultValue={externalInvoiceId}
        control={control}
        label={t("invoice:invoiceId")}
        disabled
      />
      <FormCheckBox
        fieldName={addFlowStepPrefix(flowStep, "block")}
        defaultValue={true}
        control={control}
        label={label}
        disabled
      />
    </StyledStepContentWrapper>
  );
}
