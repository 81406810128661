import { IDBPTransaction } from "idb";
import { ActionLock, ACTION_LOCK_KEY, DB, HttpCacheDB } from "./DBService";

type ActionTransaction = IDBPTransaction<HttpCacheDB, [any], "readwrite">;

export interface ActionLockSettings {
  /** Timeout before lock is automatically ignored */
  timeout?: number;
  /** Ignore lock on failure to create DB transaction */
  ignoreLockFailure?: boolean;
}

/** Default value for action lock timeout */
const DEFAULT_LOCK_TIMEOUT = 2500;
/** Interval between checking lock state on locked action */
const CHECK_LOCK_INTERVAL = 200;

/**
 * Checks by key if action is locked. When unlocked the action is
 * locked until action callback has completed.
 *
 * @param key Identifier for action lock
 * @param onAction Callback function when action is no longer locked
 * @param settings Action lock settings
 * @returns Result from callback function
 */
export async function actionLock<TResult = void>(
  key: string,
  onAction: () => Promise<TResult>,
  settings?: ActionLockSettings
): Promise<TResult> {
  let tx = await DB.createTransaction(ACTION_LOCK_KEY);

  if (tx) {
    // Set timeout for escaping in case of failure to release lock
    let escapeTimeout = false;
    const timeout = settings?.timeout ?? DEFAULT_LOCK_TIMEOUT;
    const timeoutId = setTimeout(() => (escapeTimeout = true), timeout);

    const { unsubscribe } = onPageHide(key);
    while (await getIsLocked(tx, key)) {
      if (escapeTimeout) {
        break;
      }
      await tx.done;
      await wait(CHECK_LOCK_INTERVAL);
      tx = (await DB.createTransaction(ACTION_LOCK_KEY)) || tx;
    }

    clearTimeout(timeoutId);
    await setIsLocked(tx, key, true);
    const result = await onAction();
    tx = (await DB.createTransaction(ACTION_LOCK_KEY)) || tx;
    await setIsLocked(tx, key, false);
    unsubscribe();
    return result;
  } else if (settings?.ignoreLockFailure) {
    return onAction();
  } else {
    throw new Error(`No transaction available for: ${ACTION_LOCK_KEY}`);
  }
}

async function getIsLocked(
  tx: ActionTransaction,
  key: string
): Promise<boolean | undefined> {
  return (await tx.objectStore(ACTION_LOCK_KEY).get(key))?.isLocked;
}

async function setIsLocked(
  tx: ActionTransaction,
  lockKey: string,
  isLocked: boolean
) {
  const lockState: ActionLock = { lockKey, isLocked };
  await tx.objectStore(ACTION_LOCK_KEY).put(lockState);
}

/**
 * Unlock action lock on pagehide event
 * @param key Action lock key
 * @returns Object with unsubscribe function
 */
function onPageHide(key: string) {
  const handlePageHide = async () => {
    const tx = await DB.createTransaction(ACTION_LOCK_KEY);
    tx && setIsLocked(tx, key, false);
  };

  window.addEventListener("pagehide", handlePageHide);

  return {
    unsubscribe: () => {
      window.removeEventListener("pagehide", handlePageHide);
    },
  };
}

/**
 * Resolves a Promise in given time.
 * @param time Time in milliseconds
 */
async function wait(time: number) {
  await new Promise((resolve) => setTimeout(resolve, time));
}
