import { useTheme } from "common/components";
import { ActionButton } from "core/components";
import { debounce } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";

interface ActionSizes {
  /**
   * The count refers to the number of buttons
   * counted from the first in the list, and the
   * matching value is the number of pixels those
   * buttons claim when rendered.
   */
  [count: number]: number;
}

/** Accounts for size of the menu button, plus some extra margin */
const BUFFER_MARGIN = 20;

/**
 * Calculates maximum number of actions that will fit inside the container
 * (ref for container returned from hook) by calculating width of children.
 *
 * @param actions All actions
 * @param maxWidth Max width of actions in container
 * @param maxActions Maximum visible actions, the rest is hidden in menu
 * @returns Maximum count of actions and a container ref
 */
export function useActionsMaxCount(
  actions: ActionButton[],
  maxWidth: number | undefined,
  maxActions: number | undefined
) {
  const theme = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);
  const [maxCount, setMaxCount] = useState<number>(Number.MAX_SAFE_INTEGER);
  const [sizes, setSizes] = useState<ActionSizes>({});

  /**
   * Reset max count to maximum value
   *
   * NOTE: Uses useMemo instead of useCallback to not
   * confuse the react-hooks/exhaustive-deps rule.
   * The debounce function makes it impossible for
   * ESLint to identify the dependencies for the
   * function.
   */
  const resetMaxCount = useMemo(
    () =>
      debounce(() => {
        setMaxCount(Number.MAX_SAFE_INTEGER);
      }, 50),
    []
  );

  /** Reset max count on changed actions */
  useEffect(() => {
    resetMaxCount();
  }, [actions, resetMaxCount]);

  /**
   * Reset maxCount on resize of window to be able
   * to measure button sizes
   */
  useEffect(() => {
    window.addEventListener("resize", resetMaxCount);
    return () => window.removeEventListener("resize", resetMaxCount);
  }, [resetMaxCount]);

  /**
   * Measure the total widths of buttons depending on
   * the number of them rendered
   */
  useEffect(() => {
    if (containerRef.current && maxCount === Number.MAX_SAFE_INTEGER) {
      const container = containerRef.current;
      let count = 0;
      let width = 0;
      const _sizes: ActionSizes = {};
      for (const child of Array.from(container.children)) {
        const childWidth = child.clientWidth;
        width += childWidth + parseInt(theme.spacing(1));
        count++;
        _sizes[count] = width + BUFFER_MARGIN;
      }
      setSizes(_sizes);
    }
  }, [actions, maxWidth, maxCount, theme]);

  /**
   * Check if action buttons fit the container and update
   * maxCount if the number of buttons that fits has changed
   */
  useEffect(() => {
    if (containerRef.current) {
      const container = containerRef.current;
      const maxContainerWidth = maxWidth ?? container.clientWidth;

      const getBufferWidth = (actionCount: number) =>
        actionCount < actions.length ? 1.5 * BUFFER_MARGIN : 0;

      const count = Object.values(sizes).reduce(
        (totalCount, width) =>
          width <= maxContainerWidth - getBufferWidth(totalCount)
            ? totalCount + 1
            : totalCount,
        0
      );

      const newCount =
        maxActions !== undefined && count > maxActions ? maxActions : count;

      if (maxCount !== newCount) {
        setMaxCount(newCount);
      }
    }
  }, [sizes, maxWidth, maxCount, maxActions, actions]);

  return {
    containerRef,
    maxCount:
      maxActions !== undefined ? Math.min(maxActions, maxCount) : maxCount,
  };
}
