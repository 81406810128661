import { ArrowForward } from "common/assets/icons";
import { Button, Card, CardHeader, Typography } from "common/components";
import { URLS } from "common/routing";
import { useAuthenticationContext } from "core/auth";
import { useTranslation } from "i18n";
import { ReactElement, useEffect } from "react";

import {
  LoginContainer,
  StyledCardContent,
  StyledContainer,
  StyledImg,
  StyledLink,
} from "./LoggedOut.styles";

export interface LoggedOutProps {
  cardHeaderSrc: string;
  cardHeaderAlt: string;
}

/**
 * A reusable component presenting a logged out message and a link to log in again
 * @param cardHeaderSrc The source for the image presented on the logout page
 * @param cardHeaderAlt An alternative if the image is not working
 * @returns A react jsx element
 */
export function LoggedOut({
  cardHeaderSrc,
  cardHeaderAlt,
}: LoggedOutProps): ReactElement {
  const { state, navigateToScreen } = useAuthenticationContext();
  const { t } = useTranslation("common");

  useEffect(() => {
    if (state.isAuthenticated) {
      navigateToScreen();
    }
  }, [state.isAuthenticated, navigateToScreen]);

  return (
    <LoginContainer>
      <StyledContainer maxWidth="xl">
        <Card>
          <CardHeader
            title={process.env.REACT_APP_APP_NAME ?? ""}
            titleTypographyProps={{ variant: "h5" }}
            avatar={<StyledImg src={cardHeaderSrc} alt={cardHeaderAlt} />}
          />

          <StyledCardContent>
            <Typography variant="h4">{t("loggedOut.message")}</Typography>
            {state.error && <Typography>{state.error?.userMessage}</Typography>}
            <StyledLink to={URLS.login.path} replace>
              <Button data-cy="goToLoginPage" endIcon={<ArrowForward />}>
                {t("loggedOut.gotoLogin")}
              </Button>
            </StyledLink>
          </StyledCardContent>
        </Card>
      </StyledContainer>
    </LoginContainer>
  );
}
