import {
  API,
  APIQueryResult,
  ProductOptionsContainer,
  useAPIQuery,
} from "core/api";

/**
 * Find all product offering options for provided product
 *
 * @param enabled Directive for useQuery.
 * @param productId The product id to get options for.
 *
 * @returns
 */
export function useFetchProductOptionsContainer(
  productId?: number,
  action?: string
): APIQueryResult<ProductOptionsContainer> {
  return useAPIQuery(
    ["fetchProductOptionsContainer", productId],
    API.productservice.productOptionsContainer,
    {
      pathParams: {
        productId,
      },
      queryParams: {
        allowed: action,
      },
    },
    {
      enabled: productId !== undefined && !isNaN(productId) && productId !== -1,
    }
  );
}
