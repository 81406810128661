import { Visibility, VisibilityOff } from "common/assets/icons";
import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from "common/components";
import { useToggle } from "common/hooks";
import { useTranslation } from "i18n";
import { ReactElement } from "react";
import {
  FormControlledFieldProps,
  withFormController,
} from "../FormController";

export type FormPasswordFieldProps = Omit<TextFieldProps, "variant"> &
  FormControlledFieldProps;

/**
 * Adds form handling for Password field.
 *
 * @param fieldName Static string used for the unique ids of the inner components.
 * @param control Form control.
 * @param validationRules Optional field validation rules
 * @param showPassword Boolean for displaying or hiding password content
 * @param toggleShowPassword Function for toggle password visibility
 * @param props Any remaining properties.
 */

export const FormPasswordField = withFormController<FormPasswordFieldProps>(
  ({ fieldName, helperText, renderState, ...props }): ReactElement => {
    const [showPassword, toggleShowPassword] = useToggle(false);
    const { t } = useTranslation(["common"]);
    const {
      field,
      fieldState: { error },
      gridColumn,
      isDisabled,
    } = renderState;
    return (
      <TextField
        data-cy={fieldName}
        autoComplete={props.autoComplete ?? "current-password"}
        type={showPassword ? "text" : "password"}
        InputLabelProps={{ required: props.required }}
        error={!!error}
        helperText={error?.message ?? helperText}
        {...props}
        {...field}
        disabled={isDisabled}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label={t("common:buttons.toggleVisibility")}
                onClick={toggleShowPassword}
                edge="end"
                size="large"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={[
          {
            gridColumn,
            "&::-ms-reveal": { display: "none" },
          },
          ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
        ]}
      />
    );
  }
);
