import { useMutation } from "@tanstack/react-query";
import {
  APIError,
  DownloadFileResponse,
  RequestInitWithParams,
  useAPIRequest,
} from "core/api";

export interface APIDownloadParameters {
  /** Relative API url */
  relativeUrl: string;
  /** Request settings */
  requestInit?: RequestInitWithParams;
}

/**
 * General hook for downloading files through the API.
 * @returns Blob and file name from headers
 */
export function useFileDownload() {
  const { downloadAPIFile } = useAPIRequest();
  return useMutation<DownloadFileResponse, APIError, APIDownloadParameters>(
    ({ relativeUrl, requestInit }) => downloadAPIFile(relativeUrl, requestInit)
  );
}
