import {
  ProductPartsConfigurationFlowData,
  ProductPartsConfigurationFlowStep,
} from "core/api";
import { StepDataResolver } from "../models";

/**
 * Product Offering Parts data resolver
 *
 * Formats Product Parts  Configuration based on form field values
 */
export const productPartsConfigurationDataResolver: StepDataResolver<
  ProductPartsConfigurationFlowStep,
  ProductPartsConfigurationFlowData
> = ({ data, fieldValues }) => {
  // Map through each item in cardinalityRowList and update values
  const updatedCardinalityRowList = [...data.cardinalityRowList].map(
    (rowItem) => {
      const { rowId, currentOneOf, currentValue } = rowItem;
      const formSliderData = fieldValues["formSlider_" + rowId];

      // Check if currentOneOf.productOffering exists, and update values for the currentOneOf
      if (currentOneOf?.productOffering && formSliderData) {
        currentOneOf.currentValue =
          formSliderData.currentValue ?? currentOneOf.currentValue;
        currentOneOf.minValue =
          formSliderData.minValue ?? currentOneOf.minValue;
        currentOneOf.maxValue =
          formSliderData.maxValue ?? currentOneOf.maxValue;
        currentOneOf.productOffering =
          formSliderData?.productOffering ?? currentOneOf.productOffering;
      } else {
        // Set currentValue to the value from formSliderData, or use existing value
        rowItem.currentValue = formSliderData?.currentValue ?? currentValue;
      }
      return rowItem;
    }
  );

  return {
    cardinalityRowList: updatedCardinalityRowList,
  };
};
