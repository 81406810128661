import {
  EntitiesMap,
  EntityData,
  ScreenConfig,
  setPathParameters,
} from "core/api";
import { formatEntityUrl } from "core/components";

export function getScreenRedirectUrl(
  config: ScreenConfig,
  pathParams: Record<string, any>
) {
  return config?.redirectPath
    ? setPathParameters(config?.redirectPath, pathParams)
    : config?.screens[0].routeProps.path;
}

export function buildLoadedEntities(
  data: ScreenConfig,
  loadedEntities: EntitiesMap | undefined,
  screenEntity?: Partial<EntityData>
): EntitiesMap | undefined {
  return screenEntity && data.entityType
    ? ({
        ...loadedEntities,
        [data.entityType]: [
          screenEntity,
          ...(loadedEntities?.[data.entityType] ?? []),
        ],
      } as EntitiesMap)
    : loadedEntities;
}

export function createNavigationGroupScreen(
  config: ScreenConfig | undefined,
  params: Record<string, any>
): ScreenConfig | undefined {
  if (config) {
    const { routeProps, screens, ...rest } = config;
    return {
      routeProps: {
        path: formatEntityUrl(params, routeProps.path),
        relativePath: formatEntityUrl(params, routeProps.relativePath),
      },
      screens: screens.map((screen) => {
        const { routeProps, ...rest } = screen;

        return {
          routeProps: {
            path: formatEntityUrl(params, routeProps.path),
            relativePath: formatEntityUrl(
              params,
              routeProps.relativePath,
              Object.keys(params)
            ),
          },
          ...rest,
        };
      }),
      ...rest,
    };
  }
  return undefined;
}
