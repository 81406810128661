import { styled } from "common/components";

export const LoginContainer = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  width: "100%",
  height: "100vh",
  display: "grid",
  placeItems: "center",
}));

export const StyledImg = styled("img")(() => ({
  width: "86px",
  height: "auto",
}));

export const StyledLoginForm = styled("form")(({ theme }) => ({
  width: "100%",
  marginTop: theme.spacing(1),
  display: "grid",
  rowGap: theme.spacing(1.5),
}));
