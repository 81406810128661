import {
  API,
  APIQueryOptions,
  APIQueryResult,
  createApiUrl,
  invalidateAPIQuery,
  useAPIQuery,
} from "core/api";
import { Bookmark } from "core/api/models/Bookmark";
import { getCurrentCustomerGroupId } from "core/auth";
import { Cache } from "core/idb";

/**
 * Fetch user bookmarks.
 * @param keyName Config key.
 * @returns The user's bookmarks.
 */
export function useFindBookmarks(
  queryOptions?: APIQueryOptions<Bookmark[]>
): APIQueryResult<Bookmark[]> {
  return useAPIQuery(
    [API.myAccount.bookmarks, getCurrentCustomerGroupId()],
    API.myAccount.bookmarks,
    {
      pathParams: {},
    },
    queryOptions
  );
}

export function invalidateFindBookmarks(customerGroupId?: number) {
  const cgId: number = customerGroupId ?? getCurrentCustomerGroupId() ?? -1;

  const url = createApiUrl(API.myAccount.bookmarks, {
    cgId,
  });
  Cache.clearResponse("GET", url);

  invalidateAPIQuery([API.myAccount.bookmarks, cgId]);
}
