/**
 * The type of a payment match detail can have the values specified by this enum.
 */
export enum PaymentMatchDetailType {
  /**
   * Payment has been matched/partly matched to an Invoice.
   */
  MATCH = "MATCH",

  /**
   * A previous Match has been Unmatched from the Invoice.
   */
  UNMATCH = "UNMATCH",
}
