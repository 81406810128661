import {
  CreateFlowParams,
  EntityType,
  Flow,
  setActionFlowQueryData,
  usePerformActionFlow,
} from "core/api";
import { FieldValues, SubmitHandler } from "react-hook-form";
import { insertStepValues } from "./flowUtils";
import { useActionEffectResolver } from "./useActionEffectResolver";

export interface SubmitFlowSettings {
  /** Action id */
  actionId: number;
  /** Current flow data */
  data: Flow | undefined;
  /** Current step id */
  currentStepId: string | undefined;
  entityType: EntityType;
  entityId: string;
  params: CreateFlowParams;
  /** Function for closing dialog */
  closeDialog(cancelFlow: boolean): void;
  setErrorMessage(message: string | undefined): void;
}

/**
 * Returns handler for submitting current flow
 * @param submitSettings
 * @returns Method for submitting and submit state
 */
export function useSubmitFlow({
  actionId,
  entityType,
  entityId,
  params,
  data,
  currentStepId,
  closeDialog,
  setErrorMessage,
}: SubmitFlowSettings) {
  const { mutate: perform, ...performState } = usePerformActionFlow({
    actionId,
  });
  const resolver = useActionEffectResolver();

  const onSubmit: SubmitHandler<FieldValues> = (formData) => {
    if (data && currentStepId) {
      setErrorMessage(undefined);
      perform(
        { data: insertStepValues(data, formData, currentStepId) },
        {
          onSuccess(response) {
            switch (response.status) {
              case "SUCCESS":
                setActionFlowQueryData(
                  { actionId, entityType, entityId, params },
                  response.flow
                );
                closeDialog(false);
                resolver(response.effects);
                break;
              case "STEP_VALIDATION_ERROR":
                setActionFlowQueryData(
                  { actionId, entityType, entityId, params },
                  response.flow
                );
                break;
              case "ERROR":
              default:
                setErrorMessage(response.apiError.userMessage);
                break;
            }
          },
        }
      );
    }
  };

  return { onSubmit, performState };
}
