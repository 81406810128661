import { CircularProgress, styled } from "common/components";

const LoadingScreenContainer = styled("div")(({ theme }) => ({
  display: "grid",
  placeItems: "center",
  width: "100%",
  height: "100%",
  minHeight: theme.spacing(20),
}));

/** Screen for loading state when fetching config data */
export function LoadingScreen() {
  return (
    <LoadingScreenContainer>
      <CircularProgress />
    </LoadingScreenContainer>
  );
}
