import { ListItem, ListItemText } from "common/components";
import { FindInventoryFlowStep, FlowData } from "core/api";
import { Fragment } from "react";

export interface FindInventoryConfirmationProps {
  step: FindInventoryFlowStep;
  flowData: FlowData;
  title?: string; // optional title to override the use of the steps name
}

/**
 * Sub component to confirmation.
 *
 * FindInventoryConfirmation is specific to confirmation steps where
 * the flow contains a step with the uiComponent: "FindInventory".
 *
 * @param step The current step in the parent components loop.
 * @param flowData data property from the flow object, this is fetched through
 *
 * @returns A JSX element with item(s) selected in the table.
 */
export function FindInventoryConfirmation({
  step,
  flowData,
  title,
}: FindInventoryConfirmationProps): JSX.Element {
  const fieldFlowData =
    flowData.uiComponent === "FindInventory" ? flowData : undefined;

  return (
    <Fragment key={step.id}>
      <ListItem>
        <ListItemText
          primary={title ? title : step.name}
          secondary={fieldFlowData?.tableStep.selectedNames.join(",") || ""}
        />
      </ListItem>
    </Fragment>
  );
}
