import {
  ChangeProductStatusFlowData,
  ChangeProductStatusFlowStep,
  extractChangeEntityStatusFlowData,
  extractChangeEntityStatusFlowStep,
} from "core/api";
import { ReactElement } from "react";
import { ChangeEntityStatus } from "../ChangeEntityStatus";
import { FlowStepComponentProps } from "../models";

export type ChangeProductStatusProps = FlowStepComponentProps<
  ChangeProductStatusFlowStep,
  ChangeProductStatusFlowData
>;

/** Change product status step component which is a status + order attributes. */
export function ChangeProductStatus({
  control,
  data,
  flowStep,
  ...props
}: ChangeProductStatusProps): ReactElement {
  return (
    <ChangeEntityStatus
      control={control}
      data={extractChangeEntityStatusFlowData(data)}
      flowStep={extractChangeEntityStatusFlowStep(flowStep)}
      {...props}
    />
  );
}
