import { API, useAPIQuery, View } from "core/api";
import { useAuthenticationContext } from "core/auth";

export function useFetchView(id: number | undefined) {
  const {
    state: { isAuthenticated },
  } = useAuthenticationContext();

  return useAPIQuery<View>(["views", id, isAuthenticated], API.config.view, {
    pathParams: { viewId: id },
  });
}
