import {
  AdjustmentAmountFlowData,
  AdjustmentAmountFlowStep,
  AmountWithVATFlowData,
  AmountWithVATFlowStep,
} from "core/api";
import { AmountWithVAT } from "../AmountWithVAT";
import { FlowStepComponentProps } from "../models";

export type AdjustmentAmountProps = FlowStepComponentProps<
  AdjustmentAmountFlowStep,
  AdjustmentAmountFlowData
>;

/**
 * This UI component is an extension of the AmountWithVAT component.
 *
 * @remarks
 * This component expects 2 additional properties to be present in the Flow data
 *
 * - generalLedgerInfo (ValidValue): General Ledger Information associated
 *   with the Price Type selected by the user in the AdjustmentProperties step.
 *   Not editable by the user.
 *
 * - taxBundleInfo (ValidValue): Tax Bundle Information associated with
 *   the Price Type selected by the user in the AdjustmentProperties step.
 *   Not editable by the user.
 *
 * These properties are not displayed in this UI Component. They are displayed
 * in the Confirmation step.
 *
 * @see {@link AdjustmentAmountFlowData}
 */
export function AdjustmentAmount({
  control,
  flowStep,
  data,
  ...props
}: AdjustmentAmountProps): JSX.Element {
  const amountData: AmountWithVATFlowData = {
    ...data,
    uiComponent: "AmountWithVAT",
  };

  const amountFlowStep: AmountWithVATFlowStep = {
    ...flowStep,
    uiComponent: "AmountWithVAT",
  };

  return (
    <AmountWithVAT
      control={control}
      data={amountData}
      flowStep={amountFlowStep}
      {...props}
    />
  );
}
