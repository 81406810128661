import { setQueryParameters } from "core/api";
import { useLocation, useNavigate, useSearchParams } from "common/routing";
import { useCallback, useMemo } from "react";

export type SearchParams<
  TParam extends string = string,
  TValue extends string | string[] | undefined = string | string[] | undefined
> = Record<TParam, TValue>;

export interface QueryMethods<TParam extends string> {
  setQuery(
    queryParams:
      | SearchParams
      | ((state: SearchParams<TParam>) => SearchParams<TParam>),
    options?: UpdateQueryOptions
  ): void;
}

export interface QueryState<TParam extends string = string>
  extends QueryMethods<TParam> {
  /** Search params from url */
  params: SearchParams<TParam>;
}

export interface UpdateQueryOptions {
  /** Use custom url instead of pathname */
  url?: string;
  /** Use replace instead of push for history */
  replace?: boolean;
}

/**
 * Hook for getting and handling search params in the current url
 *
 * @returns Formatted search params from the current url and query methods
 */
export function usePathQuery<
  TParam extends string = string
>(): QueryState<TParam> {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const params = useMemo(() => {
    // Format search params to JSON object
    const _params: SearchParams<string, string | string[]> = {};
    for (const [key, value] of Array.from(searchParams.entries())) {
      if (Object.keys(_params).includes(key)) {
        const resultValue = _params[key];
        if (Array.isArray(resultValue)) {
          _params[key] = [...resultValue, value];
        } else {
          _params[key] = [resultValue, value];
        }
      } else {
        _params[key] = value;
      }
    }

    return _params;
  }, [searchParams]);

  /**
   * Adds query params to the current url
   *
   * @param queryParams Query params to include in url or callback for updating params
   * @param url Use custom url instead of pathname
   */
  const setQuery = useCallback(
    (
      queryParams:
        | SearchParams<TParam>
        | ((state: SearchParams<TParam>) => SearchParams<TParam>),
      options?: UpdateQueryOptions
    ) => {
      const newParams =
        queryParams instanceof Function ? queryParams(params) : queryParams;
      const _url = setQueryParameters(options?.url ?? pathname, newParams);
      navigate(_url, { replace: options?.replace });
    },
    [pathname, params, navigate]
  );

  return { params, setQuery };
}
