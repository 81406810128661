import {
  Checkbox,
  FormSelect,
  FormSelectProps,
  ListItemText,
  MenuItem,
} from "common/components";

/** Form multiple select field */
export function FormMultipleSelect({
  renderOption,
  ...props
}: FormSelectProps) {
  return (
    <FormSelect
      {...props}
      multiple
      renderOption={
        renderOption ??
        (({ label, value }, selectedValue) => (
          <MenuItem value={value}>
            <Checkbox checked={(selectedValue as unknown[])?.includes(value)} />
            <ListItemText primary={label} />
          </MenuItem>
        ))
      }
      renderValue={(value) =>
        props.dataList
          ?.filter((option) =>
            (value as Array<string | number>)?.includes(option.value)
          )
          .map(({ label }) => label)
          .join(", ")
      }
    />
  );
}
