import { formatFormDatePickerValue } from "common/components";
import { ChangeDueDateFlowData, ChangeDueDateFlowStep } from "core/api";
import { StepDataResolver } from "../models";

/**
 * Change Due Date data resolver
 *
 */
export const changeDueDateDataResolver: StepDataResolver<
  ChangeDueDateFlowStep,
  ChangeDueDateFlowData
> = ({ data, fieldValues }) => {
  return {
    dueDate: fieldValues.dueDate
      ? formatFormDatePickerValue(fieldValues.dueDate)
      : data.dueDate,
  };
};
