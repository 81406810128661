import { StorageKey } from "core/appSettings";
import dayjs from "dayjs";
import { JwtToken } from "..";

/**
 *
 * @returns the  authorization token
 */
export function getLocalStoreAuthorization(): string | null {
  return localStorage.getItem(StorageKey.AUTH_TOKEN);
}

/**
 * Set authorization token
 * @param token the authorization token
 */
export function setLocalStoreAuthorization(token: string) {
  localStorage.setItem(StorageKey.AUTH_TOKEN, token);
}

/**
 * Set the refresh token
 * @param token the refresh token
 */
export function setLocalStoreRefreshToken(token: string) {
  localStorage.setItem(StorageKey.REFRESH_TOKEN, token);
}

/**
 *
 * @returns the refresh token
 */
export function getLocalStoreRefreshToken(): string {
  return localStorage.getItem(StorageKey.REFRESH_TOKEN) ?? "";
}

/**
 * Clear the authorization token and refresh token
 */
export function clearLocalStoreAuthorization() {
  localStorage.removeItem(StorageKey.AUTH_TOKEN);
  localStorage.removeItem(StorageKey.REFRESH_TOKEN);
}

/**
 * @returns Get parsed jwt token
 */
export function getParsedJwtToken(): JwtToken | null {
  const token = getLocalStoreAuthorization();
  return token ? parseJwt(token) : null;
}

/**
 * Get time left until token expires
 *
 * @returns Number of minutes left until token expires
 */
export function tokenExpiresInMinutes(): number {
  const token = getParsedJwtToken();
  return token ? dayjs(token.exp * 1000).diff(dayjs(), "minutes") : -1;
}

/**
 * @returns true if parsed token is valid
 */
export function tokenIsValid(): boolean {
  const token = getParsedJwtToken();
  return !!token && dayjs(token.exp * 1000).isAfter(dayjs());
}

/**
 * Parse jwt token
 * @param token The token
 * @returns A JwtToken
 */
export function parseJwt(token: string): JwtToken {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}
