import { StorageKey } from "core/appSettings";
import { createStore } from "core/store";
import { useCallback } from "react";

const AUTOCOMPLETE_COUNT = 10;

export interface AutoCompleteOption {
  /** Search text */
  text: string;
}

/** Returns current options and a method for adding options */
export function useAutoCompleteOptions() {
  const { state, updateState } = useAutoCompleteStore();

  const addOption = useCallback(
    (text: string | string[]) => {
      updateState((options) => {
        if (Array.isArray(text)) {
          return text.reduce(
            (allOptions, currentOption) =>
              insertOption(allOptions, currentOption),
            options
          );
        }
        return insertOption(options, text);
      });
    },
    [updateState]
  );

  return { options: state, addOption };
}

/**
 * Inserts new option text in options
 * @param options Autocomplete options
 * @param text Search text
 * @returns Updated options list
 */
function insertOption(options: AutoCompleteOption[], text: string) {
  const filteredOptions = options.filter((option) => option.text !== text);
  return [{ text }, ...filteredOptions].slice(0, AUTOCOMPLETE_COUNT);
}

const useAutoCompleteStore = createStore<AutoCompleteOption[]>({
  name: StorageKey.AUTOCOMPLETE,
  initialState: [],
});
