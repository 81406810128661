import { formatFormDatePickerValue } from "common/components";
import { PayInvoiceFlowData, PayInvoiceFlowStep } from "core/api";
import { StepDataResolver } from "../models";

/**
 * Pay Invoice data resolver
 *
 * Formats Pay Invoice based on form field values
 */
export const payInvoiceDataResolver: StepDataResolver<
  PayInvoiceFlowStep,
  PayInvoiceFlowData
> = ({ data, fieldValues }) => {
  return {
    paymentReference: fieldValues.paymentReference ?? data.paymentReference,
    remainingAmount: fieldValues.remainingAmount ?? data.remainingAmount,
    paymentDate: fieldValues.paymentDate
      ? formatFormDatePickerValue(fieldValues.paymentDate)
      : data.paymentDate,
    paidAmount: fieldValues.paidAmount ?? data.paidAmount,
    paymentType:
      data.applicablePaymentTypes.find(
        (payment) => payment.id === fieldValues.paymentType
      ) ?? data.paymentType,
    externalInvoiceId: data.externalInvoiceId ?? data.externalInvoiceId,
  };
};
