/**
 * Payment Method code class used to mark used differentiate
 * payment models such as Credit Card/Autogiro/OCR etc
 */
export enum PaymentMethod {
  /**
   * Standard Payment, e.g. against OCR number in a post paid manner
   */
  PAPER_INVOICE = "PAPER_INVOICE",
  /**
   * Amounts are automatically withdrawn from credit- or debit card
   */
  CREDIT_CARD = "CREDIT_CARD",

  /**
   * Electronic invoice sent through customers bank
   */
  E_INVOICE = "E_INVOICE",

  /**
   * Amounts are automatically withdrawn from customer bank account
   */
  DIRECT_DEBIT = "DIRECT_DEBIT",

  /**
   * Email invoice.
   */
  EMAIL_INVOICE = "EMAIL_INVOICE",

  /**
   * Amounts are automatically withdrawn from customer bank account- SEPA
   */
  SEPA_DIRECT_DEBIT = "SEPA_DIRECT_DEBIT",
}
