import { set } from "lodash";
import { VictoryTheme, VictoryThemeDefinition } from "victory";
import { theme } from "./themeSettings";

export const victoryTheme: VictoryThemeDefinition = {
  ...VictoryTheme.material,
};

const labelPaths = [
  ["area", "style", "labels"],
  ["axis", "style", "axisLabel"],
  ["axis", "style", "tickLabels"],
  ["bar", "style", "labels"],
  ["boxplot", "style", "maxLabels"],
  ["boxplot", "style", "medianLabels"],
  ["boxplot", "style", "minLabels"],
  ["boxplot", "style", "q1Labels"],
  ["boxplot", "style", "q3Labels"],
  ["candlestick", "style", "labels"],
  ["errorbar", "style", "labels"],
  ["histogram", "style", "labels"],
  ["legend", "style", "labels"],
  ["legend", "style", "title"],
  ["line", "style", "labels"],
  ["pie", "style", "labels"],
  ["scatter", "style", "labels"],
  ["tooltip", "style"],
  ["voronoi", "style", "labels"],
];

for (const path of labelPaths) {
  set(victoryTheme, [...path, "fontFamily"], theme.typography.fontFamily);
}
