/**
 * Task status code class.
 * <p>
 *  Corresponds to the TaskStatus enum in Billiant APIs.
 */
export enum TaskStatus {
  /**
   * Not set. Task status has not been set to a valid value.
   */
  NOT_SET = "NOT_SET",

  /**
   * Registered
   */
  REGISTERED = "REGISTERED",

  /**
   * Assigned
   */
  ASSIGNED = "ASSIGNED",

  /**
   * Closed
   */
  CLOSED = "CLOSED",
}
