import { API_BASE_URL, IgnoreError, PathParams, QueryParams } from ".";

export function createApiUrl(
  url: string,
  pathParams?: PathParams,
  queryParams?: QueryParams
): string {
  const domain = !url.startsWith("http") ? window.location.origin : "";
  return (
    domain +
    API_BASE_URL +
    setQueryParameters(setPathParameters(url, pathParams), queryParams)
  );
}

export function setPathParameters(
  url: string,
  pathParams?: PathParams
): string {
  return Object.entries(pathParams ?? {}).reduce(
    (newUrl, [name, value]) =>
      newUrl.replace(`:${name}`, encodeURIComponent(String(value))),
    url
  );
}

export function setQueryParameters(
  url: string,
  queryParams?: QueryParams
): string {
  if (!queryParams || Object.values(queryParams ?? {})?.length === 0) {
    return url;
  }
  const concatenator = url.indexOf("?") === -1 ? "?" : "&";
  const params = Object.entries(queryParams)
    .filter(([, value]) => value !== undefined)
    .map(([name, value]) =>
      Array.isArray(value)
        ? value
            .map(
              (sub_value: string | number | boolean) =>
                `${name}=${encodeURIComponent(sub_value)}`
            )
            .join("&")
        : `${name}=${encodeURIComponent(String(value))}`
    )
    .join("&");

  return url + (params.length > 0 ? concatenator : "") + params;
}

export function shouldIgnoreError(
  status: number,
  ignoreError: IgnoreError
): boolean {
  if (ignoreError instanceof Array) {
    return ignoreError.includes(status);
  } else {
    return ignoreError ?? false;
  }
}

/**
 * Downloads file from blob in browser if the user allows it.
 * @param file File blob
 * @param fileName Name of file
 */
export function downloadInBrowser(file: Blob, fileName: string): void {
  const a = document.createElement("a");
  a.href = URL.createObjectURL(file);
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  a.remove();
}
