import { TextField, TextFieldProps } from "common/components";
import { useTranslation } from "i18n";
import { ReactElement, useEffect, useRef } from "react";
import { combineSx } from "styles/theme";
import { removeFlowStepPrefix } from "../../../../utils";
import {
  FormControlledFieldProps,
  withFormController,
} from "../FormController";

export type FormSocialSecurityNumberFieldProps = Omit<
  TextFieldProps,
  "variant"
> &
  FormControlledFieldProps & {
    renderValue?: (value: any) => any;
    socialSecurityNumberLength?: FormControlledFieldProps["socialSecurityNumberLength"];
  };

/**
 * Adds form handling for Social security number field.
 *
 * @param fieldName Static string used for the unique ids of the inner components.
 * @param control Form control.
 * @param mandatoryLabel Add own label for mandatory fields (default value is field name + "(mandatory)").
 * @param validationRules Optional validation rule object, se below for example.
 *          If triggered the helper text is replaced with active validation rule message.
 * @param props Any remaining properties.
 *
 */
export const FormSocialSecurityNumberField =
  withFormController<FormSocialSecurityNumberFieldProps>(
    ({
      fieldName,
      helperText,
      renderValue,
      renderState,
      socialSecurityNumberLength = 12,
      ...props
    }): ReactElement => {
      const { t } = useTranslation(["common"]);
      const {
        field: { value, ...field },
        fieldState: { error },
        isDisabled,
        gridColumn,
        setExtendedRules,
      } = renderState;

      const inputRef = useRef<HTMLInputElement>(null);

      // refocus the TextField, as we loose focus when running a server validation
      useEffect(() => {
        inputRef.current?.focus();
      }, [value]);

      let placeholder = "";
      if (socialSecurityNumberLength === 10) {
        placeholder = t("common:forms.formatShortSocialSecNo");
      } else {
        placeholder = t("common:forms.formatLongSocialSecNo");
      }

      useEffect(() => {
        setExtendedRules({
          validate: {
            externalValidation: (value: string) => {
              // Allows empty, and YYMMDD-XXXX or YYYYMMDD-XXXX depending on variant
              const regexPattern =
                socialSecurityNumberLength === 10
                  ? /^(\d{6})-\d{4}$|^$/
                  : /^(\d{8})-\d{4}$|^$/;

              if (!regexPattern.test(value)) {
                return t("common:forms.pattern", { pattern: placeholder });
              } else {
                return true;
              }
            },
          },
        });
      }, [setExtendedRules, t]);

      const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let inputValue = event.target.value;
        // Remove non-digit characters from input value, excluding hyphens
        inputValue = inputValue.replace(/[^\d-]/g, "");
        // Update the field value
        field.onChange(inputValue);
      };

      return (
        <TextField
          inputRef={inputRef}
          value={renderValue?.(value) ?? value ?? ""}
          error={!!error}
          data-cy={removeFlowStepPrefix(fieldName)}
          InputLabelProps={{ required: props.required }}
          inputProps={{ maxLength: socialSecurityNumberLength + 1 }}
          {...props}
          {...field}
          disabled={isDisabled}
          placeholder={placeholder}
          /*
            put the "helperText" attribute after props so error messages can be 
            displayed instead of helper text if needed
          */
          helperText={error ? error.message : helperText}
          sx={combineSx({ gridColumn }, props.sx)}
          onChange={handleChange}
        />
      );
    }
  );
