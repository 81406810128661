import { API, APIMutationResult, Bookmark, useAPIMutation } from "core/api";
import { getCurrentCustomerGroupId } from "core/auth";
import { invalidateFindBookmarks } from "./useFindBookmarks";

export interface UpdateBookmarksConfigParams {
  add: boolean;
  bookmark: Bookmark;
  invalidate?: boolean;
}

/**
 * Add new bookmark or delete a bookmark that exists in DB
 * @returns APIMutationResult
 */
export function useUpdateBookmarks(): APIMutationResult<
  undefined,
  UpdateBookmarksConfigParams
> {
  return useAPIMutation<undefined, UpdateBookmarksConfigParams>(
    API.myAccount.bookmark,
    ({ add, bookmark }) => ({
      method: add ? "POST" : "DELETE",
      body: add ? JSON.stringify(bookmark.bookmarkEntry) : "",
      pathParams: {
        id: add ? "" : bookmark.bookmarkEntry.id,
      },
      expectedType: add ? "json" : "RequestOk",
    }),
    {
      onSuccess(_, { bookmark, invalidate = true }) {
        //Triggered by add but not delete (for some reason)
        if (invalidate) {
          invalidateFindBookmarks(
            bookmark.bookmarkEntry.customerGroupId === -1
              ? getCurrentCustomerGroupId() ?? -1
              : bookmark.bookmarkEntry.customerGroupId
          );
        }
      },
    }
  );
}
