import { SvgIconComponent } from "common/assets/icons";
import { Icon, IconProps } from "common/components";

export interface FontIconProps extends IconProps {
  icon: string;
}

/**
 * Wrapper component that uses Material Fonts provided as self-hosted assets.
 *
 * We support the filled icons only.
 *
 * Note!
 * Unfortunately there is no way to find out if the provided name really match
 * an icon in the font.
 *
 * @param icon the font icon name, for instance person
 */
export function FontIcon({ icon, ...props }: FontIconProps) {
  return <Icon {...props}>{icon || "error"}</Icon>;
}

/**
 * Gets a wrapped FontIcon with icon already set
 * @param icon Icon name
 * @returns SvgIconComponent
 */
export function getFontIcon(icon: string) {
  return ((props: JSX.IntrinsicAttributes) => (
    <FontIcon icon={icon} {...props} />
  )) as SvgIconComponent;
}
