import { useMutation, UseMutationResult } from "@tanstack/react-query";
import {
  API,
  APIError,
  createApiUrl,
  queryAborter,
  QueryParams,
  request,
  RequestInitWithParams,
} from "core/api";
import { StorageKey } from "core/appSettings/keys";
import { actionLock } from "core/idb";
import { getCurrentCustomerGroupId } from "./customerGroup";
import {
  clearLocalStoreAuthorization,
  getLocalStoreAuthorization,
  getLocalStoreRefreshToken,
  setLocalStoreAuthorization,
  setLocalStoreRefreshToken,
} from "./token";

/**
 * LoginData interface. Contains properties
 * used when logging in the user
 */
export interface LoginData {
  readonly userName: string;
  readonly password: string;
  readonly isProvider: boolean;
  readonly providerName: string;
  readonly sessionState: string;
  readonly code: string;
}

export interface OneTimePasswordData {
  userName: string;
  customerGroupId: string;
}

/**
 * React hook to login the user. Expects a username and a password
 * as well as other possible parameters found in LoginData type.
 * This hook is more or less a wrapper for handling multiple sorts of login,
 * in addition the regular username/pw login there is also an authorization
 * request when logging in with a provider.
 * @returns a UseMutationResult with the generic user response.
 */
export function useLoginRequest<TUserResponse>(): UseMutationResult<
  TUserResponse,
  APIError,
  LoginData,
  unknown
> {
  return useMutation<TUserResponse, APIError, LoginData>((data) =>
    loginRequest(data)
  );
}

/**
 * React hook for requesting a one time password to login the user.
 * This hook DOES NOT log in the user on its own. A second call to the
 * actual useLoginRequest-hook is still needed, since the user needs
 * to input its password after recieving it.
 * @returns a UseMutationResult with the response.
 */
export function useOneTimePasswordRequest(): UseMutationResult<
  Response,
  APIError,
  OneTimePasswordData,
  unknown
> {
  return useMutation<Response, APIError, OneTimePasswordData>((data) =>
    oneTimePasswordRequest(data)
  );
}

/**
 * Create login data
 *
 * @prop userName - The userName
 * @prop password - The password
 */
export function createLoginData(
  userName: string,
  password: string,
  providerName: string
): LoginData {
  return {
    userName,
    password,
    isProvider: false,
    providerName,
    sessionState: "",
    code: "",
  };
}

/**
 * Create provider login data
 *
 * @prop providerName - The provider name
 * @prop sessionState - The session state
 * @prop autorizationCode - The authorization code
 */
export function createLoginDataForProvider(
  providerName: string,
  sessionState: string | null,
  autorizationCode: string | null
): LoginData {
  return {
    userName: "",
    password: "",
    isProvider: true,
    providerName,
    sessionState: sessionState ?? "",
    code: autorizationCode ?? "",
  };
}

/**
 * Get refresh token from headers
 * @param headersThe headers
 * @returns A refresh token or undefined
 */
function getAuthorizationToken(headers: Headers): string | undefined {
  const authorization = headers.get("authorization");
  if (authorization) {
    return authorization.replace("Bearer ", "");
  }
}

/**
 * Get refresh token from headers
 * @param headers The headers
 * @returns A refresh token or undefined
 */
function getRefreshToken(headers: Headers): string {
  const authorization = headers.get("refresh-token");

  return authorization ?? "";
}

async function authorizationRequest(
  relativeURL: string,
  init?: RequestInitWithParams,
  ignoreError?: boolean | undefined,
  queryParams?: QueryParams
): Promise<Response> {
  return request(
    createApiUrl(relativeURL, init?.pathParams, init?.queryParams),
    {
      credentials: "include",
      mode: "same-origin",
      ...init,
      headers: {
        Authorization: `Bearer ${getLocalStoreAuthorization()}`,
        "Content-Type": "application/json",
        ...init?.headers,
      },
      queryParams: queryParams,
    },
    ignoreError
  );
}

async function setTokenAndGetJSON<TUserResponse>(
  response: Response
): Promise<TUserResponse> {
  const body = await response?.json();
  const token = getAuthorizationToken(response.headers);
  if (token) {
    const refreshToken = getRefreshToken(response.headers);

    setLocalStoreAuthorization(token);
    setLocalStoreRefreshToken(refreshToken);
    return body;
  } else {
    throw body;
  }
}

/**
 * Creates a login request
 * @param data a structure containing the login data (username, password, ...)
 * @returns A Promise<TUserResponse>
 */
export async function loginRequest<TUserResponse>(
  data: LoginData
): Promise<TUserResponse> {
  return !data.isProvider
    ? loginUserNameRequest(data.userName, data.password, data.providerName)
    : loginAuthorizationRequest(
        data.providerName,
        data.sessionState,
        data.code
      );
}

/**
 * Creates standard login request
 * @param userName The userName
 * @param password The password
 * @returns A Promise<TUserResponse>
 */
async function loginUserNameRequest<TUserResponse>(
  userName: string,
  password: string,
  providerName: string
): Promise<TUserResponse> {
  const customerGroup = getCurrentCustomerGroupId();

  const response = await authorizationRequest(API.auth.login, {
    method: "POST",
    headers: {
      Authorization: `Basic ${btoa(userName + ":" + password)}`,
      "Authentication-Provider": providerName,
      ...{ "bss-customer-group": String(customerGroup) ?? "-1" },
    },
    queryParams: {
      clientId: localStorage.getItem(StorageKey.CLIENT_ID) || "",
      expireClients: localStorage.getItem(StorageKey.EXPIRE_CLIENTS) || "",
    },
  });

  return setTokenAndGetJSON(response);
}

/**
 * Creates provider login request
 * @param userName The userName
 * @param password The password
 * @returns A Promise<TUserResponse>
 */
async function loginAuthorizationRequest<TUserResponse>(
  provider: string,
  sessionState: string,
  code: string
): Promise<TUserResponse> {
  const customerGroup = getCurrentCustomerGroupId();
  const response = await authorizationRequest(API.auth.login_provider, {
    method: "GET",
    headers: {
      ...{ "bss-customer-group": String(customerGroup) ?? "-1" },
    },
    pathParams: {
      provider,
    },
    queryParams: {
      session_state: sessionState || "",
      code: code || "",
    },
  });
  return setTokenAndGetJSON(response);
}

async function oneTimePasswordRequest({
  userName,
  customerGroupId,
}: OneTimePasswordData): Promise<Response> {
  const response = await request(
    createApiUrl(API.auth.login_otp, { userName }),
    {
      method: "POST",
      credentials: "include",
      mode: "same-origin",
      headers: {
        "Content-Type": "application/json",
        "bss-customer-group": customerGroupId,
      },
      pathParams: {
        userName,
      },
    },
    false
  );

  return response;
}

export async function refreshRequest<TUserResponse>(
  ignoreActionLockFailure = false
): Promise<TUserResponse> {
  const customerGroup = getCurrentCustomerGroupId();
  const response = await actionLock(
    "refresh",
    () =>
      authorizationRequest(API.auth.refresh, {
        signal: queryAborter.getSignal(),
        headers: {
          ...{ "Refresh-Token": getLocalStoreRefreshToken() },
          "bss-customer-group": String(customerGroup) ?? "-1",
        },
      }),
    { ignoreLockFailure: ignoreActionLockFailure }
  );
  return setTokenAndGetJSON(response);
}

export async function logoutRequest() {
  await authorizationRequest(
    API.auth.logout,
    {
      method: "POST",
    },
    true
  );
  clearLocalStoreAuthorization();
}
