import { ListItem, ListItemText } from "common/components";
import { convertBooleanToText, formatTimestamp } from "common/utils";
import {
  ChangeEntityStatusFlowData,
  ChangeEntityStatusFlowStep,
  FlowData,
} from "core/api";
import { useTranslation } from "i18n";
import { Fragment } from "react";

export interface ChangeEntityStatusConfirmationProps {
  step: ChangeEntityStatusFlowStep;
  flowData: FlowData;
}

/**
 * Sub component to confirmation. The purpose of this component is
 * to make the main Confirmation component more readable,
 * abstracting some of the logic into smaller sub components.
 *
 *
 * @returns A JSX element with with effective date and a checkbox for approving the order.
 */
export function ChangeEntityStatusConfirmation({
  step,
  flowData,
}: ChangeEntityStatusConfirmationProps): JSX.Element | null {
  const { t } = useTranslation(["common"]);
  const fieldFlowData = flowData as ChangeEntityStatusFlowData;
  const simpleMode = step.config.simpleMode;

  const selectedReasonName =
    fieldFlowData.applicableReasons.find(
      (vv) => parseInt(vv.id) === fieldFlowData.statusReasonId
    )?.name ?? t("common:noValueSelected");

  return (
    <Fragment key={step.id}>
      <ListItem>
        <ListItemText
          data-cy="confirmReason"
          primary={t("common:reason")}
          secondary={selectedReasonName}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          data-cy="confirmEffectiveDate"
          primary={t("common:effectiveDate")}
          secondary={formatTimestamp(
            fieldFlowData.orderAttributes.effectiveDate
          )}
        />
      </ListItem>
      {!simpleMode && (
        <ListItem>
          <ListItemText
            data-cy="comfirmApproveOrder"
            primary={t("common:approveOrders")}
            secondary={convertBooleanToText(
              fieldFlowData.orderAttributes.approved
            )}
          />
        </ListItem>
      )}
    </Fragment>
  );
}
