import { Launch, Link as LinkIcon } from "common/assets/icons";
import {
  Box,
  ListItem,
  StyledListItemText,
  SxProps,
  Theme,
} from "common/components";
import { Link } from "common/routing";
import { DisplayValueOptions } from "common/utils";
import {
  FieldUIComponent,
  FieldValueConfig,
  FieldValue as FieldValueModel,
  Icon,
} from "core/api";
import { useTranslation } from "i18n";
import { isArray } from "lodash";
import { ReactElement } from "react";
import { StyledList } from "../BilliantList/BilliantList.styles";
import { FieldValue, FieldValueProps } from "./FieldValue";
import {
  StyledFieldContainer,
  StyledFieldContainerProps,
  StyledFieldLabel,
} from "./ReadOnlyField.styles";
export interface ReadOnlyFieldProps
  extends Pick<
    FieldValueProps,
    "currencyCode" | "startAdornment" | "endAdornment" | "unitType"
  > {
  /** Field UI component */
  uiComponent: FieldUIComponent;
  /** Field label */
  label: string;
  /**
   * If the web configuration contains an 'align' property, then alignment overrides the default left align.
   */
  align?: string;
  /** Field display value */
  displayValue?: string | string[];
  /** If field has a secret value */
  secret?: boolean;
  /** Url to link to field related page */
  urls?: string | (string | undefined)[];
  /** Label position */
  labelPosition?: "top" | "left";
  /** Field icon */
  icon?: Icon;
  /** If displaying an icon  */
  showIcon?: boolean;
  /** Style props for field component */
  sx?: SxProps<Theme>;
  /** Property for Cypress testing purposes */
  "data-cy"?: string;
  fieldValue?: FieldValueModel;
  fieldValueConfig?: FieldValueConfig;
  groupedFieldValues?: DisplayValueOptions[];
  error?: string;
}

/** Read-only field based on FieldUIComponent */
export function ReadOnlyField({
  uiComponent,
  label,
  displayValue,
  icon,
  secret = false,
  currencyCode,
  startAdornment,
  endAdornment,
  urls,
  showIcon,
  align,
  labelPosition = "top",
  "data-cy": dataCy,
  sx,
  unitType,
  groupedFieldValues,
  error,
}: ReadOnlyFieldProps): ReactElement {
  const { t } = useTranslation(["common"]);

  const containerVariant: StyledFieldContainerProps["variant"] =
    labelPosition === "top" ? "column" : "row";
  const isExternalUrl =
    !Array.isArray(urls) &&
    !urls?.startsWith("/") &&
    !urls?.startsWith(window.location.origin);
  // If the url to link to is already part of current url then don't show internal links
  const isUrlDisplaying =
    urls && Array.isArray(urls)
      ? urls.some((u) => u && window.location.href.includes(u))
      : urls && window.location.href.includes(urls);
  const url = isArray(urls) ? urls.find((u) => u !== undefined) : urls;

  const grouped = groupedFieldValues && groupedFieldValues.length > 0;
  const isList = Array.isArray(displayValue) && !grouped;

  return (
    <StyledFieldContainer variant={containerVariant} data-cy={dataCy} sx={sx}>
      {!grouped && (
        <StyledFieldLabel
          containerVariant={containerVariant}
          variant="caption"
          component="h6"
        >
          {label}
        </StyledFieldLabel>
      )}
      <Box
        display="flex"
        flexWrap="nowrap"
        gap={0.5}
        marginBottom={"0.5em"}
        alignItems="center"
      >
        {grouped ? (
          <Box component="fieldset" sx={{ borderRadius: "5px" }}>
            <legend>
              <StyledFieldLabel
                containerVariant={containerVariant}
                variant="caption"
                component="h6"
              >
                {label}
              </StyledFieldLabel>
            </legend>
            {groupedFieldValues.map((value, index: number) => (
              <Box
                key={index}
                display="flex"
                gap={0.0}
                flexWrap="nowrap"
                alignItems="flex-start"
                justifyContent="flex-start"
                flexDirection="column"
                marginBottom={"0.0em"}
                data-cy={`${dataCy ?? ""}_${
                  value.fieldValueConfig?.valueNo ?? ""
                }`}
              >
                <StyledFieldLabel
                  containerVariant={containerVariant}
                  variant="caption"
                  component="h6"
                >
                  {value.fieldValueConfig?.label ?? ""}
                </StyledFieldLabel>
                <Box
                  display="flex"
                  gap={0.0}
                  flexWrap="nowrap"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  flexDirection="row"
                  marginBottom={"0.0em"}
                >
                  <FieldValue
                    showIcon={false}
                    uiComponent={value.uiComponent ?? FieldUIComponent.Text}
                    displayValue={value.displayValue}
                    secret={secret}
                    currencyCode={currencyCode}
                    noValue={value.fieldValue?.value === undefined}
                    align={"left"}
                    unitType={unitType}
                    error={value?.error}
                  />
                  {value.fieldValue?.url &&
                    !isExternalUrl &&
                    !isUrlDisplaying && (
                      <Link
                        to={value.fieldValue?.url}
                        data-cy={`${dataCy ?? ""}_${
                          value.fieldValueConfig?.valueNo ?? ""
                        }_Link`}
                        alignSelf="center"
                        display="flex"
                      >
                        <LinkIcon
                          fontSize="small"
                          color="action"
                          data-cy={`${dataCy ?? ""}_${
                            value.fieldValueConfig?.valueNo ?? ""
                          }_fieldLink`}
                        />
                      </Link>
                    )}
                </Box>
              </Box>
            ))}
          </Box>
        ) : Array.isArray(displayValue) && !grouped ? (
          <StyledList
            dense={true}
            data-cy={dataCy ? `${dataCy}_scrollBarContainer` : ""}
            sx={{
              display: "flex",
              flexDirection: "column",
              maxHeight: "5rem",
              overflowY: "auto",
              "&:before": {
                position: "absolute",
              },
              // Space between scrollbar and items
              paddingRight: "1.7rem",
              paddingLeft: "0",

              // Adjust scrollbar to the right
              width: "100%",
            }}
          >
            {displayValue.map((item, index) => (
              <StyledListItemText
                sx={{
                  borderBottom: "1px solid #ccc",
                  paddingBottom: 0,
                }}
                key={index}
              >
                <ListItem
                  key={index}
                  data-cy={`${dataCy ?? ""}_${index}_listItem`}
                >
                  {item ?? t("common:noValue")}
                  {Array.isArray(urls) && urls[index] !== undefined && (
                    <Link
                      to={urls[index] ?? ""}
                      alignSelf="center"
                      display="flex"
                      data-cy={`${dataCy ?? ""}_${index}_link`}
                    >
                      <LinkIcon
                        fontSize="medium"
                        color="action"
                        data-cy={`${dataCy ?? ""}_${index}_fieldLink`}
                        sx={{ paddingLeft: 1 }}
                      />
                    </Link>
                  )}
                </ListItem>
              </StyledListItemText>
            ))}
          </StyledList>
        ) : (
          <Box
            display="flex"
            gap={0.0}
            flexWrap="nowrap"
            alignItems="flex-start"
            justifyContent="flex-start"
            flexDirection="column"
            marginBottom={"0.0em"}
          >
            <FieldValue
              showIcon={showIcon}
              icon={icon}
              uiComponent={uiComponent}
              displayValue={displayValue}
              startAdornment={startAdornment}
              endAdornment={endAdornment}
              secret={secret}
              currencyCode={currencyCode}
              noValue={!displayValue}
              align={align || "left"}
              unitType={unitType}
              error={error}
            />
          </Box>
        )}
        {url && isExternalUrl && (
          <Link
            to={url}
            target="_blank"
            rel="noopener noreferrer"
            alignSelf="center"
            display="flex"
            data-cy={`${dataCy ?? ""}_link`}
          >
            <Launch fontSize="small" color="action" />
          </Link>
        )}
        {!grouped && url && !isExternalUrl && !isUrlDisplaying && !isList && (
          <Link to={url} alignSelf="center" display="flex">
            <LinkIcon
              fontSize="small"
              color="action"
              data-cy={`${dataCy ?? ""}_fieldLink`}
            />
          </Link>
        )}
      </Box>
    </StyledFieldContainer>
  );
}
