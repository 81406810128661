import { createAPIQueryKey, queryClient, fetchUserQueryKey } from "core/api";
import { SelfcareUserResponse, UserResponse } from "../models";

/**
 * Returns current user from query cache.
 * @returns UserResponse object from query cache
 */
export function getUserQueryData(): UserResponse | undefined {
  return queryClient.getQueryData<UserResponse>(
    createAPIQueryKey(fetchUserQueryKey, true)
  );
}

/**
 * Set current user in query cache.
 * @param user UserResponse object
 */
export function setUserQueryData<
  TUserResponse extends UserResponse | SelfcareUserResponse
>(user: TUserResponse): void {
  queryClient.setQueryData(createAPIQueryKey(fetchUserQueryKey, true), user);
}
