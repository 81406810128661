import { styled, TextField } from "common/components";

export const StyledGridContainer = styled("div")(({ theme }) => ({
  paddingBottom: theme.spacing(4),
  display: "flex",
  flexWrap: "wrap",
  gap: theme.spacing(2),
}));

export const StyledGrid = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "nowrap",
  gap: theme.spacing(4),
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  minWidth: "10px",
}));
