import {
  FilterFieldType,
  FilterValueState,
  FilterValueStates,
  SearchFilter,
} from "core/api";
import { ComponentType, KeyboardEvent } from "react";
import {
  AmountFilterField,
  BooleanFilterField,
  DatePickerFilterField,
  SelectFilterField,
  TextFilterField,
} from "../filters";

interface FilterPanelFieldsProps {
  /** Search filter fields */
  filterFields: SearchFilter[];
  /** Gets filter on change function by filter id */
  getOnChange: <TValueState extends FilterValueState>(
    id: number
  ) => (state: TValueState) => void;
  /** Filter value states */
  filterStates: FilterValueStates;
  /** Triggers on search action */
  triggerSearch(): void;
}

/**
 * Function for getting filter panel fields
 */
export function FilterPanelFields({
  filterFields,
  getOnChange,
  filterStates,
  triggerSearch,
}: Readonly<FilterPanelFieldsProps>): JSX.Element {
  const filters: Record<FilterFieldType, ComponentType<any>> = {
    [FilterFieldType.Amount]: AmountFilterField,
    [FilterFieldType.Boolean]: BooleanFilterField,
    [FilterFieldType.Date]: DatePickerFilterField,
    [FilterFieldType.Select]: SelectFilterField,
    [FilterFieldType.Text]: TextFilterField,
  };

  return (
    <>
      {filterFields.map(
        ({
          filterId,
          displayName,
          fieldType,
          controlledById,
          valuesUrl,
          requireCustomerNo,
          multiple,
        }) => {
          const filterState: FilterValueState = {
            ...filterStates[filterId],
            filterId,
            fieldType,
            valuesUrl,
            requireCustomerNo,
          };

          const controlledByFilterState =
            controlledById !== undefined
              ? filterStates[controlledById]
              : undefined;
          const FilterComponent = filters[fieldType];

          const onKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
            if (event.key === "Enter") {
              triggerSearch();
            }
          };

          return (
            <FilterComponent
              key={filterId}
              state={filterState}
              displayName={displayName}
              onChange={getOnChange(filterId)}
              onKeyPress={onKeyPress}
              controlledById={controlledById}
              controlledByFilterState={controlledByFilterState}
              multiple={multiple}
            />
          );
        }
      )}
    </>
  );
}
