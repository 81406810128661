import {
  Button,
  FormControllerContext,
  FormNumberField,
  FormTextField,
} from "common/components";
import { addFlowStepPrefix } from "common/utils";
import { useTranslation } from "i18n";
import { ReactElement, useContext, useState } from "react";
import { useFormContext } from "react-hook-form";
import { CreditCheckFlowData, CreditCheckFlowStep } from "../../../../api";
import { FlowStepComponentProps } from "../models";
import { StyledStepContentWrapper } from "../steps.styles";

export type CreditCheckProps = FlowStepComponentProps<
  CreditCheckFlowStep,
  CreditCheckFlowData
>;

/** Credit Check step component */
export function CreditCheck({
  control,
  data,
  flowStep,
}: CreditCheckProps): ReactElement {
  const { t } = useTranslation(["common"]);
  const { creditRating, identifier } = data;
  const { evaluateOnChange } = useContext(FormControllerContext);
  const [isLoading, setIsLoading] = useState(false);

  const context = useFormContext();
  const identifierText: string = context?.watch(
    addFlowStepPrefix(flowStep, "identifier"),
    ""
  );

  const isButtonDisabled = isLoading || identifierText === "";

  const handleCheckButtonClick = () => {
    setIsLoading(true);
    evaluateOnChange?.(() => {
      setIsLoading(false);
    });
  };

  return (
    <StyledStepContentWrapper
      sx={{
        display: "grid",
        gridTemplateColumns: "2fr 2fr 1fr",
        gridTemplateAreas: `"identifier identifier checkButton"
        "creditScore creditStatus ."`,
      }}
    >
      <FormTextField
        fieldName={addFlowStepPrefix(flowStep, "identifier")}
        defaultValue={identifier ?? ""}
        control={control}
        label={t("common:identifier")}
        required
        data-cy={"identifier"}
        sx={{ gridArea: "identifier" }}
      />
      <Button
        size="small"
        data-cy={"checkButton"}
        sx={{ gridArea: "checkButton", alignSelf: "start", height: "40px" }}
        onClick={handleCheckButtonClick}
        disabled={isButtonDisabled}
        loading={isLoading}
      >
        {t("common:check")}
      </Button>

      <FormNumberField
        fieldName={addFlowStepPrefix(flowStep, "creditScore")}
        defaultValue={creditRating?.score ?? ""}
        control={control}
        label={t("common:score")}
        disabled
        required
        data-cy={"creditScore"}
        sx={{ gridArea: "creditScore" }}
      />
      <FormTextField
        fieldName={addFlowStepPrefix(flowStep, "creditStatus")}
        defaultValue={
          creditRating?.ratingStatusValue?.name ??
          t("common:creditRatingStatus.notChecked")
        }
        control={control}
        label={t("common:status")}
        disabled
        required
        data-cy={"creditStatus"}
        sx={{ gridArea: "creditStatus" }}
      />
    </StyledStepContentWrapper>
  );
}
