export default {
  form: {
    title: "Login to Xplora",
    username: "Parents phone number",
    password: "Enter the code you received via SMS.",
    language: "Language",
    submit: "Login",
    continue: "Next",
    usernameValidationMessage: "Enter parent's phone number.",
    otpHint: "",
  },
  logoutDialog: {
    title: "Logged in as",
  },
  loginInfo: {
    title: "Are you an Xplora subscriber?",
    content: `NOTE: If the subscription is for an Xplora watch, enter the parents' mobile number, not the smartwatch's. \n \n Please note that it may take up to 30 minutes from ordering until you can log in.`,
  },
} as const;
