import {
  API,
  APIQueryKey,
  APIQueryResult,
  EntityType,
  Flow,
  FlowInput,
  setAPIQueryData,
  useAPIQuery,
} from "core/api";
import { toEntityRefs } from "core/components";
import { useScreenEntities } from "core/store";

const FLOW_KEY = "flow";

export type CreateFlowParams = {
  screenId: number;
  viewId?: number;
  widgetId?: number;
  invokedFromTable: boolean;
};

export interface BaseActionFlowSettings {
  actionId: number;
  entityType: EntityType;
  entityId: string;
}

export interface FetchActionFlowSettings extends BaseActionFlowSettings {
  params: CreateFlowParams;
}

/**
 * Fetches an action flow from the API based on the provided settings.
 *
 * @param FetchActionFlowSettings - The settings object containing parameters for the fetch operation.
 * @returns APIQueryResult<Flow, false> - The result of the API query, containing the fetched flow.
 */
export function useFetchActionFlow({
  actionId,
  entityType,
  entityId,
  params,
}: FetchActionFlowSettings): APIQueryResult<Flow, false> {
  // Screen entities to add to the flow.
  const flowInput = {
    screenEntities: toEntityRefs(useScreenEntities()),
  } as FlowInput;

  return useAPIQuery<Flow>(
    createFetchActionFlowQueryKey({
      actionId,
      entityType,
      entityId,
      params,
    }),
    API.actions.flows.create,
    {
      method: "POST",
      pathParams: { actionId },
      queryParams: {
        entityType,
        entityId,
        ...params,
      },
      body: JSON.stringify(flowInput),
    },
    { enabled: actionId > 0 }
  );
}

export function createFetchActionFlowQueryKey({
  actionId,
  entityType,
  entityId,
  params,
}: FetchActionFlowSettings): APIQueryKey {
  return [FLOW_KEY, actionId, entityType, entityId, params];
}

export function setActionFlowQueryData(
  { actionId, entityType, entityId, params }: FetchActionFlowSettings,
  data: Flow
): void {
  setAPIQueryData<Flow>(
    createFetchActionFlowQueryKey({
      actionId,
      entityType,
      entityId,
      params,
    }),
    data
  );
}
