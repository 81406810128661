import {
  FilterFieldType,
  FilterValue,
  FilterValueState,
  FilterValueStates,
} from "core/api";

// Returns the first and last characters from the letters in the user name
export const getAvatarCharacters = (text: string | undefined) => {
  if (typeof text === "string") {
    const splittedWords = text.split(" ");
    return (
      splittedWords[0].slice(0, 1) +
      splittedWords[splittedWords.length - 1].slice(0, 1)
    );
  } else {
    return "";
  }
};

// Constructs the following FIQL string: filters=filterId=id[;value=v][;minValue=mn][;maxValue=mx][;currencyCode=cc].
// UrlEncoding is applied to value / minValue / maxValue.
export const getFilterValue = ({
  filterId,
  value,
  minValue,
  maxValue,
  currencyCode,
}: FilterValue): string => {
  const fiqlQueryBuilder = require("fiql-query-builder");

  const queryJson = {
    custom_expression: {
      operator: ";",
      children: [getFieldSection("filterId", filterId)],
    },
  };

  if (value && (typeof value === "string" || typeof value === "boolean")) {
    queryJson.custom_expression.children.push(
      getFieldSection("value", encodeURIComponent(value))
    );
  }
  if (minValue) {
    queryJson.custom_expression.children.push(
      getFieldSection("minValue", encodeURIComponent(minValue))
    );
  }
  if (maxValue) {
    queryJson.custom_expression.children.push(
      getFieldSection("maxValue", encodeURIComponent(maxValue))
    );
  }
  if (currencyCode) {
    queryJson.custom_expression.children.push(
      getFieldSection("currencyCode", currencyCode)
    );
  }
  const query: string = fiqlQueryBuilder.convertFromJson(queryJson);

  return query;
};

function getFieldSection(name: string, value: number | string) {
  return {
    custom_operator: {
      operator: "=",
      selector: name,
      args: value,
    },
  };
}

/**
 * Converting a FIQL formatted string
 * @param url A FIQL-formatted string with parameters matching the model FilterValue
 * @returns Array with FilterValue items, key as filter returns an array with
 */
export function convertFiqlUrltoArray(
  url: string | string[] | undefined
): FilterValueStates {
  if (url !== undefined) {
    const result: FilterValueStates = {};
    const filterUrls = Array.isArray(url) ? url : [url];
    for (const filterUrl of filterUrls) {
      const decodedURL = decodeURIComponent(filterUrl);
      const filterId: string = String(
        new RegExp(/filterId=(\S+);/).exec(decodedURL)?.[1]
      );

      let filter: FilterValueState = {
        filterId: filterId,
        fieldType: FilterFieldType.Amount,
      };

      const keyValues = decodedURL
        .split(";")
        .map((keyValue) => keyValue.split("="));
      for (const [key, value] of keyValues) {
        const _value = key === "value" ? new Array(value) : value;
        filter = { ...filter, [key]: _value };
      }

      // for multiselect
      if (result[filterId] !== undefined) {
        filter = {
          ...filter,
          value: [
            filter?.value,
            (result[filterId].value as unknown as string[]).join(" "),
          ]
            .join(" ")
            .split(" "),
        };
      }
      result[filter.filterId] = filter;
    }
    return result;
  }
  return {};
}

function getFilterValueByState(filterState: FilterValueState): any {
  return getFilterValue({
    filterId: filterState.filterId,
    minValue: filterState?.minValue,
    maxValue: filterState?.maxValue,
    value: filterState?.value,
    currencyCode: filterState?.currencyCode,
  });
}
/**
 * Converts filterstates to FIQL formatted strings
 * @param filterStates The filter states
 * @returns Array with FIQL formatted filters
 */
export function formatToFIQL(filterStates: FilterValueStates): string[] {
  const fiqlFilters: string[] = [];
  for (const filterState of Object.values(filterStates)) {
    if (filterState?.value !== undefined && Array.isArray(filterState.value)) {
      for (const singleValue of Object.values(filterState?.value)) {
        fiqlFilters.push(
          getFilterValueByState({
            ...filterState,
            value: singleValue as unknown as string,
          })
        );
      }
    } else {
      fiqlFilters.push(getFilterValueByState(filterState));
    }
  }

  return fiqlFilters;
}

/**
 * Checks if string is a numeric value
 * @param str String to check
 * @returns If string is a numeric value
 */
export function isNumeric(str: string) {
  return !isNaN(parseFloat(str));
}

/**
 * Filters out all the characters that are creating problems in a Cypress
 * data-cy string.
 *
 * @remarks An alternative to using this function everywhere we define a data-cy
 *   would be to use the CSS.escape in the implementation of the getByDataCy() function.
 *   @see https://docs.cypress.io/faq/questions/using-cypress-faq#How-do-I-use-special-characters-with-cyget
 *
 * @param input The string to be used as data-cy
 * @param prefix Optional prefix to be added to the data-cy string
 * @returns A string obtained by filtering out all illegal characters
 *   from the input string and adding the prefix
 */
export function formatDataCy(input: string, prefix?: string): string {
  const prefixedInput = prefix ? `${prefix}${input}` : input;
  return prefixedInput.replace(/[ )(:<>.]/g, "");
}
