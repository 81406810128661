import { FormSelect } from "common/components";
import { addFlowStepPrefix } from "common/utils";
import { DecisionFlowData, DecisionFlowStep } from "core/api";
import { useTranslation } from "i18n";
import { FlowStepComponentProps } from "../models";
import { StyledStepContentWrapper } from "../steps.styles";

export type DecisionProps = FlowStepComponentProps<
  DecisionFlowStep,
  DecisionFlowData
>;

/**
 * This UI component allows the user to make a decision by selecting
 * a choice from a drop-down list.
 *
 * @remarks
 * This component expects 2 properties to be present in the Flow data:
 *
 * - decisionChoices (List of ValidValue objects): The items that represent
 *   the decision choices from which the user will have to select.
 *   The first choice is selected by default.
 *   If there is only one choice in the list, the drop-down will be disabled.
 *
 * - selectedDecision (string): The ID of the decision selected by the user.
 *   This property is set by the data resolver associated with this component
 *   (decisionDataResolver) and returned to the server.
 *
 * @see {@link decisionDataResolver}
 */
export function Decision({
  control,
  flowStep,
  data,
}: DecisionProps): JSX.Element {
  const { t } = useTranslation(["common"]);
  const decisionOptions = data.decisionChoices.map((decision) => {
    return {
      value: decision.id,
      label: decision.name,
      description: decision.description,
    };
  });
  return (
    <StyledStepContentWrapper>
      {/* Combo box to select the decision (Approve or Reject) */}
      <FormSelect
        dataList={decisionOptions}
        defaultValue={decisionOptions[0].value}
        includeEmptyOption={false}
        fieldName={addFlowStepPrefix(flowStep, "decision")}
        required={flowStep.config.selectionRequired}
        control={control}
        label={t("common:decision")}
        disabled={decisionOptions.length === 1}
      />
    </StyledStepContentWrapper>
  );
}
