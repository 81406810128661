import {
  AddressFieldsFlowData,
  AddressFieldsFlowStep,
  FieldsFlowData,
  FieldsFlowStep,
} from "core/api";
import { StepDataResolver } from "..";
import { fieldsDataResolver } from "./fieldsDataResolver";

/**
 * This data resolver makes sure that the values input by the user in the
 * form elements of the AddressFields UI Component are stored in the flow data.
 * If a form element does not have a value, the value already stored in the
 * flow data is returned.
 *
 * @returns An object that contains the following properties (defined in
 * AdjustmentPropertiesFlowData):
 *
 * - copyFromAddressId: the ID of the Address selected by the user in the 'copy from',
 *   or -1 if the user Selected <None>.
 *
 * - fields: contains the Address field values;
 *
 */
export const addressFieldsDataResolver: StepDataResolver<
  AddressFieldsFlowStep,
  AddressFieldsFlowData
> = ({ flow, flowStep, data, fieldValues }) => {
  const fieldsData: FieldsFlowData = {
    ...data,
    uiComponent: "Fields",
  };

  // Extract a FieldsFlowStep from the AddressFieldsFlowStep object
  // to be able to pass it to the fieldsDataResolver
  const fieldsFlowStep: FieldsFlowStep = {
    ...flowStep,
    uiComponent: "Fields",
  };

  // Use the 'super' data resolver to resolve the properties of the 'super' component
  const returnProps = fieldsDataResolver({
    flow,
    flowStep: fieldsFlowStep,
    data: fieldsData,
    fieldValues,
  });

  // Return a new set of properties built using returnProps.
  // Returning returnProps directly does not work because not the correct Type.
  return {
    fields: returnProps.fields,
    copyFromAddressId: fieldValues.copyFrom ?? data.copyFromAddressId,
  };
};
