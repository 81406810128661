import { Box, Checkbox, CheckboxProps } from "common/components";
import { ReactElement } from "react";
import { Control, Controller } from "react-hook-form";

interface FormOptionalFieldWrapperProps
  extends Omit<CheckboxProps, "defaultValue"> {
  fieldName: string;
  control: Control<any> | undefined;
  defaultChecked: boolean;
  validationRules?: object;
  renderField(checked: boolean, disabled: boolean | undefined): JSX.Element;
}

/** Form field optional field wrapper */
export function FormOptionalFieldWrapper({
  fieldName,
  control,
  validationRules,
  defaultChecked,
  renderField,
  ...props
}: FormOptionalFieldWrapperProps): ReactElement {
  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Box
          display="grid"
          gridTemplateColumns="min-content 1fr"
          gap={(theme) => theme.spacing(0.5)}
        >
          <Checkbox
            {...props}
            size="small"
            onChange={(e) => onChange(e.target.checked)}
            checked={value}
          />
          {renderField(value, props.disabled)}
        </Box>
      )}
      defaultValue={defaultChecked}
      rules={validationRules ? validationRules : {}}
    />
  );
}
