import { Skeleton } from "common/components";
import { ReactElement } from "react";

const SKELETON_HEIGHT = 32;

export interface TableSkeletonProps {
  fullColumn?: boolean;
  variant?: "text" | "rectangular" | "rounded" | "circular";
}

export function TableSkeleton({
  fullColumn = false,
  variant,
}: TableSkeletonProps): ReactElement {
  return (
    <Skeleton
      height={SKELETON_HEIGHT}
      sx={{ minWidth: SKELETON_HEIGHT }}
      variant={variant}
      width={fullColumn ? undefined : SKELETON_HEIGHT}
    />
  );
}
