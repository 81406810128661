/**
 * Note status code class.
 * <p>
 *  Corresponds to the NoteStatus enum in Billiant APIs.
 */
export enum NoteStatus {
  /**
   * Closed
   */
  CLOSED = "CLOSED",

  /**
   * Open
   */
  OPEN = "OPEN",
}
