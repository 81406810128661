import { MoreVert } from "common/assets/icons";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "common/components";
import { Link } from "common/routing";
import { useConfigMode } from "core/auth";
import { MouseEvent, ReactElement, useState } from "react";
import { ActionButton } from ".";
export interface ActionsMenuProps {
  actions: ActionButton[];
}

export function ActionsMenu({ actions }: ActionsMenuProps): ReactElement {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { state: configModeEnabled } = useConfigMode();

  return (
    <>
      <IconButton
        data-cy={"collapsedActionsMenu"}
        aria-controls="actions-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        data-cy="hiddenActionsMenu"
      >
        {actions.map(
          ({ actionId, label, Icon, href, onClick, disabled }, index) => (
            <MenuItem
              key={label + "_" + index}
              data-cy={"actionButton" + label.replace(/[\s\\/]/g, "")}
              LinkComponent={href ? Link : undefined}
              {...(href && { to: href })}
              onClick={() => {
                if (onClick) {
                  onClick();
                }
                handleClose();
              }}
              disabled={disabled}
            >
              {/* Only include padding if any action in menu has an icon */}
              {actions.find((action) => action.Icon) && (
                <ListItemIcon>{Icon && <Icon />}</ListItemIcon>
              )}

              {/* Actionbuttons were deemed unlikely to receive additional edits, hence
                  the config mode modification is done directly to the label instead of adding
                  another component. This also means the config mode enabled check has to be done here. 
                  Config mode also provides the ability to right click enabled actionbuttons (doesn't
                  work on disabled, grey'd out, buttons) to copy their displayed actionId*/}

              {configModeEnabled ? (
                <ListItemText
                  primary={label}
                  secondary={"Action Id: " + actionId}
                  onContextMenu={(e: MouseEvent) => {
                    e.preventDefault();
                    navigator.clipboard.writeText("" + actionId);
                  }}
                />
              ) : (
                <ListItemText primary={label} />
              )}
            </MenuItem>
          )
        )}
      </Menu>
    </>
  );
}
