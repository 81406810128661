import { AmountWithVATFlowData, AmountWithVATFlowStep } from "core/api";
import { StepDataResolver } from "..";

/**
 * This data resolver makes sure that the values input by the user in the
 * form elements of the AmountWithVAT UI Component are stored in the flow data.
 * If a form element does not have a value, the value already stored in the
 * flow data is returned.
 *
 * @returns An object that contains the properties inputAmountInclVat and amount
 * defined in AmountWithVATFlowData.
 * The properties are set to the values input by the user, or to the values present
 * in the flow data, if there is no value in the corresponding form element.
 */
export const amountWithVATDataResolver: StepDataResolver<
  AmountWithVATFlowStep,
  AmountWithVATFlowData
> = ({ data, fieldValues }) => {
  const inputAmountInclVat =
    fieldValues.uiInputAmountInclVat ?? data.inputAmountInclVat;
  return {
    // currencyCode and vatRate do not need to be resolved because the user
    // cannot edit these values.
    inputAmountInclVat: inputAmountInclVat,
    amount: inputAmountInclVat
      ? fieldValues.amountInclVat ?? data.amount
      : fieldValues.amountExclVat ?? data.amount,
  };
};
