import {
  EntityType,
  FilterValueState,
  FilterValueStates,
  SearchFilter,
  useFetchFilters,
} from "core/api";
import { cloneDeep } from "lodash";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSearchFilters } from "./useSearchFilters";

export interface SearchFilterPanelOptions {
  entityType: EntityType;
  tableOptionId: number;
  historyEntryEntity?: EntityType | undefined;
  disableUrlParameters?: boolean;
  /** Optional: the initial value of the filter */
  initialFilterStates?: FilterValueStates;
}

export interface UseSearchFilterPanelOutputProps {
  /** The current state of the filter search query (to include in the api url) */
  filters: string | string[] | undefined;
  /** TODO [LucaN] understand exactly what this is !!! */
  activeFilterState: FilterValueStates | undefined;
  /** State of the filter fields */
  filterStates: FilterValueStates;
  /** Available filters */
  filterFields: SearchFilter[];
  /** Reset hook: gets called when the user clicks on the RESET button */
  resetFilterPanel(): void;
  /** onChange hook: gets called everytime the user changes the value of a filter field through the UI */
  getOnChange: <TValueState extends FilterValueState>(
    id: number
  ) => (state: TValueState) => void;
  /** Function to set The current state of the filter search string */
  setFilters: Dispatch<SetStateAction<string | string[] | undefined>>;
  /** Search in entity */
  activeFilters?: FilterValueStates;
  /** Function that can be used to generate the filter search query that corresponds to a given filter state */
  formatToFilterQuery: (filterStates: FilterValueStates) => string[];
  /** Function that returns **true** if the given filter states contain
   *  at least one filter that has been set by the user. Returns false if none
   * of the filter fields has been set. */
  hasModifiedFilters: (filterStates: FilterValueStates) => boolean;
}

/** This hook manages the state of the Search Filters of the SearchFilterPanel */
export function useSearchFilterPanel({
  entityType,
  tableOptionId,
  historyEntryEntity,
  disableUrlParameters,
  initialFilterStates = {},
}: SearchFilterPanelOptions): UseSearchFilterPanelOutputProps {
  const { data = [] } = useFetchFilters(entityType, tableOptionId);

  const filterFields = useMemo(() => {
    if (historyEntryEntity) {
      return data.filter(
        (filter) =>
          filter.entityType === EntityType.NONE ||
          filter.entityType === historyEntryEntity
      );
    }
    return data;
  }, [data, historyEntryEntity]);

  const { filters, setFilters, activeFilterState, formatToFilterQuery } =
    useSearchFilters(filterFields, disableUrlParameters, initialFilterStates);

  const [filterStates, setFilterStates] = useState<FilterValueStates>({});
  if (
    Object.keys(filterStates).length === 0 &&
    Object.keys(initialFilterStates).length > 0
  ) {
    setFilterStates(initialFilterStates);
  }

  useEffect(() => {
    setFilterStates(cloneDeep(activeFilterState ?? {}));
  }, [activeFilterState]);

  /**
   * Updates the filterState with the value of the filter field identified
   * by **id**. The filter field value is available in **state**.
   *
   * @remarks
   * If the FilterValueState **state** is empty, i.e. the filter field does not have any value,
   * then the corresponding state is removed from the array.
   * Else (if the FilterValueState **state** is NOT empty), the state it is added to the array.
   */
  const getOnChange = useCallback(
    <TValueState extends FilterValueState>(id: number) =>
      (state: TValueState) => {
        if (isFilterFieldEmpty(state)) {
          delete filterStates[id];
          setFilterStates({ ...filterStates });
        } else {
          setFilterStates((currentState) => ({
            ...currentState,
            [id]: state,
          }));
        }
      },
    [filterStates]
  );

  const resetFilterPanel = useCallback(() => {
    setFilterStates({});
  }, []);

  return {
    filters,
    activeFilterState,
    filterFields,
    filterStates,
    resetFilterPanel,
    getOnChange,
    setFilters,
    formatToFilterQuery,
    hasModifiedFilters,
  };
}

function isFilterFieldEmpty(state: FilterValueState): boolean {
  if (
    !state.value &&
    !state.currencyCode &&
    !state.minValue &&
    !state.maxValue
  ) {
    return true;
  }
  return false;
}

function hasModifiedFilters(filterStates: FilterValueStates) {
  return (
    Object.values(filterStates).filter(
      ({ value, maxValue, minValue, currencyCode }) =>
        (value && (value === true || value?.length > 0)) ||
        minValue ||
        maxValue ||
        currencyCode
    ).length > 0
  );
}
