import { getEntityStatusIcon, getEntityTitle, mapFields } from "common/utils";
import { EntityData, Icon, ScreenConfig, useFetchFieldConfig } from "core/api";
export interface UseScreenTitleProps {
  title: string;
  breadcrumbTitle: string;
  icon: Icon | undefined;
}

/**
 * From provided screen data and config the title, breadcrumbTitle and optional entitystatus icon are returned.
 *
 * @param config
 * @param screenEntity
 *
 * @returns UseScreenTitleProps
 */
export function useScreenTitle(
  config: ScreenConfig | undefined,
  screenEntity: EntityData | undefined
): UseScreenTitleProps {
  const { data: fieldConfigList } = useFetchFieldConfig(config?.entityType);

  const fieldConfigMap = mapFields(fieldConfigList);

  const title = getEntityTitle(
    screenEntity,
    config?.title,
    fieldConfigMap,
    config?.entityFetchUrl
  );
  const breadcrumbTitle = getEntityTitle(
    screenEntity,
    config?.breadcrumbTitle,
    fieldConfigMap,
    config?.entityFetchUrl
  );

  const icon =
    screenEntity &&
    fieldConfigList &&
    getEntityStatusIcon(screenEntity, fieldConfigList);

  return { title, breadcrumbTitle, icon };
}
