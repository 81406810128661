import {
  Autocomplete,
  FormControl,
  FormControlledFieldProps,
  FormControlProps,
  FormHelperText,
  SelectOption,
  TextField,
  withFormController,
} from "common/components";
import { ReactElement } from "react";

export interface FormAutocompleteProps extends FormControlledFieldProps {
  /** If set to true the component will use the full width available in the container */
  fullWidth?: boolean;
  /** Optional. Helper text that is displayed in the input box when empty */
  helperText?: string;
  /** The list of pre-defined choices that the user can select */
  dataList: SelectOption[];
  /** Defaults to false => Combo box mode, i.e. the user can only select from the pre-defined list.
   *  Set to true to allow the user to input free text. */
  freeSolo?: boolean;
  formControlProps?: FormControlProps;
}

function formatComponentId(value: string, valueVariant: string) {
  return value.toLowerCase().replaceAll(" ", "-").concat(`-${valueVariant}`);
}

/** A form element that uses the MUI Autocomplete component */
export const FormAutocomplete = withFormController<FormAutocompleteProps>(
  ({
    label,
    "data-cy": dataCy,
    required,
    fieldName,
    defaultValue,
    renderState,
    fullWidth,
    helperText,
    dataList,
    freeSolo = false,
    formControlProps,
    ...props
  }): ReactElement => {
    const {
      field,
      fieldState: { error },
      gridColumn,
      isDisabled,
    } = renderState;
    return (
      <FormControl
        sx={{ gridColumn }}
        fullWidth={fullWidth}
        {...formControlProps}
      >
        <Autocomplete
          {...field}
          {...props}
          freeSolo={freeSolo}
          disableClearable
          options={dataList.map(({ label }) => label)}
          sx={{ flex: 1 }}
          disabled={isDisabled}
          onChange={(event, newValue) => field.onChange(newValue)}
          onInputChange={(event, newValue) => field.onChange(newValue)}
          renderInput={(params) => (
            <TextField
              ref={params.InputProps.ref}
              {...params}
              InputProps={{
                ...params.InputProps,
                type: "text",
              }}
              required={required}
              name={fieldName}
              id={fieldName}
              data-cy={dataCy}
              label={label}
              placeholder={helperText}
            />
          )}
        />
        <FormHelperText error={!!error}>{error?.message}</FormHelperText>
      </FormControl>
    );
  }
);
