import { DefaultPageSizes } from "core/appSettings";
import { useEffect, useState } from "react";

export interface PaginationStateSettings {
  pageSizes?: number[];
  initPage?: number;
  initPageSize?: number;
  pageSizeListener?(newPageSize: number): void;
  onPageChange?(page: number): void;
  onPageSizeChange?(pageSize: number): void;
}

export function usePaginationState({
  pageSizes = DefaultPageSizes,
  initPage,
  initPageSize,
  pageSizeListener,
  onPageChange,
  onPageSizeChange,
}: PaginationStateSettings) {
  const [page, setPage] = useState<number>(initPage ? initPage : 0);
  const [pageSize, setPageSize] = useState<number>(
    initPageSize ? initPageSize : pageSizes[0]
  );

  useEffect(() => {
    onPageChange?.(page);
  }, [page, onPageChange]);

  useEffect(() => {
    onPageSizeChange?.(pageSize);
  }, [pageSize, onPageSizeChange]);

  useEffect(() => {
    if (pageSizeListener) {
      pageSizeListener(pageSize);
    }
  }, [pageSize, pageSizeListener]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newPageSize = parseInt(event.target.value, 10);
    setPageSize(newPageSize);
    setPage(0);
  };

  return {
    page,
    setPage,
    handleChangePage,
    pageSize,
    setPageSize,
    handleChangeRowsPerPage,
  };
}
