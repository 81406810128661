import {
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  FormTable,
} from "common/components";
import { addFlowStepPrefix } from "common/utils";
import { TableFlowData, TableFlowStep, useFetchTableConfig } from "core/api";
import { FlowStepComponentProps } from "../models";
import { StyledStepContentWrapper } from "../steps.styles";

export type TableProps = FlowStepComponentProps<TableFlowStep, TableFlowData>;

/** Table step component */
export function Table({ control, flowStep, flow }: TableProps): JSX.Element {
  const {
    data: tableConfig,
    isError,
    error,
  } = useFetchTableConfig(flowStep.config.tableId, { ignoreError: true });

  if (isError) {
    return (
      <Alert severity="error" sx={{ alignSelf: "start" }}>
        <AlertTitle>{error?.userMessage}</AlertTitle>
      </Alert>
    );
  }

  if (!tableConfig) {
    return (
      <Box display="grid" sx={{ placeItems: "center" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <StyledStepContentWrapper
      dialogSize={flow.config.dialogSize}
      sx={{ padding: 0 }}
    >
      <FormTable
        control={control}
        fieldName={addFlowStepPrefix(flowStep, "tableStep")}
        required={flowStep.config.required}
        tableConfig={tableConfig}
        apiUrl={flowStep.config.apiUrl}
        tableItems={flowStep.config.tableItems}
        selectionVariant={flowStep.config.selectionVariant}
        tableId={flowStep.config.tableId}
        showSearchIfMoreThan={flowStep.config.showSearchIfMoreThan}
        tagCheckboxLimit={flowStep.config.tagCheckboxLimit}
      />
    </StyledStepContentWrapper>
  );
}
