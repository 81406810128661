import { styled } from "common/components";

export const StyledFilterWrapper = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateRows: "min-content",
}));

interface StyledSearchFilterContainerProps {
  filtersOpen: boolean;
}

export const StyledSearchFilterContainer = styled("div", {
  shouldForwardProp: (propName) => propName !== "filtersOpen",
})<StyledSearchFilterContainerProps>(({ theme, filtersOpen }) => ({
  display: "grid",
  gridTemplateColumns: "1fr auto auto",
  gridTemplateRows: "40px auto auto",
  gridTemplateAreas: `
    "searchField searchButton resetButton filterButton"
    "searchField . . ."
    "filters filters filters filters"
  `,
  alignItems: "start",
  columnGap: theme.spacing(1),
  rowGap: filtersOpen ? theme.spacing(1) : 0,
  width: "100%",
  backgroundColor: theme.palette.grey["50"],
  border: `1px solid ${theme.palette.grey[200]}`,
  padding: theme.spacing(1),
}));

export const StyledFiltersContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "flex-end",
  flexWrap: "wrap",
  gap: theme.spacing(1),
}));

export const StyledSearchFieldContainer = styled("div")(({ theme }) => ({
  gridArea: "searchField",
  display: "flex",
  flexWrap: "wrap",
  gap: theme.spacing(1),
}));
