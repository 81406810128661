import { Search } from "common/assets/icons";
import {
  Box,
  Button,
  FormControllerContext,
  FormSelect,
  FormTextField,
} from "common/components";
import { addFlowStepPrefix } from "common/utils";
import {
  FindInventoryFlowData,
  FindInventoryFlowStep,
  TableFlowStep,
} from "core/api";
import { useTranslation } from "i18n";
import { ReactElement, useContext, useState } from "react";
import { useFormContext } from "react-hook-form";
import { theme } from "styles/theme";
import { toSelectOption } from "../../flowUtils";
import { Table } from "../Table";
import { FlowStepComponentProps } from "../models";
import { StyledStepContentWrapper } from "../steps.styles";

export type FindInventoryProps = FlowStepComponentProps<
  FindInventoryFlowStep,
  FindInventoryFlowData
>;

/** Finds Inventory step component that allows you to find inventories to assign to a product. */
export function FindInventory({
  control,
  flow,
  data,
  flowStep,
  setValue,
  ...props
}: FindInventoryProps): ReactElement {
  const { t } = useTranslation(["common", "action"]);

  const {
    searchText,
    statusId,
    locationId,
    articleId,
    maxInvItems,
    tableStep,
  } = data;
  const { tableStepConfig, statuses, locations, articles } = flowStep.config;

  const mergedTableStep = {
    ...flowStep,
    ...tableStep,
    config: { ...tableStepConfig },
  } as unknown as TableFlowStep;

  const context = useFormContext();
  const searchTextFieldName = addFlowStepPrefix(flowStep, "searchText");

  const enteredText: string = context?.watch(searchTextFieldName, "");

  const { evaluateOnChange } = useContext(FormControllerContext);
  const [isLoading, setIsLoading] = useState(false); //

  const handleClick = () => {
    setIsLoading(true);
    evaluateOnChange?.(() => {
      setIsLoading(false);
    });
  };

  return (
    <>
      <StyledStepContentWrapper>
        <Box
          style={{
            display: "grid",
            gridTemplateColumns: "3fr 2fr 2fr 2fr 1fr min-content",
            alignItems: "center",
            gap: theme.spacing(1),
          }}
          data-cy="searchFields"
        >
          <FormTextField
            fieldName={searchTextFieldName}
            control={control}
            label={t("action:findInventoryStep.searchText")}
            data-cy="searchText"
            defaultValue={searchText}
            onKeyDown={(event) => {
              if (event.key === "Enter" && enteredText !== "") {
                evaluateOnChange?.();
              }
            }}
          />
          <FormSelect
            dataList={toSelectOption(statuses)}
            includeEmptyOption={true}
            fieldName={addFlowStepPrefix(flowStep, "statusId")}
            required={false}
            control={control}
            defaultValue={statusId}
            label={t("action:findInventoryStep.status")}
            data-cy="status"
          />
          <FormSelect
            dataList={toSelectOption(locations)}
            includeEmptyOption={true}
            fieldName={addFlowStepPrefix(flowStep, "locationId")}
            required={false}
            control={control}
            defaultValue={locationId}
            label={t("action:findInventoryStep.location")}
            data-cy="location"
          />
          {articles?.length > 0 && (
            <FormSelect
              dataList={toSelectOption(articles)}
              includeEmptyOption={true}
              fieldName={addFlowStepPrefix(flowStep, "articleId")}
              required={false}
              control={control}
              defaultValue={articleId}
              label={t("action:findInventoryStep.article")}
              data-cy="article"
            />
          )}
          <FormTextField
            fieldName={addFlowStepPrefix(flowStep, "maxInvItems")}
            control={control}
            label={t("action:findInventoryStep.maxInvItems")}
            data-cy="maxInvItems"
            defaultValue={maxInvItems}
          />
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={handleClick}
            disabled={isLoading || enteredText === ""}
            loading={isLoading}
            data-cy="searchButton"
            aria-label={t("common:buttons.search")}
          >
            <Search />
          </Button>
        </Box>
        {tableStep && (
          <Table
            control={control}
            data={tableStep}
            flowStep={mergedTableStep}
            flow={flow}
            setValue={setValue}
            {...props}
          ></Table>
        )}
      </StyledStepContentWrapper>
    </>
  );
}
