import {
  ActionConfig,
  EntityData,
  RequestActionProps,
  useAPIMutation,
} from "core/api";
import { getEntityUrl } from "core/components";
import { useCallback } from "react";
import { ActionParams, useActionDialog } from "./flows";

export function useAction() {
  const { openDialog } = useActionDialog();
  const { mutate } = useRequestAction();

  const triggerAction = useCallback(
    (config: ActionConfig, params?: ActionParams) => {
      const {
        entityType,
        entityId,
        viewId,
        widgetId,
        entity,
        invokedFromTable,
      } = params ?? {};
      const actionEntityType = entityType ?? config.entityType;
      switch (config.actionType) {
        case "FLOW":
          if (actionEntityType && config.actionId) {
            openDialog({
              entityType: actionEntityType,
              entityId: entityId,
              actionId: config.actionId,
              viewId: viewId,
              widgetId: widgetId,
              invokedFromTable: invokedFromTable ?? false,
            });
          }
          break;
        case "REQUEST":
          if (entity && config.config && "apiUrl" in config.config) {
            mutate({ entity, actionProps: config.config });
          }
          break;
        case "NAVIGATE":
        default:
          break;
      }
    },
    [mutate, openDialog]
  );

  return { triggerAction };
}

interface RequestAction {
  entity: EntityData | EntityData[];
  actionProps: RequestActionProps;
}

function useRequestAction() {
  return useAPIMutation<unknown, RequestAction>(
    ({ entity, actionProps }) => {
      if (Array.isArray(entity)) {
        return actionProps.apiUrl;
      }
      return getEntityUrl(entity, actionProps.apiUrl) ?? "";
    },
    ({ actionProps }) => ({
      method: actionProps.method,
    })
  );
}
