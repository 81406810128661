export default {
  cancelDialog: {
    title: 'Lämna aktivitet "{{action}}"?',
    body: "Du kommer förlora inmatade data.",
    cancelButton: "Lämna aktivitet",
    closeButton: "Avbryt",
  },

  transferProductStep: {
    copyProduct: "Kopiera",
    transferBinds: "Flytta med bindningstider",
    deactivateStatusReasonId: "Skäl för deaktivering",
    activateStatusReasonId: "Skäl för aktivering",
  },

  findInventoryStep: {
    searchText: "Söktext",
    status: "Status",
    location: "Plats",
    article: "Artikel",
    maxInvItems: "Visa inte fler än",
  },

  findNetworkElementStep: {
    searchText: "Söktext",
    status: "Status",
    networkElementType: "Nätverkselement Typ",
    maxTableItems: "Visa inte fler än",
  },
} as const;
