import { styled } from "common/components";

export const StyledMultipleFieldFilterContainer = styled("article")(
  ({ theme }) => ({
    flexWrap: "nowrap",
    gap: theme.spacing(0.8),
    whiteSpace: "nowrap",
    padding: theme.spacing(0.25, 0),
    display: "flex",
  })
);
