import { useCallback, useState } from "react";

export function useToggle(initialState = false): [boolean, () => void] {
  const [active, setActive] = useState(initialState);

  const toggle = useCallback(() => {
    setActive((isActive) => !isActive);
  }, []);

  return [active, toggle];
}
