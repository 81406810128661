import { AvailableLanguageItem, useTranslation } from "i18n";
import { AvailableLanguage } from "i18n/models";

/**
 * @returns Available languages
 */
export function useAvailableLanguages(): AvailableLanguageItem[] {
  const { t } = useTranslation("languages");

  return [
    {
      value: AvailableLanguage.de,
      label: t("de"),
    },
    {
      value: AvailableLanguage.en,
      label: t("en"),
    },
    {
      value: AvailableLanguage.gb,
      label: t("gb"),
    },
    {
      value: AvailableLanguage.sv,
      label: t("sv"),
    },
    {
      value: AvailableLanguage.fr,
      label: t("fr"),
    },
  ];
}
