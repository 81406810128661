import { Link as MuiLink, LinkProps as MuiLinkProps } from "@mui/material";
import { forwardRef } from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";

export type LinkProps = RouterLinkProps & Omit<MuiLinkProps, "component">;

/** MUI Link with React Router Link as component */
export const Link = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
  return <MuiLink ref={ref} component={RouterLink} {...props} />;
});
