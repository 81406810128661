import { isNumeric } from "common/utils";
import { useParams } from "react-router-dom";

/**
 * Returns path param id value for a given key as number.

 * @param paramKey Path param key
 * @returns Path id value
 */
export function usePathIdAsNumber(paramKey: string): number | undefined {
  const params = useParams<Record<string, string>>();

  if (paramKey) {
    const value = params[paramKey];
    if (value !== undefined) {
      if (isNumeric(value)) {
        return parseInt(value);
      } else {
        console.error(
          "Expected a number in the url path parameter " +
            paramKey +
            " but got " +
            value
        );
      }
    }
  }

  return undefined;
}
