import { API, APIQueryResult, useAPIQuery } from "core/api";
import { SubscriptionStatusReason } from "core/api/models";

/**
 * Find all subscription status reasons user has access to.
 *
 * @param enabled Directive for useQuery.
 *
 * @returns
 */
export function useFetchSubscriptionsStatusReasons(
  enabled = true
): APIQueryResult<SubscriptionStatusReason[]> {
  return useAPIQuery(
    ["fetchSubscriptionStatusReasons"],
    API.common.subscriptionStatusReasons,
    {},
    { enabled }
  );
}
