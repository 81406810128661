import { Error } from "common/assets/icons";
import { Box, Button, Typography } from "common/components";
import { useTranslation } from "i18n";
import { Fragment, useEffect } from "react";
import { StyledActionForm } from "../../ActionDialog/StepperDialog/StepperDialog.styles";
import { StepAlert, StepDetails } from "../../ActionDialog/components";
import { getCurrentData } from "../../flowUtils";
import { ActionProps } from "../../models";
import { useActionFlow } from "../../useActionFlow";

export type FormProps = ActionProps;

/**
 * Inline Flow action form that displays all steps
 */
export function Form({
  actionId,
  entityType,
  entityId,
  screenId,
  viewId,
  widgetId,
}: Readonly<FormProps>) {
  const { t } = useTranslation(["common"]);
  const {
    data,
    setCurrentStepId,
    performState,
    apiErrorMessage,
    onSubmit,
    form,
    StepComponent,
    CancelConfirmDialog,
  } = useActionFlow({
    actionId,
    entityType,
    entityId,
    screenId,
    viewId,
    widgetId,
    invokedFromTable: false,
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = form;

  useEffect(() => {
    if (data) {
      setCurrentStepId(data.steps[data.steps.length - 1].id);
    }
  }, [data, setCurrentStepId]);

  if (!data) {
    return null;
  }

  return (
    <>
      <StyledActionForm context={form} onSubmit={handleSubmit(onSubmit)}>
        <Box display="grid" gap={1} paddingBottom={1}>
          <Typography variant="h4" component="h3" textAlign="center">
            {data.title}
          </Typography>
          {apiErrorMessage && (
            <StepAlert
              color="error"
              icon={<Error />}
              messages={apiErrorMessage.split("\n")}
            />
          )}
        </Box>
        <Box display="grid" gap={1}>
          {data.steps.map((step) => {
            const stepData = getCurrentData(data, step.id);
            return (
              <Fragment key={step.id}>
                <StepDetails
                  title={step.name}
                  hint={step.hint}
                  description={step.description}
                  icon={step.icon}
                  messages={stepData?.messages}
                  showStepTitle={data.config.showStepTitle}
                />
                {stepData && (
                  <StepComponent flowStep={step} stepData={stepData} />
                )}
              </Fragment>
            );
          })}
        </Box>
        <Box sx={{ marginTop: "auto" }}>
          <Button
            type="submit"
            fullWidth
            loading={performState.status === "loading"}
            disabled={
              !data.steps.find((step) => step.finishAllowed) || !isValid
            }
            data-cy="actionButtonFinish"
          >
            {t("common:buttons.update")}
          </Button>
        </Box>
      </StyledActionForm>
      <CancelConfirmDialog />
    </>
  );
}
