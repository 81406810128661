import { API, APIQueryResult, useAPIQuery } from "core/api";
import { CustomerStatusReason } from "core/api/models";

/**
 * Find all customer status reasons user has access to.
 *
 * @param enabled Directive for useQuery.
 *
 * @returns
 */
export function useFetchCustomerStatusReasons(
  enabled = true
): APIQueryResult<CustomerStatusReason[]> {
  return useAPIQuery(
    ["fetchCustomerStatusReasons"],
    API.common.customerStatusReasons,
    {},
    { enabled }
  );
}
