import { BookmarkBorder } from "common/assets/icons";
import { IconButton, IconButtonProps, Tooltip } from "common/components";
import { BookmarkEntryType, EntityType } from "core/api";
import { Bookmark } from "core/api/models/Bookmark";
import { useBookmarks } from "core/hooks";
import { useTranslation } from "i18n";
import { MouseEvent } from "react";
import { StyledBookmarkIcon } from "./BookmarkIconButton.styles";

interface BookmarkIconButtonProps extends IconButtonProps {
  filterEntityType?: EntityType;
  filterEntryType?: BookmarkEntryType;
  recentlyViewed?: boolean;
  /**
   * Indicates whether the target component is the search results tab of the quick search.
   */
  searchResults?: boolean;
  bookmark: Bookmark;
}

/**
 *
 * @param bookmarkEntry A prefilled bookmark entry
 * @returns An starred/unstarred icon with tooltip
 */
export function BookmarkIconButton({
  filterEntityType,
  filterEntryType,
  recentlyViewed,
  searchResults,
  bookmark,
  ...props
}: Readonly<BookmarkIconButtonProps>) {
  const { t } = useTranslation(["common"]);
  const { updateStatus, isBookmarked, addBookmark, removeBookmark } =
    useBookmarks(
      filterEntityType ?? undefined,
      filterEntryType ?? BookmarkEntryType.BOOKMARK
    );
  let isEntityBookmark = isBookmarked(bookmark.url);

  const label = isEntityBookmark
    ? t("common:bookmarks.removeFromBookmarks")
    : t("common:bookmarks.addToBookmarks");

  const Icon = isEntityBookmark ? StyledBookmarkIcon : BookmarkBorder;

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (updateStatus !== "loading") {
      try {
        isEntityBookmark
          ? removeBookmark(bookmark, true, recentlyViewed, searchResults)
          : addBookmark(bookmark, true, recentlyViewed);

        event.preventDefault();
        event.stopPropagation();
      } finally {
        isEntityBookmark = !isEntityBookmark;
      }
    } else {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  if (
    bookmark.primaryText ||
    (bookmark.bookmarkEntry?.entityId &&
      bookmark.bookmarkEntry.entityId !== "-1")
  ) {
    return (
      <IconButton
        disabled={updateStatus === "loading"}
        onClick={handleClick}
        data-cy="bookmarkIconButton"
        {...props}
        aria-label={label}
      >
        <Tooltip key={label} title={label}>
          <Icon data-active={isEntityBookmark} fontSize={props.size} />
        </Tooltip>
      </IconButton>
    );
  }
  return null;
}
