import { ListItem, ListItemText } from "common/components";
import { FlowData, FlowStep } from "core/api";
import { useTranslation } from "i18n";
import { Fragment } from "react";

export interface CreditCheckConfirmationProps {
  step: FlowStep;
  flowData: FlowData;
}

/**
 * Sub component to confirmation. The purpose of this component is
 * to make the main Confirmation component more readable,
 * abstracting some of the logic into smaller sub components.
 *
 * CreditCheckConfirmation is specific to confirmation steps where
 * the flow contains a step with the uiComponent: "CreditCheck".
 *
 * @param flowData data property from the flow object, this is fetched through
 * const flowData = flow.data[step.id]; in the parent component.
 * @param getValues A helper function from react-hook-form. The function is passed
 * into the parent Confirmation component and in turn passed here.
 *
 * @returns A JSX element with credit check details from the previous "CreditCheck" step in the flow.
 */
export function CreditCheckConfirmation({
  step,
  flowData,
}: CreditCheckConfirmationProps): JSX.Element {
  const { t } = useTranslation(["common"]);
  const fieldFlowData =
    flowData.uiComponent === "CreditCheck" ? flowData : undefined;

  return (
    <Fragment key={step.id}>
      <ListItem>
        <ListItemText
          primary={t("common:creditScore")}
          secondary={fieldFlowData?.creditRating.score}
          data-cy={"confirmCreditScore"}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={t("common:creditStatus")}
          secondary={fieldFlowData?.creditRating.ratingStatusValue?.name}
          data-cy={"confirmCreditStatus"}
        />
      </ListItem>
    </Fragment>
  );
}
