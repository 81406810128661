import { API, APIMutationResult, useAPIMutationNoAuthContext } from "core/api";
import { LogEvent } from "core/api/models";

/**
 * Logs an event to the Billiant eventlog.
 * @param logEvent The event to log.
 * @returns An empty response.
 */
export function useLogEvent(): APIMutationResult<void, LogEvent> {
  return useAPIMutationNoAuthContext<void, LogEvent>(
    API.loggerservice.logEvent,
    (logEvent: LogEvent) => ({
      method: "POST",
      credentials: "include",
      mode: "same-origin",
      body: JSON.stringify(logEvent),
    })
  );
}
