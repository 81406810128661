import { isValidDate } from "common/utils";
import { FieldError } from "react-hook-form";

export interface FormDatePickerEvent {
  /** Date object */
  $d?: Date;
}

export interface FormDatePickerValue {
  /** Form date picker event */
  event: FormDatePickerEvent;
  /** Keyboard input value */
  keyboardInputValue: string | undefined;
}

/**
 * Gets a date validator function based on input format
 * @param inputFormat Date input format
 * @param dateValidationError Validation error message for date value
 * @param required If field is required
 * @param requiredValidationError Validation error message for required
 * @returns Date validator function
 */
export function getKeyboardInputDateValidator(
  inputFormat: string,
  dateValidationError: string,
  required: boolean | undefined,
  requiredValidationError: string
) {
  return (value: FormDatePickerValue | Date | string) => {
    if (typeof value === "string" || value instanceof Date) {
      return true;
    }
    if (value.keyboardInputValue) {
      return (
        isValidDate(value.keyboardInputValue, inputFormat, true) ||
        dateValidationError
      );
    }
    if (required && !value.event) {
      return requiredValidationError;
    }
    return true;
  };
}

/**
 * Gets date picker onChange function
 * @param onChange Form onChange function
 * @returns Picker onChange function
 */
export function getFormDatePickerOnChange(
  onChange: (value: FormDatePickerValue) => void
) {
  return (
    event: FormDatePickerEvent | any,
    keyboardInputValue: string | undefined
  ) => {
    onChange({ event, keyboardInputValue });
  };
}

/**
 * Formats form date picker value to ISO string
 * @param value Form date picker value
 * @returns Formatted date value
 */
export function formatFormDatePickerValue(value: FormDatePickerValue | string) {
  if (typeof value === "string") {
    return value;
  }
  if (isValidDate(value.event?.$d)) {
    return value.event.$d?.toISOString() ?? "";
  }
  return "";
}

/**
 * Inserts input hint in error message
 * @param error Field error
 * @param helperText Field helper text
 * @param hint Date format error input hint
 * @returns Error message with inserted hint, or helper text
 */
export function insertDateErrorHint(
  error: FieldError | undefined,
  helperText: string | undefined,
  hint: string | undefined
) {
  return error?.message?.replace("{{hint}}", hint ?? "") ?? helperText;
}
