import { ListItem, ListItemText } from "common/components";
import {
  MAXIMUM_FRACTION_DIGITS,
  calculateVATAmounts,
  formatCurrency,
  formatPercentage,
} from "common/utils";
import { FlowData, FlowStep } from "core/api";
import { useTranslation } from "i18n";
import { Fragment } from "react";

export interface AdjustmentAmountConfirmationProps {
  step: FlowStep;
  flowData: FlowData;
}

/**
 * Sub component to confirmation. The purpose of this component is
 * to make the main Confirmation component more readable,
 * abstracting some of the logic into smaller sub components.
 *
 * AdjustmentAmountConfirmation is specific to confirmation steps where
 * the flow contains a step with the uiComponent: "AdjustmentAmount".
 *
 * @param flowData data property from the flow object, this is fetched through
 * const flowData = flow.data[step.id]; in the parent component.
 * @returns A JSX element with invoice details from the previous "AdjustmentAmount" step in the flow.
 */
export function AdjustmentAmountConfirmation({
  step,
  flowData,
}: AdjustmentAmountConfirmationProps): JSX.Element {
  const { t } = useTranslation();
  const {
    generalLedgerInfo = null,
    taxBundleInfo = null,
    currencyCode = "?",
    inputAmountInclVat = true,
    amount = 0.0,
    vatRate = 0.0,
  } = flowData.uiComponent === "AdjustmentAmount" ? flowData : {};
  const vatAmounts = calculateVATAmounts(amount, inputAmountInclVat, vatRate);
  const vatPercentage = formatPercentage(vatRate);
  return (
    <Fragment key={step.id}>
      <ListItem>
        <ListItemText
          primary={t("common:generalLedgerInfo")}
          secondary={
            generalLedgerInfo?.name + " - " + generalLedgerInfo?.description
          }
          data-cy={"confirmGeneralLedgerInfo"}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={t("common:taxBundleInfo")}
          secondary={taxBundleInfo?.name}
          data-cy={"confirmTaxBundleInfo"}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={t("common:amountExclVat", {
            vatRate: vatPercentage,
          })}
          secondary={formatCurrency(vatAmounts.amountExclVat, currencyCode, {
            maximumFractionDigits: MAXIMUM_FRACTION_DIGITS,
          })}
          data-cy={"confirmAmountExclVat"}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={t("common:amountInclVat", {
            vatRate: vatPercentage,
          })}
          secondary={formatCurrency(vatAmounts.amountInclVat, currencyCode, {
            maximumFractionDigits: MAXIMUM_FRACTION_DIGITS,
          })}
          data-cy={"confirmAmountInclVat"}
        />
      </ListItem>
    </Fragment>
  );
}
