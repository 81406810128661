import { TransferProductFlowData, TransferProductFlowStep } from "core/api";
import { StepDataResolver } from "../models";

/**
 * Transfer Products data resolver
 *
 * Format transfer product settings based on form field values
 */
export const transferProductDataResolver: StepDataResolver<
  TransferProductFlowStep,
  TransferProductFlowData
> = ({ data, fieldValues }) => {
  return {
    copyProduct: fieldValues.copyProduct ?? data.copyProduct,
    transferBinds: fieldValues.transferBinds ?? data.transferBinds,
    deactivateStatusReasonId:
      fieldValues.deactivateStatusReasonId ?? data.deactivateStatusReasonId,
    activateStatusReasonId:
      fieldValues.activateStatusReasonId ?? data.activateStatusReasonId,
  };
};
