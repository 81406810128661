import {
  Skeleton as MuiSkeleton,
  SkeletonProps as MuiSkeletonProps,
} from "@mui/material";
import { forwardRef } from "react";

/**
 * To prepare if/when we need to add our out props.
 *
 * Future extension would be to replace type with:
 *
 * export interface SkeletonProps extends MuiSkeletonProps {
 *  extraProp1: string;
 * }
 */
export type SkeletonProps = MuiSkeletonProps;

/**
 *
 *  Wrapper aropund the MUI Skeletion to add a data-cy tag for UI testing.
 *
 * @param props
 * @returns Skeleton
 */
export const Skeleton = forwardRef<HTMLSpanElement, SkeletonProps>(
  function Skeleton(props, ref) {
    return <MuiSkeleton ref={ref} data-cy="skeleton" {...props} />;
  }
);
