import { PINCodeField, PINCodeFieldProps } from "common/components";
import { ReactElement } from "react";
import {
  FormControlledFieldProps,
  withFormController,
} from "../FormController";

export interface FormPINCodeFieldProps
  extends Omit<
      PINCodeFieldProps,
      "setValue" | "value" | "defaultValue" | "label"
    >,
    FormControlledFieldProps {}

/** Form PIN code field */
export const FormPINCodeField = withFormController<FormPINCodeFieldProps>(
  ({ helperText, renderState, ...props }): ReactElement => {
    const {
      field: { onChange, ...field },
      fieldState: { error },
      gridColumn,
      isDisabled,
    } = renderState;
    return (
      <PINCodeField
        {...props}
        {...field}
        setValue={onChange}
        error={!!error}
        helperText={error?.message ?? helperText}
        disabled={isDisabled}
        sx={[
          { gridColumn },
          ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
        ]}
      />
    );
  }
);
