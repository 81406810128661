import { ScreenConfig } from "core/api";
import { ScreenEntities } from "core/store";
import { createContext, ReactNode, useContext } from "react";

export interface ScreenContextState {
  screenConfig?: ScreenConfig;
  entities?: ScreenEntities;
}

const ScreenContext = createContext<ScreenContextState | null>(null);

/** Use context for shared state related to views */
export function useScreenContext() {
  const context = useContext(ScreenContext);
  if (!context) {
    throw new Error(
      "Consumer of ScreenContext needs to be a child of a Provider"
    );
  }
  return context;
}

export interface ScreenContextProviderProps {
  children: ReactNode;
  screenConfig?: ScreenConfig;
  entities?: ScreenEntities;
}

/** Context for shared state related to views */
export function ScreenContextProvider({
  children,
  screenConfig,
  entities,
}: ScreenContextProviderProps) {
  return (
    <ScreenContext.Provider value={{ screenConfig, entities }}>
      {children}
    </ScreenContext.Provider>
  );
}
