import {
  API,
  Flow,
  FlowResults,
  setPathParameters,
  useAPIMutation,
} from "core/api";

export interface PerformActionFlowSettings {
  actionId: number;
}

export function usePerformActionFlow({ actionId }: PerformActionFlowSettings) {
  return useAPIMutation<FlowResults, { data: Flow }>(
    setPathParameters(API.actions.flows.perform, {
      actionId,
    }),
    ({ data }) => ({ body: JSON.stringify(data) })
  );
}
