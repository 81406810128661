import {
  CircularProgress as MuiCircularProgress,
  CircularProgressProps as MuiCircularProgressProps,
} from "@mui/material";

/**
 * To prepare if/when we need to add our out props.
 *
 * Future extension would be to replace type with:
 *
 * export interface CircularProgressProps extends MuiCircularProgressProps {
 *  extraProp1: string;
 * }
 */
export type CircularProgressProps = MuiCircularProgressProps;

/**
 *
 *  Wrapper around the MUI CircularProgress to add a data-cy tag for UI testing.
 *
 * @param props
 * @returns CircularProgress
 */
export function CircularProgress(props: CircularProgressProps) {
  return <MuiCircularProgress data-cy="spinner" {...props} />;
}
