import { lighten, styled, TableRow } from "common/components";

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:focus": {
    backgroundColor: lighten(theme.palette.primary.main, 0.7),
  },
  "&:focus:hover": {
    backgroundColor: lighten(theme.palette.primary.main, 0.8),
  },
}));
