import { Route, Routes } from "common/routing";
import { LoggedOut } from "layout/SelfcareLoginLayout";
import { ReactElement } from "react";
import logo from "../../../assets/logo.xplora.png";

/**
 * A component for providing the log out route with a page
 * @returns A react jsx element
 */
export function LoggedOutScreen(): ReactElement {
  return (
    <Routes>
      <Route
        index
        element={
          <LoggedOut
            cardHeaderSrc={logo}
            cardHeaderAlt={`${process.env.REACT_APP_APP_NAME} logo`}
          />
        }
      />
    </Routes>
  );
}
