export default {
  form: {
    title: "Xplora Login ",
    username: "Telefonnummer der Eltern",
    password: "Gib den Code ein, den wir dir per SMS gesendet haben.",
    language: "Sprache",
    submit: "Login",
    continue: "Weiter",
    usernameValidationMessage: "Die Telefonnummer der Eltern eingeben.",
    otpHint: "",
  },
  logoutDialog: {
    title: "Eingeloggt als",
  },
  loginInfo: {
    title: "Bis du ein Xplora-Abonnent?",
    content: `HINWEIS: Wenn das Abonnement für eine Xplora Uhr gilt, gib die Handynummer der Eltern ein, nicht die der Smartwatch. \n \n Bitte beachte, dass es nach der Auftrag bis zu 30 Minuten dauern kann, bis du dich einloggen kannst.`,
  },
} as const;
