import { Flow, FlowData, FlowStep } from "core/api";
import { Fragment } from "react";
import { renderConfirmationComponent } from "../Confirmation";

export interface SelectStepConfirmationProps {
  step: FlowStep;
  flowData: FlowData;
  flow: Flow;
}

/**
 * Sub component to confirmation. The purpose of this component is
 * to make the main Confirmation component more readable,
 * abstracting some of the logic into smaller sub components.
 *
 * SelectStepConfirmation is specific to confirmation steps where
 * the flow can contain steps with the uiComponent: "SelectStep".
 *
 * @param step The current step in the parent components loop.
 * @param flow ConfirmationFlowData
 *
 * @returns A JSX element with fields from the selected "Fields" step in the flow.
 * It checks for the selectedStepId and only displays data from that "Fields" step.
 */
export function SelectStepConfirmation({
  step,
  flowData,
  flow,
}: SelectStepConfirmationProps): JSX.Element {
  const fieldFlowData =
    flowData.uiComponent === "SelectStep" ? flowData : undefined;

  if (!fieldFlowData?.selectedStepId) {
    return <></>;
  }

  if (!(step.config && "steps" in step.config)) {
    return <></>;
  }

  return (
    <Fragment key={step.id}>
      {step.config.steps.map((subStep: FlowStep) => {
        const flowData = flow.data[subStep.id];

        if (subStep.id === fieldFlowData.selectedStepId) {
          return renderConfirmationComponent(
            subStep,
            flowData,
            flow,
            step.name
          );
        }

        return null;
      })}
    </Fragment>
  );
}
