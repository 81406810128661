/**
 * The match option of a payment can have the values specified by this enum.
 */
export enum PaymentMatchOption {
  /**
   * Deposits, no matching allowed
   */
  NO_MATCH_ALLOWED = "NO_MATCH_ALLOWED",
  /**
   * Match only against the exact invoice referenced.
   */
  EXACT_REFERENCE = "EXACT_REFERENCE",
  /**
   * Match any invoice on the match customer
   */
  MATCH_ALL_INVOICES = "MATCH_ALL_INVOICES",
  /**
   * Match exact references and new invoices on the match customer
   */
  MATCH_NEW_INVOICES = "MATCH_NEW_INVOICES",
}
