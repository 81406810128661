import { Badge, Card, CardContent, styled } from "common/components";
import { Link } from "common/routing";

export const StyledCard = styled(Card)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.grey[100],
  },
}));

export const StyledCardContent = styled(CardContent)(({ theme }) => ({
  display: "grid",
  placeItems: "center",
  color: theme.palette.grey[700],
}));

export const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-anchorOriginTopRightRectangular": {
    top: "0.6rem",
    right: "0.6rem",
  },
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
}));
