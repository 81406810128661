import {
  MutationOptions,
  useMutation,
  UseMutationResult,
} from "@tanstack/react-query";
import { RequestInitWithParams } from "core/api/interfaces";
import { APIError } from ".";
import { useAPIRequest } from "./request";

export type APIMutationOptions<TData, TMutationParameters> = MutationOptions<
  TData,
  APIError,
  TMutationParameters,
  void
>;

export type APIMutationResult<TData, TMutationParameters> = UseMutationResult<
  TData,
  APIError,
  TMutationParameters,
  void
>;

export type RequestInitFn<TMutationParameters> = (
  params: TMutationParameters
) => RequestInitWithParams;

/**
 * Returns a `APIMutationResult`, a type based on `UseMutationResult`, which is
 * used to send queries to the REST API.
 *
 * Should be used for mutations, in most cases requests that use an HTTP method other than GET.
 *
 * Can also be used for GET requests in specific circumstances. For instance, used
 * to fetch documents from the server and wait for the result.
 *
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useMutation TanStack Query | useMutation}.
 *
 * @param relativeUrl Relative URL to request in REST API
 * @param requestInit Initializtion parameters
 * @param options Options to customize query
 * @returns APIMutationResult, type based on UseMutationResult from react-query, see separate docs
 *
 * ### Basic usage
 *
 * @example
 *
 * ```
 * interface UserSettingsParams {
 *   userId: number;
 *   settings: UserSettings;
 * };
 *
 * type UserSettingsOptions = APIMutationOptions<
 *   User,
 *   UserSettingsParams
 * >;
 *
 * function useUpdateUserSettings(options?: UserSettingsOptions) {
 *   return useAPIMutation<User, UserSettingsParams>(
 *     API.userservice.settings,
 *     ({ userId, settings }) => ({
 *       pathParams: { userId },
 *       body: settings
 *     }),
 *     options
 *   )
 * }
 *
 * function UserSettingsForm({ userId }: UserSettingsFormProps) {
 *   const { t } = useTranslation(["user"])
 *   const { mutate, error, status } = useUpdateUserSettings();
 *
 *   const updateSettings = (settings: UserSettings) => {
 *     mutate({ userId, settings }, {
 *       onSuccess(data) {
 *         Messages().addMessage({
 *           text: t("user:settingsUpdated"),
 *           type: "success",
 *         });
 *         // We can create functions based on our custom
 *         // setAPIQueryData function for updating a
 *         // query cache on mutation success.
 *         setUserQueryData(userId, data);
 *       },
 *     });
 *   }
 *   ...
 * }
 * ```
 */
export function useAPIMutation<TData, TMutationParameters extends Object>(
  relativeUrl: string | ((params: TMutationParameters) => string),
  requestInit?: RequestInitFn<TMutationParameters>,
  options?: APIMutationOptions<TData, TMutationParameters>
): APIMutationResult<TData, TMutationParameters> {
  const { requestAPI } = useAPIRequest();
  return useMutation<TData, APIError, TMutationParameters, void>(
    (params: TMutationParameters) =>
      requestAPI(
        relativeUrl instanceof Function ? relativeUrl(params) : relativeUrl,
        {
          method: "POST",
          credentials: "include",
          mode: "same-origin",
          ...requestInit?.(params),
        }
      ),
    options
  );
}
