import {
  IgnoreError,
  InfiniteAPIQueryContext,
  InfiniteAPIQueryResult,
  invalidateAPIQuery,
  RequestInitWithParams,
  useInfiniteAPIQuery,
} from "core/api";
import { EntityType } from "core/api/enums";
import { Page } from "core/api/models";

export const SEARCH_QUERY_KEY = "searchQuery";

export interface SearchQueryOptions {
  /** Directive for useQuery. */
  enabled?: boolean;
  /** Search entity type */
  entityType: EntityType | undefined;
  /** API url for search query */
  apiUrl: string | undefined;
  /** Search query */
  search: string | undefined;
  /** Setting this parameter to **true** forces the query request to be sent
   * to the server even if **currentSearch** is undefined or empty.
   * The default value is **false**, which will prevent sending the request
   * to the server if **currentSearch** is undefined or empty. */
  acceptEmptySearch?: boolean;
  /** Search filters */
  filters?: string | string[];
  /** Order results by */
  orderBy?: string;
  /** Order in ascending order */
  ascending?: boolean;
  /** Result page number */
  pageNo?: number;
  /** Number of results per page */
  pageSize?: number;
  /** Disable IndexedDB cache */
  noCache?: boolean;
  /** Unique search id */
  searchId?: string;
  /** Ignore all errors or based on request status codes */
  ignoreError?: IgnoreError;
}

function removeUndefinedValues<
  TObject extends Record<
    string,
    string | string[] | number | boolean | undefined
  >
>(object: TObject): Partial<TObject> {
  return Object.entries(object)
    .filter(
      ([, value]) =>
        value !== undefined &&
        (typeof value === "number" ||
          typeof value === "boolean" ||
          value.length > 0)
    )
    .reduce(
      (newObject, [key, value]) => ({
        ...newObject,
        [key]: value,
      }),
      {}
    );
}

/**
 * General search infinity query hook.
 * @param SearchQueryOptions
 * @returns
 */
export function useSearchQuery<TResult = any>({
  enabled = true,
  apiUrl,
  entityType,
  noCache,
  searchId,
  search,
  acceptEmptySearch = false,
  ignoreError,
  ...options
}: SearchQueryOptions): InfiniteAPIQueryResult<Page<TResult>> {
  function requestInitFun(
    context: InfiniteAPIQueryContext<SearchQueryOptions>
  ): RequestInitWithParams {
    return {
      queryParams: removeUndefinedValues({
        search,
        filters: options.filters,
        orderBy: options.orderBy,
        ascending: options.ascending,
        pageNo: context.pageParam
          ? context.pageParam?.pageNo
          : options.pageNo ?? 0,
        pageSize: options.pageSize,
      }),
    };
  }

  function nextPage(
    lastPage: SearchQueryOptions,
    allPages: SearchQueryOptions[]
  ) {
    const defObj = lastPage ? lastPage : options;
    return {
      pageNo: typeof defObj.pageNo === "number" ? defObj.pageNo + 1 : undefined,
    };
  }

  function previousPage(
    lastPage: SearchQueryOptions,
    allPages: SearchQueryOptions[]
  ) {
    if (lastPage.pageNo && lastPage.pageNo > 1) {
      return {
        pageNo: lastPage.pageNo && lastPage.pageNo - 1,
      };
    }

    return undefined;
  }

  return useInfiniteAPIQuery<Page<TResult>, SearchQueryOptions>(
    [SEARCH_QUERY_KEY, entityType, apiUrl, options, searchId, search],
    apiUrl ?? "",
    requestInitFun,
    {
      enabled:
        enabled &&
        (!!search?.length || acceptEmptySearch) &&
        !!apiUrl &&
        !!entityType,
      getNextPageParam: nextPage,
      getPreviousPageParam: previousPage,
      noCache,
      ignoreError,
    }
  );
}

export function invalidateSearchQueryCache(
  apiUrl: string,
  options: Omit<SearchQueryOptions, "enabled" | "apiUrl">
) {
  invalidateAPIQuery([SEARCH_QUERY_KEY, apiUrl, options], false);
}
