/**
 * The status of the product part / overall product
 */
export enum SubscriptionStatus {
  /**
   * Pending
   */
  PENDING = "PENDING",
  /**
   * The subscription is active
   */
  ACTIVE = "ACTIVE",
  /**
   * The subscription is blocked
   */
  BLOCKED = "BLOCKED",
  /**
   * The subscription is suspended
   */
  SUSPENDED = "SUSPENDED",
  /**
   * The subscription is inactive
   */
  INACTIVE = "INACTIVE",
  /**
   * The subscription is canceled
   */
  CANCELED = "CANCELED",
}
