import { Flow } from "core/api";
import { useEffect, useMemo, useState } from "react";
import { getCurrentStep, getCurrentSubStep } from "./flowUtils";

/**
 * Return current step state
 * @param data Current flow data
 * @returns Current step state
 */
export function useCurrentStep(data: Flow | undefined) {
  const [currentStepId, setCurrentStepId] = useState<string>();
  const [currentSubStepId, setCurrentSubStepId] = useState<string>();

  useEffect(() => {
    if (data) {
      setCurrentStepId(data?.currentStepId);
    }
  }, [data]);

  const currentStep = useMemo(
    () => getCurrentStep(data, currentStepId),
    [data, currentStepId]
  );

  const currentSubStep = useMemo(
    () => getCurrentSubStep(data, currentStepId, currentSubStepId),
    [data, currentStepId, currentSubStepId]
  );

  useEffect(() => {
    if (!currentSubStep) {
      setCurrentSubStepId(undefined);
    }
  }, [currentSubStep]);

  return {
    currentStepId,
    currentSubStepId,
    currentStep,
    currentSubStep,
    setCurrentStepId,
    setCurrentSubStepId,
  };
}
