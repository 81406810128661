import { HelpOutline } from "common/assets/icons";
import {
  Container,
  Link as MUILink,
  styled,
  Typography,
} from "common/components";
import { Link, URLS } from "common/routing";
import { useTranslation } from "i18n";
import { ReactElement } from "react";

const StyledContainer = styled(Container)(({ theme }) => ({
  "&, & > div": {
    display: "grid",
    placeItems: "center",
  },
  gap: theme.spacing(2),
  paddingTop: theme.spacing(8),
}));

export function PageNotFound(): ReactElement {
  const { t } = useTranslation("error");
  return (
    <StyledContainer maxWidth="md">
      <HelpOutline fontSize="large" />
      <div>
        <Typography variant="h1" data-cy="titleElement">
          {t("_404.urlNotFound")}
        </Typography>
        <Typography data-cy="notFoundParagraph">
          {t("_404.notFoundOrNotAvailable")}
        </Typography>
        <Typography variant="body2">{window.location.href}</Typography>
      </div>
      <MUILink
        data-cy="notFoundLink"
        component={Link}
        to={URLS.home.path}
        variant="button"
        sx={{ textDecoration: "none" }}
      >
        {t("backToApplications")}
      </MUILink>
    </StyledContainer>
  );
}
