import { FormCheckBox, FormCurrencyField } from "common/components";
import {
  addFlowStepPrefix,
  calculateVATAmounts,
  formatPercentage,
} from "common/utils";
import { AmountWithVATFlowData, AmountWithVATFlowStep } from "core/api";
import { useTranslation } from "i18n";
import { useFormContext } from "react-hook-form";
import { NumberFormatValues } from "react-number-format";
import { FlowStepComponentProps } from "../models";
import { StyledStepContentWrapper } from "../steps.styles";

export type AmountWithVATProps = FlowStepComponentProps<
  AmountWithVATFlowStep,
  AmountWithVATFlowData
>;

/**
 * This UI component allows the user to input an amount on which a given
 * VAT rate applies, in a given currency. The component displays the amount
 * excluding VAT, the VAT amount, and the amount including VAT.
 * The component also displays a check box that allows the user to choose
 * between inputing the amount excluding VAT or including VAT.
 *
 * @remarks
 *
 * - By default this check box is unchecked => the user inputs the amount
 * including VAT and the component calculates the amount of VAT and the amount
 * excluding VAT.
 *
 * - If the user checks the box, he/she can input the amount excluding VAT
 * and the component calculates the amount of VAT and the amount including VAT.
 *
 * The component expects the following properties to be present in the Flow data
 *
 * - inputAmountInclVat (boolean): Value for/of the 'Input amount including VAT' check box.
 *   Editable by the user.
 *
 * - amount (number): Amount including VAT if inputAmountInclVat === true
 *   or excluding VAT if inputAmountInclVat === false. Editable by the user.
 *
 * - vatRate (number): VAT rate applicable to the amount.
 *   Not editable by the user.
 *
 * - currencyCode (string): Currency code used to format the amount.
 *   Not editable by the user.
 *
 * @see {@link AmountWithVATFlowData}
 */
export function AmountWithVAT({
  control,
  data,
  flowStep,
}: AmountWithVATProps): JSX.Element {
  const { t } = useTranslation(["common"]);

  const context = useFormContext();

  // If necessary, reject zero amount
  const validateAmount = (values: NumberFormatValues) => {
    if (flowStep.config.allowZeroAmount) {
      return true;
    } else {
      const { floatValue } = values;
      return floatValue !== 0.0;
    }
  };

  // Calculate amounts based on the values received in the flow data
  const flowDataVatAmounts = calculateVATAmounts(
    data.amount,
    data.inputAmountInclVat,
    data.vatRate
  );
  /* Watch the check box that the user can click to switch between inputing
   * the amount including VAT or inputing amount excluding VAT.
   * Clicking this checkbox will trigger a rendering of the component */
  const uiInputAmountInclVat: boolean = context?.watch(
    addFlowStepPrefix(flowStep, "uiInputAmountInclVat"),
    data.inputAmountInclVat
  );
  /* Watch the text box in which the user can input the amount.
   * This is either the text box that displays the amount including VAT or the
   * Text box that excludes VAT. Changing the value in this text box
   * will trigger a rendering of the component. */
  let uiInputAmount: number = uiInputAmountInclVat
    ? context?.watch(addFlowStepPrefix(flowStep, "amountInclVat"))
    : context?.watch(addFlowStepPrefix(flowStep, "amountExclVat"));
  if (Number.isNaN(uiInputAmount)) {
    uiInputAmount = uiInputAmountInclVat
      ? flowDataVatAmounts.amountInclVat
      : flowDataVatAmounts.amountExclVat;
  }
  // Calculate the VAT amounts based on the values of the form input components
  const uiVatAmounts = calculateVATAmounts(
    uiInputAmount,
    uiInputAmountInclVat,
    data.vatRate
  );
  const vatPercentage = formatPercentage(data.vatRate);

  return (
    <StyledStepContentWrapper>
      {/* Check box to choose between amount input excluding/including VAT */}
      <FormCheckBox
        control={control}
        fieldName={addFlowStepPrefix(flowStep, "uiInputAmountInclVat")}
        label={t("common:inputAmountInclVat", { vatRate: vatPercentage })}
        defaultValue={data.inputAmountInclVat}
      />
      {/* Text box to input Amount excl. VAT */}
      <FormCurrencyField
        defaultValue={uiVatAmounts.amountExclVat}
        fieldName={addFlowStepPrefix(flowStep, "amountExclVat")}
        required
        control={control}
        label={t("common:amountExclVat", { vatRate: vatPercentage })}
        currencyCode={data.currencyCode}
        disabled={uiInputAmountInclVat}
        isAllowed={validateAmount}
      />
      {/* Read only Text box to display VAT amount */}
      <FormCurrencyField
        defaultValue={uiVatAmounts.amountVat}
        fieldName={addFlowStepPrefix(flowStep, "amountVat")}
        control={control}
        label={t("common:amountVat")}
        currencyCode={data.currencyCode}
        disabled
      />
      {/* Text box to input Amount incl. VAT */}
      <FormCurrencyField
        defaultValue={uiVatAmounts.amountInclVat}
        fieldName={addFlowStepPrefix(flowStep, "amountInclVat")}
        required
        control={control}
        label={t("common:amountInclVat", { vatRate: vatPercentage })}
        currencyCode={data.currencyCode}
        disabled={!uiInputAmountInclVat}
        isAllowed={validateAmount}
      />
    </StyledStepContentWrapper>
  );
}
