import { FormSelect, FormTextField } from "common/components";
import { addFlowStepPrefix } from "common/utils";
import { SetResellerFlowData, SetResellerFlowStep } from "core/api";
import { ReactElement } from "react";
import { toSelectOption } from "../../flowUtils";
import { FlowStepComponentProps } from "../models";
import { StyledStepContentWrapper } from "../steps.styles";

export type SetResellerProps = FlowStepComponentProps<
  SetResellerFlowStep,
  SetResellerFlowData
>;

/**
 * Reseller step component
 *
 * The data is actually in a Field to make it easier in the server (and UI side).
 */
export function SetReseller({
  control,
  data,
  flowStep,
}: SetResellerProps): ReactElement {
  const { reseller } = data;
  const { resellers, enableAgentField, resellerLabel, agentLabel } =
    flowStep.config;

  return (
    <StyledStepContentWrapper>
      <FormSelect
        fieldName={addFlowStepPrefix(flowStep, "resellerId")}
        control={control}
        label={resellerLabel}
        defaultValue={
          reseller.values.find(({ valueNo }) => valueNo === 0)?.value
        }
        includeEmptyOption={true}
        dataList={toSelectOption(resellers)}
      />
      {enableAgentField && (
        <FormTextField
          fieldName={addFlowStepPrefix(flowStep, "agent")}
          defaultValue={
            reseller.values.find(({ valueNo }) => valueNo === 1)?.value
          }
          control={control}
          label={agentLabel}
          disabled={!enableAgentField}
        />
      )}
    </StyledStepContentWrapper>
  );
}
