import { getEntityIdKey } from "common/utils";
import {
  API,
  EntityType,
  InfiniteAPIQueryContext,
  InfiniteAPIQueryResult,
  invalidateAPIQuery,
  Note,
  Page,
  RequestInitWithParams,
  useInfiniteAPIQuery,
} from "core/api";

const FIND_NOTES_QUERY_KEY = "findNotes";

export interface FindTaskNotesOptions {
  /** Directive for useQuery. */
  enabled?: boolean;
  /** Optional paging parameter */
  pageNo?: number;
  /** Optional paging parameter */
  pageSize?: number;
  /** Optional filters */
  filters?: string | string[] | undefined;
}

/** Find notes for a specific EntityType */
export function useFindEntityNotes(
  entityId: number,
  entityType: EntityType,
  { enabled = true, ...options }: FindTaskNotesOptions
): InfiniteAPIQueryResult<Page<Note>> {
  function requestInitFun(
    context: InfiniteAPIQueryContext<FindTaskNotesOptions>
  ): RequestInitWithParams {
    return {
      pathParams: {
        [getEntityIdKey(entityType)]: entityId ?? -1,
      },
      queryParams: {
        pageNo: context.pageParam
          ? context.pageParam?.pageNo
          : options.pageNo ?? 0,
        pageSize: options.pageSize,
        filters: options.filters,
      },
    };
  }

  function nextPage(
    lastPage: FindTaskNotesOptions,
    _allPages: FindTaskNotesOptions[]
  ) {
    const defObj = lastPage ? lastPage : options;
    return {
      pageNo: typeof defObj.pageNo === "number" ? defObj.pageNo + 1 : undefined,
    };
  }

  function previousPage(
    lastPage: FindTaskNotesOptions,
    _allPages: FindTaskNotesOptions[]
  ) {
    if (lastPage.pageNo && lastPage.pageNo > 1) {
      return {
        pageNo: lastPage.pageNo && lastPage.pageNo - 1,
      };
    }

    return undefined;
  }

  return useInfiniteAPIQuery<Page<Note>, FindTaskNotesOptions>(
    [FIND_NOTES_QUERY_KEY, entityId, entityType, options],
    getApiUrl(entityType),
    requestInitFun,
    {
      enabled: enabled && entityId !== -1,
      getNextPageParam: nextPage,
      getPreviousPageParam: previousPage,
      noCache: true,
    }
  );
}

export function invalidateFindEntityNotesCache() {
  invalidateAPIQuery([FIND_NOTES_QUERY_KEY], false);
}

function getApiUrl(entityType: EntityType) {
  switch (entityType) {
    case EntityType.TASK:
      return API.taskservice.taskNotes;
    case EntityType.CUSTOMER:
      return API.customerservice.customerNotes;
    default:
      return API.taskservice.taskNotes;
  }
}
