import { styled } from "common/components";

export const Layout = styled("div")(({ theme }) => ({
  display: "grid",
  alignContent: "space-between",
  gridTemplateRows: "1fr min-content",
  height: "100vh",
}));

export const ScreenContentWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignContent: "start",
  gap: 2,
  overflow: "auto",
  backgroundColor: theme.palette.common.white,
  height: "100%",
}));
