import { Autocomplete, TextField } from "common/components";
import { useAutoCompleteOptions } from "core/hooks";
import { FilterValueState, FilterValueStateText } from "core/api";
import { KeyboardEvent, ReactElement } from "react";

export interface TextFilterFieldProps {
  /** The filter's state */
  state: FilterValueStateText;
  /** Label for the field */
  displayName: string;
  /** Gets filter on change function by filter id */
  onChange: (event: FilterValueState) => void;
  /** Function for performing a search when pressing enter in the filter field */
  onKeyPress?(event: KeyboardEvent<HTMLDivElement>): void;
}

export function TextFilterField({
  state,
  displayName,
  onChange,
  onKeyPress,
}: TextFilterFieldProps): ReactElement {
  const { options: autocomplete } = useAutoCompleteOptions();
  const value = (state?.value ?? "") as string;

  const handleChange = (inputValue: string) => {
    onChange({ ...state, value: inputValue });
  };

  return (
    <Autocomplete
      freeSolo
      disableClearable
      options={autocomplete.map(({ text }) => text)}
      value={value}
      onInputChange={(_, inputValue) => handleChange(inputValue)}
      sx={{ width: 220, flex: 1 }}
      renderInput={(autocompleteParams) => (
        <TextField
          {...autocompleteParams}
          InputProps={{
            ...autocompleteParams.InputProps,
            type: "search",
          }}
          name="value"
          value={value}
          placeholder={displayName}
          label={displayName}
          onKeyPress={onKeyPress}
          data-cy={`filter-${state.filterId}-textValue`}
        />
      )}
    />
  );
}
