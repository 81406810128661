import {
  LabeledSelect,
  NumberField,
  SelectChangeEvent,
  SelectOption,
} from "common/components";
import {
  FilterValueState,
  FilterValueStateAmount,
  useFetchCurrencies,
} from "core/api";
import { useTranslation } from "i18n";
import { ChangeEvent, KeyboardEvent, ReactElement } from "react";
import { StyledMultipleFieldFilterContainer } from "../SearchFilterFields.styles";

interface AmountFilterFieldProps {
  /** The filter's state */
  state: FilterValueStateAmount;
  /** Label for the field */
  displayName: string;
  /** Gets filter on change function by filter id */
  onChange(event: FilterValueState): void;
  /** Function for performing a search when pressing enter in the filter field */
  onKeyPress?(event: KeyboardEvent<HTMLDivElement>): void;
}

export function AmountFilterField({
  displayName,
  state,
  onChange,
  onKeyPress,
}: AmountFilterFieldProps): ReactElement {
  const { t } = useTranslation(["common"]);
  const { data: currencies } = useFetchCurrencies();

  const handleCurrencyChange = (
    event:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<unknown>
  ) => {
    onChange({ ...state, [event.target.name]: event.target.value });
  };

  const handleAmountChange = (field: string) => (value: string) => {
    onChange({ ...state, [field]: value });
  };

  const dataList: SelectOption[] = (currencies ?? []).map(
    ({ currencyCode }) => {
      return {
        value: currencyCode,
        label: currencyCode,
      };
    }
  );

  return (
    <StyledMultipleFieldFilterContainer data-cy={`filter-${state.filterId}`}>
      <LabeledSelect
        data-cy={`filter-${state.filterId}-currencyCode`}
        name="currencyCode"
        label={t("common:searchFilter.selectCurrency")}
        dataList={dataList}
        includeEmptyOption
        value={state?.currencyCode ?? ""}
        onChange={handleCurrencyChange}
        onKeyPress={onKeyPress}
        sx={{ minWidth: 160 }}
      />
      <NumberField
        value={state?.minValue ?? ""}
        fieldName="minValue"
        data-cy="minValue"
        label={t("common:searchFilter.textFrom", { text: displayName })}
        onChange={handleAmountChange("minValue")}
        onKeyPress={onKeyPress}
      />
      <NumberField
        value={state?.maxValue ?? ""}
        fieldName="maxValue"
        data-cy="maxValue"
        label={t("common:searchFilter.textTo", { text: displayName })}
        onChange={handleAmountChange("maxValue")}
        onKeyPress={onKeyPress}
      />
    </StyledMultipleFieldFilterContainer>
  );
}
