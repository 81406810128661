import { styled, Typography, TypographyProps } from "common/components";
import { DetailedHTMLProps, ElementType, HTMLAttributes } from "react";

export interface StyledFieldContainerProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  variant: "column" | "row";
}

export const StyledFieldContainer = styled("div", {
  shouldForwardProp: (propName) => propName !== "variant",
})<StyledFieldContainerProps>(({ theme, variant }) => ({
  display: "flex",
  flexWrap: "nowrap",
  columnGap: theme.spacing(1),
  flexDirection: variant === "row" ? "row" : "column",
  alignItems: variant === "row" ? "center" : "flex-start",
  width: "100%",
}));

export interface StyledFieldLabelProps extends TypographyProps {
  containerVariant: "column" | "row";
  component?: ElementType<any>;
}

export const StyledFieldLabel = styled(Typography, {
  shouldForwardProp: (propName) => propName !== "containerVariant",
})<StyledFieldLabelProps>(({ theme, containerVariant }) => ({
  minWidth: containerVariant === "row" ? 150 : undefined,
  maxWidth: containerVariant === "row" ? 150 : undefined,
  textAlign: containerVariant === "row" ? "right" : "left",
}));
