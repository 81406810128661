import { Logo as CommonLogo } from "common/components";
import { useTranslation } from "i18n";
const APP_NAME = process.env.REACT_APP_APP_NAME;

interface LogoProps {
  src?: string;
  width?: string;
  height?: string;
  sxOverride?: any;
}

export function Logo({ src, width, height, sxOverride }: LogoProps) {
  const { t } = useTranslation(["common"]);
  return (
    <CommonLogo
      src={src}
      width={width ?? "60px"}
      height={height ?? "60px"}
      alt={t("common:appNameLogo", { appName: APP_NAME })}
      sx={sxOverride ?? undefined}
    />
  );
}
