import { Box, Container, Stack } from "common/components";
import { LoginContentContainer } from "./LoginContentContainer";

/**
 *
 * @param top Top slot in the layout, rendered above the other two slots.
 * Suited for images, logos etc.
 * @param left Left bottom slot of the layout. Suited for regular content.
 * @param right Right bottom slot of the layout. Suited for regular content.
 * @returns A ReactElement
 */
export function SelfcareLoginLayout({
  top,
  left,
  right,
}: any): React.ReactElement {
  return (
    <Container
      sx={{
        backgroundColor: (theme) => theme.palette.common.white,
      }}
    >
      <Box
        pt={8}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {top}
        <Stack
          direction="row"
          spacing={2}
          margin={"auto"}
          mt={8}
          width="100%"
          useFlexGap
          flexWrap="wrap"
          justifyContent={"center"}
          minWidth={"350px"}
        >
          {left && <LoginContentContainer>{left}</LoginContentContainer>}
          {right && <LoginContentContainer>{right}</LoginContentContainer>}
        </Stack>
      </Box>
    </Container>
  );
}
