import {
  API,
  APIQueryResult,
  EntityType,
  getAPIQueryData,
  useAPIQuery,
  ValidValue,
} from "core/api";
import { replacePlaceholders } from "core/components";
import { useScreenEntities } from "core/store";

/**
 * Hook for retrieving selectable values for a specific filter.
 *
 * @param filterId the id of the filter to retrieve values for.
 * @param requiresCustomer if true then the server wants query param customerNo and that will also be used in the query key in the cache.
 * @param optional valuesURL from the filter config to use instead of the default endpoint.
 *
 * @returns Possible filter values as ValidValue array.
 */
export function useFetchFilterValues(
  filterId: number,
  requireCustomerNo: boolean,
  valuesUrl: string | undefined = undefined
): APIQueryResult<ValidValue[]> {
  const screenEntities = useScreenEntities();

  const pathParams = { filterId };
  const customerNo = requireCustomerNo
    ? screenEntities[EntityType.CUSTOMER]?.entityData.entityId
    : undefined;

  const queryParams = customerNo ? { customerNo } : {};

  const _valuesUrl = replacePlaceholders(
    valuesUrl || API.config.filterValues,
    screenEntities
  );

  return useAPIQuery<ValidValue[]>(
    createFetchFilterValuesQueryKey(filterId, _valuesUrl, customerNo),
    _valuesUrl,
    {
      pathParams,
      queryParams,
    },
    { enabled: filterId !== undefined },
    transformerFn
  );
}

/**
 * Creates the internal query key for the Tanstack query cache.
 *
 * @param filterId
 * @param valuesUrl
 * @param customerNo
 *
 * @returns the cache key
 */
function createFetchFilterValuesQueryKey(
  filterId: number,
  valuesUrl: string,
  customerNo: string | undefined
): (string | number)[] {
  if (customerNo) {
    return ["fetchFilterValues", filterId, valuesUrl, customerNo];
  } else {
    return ["fetchFilterValues", filterId, valuesUrl];
  }
}

/**
 * This is used by the Chips that display filter selections to fetch data
 *
 * @remarks
 * JSO: Really don't understand why since data should only be fetched with the hook itself.
 *
 * @param filterId
 * @param valuesUrl
 * @returns
 */
export function getFilterValuesQueryData(
  filterId: number,
  requireCustomerNo: boolean,
  valuesUrl?: string
): ValidValue[] | undefined {
  const screenEntities = useScreenEntities();

  const customerNo = requireCustomerNo
    ? screenEntities[EntityType.CUSTOMER]?.entityData.entityId
    : undefined;

  const _valuesUrl = replacePlaceholders(
    valuesUrl || API.config.filterValues,
    screenEntities
  );
  return getAPIQueryData<ValidValue[]>(
    createFetchFilterValuesQueryKey(filterId, _valuesUrl, customerNo)
  );
}

/**
 * Function to transform the provided data fetched into a ValidValue
 *
 * The result is not sorted in UI, assumed to be properly sorted in the endpoint.
 *
 * @remarks
 * If the data is an array then it is assumed to already be an ValidValue array.
 * If not it check if it is the structure returned by our paginated searches, items with optional ValidValue within.
 * If it is items, but no ValidValue within a ValidValue is brutally constructed.
 *
 * @param data
 *
 * @returns The data as a ValidValue[] array.
 */
function transformerFn(data: any): ValidValue[] | undefined {
  if (Array.isArray(data)) {
    // Assume it is an array of ValidValue sorted on server
    return data;
  } else {
    // Probably a search result, with items and row count
    // Try to extract a validValue
    const items = data["items"];
    if (items && Array.isArray(items)) {
      return items.map((elem) => {
        const validValue = elem["validValue"];

        if (validValue) {
          // There is a proper validValue, use that
          return validValue;
        } else {
          // No proper valid value, try to create one based on the assumption that it is at least an entity
          return {
            id: elem["entityId"],
            entityTypeId: elem["entityTypeId"],
            name:
              elem["name"] ||
              elem["inventoryItem"] ||
              elem["externalInvoiceId"] ||
              elem["subscriptionName"] ||
              elem["productName"] ||
              elem["customerName"],
            description: "",
          } as ValidValue;
        }
      });
    }
  }
}
