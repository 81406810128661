import { InputBase, Paper, styled, TextField } from "common/components";

export const StyledSearchFieldContainer = styled(Paper)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(0.25, 0.5),
}));

export const StyledSelectInput = styled(InputBase)(({ theme }) => ({
  borderRight: `1px solid ${theme.palette.grey["A100"]}`,
  padding: theme.spacing(0.75, 1.2, 0, 1.2),
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  flex: 1,
}));
StyledTextField.defaultProps = {
  size: "small",
  variant: "standard",
};
