import { LogisticsFlowData, LogisticsFlowStep } from "core/api";
import { StepDataResolver } from "../models";

/**
 * Logistics data resolver
 *
 * Formats logistics based on form field values
 */
export const logisticsDataResolver: StepDataResolver<
  LogisticsFlowStep,
  LogisticsFlowData
> = ({ data, fieldValues, flowStep }) => {
  return {
    selectedArticle:
      flowStep.config.articles.find(
        (article) => article.id === fieldValues.selectedArticle
      ) ?? data.selectedArticle,
  };
};
