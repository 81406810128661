/**
 * The severity of a log event is limited to the values specified by this enum.
 */
export enum Severity {
  /**
   * Good to know.
   */
  INFO = "INFO",

  /**
   * Action should be taken.
   */
  WARNING = "WARNING",

  /**
   * Action must be taken.
   */
  ERROR = "ERROR",
}
