/**
 * The status of a user can have the values specified by this enum.
 */
export enum UserStatus {
  /**
   * The user is about to be created, but the creation is not complete yet.
   */
  PENDING = "PENDING",
  /**
   * The user is active and can be used by subscriptions.
   */
  ACTIVE = "ACTIVE",

  /**
   * The user is no longer active, it can not have subscription anymore, and it can not be
   * selected for new subscriptions.
   */
  INACTIVE = "INACTIVE",

  /**
   * The user was never active.
   */
  CANCELED = "CANCELED",
}
