/**
 * Simple name-of to be able to get compile time errors when accessing prop names
 *
 * @returns name of provided prop belonging to T.
 *
 * @example
 * ```ts
 * const nameof = nameofFactory<DocumentType>();
 * ...
 * nameof('documentTypeId'); // works
 * nameof('typo'); // doesn't work give compile time errors
 * ```
 */
export const nameofFactory =
  <T>() =>
  (name: keyof T) =>
    name;
