import { FormSelect, FormTextField } from "common/components";
import { addFlowStepPrefix } from "common/utils";
import { toSelectOption } from "core/actions/flows/flowUtils";
import { LogisticsFlowData, LogisticsFlowStep } from "core/api";
import { useTranslation } from "i18n";
import { ReactElement } from "react";
import { FlowStepComponentProps } from "../models";
import { StyledStepContentWrapper } from "../steps.styles";

export type LogisticsProps = FlowStepComponentProps<
  LogisticsFlowStep,
  LogisticsFlowData
>;

/** Logistics step component */
export function Logistics({
  control,
  data,
  flowStep,
}: LogisticsProps): ReactElement {
  const { t } = useTranslation(["common"]);
  const { selectedArticle } = data;

  return (
    <StyledStepContentWrapper>
      <FormSelect
        control={control}
        label={t("common:type")}
        fieldName={addFlowStepPrefix(flowStep, "selectedArticle")}
        required
        dataList={toSelectOption(flowStep.config.articles) ?? []}
        defaultValue={selectedArticle?.id}
      />
      <FormTextField
        value={data.addressText}
        fieldName={addFlowStepPrefix(flowStep, "address")}
        control={control}
        label={t("common:address")}
        disabled
      />
    </StyledStepContentWrapper>
  );
}
