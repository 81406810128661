import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from "@mui/material";
import { forwardRef, ReactElement } from "react";
import { StyledCircularProgress } from "./Button.styles";

export interface ButtonProps extends MuiButtonProps {
  loading?: boolean;
  target?: string;
  to?: string;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, loading, disabled, ...props }, ref): ReactElement => {
    return (
      <MuiButton ref={ref} disabled={loading || disabled} {...props}>
        {loading && <StyledCircularProgress size="1.6rem" data-cy="spinner" />}
        {children}
      </MuiButton>
    );
  }
);
