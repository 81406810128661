import { ListItem, ListItemText } from "common/components";
import { formatDataCy } from "common/utils";
import { FlowData, TableFlowStep } from "core/api";
import { Fragment } from "react";

export interface TableConfirmationProps {
  step: TableFlowStep;
  flowData: FlowData;
  title?: string; // optional title to override the use of the steps name
}

/**
 * Sub component to confirmation.
 *
 * TableConfirmation is specific to confirmation steps where
 * the flow contains a step with the uiComponent: "Table".
 *
 * @param step The current step in the parent components loop.
 * @param flowData data property from the flow object, this is fetched through
 * @param title (optional) Used as label for the 'selectedNames' field, if provided.
 *   If not provided the step name is used.
 *
 * @returns A JSX element with iitem(s) selected in the table.
 */
export function TableConfirmation({
  step,
  flowData,
  title,
}: TableConfirmationProps): JSX.Element {
  const fieldFlowData = flowData.uiComponent === "Table" ? flowData : undefined;

  return (
    <Fragment key={step.id}>
      <ListItem>
        <ListItemText
          data-cy={title ? formatDataCy(title) : formatDataCy(step.name)}
          primary={title ? title : step.name}
          secondary={fieldFlowData?.selectedNames.join(",") || ""}
        />
      </ListItem>
    </Fragment>
  );
}
