import { formatFormDatePickerValue } from "common/components";
import { OrderAttributesFlowData, OrderAttributesFlowStep } from "core/api";
import { StepDataResolver } from "../models";

/**
 * Order attributes data resolver
 *
 * Formats order attributes based on form field values
 */
export const orderAttributesDataResolver: StepDataResolver<
  OrderAttributesFlowStep,
  OrderAttributesFlowData
> = ({ data, fieldValues }) => {
  return {
    orderAttributes: {
      ...data.orderAttributes,
      approved: fieldValues.approved ?? data.orderAttributes.approved,
      effectiveDate: fieldValues.effectiveDate
        ? formatFormDatePickerValue(fieldValues.effectiveDate)
        : data.orderAttributes.effectiveDate,
    },
  };
};
