import { Close } from "common/assets/icons";
import { DialogTitle, DialogTitleProps, IconButton } from "common/components";
import { useTranslation } from "i18n";

export type ActionDialogTitleProps = DialogTitleProps & CloseButtonProps;

/** Action dialog title */
export function ActionDialogTitle({
  children,
  onClose,
  ...props
}: ActionDialogTitleProps) {
  return (
    <DialogTitle {...props}>
      {children}
      <CloseButton onClose={onClose} />
    </DialogTitle>
  );
}

interface CloseButtonProps {
  /** Function for closing dialog */
  onClose(): void;
}

/** Action dialog close button */
function CloseButton({ onClose }: CloseButtonProps) {
  const { t } = useTranslation("common");
  return (
    <IconButton
      aria-label={t("buttons.close")}
      onClick={onClose}
      data-cy="actionClose"
      sx={{
        position: "absolute",
        right: 8,
        top: 8,
        color: (theme) => theme.palette.grey[500],
      }}
    >
      <Close />
    </IconButton>
  );
}
