/**
 * Sort an array based on the given comparator
 * @param array
 * @param comparator
 * @returns
 */
export function tableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis: [T, number][] = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

/**
 * Defines a comparator for type T, based on the natural ordering
 * of a key set by 'orderBy'
 * @param a
 * @param b
 * @param orderBy
 * @returns
 */
export function descendingComparator<T>(a: T, b: T, orderBy: string) {
  if (b[orderBy as keyof T] < a[orderBy as keyof T]) {
    return -1;
  }
  if (b[orderBy as keyof T] > a[orderBy as keyof T]) {
    return 1;
  }
  return 0;
}

/**
 * Get a comparator for table items based on the current
 * order + orderBy state
 * @param order
 * @param orderBy
 * @returns
 */
export function getComparator<T>(order: string, orderBy: string) {
  return order === "desc"
    ? (a: T, b: T) => descendingComparator<T>(a, b, orderBy)
    : (a: T, b: T) => -descendingComparator<T>(a, b, orderBy);
}
