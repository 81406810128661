import action from "./action";
import common from "./common";
import error from "./error";
import invoice from "./invoice";
import languages from "./languages";
import payment from "./payment";

export const gb = {
  action,
  common,
  error,
  invoice,
  languages,
  payment,
} as const;
