import { FormNumberField, FormNumberFieldProps } from "common/components";
import {
  NumberFormatOptions,
  formatCurrency,
  getCurrencySymbol,
  isPrefixSymbol,
} from "common/utils";
import { ReactElement } from "react";
import { withFormController } from "../FormController";

export interface FormCurrencyFieldProps extends FormNumberFieldProps {
  /** 3 character ISO code that identifies the currency ("SEK", "EUR", "USD", ...) */
  currencyCode: string;
  /** Set to false to prevent user from entering negative values. By default negative values are allowed. */
  allowNegative?: boolean;
  /** Optional. Can be used to override some of the number formatting options.
   * @defaultValue If not provided, formatOptions.**numberOfDecimals** defaults to 2
   * if the field is enabled or 4 if the field is disabled.
   * formatOptions.**fixedDecimals** defaults to false.
   */
  formatOptions?: NumberFormatOptions;
}

/**
 * Simple extension of the FormNumberField that formats the input according to
 * the currency format selected by the user.
 */
export const FormCurrencyField = withFormController<FormCurrencyFieldProps>(
  ({ currencyCode, allowNegative, formatOptions, ...props }): ReactElement => {
    const currencySymbol = getCurrencySymbol(currencyCode, formatOptions);
    const isCurrencyPrefix = isPrefixSymbol(currencyCode, formatOptions);
    const isNegativeAllowed =
      allowNegative !== undefined ? allowNegative : true;
    // get decimal separator from locale formatted input
    let formattedCurrency = formatCurrency(1.1, currencyCode, formatOptions);
    /* To avoid making any assumption on the length of the currency symbol,
     * the slice() function counts the characters from the back
     * of the formatted string when the currency symbol is prefixed.
     * Hence the negative arguments in the slice function.
     * When the currency symbol is suffixed, the slice function counts
     * the characters from the front of the formatted string, hence the positive arguments.
     * Example:
     * If the currency symbol is prefixed, 1.1 gets formatted as "€1.10".
     * The decimal separator is in position -3 => slice(-3, -2)
     * If the currency symbol is suffixed, 1.1 gets formatted as "1.10€".
     * The decimal separator is in position 1 => slice(1, 2)
     *
     */
    const decimalSeparator = isCurrencyPrefix
      ? formattedCurrency.slice(-3, -2)
      : formattedCurrency.slice(1, 2);
    // get thousands separator from locale formatted input
    // (force thousands grouping so that thousands separator gets used)
    formattedCurrency = formatCurrency(1000, currencyCode, {
      useGrouping: true,
    });
    /* Example:
     * If the currency symbol is prefixed, 1000 gets formatted as "€1,000.00".
     * The decimal separator is in position -7 => slice(-7, -6)
     * If the currency symbol is suffixed, 1000 gets formatted as "1,000.00€".
     * The decimal separator is in position 1 => slice(1, 2)
     */
    const thousandSeparator = isCurrencyPrefix
      ? formattedCurrency.slice(-7, -6)
      : formattedCurrency.slice(1, 2);
    return (
      <FormNumberField
        {...props}
        prefix={isCurrencyPrefix ? currencySymbol : undefined}
        suffix={!isCurrencyPrefix ? currencySymbol : undefined}
        thousandSeparator={thousandSeparator}
        decimalSeparator={decimalSeparator}
        allowNegative={isNegativeAllowed}
        decimalScale={
          formatOptions?.numberOfDecimals
            ? formatOptions?.numberOfDecimals
            : props.disabled
            ? 4
            : 2
        } // Note - if the field is disabled => display up to 4 decimals, else display 2 - true for most currencies so it will do.
        fixedDecimalScale={
          formatOptions?.fixedDecimals ? formatOptions?.fixedDecimals : false
        }
      />
    );
  }
);
