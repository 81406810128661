import { EntityData, EntityType } from "core/api";
import { isEqual } from "lodash";
import { useEffect } from "react";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

export interface TypedEntity {
  entityType: EntityType;
  entityData: EntityData;
}

export type ScreenEntities = Partial<Record<EntityType, TypedEntity>>;

interface ScreenEntitiesStore {
  entities: ScreenEntities;
  update(entities: ScreenEntities): void;
}

const useScreenEntitiesStore = create<ScreenEntitiesStore>()(
  devtools((set, get) => ({
    entities: {},
    update: (entities: ScreenEntities) => {
      if (!isEqual(get().entities, entities)) {
        set((state) => ({ ...state, entities }));
      }
    },
  }))
);

export interface RegisterScreenEntitiesProps {
  entities: ScreenEntities;
}

export function RegisterScreenEntities({
  entities,
}: RegisterScreenEntitiesProps) {
  const updateEntities = useScreenEntitiesStore((state) => state.update);
  useEffect(() => {
    updateEntities(entities);
  }, [entities]);

  return null;
}

/**
 * Fetch current screen entities
 * @returns
 */
export function useScreenEntities(): ScreenEntities {
  return useScreenEntitiesStore((state) => state.entities);
}
