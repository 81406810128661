import { MultiSelect, createFilterOptions } from "common/components";
import { SingleSelect } from "common/components/inputs/SingleSelect";
import {
  FilterValueState,
  FilterValueStateSelect,
  Icon,
  ValidValue,
  useFetchFilterValues,
} from "core/api";
import { ReactElement, SyntheticEvent } from "react";

/**
 * The select filter fields used in table filters.
 */
export interface SelectFilterFieldProps {
  /** The filter's state */
  state: FilterValueStateSelect;
  /** Label for the field */
  displayName: string;
  /** Is single or multi select */
  multiple: boolean;
  /** Gets filter on change function by filter id */
  onChange(event: FilterValueState): void;
  /** The state from a filter when dependencies between filters exists, used to get a grouped value list */
  controlledByFilterState?: FilterValueStateSelect;
}

/**
 * A Select filter with support for single or multi select.
 */
export function SelectFilterField({
  state,
  displayName,
  onChange,
  multiple = true,
  controlledByFilterState,
}: SelectFilterFieldProps): ReactElement | null {
  const { data: filterValues } = useFetchFilterValues(
    state.filterId,
    state.requireCustomerNo || false,
    state.valuesUrl
  );

  // don't display filters if the values are empty
  if (filterValues === undefined || filterValues.length === 0) {
    return null;
  }

  /**
   * return option values, if controlledByFilterState
   * has values the option values will be filtered
   */
  const getOptions = (): ValidValue[] => {
    const options = filterValues ?? [];

    const controlledFilterValues = controlledByFilterState?.value;
    if (controlledFilterValues?.length) {
      return options.filter((option) =>
        controlledFilterValues?.includes(String(option.groupById))
      );
    }
    return options;
  };

  /**
   * Used to set selected items into the filters state
   *
   * @param _event
   * @param value
   */
  const handleMultiChange = (_event: SyntheticEvent, value: ValidValue[]) => {
    // create string array with selected id's
    const selectedValues: string[] = value.map((item) => {
      return item.id;
    });

    onChange({ ...state, value: selectedValues });
  };

  /**
   * Used to set selected item into the filters state
   *
   * @param event
   * @param value
   */
  const handleSingleChange = (_event: SyntheticEvent, value: ValidValue) => {
    onChange({ ...state, value: value ? [value.id] : [] });
  };

  const options = getOptions();

  if (multiple) {
    const selectedValues = getSelectedValues(state.value, options);

    return (
      <MultiSelect<ValidValue>
        id={`filter-${state.filterId}`}
        data-cy={`filter-${state.filterId}`}
        value={selectedValues}
        groupBy={getGroupBy}
        label={displayName}
        onChange={handleMultiChange}
        options={options}
        getOptionName={getName}
        getIcon={getIcon}
        filterOptions={filterOptions}
      />
    );
  } else {
    const selectedValue = getSelectedValue(state.value, options);

    return (
      <SingleSelect<ValidValue>
        id={`filter-${state.filterId}`}
        data-cy={`filter-${state.filterId}`}
        value={selectedValue}
        groupBy={getGroupBy}
        label={displayName}
        onChange={handleSingleChange}
        options={options}
        getOptionId={getId}
        getOptionName={getName}
        getIcon={getIcon}
        filterOptions={filterOptions}
      />
    );
  }
}

/**
 *  Helper function to provide the options id
 *
 * @param option
 *
 * @returns the option's id
 */
function getId(option: ValidValue): string {
  return option.id;
}

/**
 *  Helper function to provide the options name
 *
 * @param option
 *
 * @returns the options name
 */
function getName(option: ValidValue): string {
  return option.name;
}

/**
 *  Helper function to provide the optional icon
 *
 * @param option
 *
 * @returns the options icon
 */
function getIcon(option: ValidValue): Icon | undefined {
  return option.icon;
}

/**
 *  Helper function to provide the options optional groupBy string
 *
 * @param option
 *
 * @returns the options groupBy string, or an empty string if not set.
 */
function getGroupBy(option: ValidValue): string {
  return option.groupBy || "";
}

const filterOptions = createFilterOptions({
  matchFrom: "start",
  stringify: (option: ValidValue) => option.name,
});

/**
 * Return the selected values as an array of ValidValues
 *
 * @param value - a string array with the validValues Ids
 * @param optionItems
 *
 * @returns
 */
function getSelectedValues(
  value: string[] | undefined,
  optionItems: ValidValue[]
): ValidValue[] {
  const selectedOptions: ValidValue[] =
    value !== undefined && value.length > 0
      ? optionItems.filter((option) => value.includes(String(option.id)))
      : [];

  return selectedOptions;
}

/**
 * Return the selected value as a ValidValue
 *
 * @param value - a string array with the validValues Ids
 * @param optionItems
 *
 * @returns
 */
function getSelectedValue(
  value: string[] | undefined,
  optionItems: ValidValue[]
): ValidValue | undefined {
  return getSelectedValues(value, optionItems)[0] || undefined;
}
