export default {
  cancelDialog: {
    title: 'Abandonner l\'action "{{action}}"?',
    body: "Les données saisies seront perdues.",
    cancelButton: "Abandonner les modifications",
    closeButton: "Annuler",
  },

  transferProductStep: {
    copyProduct: "Copier le produit",
    transferBinds: "Transférer la durée d'engagement contractuel",
    deactivateStatusReasonId: "Motif de désactivation",
    activateStatusReasonId: "Motif d'activation",
  },

  findInventoryStep: {
    searchText: "Chaîne de recherche",
    status: "Statut",
    location: "Localisation",
    article: "Article",
    maxInvItems: "Limiter # de résultats à",
  },

  findNetworkElementStep: {
    searchText: "Chaîne de recherche",
    status: "Statut",
    networkElementType: "Type d'élément de réseau",
    maxTableItems: "Limiter # d'éléments à",
  },
} as const;
