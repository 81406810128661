import { InputLabelProps, InputProps, TextField } from "@mui/material";

import { default as ReactPhoneInput } from "react-phone-input-material-ui";
import "react-phone-input-material-ui/lib/material.css";
import { theme } from "styles/theme";
import {
  FormControlledFieldProps,
  withFormController,
} from "../FormController";

export type FormPhoneNumberFieldProps = Omit<
  FormControlledFieldProps,
  "variant"
> & {
  renderValue?: (value: any) => any;
  country: string;
  label: string;
  validationRules: FormControlledFieldProps["validationRules"];
  helperText?: string;
};

/**
 * Styling for the input
 */
const inputStyles: InputProps = {
  style: {
    margin: "0px",
    marginTop: "2px",
    padding: "0px",
    backgroundColor: "transparent",
  },
};

/**
 * Styling for the label
 */
const labelStyles: InputLabelProps = {
  style: {
    backgroundColor: theme.palette.grey[100],
  },
};

/**
 * Adds form handling for Phone Number field.
 *
 * @param fieldName Static string used for the unique ids of the inner components.
 * @param control Form control.
 * @param validationRules Optional field validation rules
 * @param helperText Optional helper text to display below the input field.
 * @param country The country code for the phone number input.
 * @param label The label for the phone number input field.
 * @param props Any remaining properties.
 */

export const FormPhoneNumberField =
  withFormController<FormPhoneNumberFieldProps>(
    ({
      fieldName,
      renderValue,
      renderState,
      validationRules,
      helperText,
      country,
      label,
      ...props
    }) => {
      const {
        field: { value, ...field },
        fieldState: { error },
        isDisabled,
      } = renderState;

      return (
        <ReactPhoneInput
          country={country}
          regions={"europe"}
          placeholder=""
          containerStyle={{
            backgroundColor: "transparent",
          }}
          inputStyle={{
            width: "100%",
            backgroundColor: "transparent",
          }}
          value={renderValue?.(value) ?? value ?? ""}
          component={TextField}
          disabled={isDisabled}
          countryCodeEditable={false}
          autoFormat={false}
          {...props}
          {...field}
          inputProps={{
            error: !!error,
            helperText: error ? error.message : helperText,
            label,
            "data-cy": fieldName,
            autoComplete: "username",
            InputProps: inputStyles,
            InputLabelProps: labelStyles,
            variant: "standard",
          }}
        />
      );
    }
  );

export default FormPhoneNumberField;
