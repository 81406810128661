import {
  ChangeCustomerStatusFlowData,
  ChangeCustomerStatusFlowStep,
  extractChangeEntityStatusFlowData,
  extractChangeEntityStatusFlowStep,
} from "core/api";
import { ReactElement } from "react";
import { ChangeEntityStatus } from "../ChangeEntityStatus";
import { FlowStepComponentProps } from "../models";

export type ChangeCustomerStatusProps = FlowStepComponentProps<
  ChangeCustomerStatusFlowStep,
  ChangeCustomerStatusFlowData
>;

/**
 * A component that can be used to change the status of a customer and set order properties.
 * It is an extension of the ChangeEntityStatus component.
 */
export function ChangeCustomerStatus({
  control,
  data,
  flowStep,
  ...props
}: ChangeCustomerStatusProps): ReactElement {
  return (
    <ChangeEntityStatus
      control={control}
      data={extractChangeEntityStatusFlowData(data)}
      flowStep={extractChangeEntityStatusFlowStep(flowStep)}
      {...props}
    />
  );
}
