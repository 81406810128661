export default {
  _404: {
    title: "Seite wurde nicht gefunden",
    urlNotFound: "URL wurde nicht gefunden",
    notFoundOrNotAvailable:
      "Die angeforderte Ressource wurde nicht gefunden oder ist nicht mehr verfügbar",
  },
  _500: {
    fatalError: {
      title: "Es ist ein unerwarteter Serverfehler aufgetreten",
      title_plural: "Es sind mehrere unerwartete Serverfehler aufgetreten",
    },
  },
  backToApplications: "Zurück zu meinen Anwendungen",
  error: "fehler",
  error_plural: "fehler",
  errorWithCount: "{{count}} fehler",
  errorWithCount_plural: "{{count}} fehler",
  preventNavigationDialog: {
    title: "Ungespeicherte Änderungen",
    body: "Es gibt ungespeicherte Änderungen. Bis du sicher, dass du diese Seite verlassen und die Änderungen verwerfen möchtest?",
    stayOnPage: "Auf der Seite bleiben",
    leavePage: "Seite verlassen",
  },
  route: {
    noAccess:
      "Die mit Ihrem Nutzer verbundene Rolle hat nicht die benötigten Zugriffsrechte für URL {{url}}.",
  },
  fetchError:
    "Die Komponente konnte aufgrund eines Serverfehlers nicht geladen werden (Fehlercode {{status}}))",
  fields: {
    valueAmountError:
      "Fehler: Feld {{fieldName}} erwartete {{expectedAmount}} Werte, erhielt nur {{actualAmount}}",
  },
  auth: {
    logoutFail:
      "Der Server hat die Abmeldeanfrage nicht beantwortet. Die lokalen Authentifizierungsdaten wurden gelöscht.",
  },
  emptyQuery: "Fehler: Die Antwort des Servers enthält keine Werte.",
  clientError: "Fehler im Web-Client: ",
  validationError: "Validierungsfehler",
  screenLoadingError:
    "Beim Laden der Seite ist ein Fehler aufgetreten. Dies könnte durch eine ungültige oder abgelaufene URL verursacht werden. Server-Fehlermeldung: {{serverMessage}}",
  screenLoadingError_nomessage:
    "Beim Laden der Seite ist ein Fehler aufgetreten. Dies könnte durch eine ungültige oder abgelaufene URL verursacht werden.",
  failedToLoadWidget: "Komponente konnte nicht geladen werden",
} as const;
