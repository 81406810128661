/**
 * The type of the BookmarkEntry.
 */
export enum BookmarkEntryType {
  /**
   * Bookmark
   */
  BOOKMARK = "BOOKMARK",
  /**
   * RECENTLY_VIEWED
   */
  RECENTLY_VIEWED = "RECENTLY_VIEWED",
}
