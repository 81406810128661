import { PageNotFoundScreen } from "404";
import { SvgIconComponent } from "common/assets/icons";
import { Navigate, Route, RouteProps, Routes, URLS } from "common/routing";
import { useAuthenticationContext } from "core/auth";
import { Head, LoadingView } from "core/components";
import { LoggingErrorBoundary } from "error/components";
import { useTranslation } from "i18n";
import { SelfcareAppLayout } from "layout";
import { LoggedOutScreen, LoginScreen } from "login/pages";
import {
  ComponentType,
  ReactElement,
  Suspense,
  createContext,
  useContext,
} from "react";
import xploraLogo from "./assets/logo.xplora.png";

interface AppRoute {
  title: string;
  routeProps: RouteProps;
  Icon?: SvgIconComponent;
  description?: string;
  Component: ComponentType;
}

interface AppRoutesContextState {
  appRoutes: AppRoute[];
}

const AppRoutesContext = createContext<AppRoutesContextState>({
  appRoutes: [],
});

export function useAppRoutesContext(): AppRoutesContextState {
  return useContext(AppRoutesContext);
}

function AppRoutes(): ReactElement {
  const { t } = useTranslation(["login", "error"]);
  const { state } = useAuthenticationContext();

  const routes: AppRoute[] = [
    {
      title: "",
      routeProps: {
        path: "/",
      },
      Component: () => <Navigate to={URLS.login.path} />,
    },
    {
      title: t("login:form.title"),
      routeProps: {
        path: URLS.login.path,
      },
      Component: LoginScreen,
    },
    {
      title: "Logout",
      routeProps: {
        path: URLS.logout.path,
      },
      Component: LoggedOutScreen,
    },
    {
      title: t("error:_404.title"),
      routeProps: {
        path: "",
      },
      Component: PageNotFoundScreen,
    },
  ];

  if (!state.loaded) {
    return <LoadingView />;
  }

  return (
    <Suspense fallback={<LoadingView />}>
      <Routes>
        {state.isAuthenticated ? (
          <Route
            path="*"
            element={<SelfcareAppLayout logoSrc={xploraLogo} />}
          />
        ) : (
          <>
            {routes.map(({ Component, title, routeProps }, index) => {
              return (
                <Route
                  key={index}
                  path={routeProps.path + "/*"}
                  element={
                    <LoggingErrorBoundary>
                      <Head>
                        <title>{title}</title>
                      </Head>
                      <Component />
                    </LoggingErrorBoundary>
                  }
                />
              );
            })}
          </>
        )}
      </Routes>
    </Suspense>
  );
}

export default AppRoutes;
