/**
 * Web model for enum from java.
 */
export enum FilterFieldType {
  Amount = "Amount",
  Boolean = "Boolean",
  Date = "Date",
  Select = "Select",
  Text = "Text",
}
