import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import {
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from "common/components";
import { BrowserRouter as Router } from "common/routing";
import { queryClient } from "core/api";
import { AuthenticationContextProvider } from "core/auth";
import { CookieDialog } from "core/components";
import { MessageContainer } from "core/message";
import { ShortcutsDialog } from "core/shortcuts";
import { LoggingErrorBoundary } from "error/components";
import { initI18n } from "i18n/init";
import { authSettings } from "layout/authSettings";
import { HelmetProvider } from "react-helmet-async";
import { resources } from "translations";
import "./App.css";
import AppRoutes from "./AppRoutes";
import { theme } from "./theme";
//TODO move this import to a better place
import "./theme/overrides.xplora.css";

const isDev = process.env.NODE_ENV === "development";

// Run accessibility testing during development
if (process.env.NODE_ENV !== "production") {
  const React = require("react");
  const ReactDOM = require("react-dom");
  const axe = require("@axe-core/react");
  axe(React, ReactDOM, 1000);
}

const customerGroupId = process.env.REACT_APP_CUSTOMER_GROUP_ID;
const environmentLanguage = process.env.REACT_APP_SELFCARE_LANGUAGE;

if (!customerGroupId || !Number.isInteger(parseInt(customerGroupId))) {
  console.error(
    "Missing customer group id. Add env value for REACT_APP_CUSTOMER_GROUP_ID."
  );
}

initI18n(resources, Object.keys(resources.en));

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <LoggingErrorBoundary>
        <HelmetProvider>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Router>
                <AuthenticationContextProvider settings={authSettings}>
                  <CookieDialog lang={environmentLanguage} />
                  <AppRoutes />
                </AuthenticationContextProvider>
                <MessageContainer />
                <ShortcutsDialog />
              </Router>
            </ThemeProvider>
          </StyledEngineProvider>
        </HelmetProvider>
        {isDev && <ReactQueryDevtools />}
      </LoggingErrorBoundary>
    </QueryClientProvider>
  );
}

export default App;
