import { ErrorDialog } from "core/message";
import { Component, ComponentType, ErrorInfo, ReactNode } from "react";

export interface ErrorBoundaryProps {
  ErrorContent?: ComponentType<ErrorBoundaryState>;
  children: ReactNode;
  logError(message: string): void;
  onError?(error: Error, info: ErrorInfo): void;
}

export interface AppError extends Error {
  cause?: string;
}
export interface ErrorBoundaryState {
  hasError: boolean;
  error: AppError;
  info: ErrorInfo;
  dialogOpen: boolean;
}

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state: ErrorBoundaryState = {
    hasError: false,
    error: { name: "", message: "", stack: "" },
    info: { componentStack: "" },
    dialogOpen: false,
  };

  static getDerivedStateFromError(_: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, dialogOpen: true };
  }

  componentDidCatch(error: AppError, info: ErrorInfo) {
    console.error(error, info);
    this.setState({ error, info });
    this.props.logError(
      error.name +
        ":" +
        error.message +
        (error.stack ? error.stack : "") +
        info.componentStack +
        (error.cause ?? "")
    );
    this.props.onError?.(error, info);
  }

  render() {
    if (this.state.hasError) {
      if (this.props.ErrorContent) {
        const ErrorContent = this.props.ErrorContent;
        return <ErrorContent {...this.state} />;
      }

      return (
        <>
          <ErrorDialog
            title={this.state.error.name + ":" + this.state.error.message}
            dialogMessages={[
              ...(this.state.error.stack
                ? [{ details: this.state.error.stack }]
                : []),
              { details: this.state.info.componentStack },
            ]}
            open={this.state.dialogOpen}
            onClose={() => {
              this.setState({ dialogOpen: false });
            }}
            includeCount={false}
          ></ErrorDialog>

          <h1>Component failed to load</h1>
        </>
      );
    }

    return this.props.children;
  }
}
