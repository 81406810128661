import { useCodeItems } from "core/hooks";
import {
  CodeObject,
  Invoice,
  InvoicePaymentStatus,
  InvoiceStatus,
  StatementType,
} from "core/api";
import { useCallback } from "react";

/**
 * This is a helper that builds the single invoice status value from the
 * payment status for invoices and from just status for credit notes.
 */
export function useInvoiceStatus() {
  const { getCodeName } = useCodeItems();

  const findInvoicePaymentStatusText = useCallback(
    (paymentStatus: InvoicePaymentStatus) => {
      return getCodeName(CodeObject.InvoicePaymentStatus, paymentStatus);
    },
    [getCodeName]
  );

  const findInvoiceStatusText = useCallback(
    (status: InvoiceStatus) => {
      return getCodeName(CodeObject.InvoiceStatus, status);
    },
    [getCodeName]
  );

  const getInvoiceStatus = useCallback(
    (invoice: Invoice) => {
      if (invoice?.statementType === StatementType.CREDIT_NOTE) {
        return {
          status: invoice?.status,
          reasonText: findInvoiceStatusText(invoice?.status),
        };
      } else {
        return {
          status: invoice?.paymentStatus,
          reasonText: findInvoicePaymentStatusText(invoice?.paymentStatus),
        };
      }
    },
    [findInvoicePaymentStatusText, findInvoiceStatusText]
  );

  return { getInvoiceStatus };
}
