import { styled, Typography, TypographyProps } from "common/components";
import { FieldUIComponent } from "core/api";

export interface StyledTextProps extends TypographyProps {
  noValue: boolean;
  align: "left" | "right" | "center";
}

export const StyledText = styled(Typography, {
  shouldForwardProp: (propName: string) =>
    !["noValue", "align"].includes(propName),
})<StyledTextProps>(({ theme, noValue, align }) => ({
  color: noValue ? theme.palette.action.disabled : undefined,
  flex: 1,
  userSelect: noValue ? "none" : undefined,
  textAlign: align,
  outline: "none",
  wordBreak: "break-word",
}));

export interface StyledValueContainerProps extends TypographyProps {
  uiComponent: FieldUIComponent;
}

export const StyledValueContainer = styled("div", {
  shouldForwardProp: (propName: string) => !["uiComponent"].includes(propName),
})<StyledValueContainerProps>(({ theme, uiComponent }) => ({
  display: "flex",
  flexWrap: "nowrap",
  alignItems: "center",
  alignContent: "start",
  justifyContent: "space-between",
  width: "100%",
  minWidth: 120,
  maxWidth: 320,
  gap: theme.spacing(0.5),
  flex: 1,
  ...(() => {
    switch (uiComponent) {
      case "TextArea":
        return {
          maxWidth: 390,
        };
      case "Number":
      case "Percent":
      case "Amount":
        return {
          maxWidth: 180,
        };
      case "Date":
      case "DateTime":
        return {
          maxWidth: 220,
        };
      default:
        break;
    }
  })(),
}));
