import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { IgnoreError, RequestInitWithParams } from "core/api";
import { getCurrentCustomerGroupId, useAuthenticationContext } from "core/auth";
import { Cache } from "core/idb";
import { getI18n } from "i18n";
import { APIError, DownloadFileResponse, useAPIRequest } from ".";
import { queryClient } from "./config";
import { queryAborter } from "./QueryAborter";

export interface APIQueryOptions<TData, TIsDownload extends boolean = false>
  extends Omit<
    UseQueryOptions<
      APIResponse<TData, TIsDownload>,
      APIError,
      any,
      APIQueryKey
    >,
    "queryKey" | "queryFn"
  > {
  /** Exclude customer group from query */
  excludeCustomerGroup?: boolean;
  /** Don't inform the user about the error, but throw as normal */
  ignoreError?: IgnoreError;
  /** If expected data is a file download */
  isDownload?: TIsDownload;
  /** If cache in IndexedDB should be ignored */
  noCache?: boolean;
  /** If the query does not require authentication */
  noAuthentication?: boolean;
}

export type APIResponse<
  TData,
  TIsDownload extends boolean = false
> = TIsDownload extends true ? DownloadFileResponse : TData;

export type APIQueryResult<
  TData,
  TIsDownload extends boolean = false
> = UseQueryResult<APIResponse<TData, TIsDownload>, APIError>;

export type APIQueryKey = any[];

/**
 * Hook used for requests that fetch data from REST API, mostly `GET` requests.
 *
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useQuery TanStack Query | useQuery}.
 *
 * For requests that require pagination use `useInfiniteAPIQuery`.
 *
 * For mutations, mostly `POST`, `PUT`, `DELETE` etc, use `useAPIMutation`.
 *
 * @param queryKey Key used by react-query to store query result. Must include relative URL and parameters
 * @param relativeUrl Relative URL to request in REST API
 * @param requestInit Initializtion parameters
 * @param options Options to customize query
 * @returns APIQueryResult, type based on UseQueryResult from react-query, see separate docs
 *
 * ### Basic usage
 * @example
 *
 * ```
 * function useFetchUser(
 *   userId: number | undefined,
 *   options?: APIQueryOptions<User>
 * ): APIQueryResult<User> {
 *   return useAPIQuery<User>(
 *     ["fetchUser", userId],
 *     API.userservice.user,
 *     { pathParams: { userId } },
 *     {
 *       ...options,
 *       enabled: userId !== undefined && !!options?.enabled
 *     }
 *   )
 * }
 *
 * function UserWidget({ userId }: UserWidgetProps) {
 *   const { data: user, fetchStatus } = useFetchUser(userId);
 *   ...
 * }
 * ```
 */
export function useAPIQuery<TData, TIsDownload extends boolean = false>(
  queryKey: APIQueryKey,
  relativeUrl: string,
  requestInit?: RequestInitWithParams,
  options?: APIQueryOptions<APIResponse<TData, TIsDownload>, TIsDownload>,
  transformerFn?: any // JSO Couldn't figure out how to type this correctly, something like (data: TData) => unknown.
): APIQueryResult<APIResponse<TData, TIsDownload>, TIsDownload> {
  const {
    state: { isAuthenticated, currentCustomerGroup },
  } = options?.noAuthentication
    ? { state: { isAuthenticated: false, currentCustomerGroup: undefined } }
    : useAuthenticationContext();
  const { requestAPI, downloadAPIFile } = useAPIRequest(
    options?.noAuthentication
  );

  const init: RequestInitWithParams = {
    method: "GET",
    credentials: "include",
    mode: "same-origin",
    signal: queryAborter.getSignal(),
    ...requestInit,
  };

  return useQuery(
    createAPIQueryKey(
      queryKey,
      options?.excludeCustomerGroup,
      isAuthenticated,
      currentCustomerGroup
    ),
    () =>
      options?.isDownload
        ? downloadAPIFile(relativeUrl, init, options?.ignoreError)
        : requestAPI(relativeUrl, init, {
            ignoreError: options?.ignoreError,
            noCache: options?.noCache,
          }),
    {
      ...options,
      enabled:
        (options?.enabled ?? true) &&
        (isAuthenticated || (options?.noAuthentication ?? false)),
      // Query is enabled if the 'enabled' option has not been set to false
      // AND if (the user is authenticated
      //         OR the 'noAuthentification' option has been set to true)
      select: transformerFn,
    }
  );
}

export function setAPIQueryData<TData>(
  queryKey: APIQueryKey,
  data: TData,
  excludedFromCustomerGroup = false
) {
  queryClient.setQueryData(
    createAPIQueryKey(queryKey, excludedFromCustomerGroup),
    data
  );
}

export function getAPIQueryData<TData>(
  queryKey: APIQueryKey,
  excludedFromCustomerGroup = false
): TData | undefined {
  return queryClient.getQueryData<TData>(
    createAPIQueryKey(queryKey, excludedFromCustomerGroup)
  );
}

export async function invalidateAPIQuery(
  queryKey: APIQueryKey,
  exact = true,
  excludedFromCustomerGroup = false
) {
  await queryClient.invalidateQueries(
    exact ? createAPIQueryKey(queryKey, excludedFromCustomerGroup) : queryKey,
    {
      exact,
      refetchType: exact ? "all" : "active",
    },
    {}
  );
}

export function clearAllQueryData() {
  queryClient.clear();
}

export function removeQueryData(
  queryKey: APIQueryKey,
  url?: string,
  excludedFromCustomerGroup = false
) {
  if (url) {
    Cache.clearResponse("GET", url);
  }
  queryClient.removeQueries(
    createAPIQueryKey(queryKey, excludedFromCustomerGroup)
  );
}

export function createAPIQueryKey(
  queryKey: APIQueryKey,
  excludedFromCustomerGroup = false,
  isAuthenticated = true,
  currentCustomerGroup: number | undefined = getCurrentCustomerGroupId()
  //currentAppConfigId: number | undefined = getCurrentAppConfig()
): APIQueryKey {
  return [
    ...queryKey,
    { isAuthenticated },
    ...(excludedFromCustomerGroup ? [] : [{ currentCustomerGroup }]),
    getI18n().language,
  ];
}
