import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormCheckBox,
  FormCurrencyField,
  FormTable,
  LabeledSelect,
  SelectChangeEvent,
  useTheme,
} from "common/components";
import {
  NumberFormatOptions,
  absoluteClamp,
  addFlowStepPrefix,
  calculateVATAmounts,
  roundHalfUp,
} from "common/utils";
import {
  CreditInvoiceFlowData,
  CreditInvoiceFlowStep,
  CreditInvoiceRow,
  TaggedTableData,
  useFetchTableConfig,
} from "core/api";
import currency from "currency.js";
import { useTranslation } from "i18n";
import { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FlowStepComponentProps } from "../models";
import { StyledStepContentWrapper } from "../steps.styles";

export type CreditInvoiceProps = FlowStepComponentProps<
  CreditInvoiceFlowStep,
  CreditInvoiceFlowData
>;

/**
 * Credit Invoice step component
 *
 * Renders a flow for crediting invoice rows. It mutates data in the table to make changes
 * but these mutations do not stay on refresh and are not stored anywhere. Mutation in this case
 * is ok since it does not affect any other component or state.
 *
 * Rows are credited by selecting them, and the credit is reverted by unselecting them. A partial
 * credit is done through the more option on each row. Only individual rows can be partially credited.
 */
export function CreditInvoice({
  control,
  data,
  flowStep,
}: CreditInvoiceProps): JSX.Element {
  const theme = useTheme();
  const { t } = useTranslation(["common", "invoice"]);
  const { data: tableConfig } = useFetchTableConfig(flowStep.config.tableId, {
    ignoreError: true,
  });

  const formContext = useFormContext();

  // Form field names
  const formFieldNameApprovalCheckbox = addFlowStepPrefix(flowStep, "approved");
  const formFieldNameTaxCheckbox = addFlowStepPrefix(
    flowStep,
    "uiAmountsInclTax"
  );

  const uiAmountsInclTax: boolean = formContext.watch(
    formFieldNameTaxCheckbox,
    data.amountsInclTax
  );

  // Set the amounts displayed in the table on every row to either incl. tax
  // or excl. tax, depending on the current value of the checkbox
  data.rows.map((row) => {
    row.invoicedAmount = uiAmountsInclTax
      ? row.invoicedAmountInclTax
      : row.invoicedAmountExclTax;
    row.creditedTotalAmount = uiAmountsInclTax
      ? row.creditedTotalAmountInclTax
      : row.creditedTotalAmountExclTax;
  });

  // Local state

  // The open/closed state of the Dialog for entering partial credits
  const [open, setOpen] = useState(false);

  // Stores the partial credit value input by the user in the Dialog
  const [partialCreditValue, setPartialCreditValue] = useState<number | null>(
    null
  );

  // Collection of rows that have been partially credited
  // (do not remove, even if it looks like the state is never read.
  //  it is actually read through the 'prevState' keyword)
  const [partiallyCreditedRows, setPartiallyCreditedRows] = useState<number[]>(
    []
  );

  // Stores a reference to the CreditInvoiceRow on which the user invokes the
  // Credit action in the table.
  const [rowToCredit, setRowToCredit] = useState<CreditInvoiceRow | undefined>(
    undefined
  );

  // Credit reason selected by the user
  const [selectedReason, setSelectedReason] = useState(data.creditReason.id);

  const reset = () => {
    setOpen(false);
    setPartialCreditValue(null);
    setRowToCredit(undefined);
  };

  function applyRounding(amount: number) {
    if (data.invoiceRoundingEnabled) {
      return roundHalfUp(amount, data.invoiceRoundingPrecision);
    } else {
      return amount;
    }
  }

  const calculateNewCredit = useCallback(() => {
    const sumInclTax: number = data.rows
      .filter((row) => row.credited)
      .map((row) => row.creditedTotalAmountInclTax)
      .reduce((a, b) => a + b, 0);
    data.creditedAmountInclTax = applyRounding(sumInclTax);
    if (data.creditedAmountInclTax >= data.approvalLimitInclTax) {
      data.approve = false;
      formContext.setValue(formFieldNameApprovalCheckbox, data.approve);
    }
  }, [data.rows]);

  useEffect(() => {
    calculateNewCredit();
  }, [calculateNewCredit]);

  //Event handlers

  /* Fully credits an invoice row when clicking on a row,
   *  Fully credits ALL invoice rows when clicking the checkbox in the table header. */
  const handleCreditRow = (selectedRows: CreditInvoiceRow[]) => {
    data.rows.forEach((row) => {
      const invoiceDetail = selectedRows.find(
        (selectedRow) =>
          selectedRow.originalInvoiceDetailId === row?.originalInvoiceDetailId
      );

      // If the row was not found it was not selected. Remove credited status.
      if (!invoiceDetail) {
        unCreditRow(row);
        return;
      }

      // If the row exists it was selected. Set credited status.
      if (invoiceDetail) {
        // Make sure not to revert by mistake when selecting another row.
        if (row?.credited) {
          return;
        }
        // If the addition of the rows amount is below max credit amount, allow credit action.
        row.credited = true;
        row.creditedNetAmount = row.invoicedAmountExclTax;
        row.creditedTotalAmount = row.invoicedAmount;
        row.creditedTotalAmountExclTax = row.invoicedAmountExclTax;
        row.creditedTotalAmountInclTax = row.invoicedAmountInclTax;
      }
    });

    calculateNewCredit();
  };

  // handle opening of the credit dialog when user clicks Credit on a row
  const handlePartialCreditDialogToggle = useCallback(
    (
      selectedItems: CreditInvoiceRow[],
      selectedItem: CreditInvoiceRow | undefined
    ) => {
      if (selectedItem) {
        setRowToCredit(selectedItem);
        setPartialCreditValue(
          uiAmountsInclTax
            ? selectedItem.creditedTotalAmountInclTax
            : selectedItem.creditedTotalAmountExclTax
        );
      }
      setOpen((prevState) => !prevState);
    },
    [uiAmountsInclTax]
  );

  // handle user input in the credit dialog
  const handlePartialCreditChange = useCallback(
    (value: string) => {
      if (rowToCredit) {
        // Allow only credit values that are included between zero and the amount
        // that was invoiced. N.B. Invoiced amount can be negative!
        setPartialCreditValue(
          absoluteClamp(
            Number(value),
            uiAmountsInclTax
              ? rowToCredit.invoicedAmountInclTax
              : rowToCredit.invoicedAmountExclTax
          )
        );
        // Recalculate the total credited amount
        calculateNewCredit();
      }
    },
    [rowToCredit, uiAmountsInclTax, calculateNewCredit]
  );

  // handle submission of the credit dialog
  const handlePartialCreditSubmit = useCallback(() => {
    let row: CreditInvoiceRow | undefined = undefined;
    // Find the row that is being partially credited in the flow data
    row = data.rows.find(
      (r) => r.originalInvoiceDetailId === rowToCredit?.originalInvoiceDetailId
    );
    if (row) {
      if (partialCreditValue !== null) {
        applyPartialCreditToRow(row, partialCreditValue, uiAmountsInclTax);
        // If user entered zero, then it is equivalent to uncheck the row
        row.credited = row.creditedNetAmount > 0.0 ? true : false;
        setPartiallyCreditedRows((prevState) => [
          ...prevState,
          row?.originalInvoiceDetailId ?? -1,
        ]);
        calculateNewCredit();
        reset();
      }
    } else {
      // ERROR that should never happen: rowToCredit refers to a row that
      // does not exist in data.rows.
      console.error(
        `ERROR: could not find a row with invoiceDetailId == ${rowToCredit?.originalInvoiceDetailId} in data.rows`
      );
    }
  }, [partialCreditValue, rowToCredit, data.rows, calculateNewCredit]);

  //handle cancellation of the credit dialog
  const handlePartialCreditCancel = useCallback(() => {
    reset();
  }, []);

  // handle reverting partial credit on a row
  const handleRevertPartialCredit = useCallback(
    (
      selectedItems: CreditInvoiceRow[],
      selectedItem: CreditInvoiceRow | undefined
    ) => {
      if (selectedItem) {
        // if revert is clicked on the row, revert only that row.
        const row = data.rows.find(
          (row) =>
            row.originalInvoiceDetailId ===
            selectedItem?.originalInvoiceDetailId
        );

        if (row) {
          unCreditRow(row);
          setPartiallyCreditedRows((prevState) => [
            ...prevState.filter(
              (invoiceDetailId) =>
                invoiceDetailId !== row.originalInvoiceDetailId
            ),
          ]);
        }
      }

      // if revert is clicked in the header, revert all rows.
      else if (selectedItems?.length > 0) {
        data.rows.forEach((row) => {
          unCreditRow(row);
        });
        setPartiallyCreditedRows(() => []);
      }

      calculateNewCredit();
      reset();
    },
    [data, calculateNewCredit]
  );

  const handleSelectCreditReason = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      const selectedReason = data.creditReasons.find(
        (reason) => reason.id === event.target.value
      );

      if (selectedReason) {
        data.creditReason = selectedReason;
        setSelectedReason(event.target.value as string);
      }
    },
    [data.creditReasons]
  );

  const handleApproveCheck = useCallback(
    (event: any) => {
      data.approve = event.target.checked;
    },
    [data.approve]
  );

  const currencyFormatOptions: NumberFormatOptions = {
    numberOfDecimals: 2,
    fixedDecimals: true,
  };

  return (
    <>
      <Box
        display="grid"
        sx={{
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: theme.spacing(1.5),
          alignContent: "start",
          padding: theme.spacing(1, 0),
        }}
      >
        <FormCurrencyField
          fieldName={addFlowStepPrefix(flowStep, "fullAmount")}
          value={data.invoiceTotalAmountInclTax}
          defaultValue={0}
          control={control}
          label={t("invoice:creditInvoice.totalInvoicedAmount")}
          disabled
          currencyCode={data.currencyCode}
          formatOptions={currencyFormatOptions}
        />
        <FormCurrencyField
          fieldName={addFlowStepPrefix(flowStep, "creditedAmount")}
          value={data.creditedAmountInclTax}
          defaultValue={0}
          control={control}
          label={t("invoice:creditInvoice.creditedAmount")}
          currencyCode={data.currencyCode}
          formatOptions={currencyFormatOptions}
          customValidation={() => {
            if (data.creditedAmountInclTax <= 0.0) {
              return t(
                "invoice:creditInvoice.negativeOrZeroCreditedAmountWarning"
              );
            } else if (
              data.creditedAmountInclTax > data.maxAmountToCreditInclTax
            ) {
              return t(
                "invoice:creditInvoice.remainingMaxAmountToCreditWarning"
              );
            } else {
              return true;
            }
          }}
        />
        <FormCurrencyField
          fieldName={addFlowStepPrefix(flowStep, "remainingAmount")}
          value={currency(data.maxAmountToCreditInclTax)
            .subtract(data.creditedAmountInclTax)
            .toString()}
          defaultValue={0}
          control={control}
          label={t("invoice:creditInvoice.remainingAmount")}
          currencyCode={data.currencyCode}
          formatOptions={currencyFormatOptions}
        />
        <FormCurrencyField
          fieldName={addFlowStepPrefix(flowStep, "maxAmountToCredit")}
          value={data.maxAmountToCreditInclTax}
          defaultValue={0}
          control={control}
          label={t("invoice:creditInvoice.maxAmountToCredit")}
          disabled
          currencyCode={data.currencyCode}
          formatOptions={currencyFormatOptions}
        />
        <LabeledSelect
          label={t("invoice:creditInvoice.creditReason")}
          dataList={data.creditReasons.map((reason) => ({
            value: reason.id,
            label: `${reason.name} | ${reason.description}`,
          }))}
          value={selectedReason}
          onChange={handleSelectCreditReason}
        />
        <FormCheckBox
          control={control}
          fieldName={formFieldNameApprovalCheckbox}
          label={t("invoice:creditInvoice.creditApprove")}
          disabled={data.creditedAmountInclTax >= data.approvalLimitInclTax}
          onChange={handleApproveCheck}
          onClick={handleApproveCheck}
        />
      </Box>
      {!tableConfig ? (
        <Box display="grid" sx={{ placeItems: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <StyledStepContentWrapper sx={{ padding: 0 }}>
          <FormTable
            control={control}
            fieldName={addFlowStepPrefix(flowStep, "tableStep")}
            required={false}
            tableConfig={tableConfig}
            tableItems={data.rows as unknown as TaggedTableData[]}
            selectionVariant={"MULTI"}
            tableId={flowStep.config.tableId}
            tagCheckboxLimit={5}
            selectedItems={
              data.rows.filter(
                (row) => row.credited
              ) as unknown as TaggedTableData[]
            }
            showSearchIfMoreThan={99999}
            selectionCallback={
              handleCreditRow as unknown as
                | ((selectedItems: TaggedTableData[]) => void)
                | undefined
            }
            tableActions={[
              {
                label: t("invoice:creditInvoice.credit"),
                disableActionForSelection: (selectedItems, selectedItem) => {
                  //If clicking "more" on a row, enable credit action.
                  if (selectedItem) {
                    return false;
                  }
                  //Bulk action disabled for credit since selecting a row without crediting it is not possible.
                  return true;
                },
                action: handlePartialCreditDialogToggle as unknown as (
                  selectedItems: TaggedTableData[],
                  selectedItem: TaggedTableData | undefined
                ) => void,
              },
              {
                label: t("invoice:creditInvoice.revertCredit"),
                disableActionForSelection: () => false,
                action: handleRevertPartialCredit as unknown as (
                  selectedItems: TaggedTableData[],
                  selectedItem: TaggedTableData | undefined
                ) => void,
              },
            ]}
          />
        </StyledStepContentWrapper>
      )}
      <FormCheckBox
        control={control}
        fieldName={formFieldNameTaxCheckbox}
        label={t("common:amountsInclTax")}
        defaultValue={data.amountsInclTax}
      />

      <Dialog
        open={open}
        scroll="paper"
        data-cy="creditInvoiceDialog"
        disableRestoreFocus
      >
        <DialogTitle>{t("invoice:creditInvoice.creditInvoiceRow")}</DialogTitle>
        <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
          <FormCurrencyField
            fieldName={addFlowStepPrefix(flowStep, "maxCreditAmount")}
            defaultValue={null}
            value={currency(rowToCredit?.invoicedAmount ?? 0)
              .subtract(partialCreditValue ?? 0)
              .toString()}
            control={control}
            label={t("invoice:creditInvoice.remainingAmountToCredit")}
            currencyCode={data.currencyCode}
            margin="normal"
            disabled
          />
          <FormCurrencyField
            fieldName={addFlowStepPrefix(flowStep, "creditAmount")}
            value={partialCreditValue ?? rowToCredit?.creditedNetAmount}
            onChange={handlePartialCreditChange}
            control={control}
            label={
              uiAmountsInclTax
                ? t("invoice:creditInvoice.amountInclTax")
                : t("invoice:creditInvoice.amountExclTax")
            }
            currencyCode={data.currencyCode}
            margin="normal"
            data-cy="partialCreditInput"
            focused
            autoFocus
            onKeyDown={(event) => {
              // User pressed the [Enter] key => same as clicking the CREDIT button
              if (event.key === "Enter") {
                handlePartialCreditSubmit();
              }
            }}
          />
        </DialogContent>
        <DialogActions sx={{ padding: "16px 24px" }}>
          <Button onClick={handlePartialCreditCancel} color="inherit">
            {t("common:buttons.cancel")}
          </Button>
          <Button
            onClick={handlePartialCreditSubmit}
            color="primary"
            data-cy="partialCreditConfirm"
          >
            {t("invoice:creditInvoice.credit")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function unCreditRow(row: CreditInvoiceRow) {
  row.credited = false;
  row.creditedNetAmount = 0;
  row.creditedTotalAmountExclTax = 0;
  row.creditedTotalAmountInclTax = 0;
  row.creditedTotalAmount = 0;
}

function applyPartialCreditToRow(
  row: CreditInvoiceRow,
  partialCreditValue: number,
  amountsInclTax: boolean
) {
  if (amountsInclTax) {
    row.creditedTotalAmountInclTax = absoluteClamp(
      partialCreditValue,
      row.invoicedAmountInclTax
    );
    const newAmounts = calculateVATAmounts(
      row.creditedTotalAmountInclTax,
      true,
      row.taxRate
    );
    row.creditedNetAmount = newAmounts.amountExclVat;
    row.creditedTotalAmountExclTax = newAmounts.amountExclVat;
    row.creditedTotalAmount = row.creditedTotalAmountExclTax;
  } else {
    row.creditedNetAmount = absoluteClamp(
      partialCreditValue,
      row.invoicedAmountExclTax
    );
    const newAmounts = calculateVATAmounts(
      row.creditedNetAmount,
      false,
      row.taxRate
    );
    row.creditedTotalAmountExclTax = row.creditedNetAmount;
    row.creditedTotalAmountInclTax = newAmounts.amountInclVat;
    row.creditedTotalAmount = row.creditedTotalAmountExclTax;
  }
}
