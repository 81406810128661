import {
  APIQueryOptions,
  APIQueryResult,
  createApiUrl,
  EntityData,
  invalidateAPIQuery,
  RequestInitWithParams,
  useAPIQuery,
} from "core/api";
import { getEntityUrl } from "core/components";
import { Cache } from "core/idb";

export interface FetchQueryOptions<TEntityData>
  extends Pick<
    APIQueryOptions<TEntityData>,
    "ignoreError" | "excludeCustomerGroup" | "staleTime"
  > {
  /** Directive for useQuery. */
  enabled?: boolean;
  /** API url for search query */
  apiUrl: string | undefined;
  ids: string[] | undefined;
  entityType?: string;
  requestInit?: RequestInitWithParams;
}

export const FETCH_ENTITY_QUERY_KEY = "fetchEntity";

export function useFetchEntityQuery<
  TEntityProperties extends Partial<EntityData> = EntityData
>({
  enabled = true,
  apiUrl,
  ids,
  entityType,
  ignoreError,
  requestInit,
  ...options
}: FetchQueryOptions<EntityData<TEntityProperties>>): APIQueryResult<
  EntityData<TEntityProperties>
> {
  apiUrl = getEntityUrl(
    { ...requestInit?.pathParams, ...requestInit?.queryParams },
    apiUrl
  );
  return useAPIQuery<EntityData<TEntityProperties>>(
    createFetchEntityQueryKey(entityType, ids, apiUrl),
    apiUrl ?? "",
    requestInit,
    {
      enabled:
        enabled &&
        !!apiUrl &&
        !!ids &&
        ids.reduce((currVal, id) => currVal && !!id, true) &&
        !!entityType,
      ignoreError,
      ...options,
    }
  );
}

export function createFetchEntityQueryKey(
  entityType: string | undefined,
  ids: string[] | undefined,
  apiUrl: string | undefined
) {
  return [FETCH_ENTITY_QUERY_KEY, entityType, ids, apiUrl];
}

export function invalidateFetchEntityQuery(
  entityType: string | undefined,
  ids: string[] | undefined,
  apiUrl: string | undefined
) {
  Cache.clearResponse("GET", createApiUrl(apiUrl ?? ""));
  invalidateAPIQuery(createFetchEntityQueryKey(entityType, ids, apiUrl), false);
}
