import { IconButton, IconButtonProps } from "common/components";
import { ReactElement } from "react";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

/**
 * Nice animation helper for expanding/collapsing, should be used together with <Collapse>
 *
 * "Stolen" from the MUI online examples for cards, and cleaned up to be a proper component.
 *
 * @param props
 * @returns
 */
export function ExpandMore(props: ExpandMoreProps): ReactElement {
  const { expand, ...other } = props;
  return (
    <IconButton
      size="small"
      sx={{
        transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
        marginLeft: "auto",
        transition: (theme) =>
          theme.transitions.create("transform", {
            duration: theme.transitions.duration.short,
          }),
      }}
      {...other}
    />
  );
}
