import { ListItem, ListItemText } from "common/components";
import { convertBooleanToText } from "common/utils";
import { FlowData, TransferProductFlowStep } from "core/api";
import { useTranslation } from "i18n";
import { Fragment } from "react";

export interface TransferProductConfirmationProps {
  step: TransferProductFlowStep;
  flowData: FlowData;
}

/**
 * Sub component to confirmation.
 *
 * TransferProductConfirmation is specific to confirmation steps where
 * the flow contains a step with the uiComponent: "TransferProduct".
 *
 * @param step The current step in the parent components loop.
 * @param flowData data property from the flow object, this is fetched through
 * const flowData = flow.data[step.id]; in the parent component.
 *
 * @returns A JSX element with important product transfer settings
 */
export function TransferProductConfirmation({
  step,
  flowData,
}: TransferProductConfirmationProps): JSX.Element {
  const { t } = useTranslation(["action"]);
  const fieldFlowData =
    flowData.uiComponent === "TransferProduct" ? flowData : undefined;

  return (
    <Fragment key={step.id}>
      <ListItem>
        <ListItemText
          primary={t("transferProductStep.copyProduct")}
          secondary={convertBooleanToText(fieldFlowData?.copyProduct)}
          data-cy={"copyProduct"}
        />
      </ListItem>
      {fieldFlowData?.copyProduct && (
        <ListItem>
          <ListItemText
            primary={t("transferProductStep.transferBinds")}
            secondary={convertBooleanToText(fieldFlowData?.transferBinds)}
          />
        </ListItem>
      )}
    </Fragment>
  );
}
