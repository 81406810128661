/**
 * Enum for entity types used in web, corresponds to EntityType.java in Billiant core.
 */
export enum EntityType {
  ACCOUNT = "ACCOUNT",
  ADDRESS = "ADDRESS",
  ADJUSTMENT = "ADJUSTMENT",
  ARTICLE = "ARTICLE",
  BUNDLED_PRODUCT = "BUNDLED_PRODUCT",
  COLLECTOR = "COLLECTOR",
  CREDIT_NOTE = "CREDIT_NOTE",
  CUSTOMER = "CUSTOMER",
  CUSTOMER_BUCKET = "CUSTOMER_BUCKET",
  CUSTOMER_MANDATE = "CUSTOMER_MANDATE",
  DAEMON = "DAEMON",
  DOCUMENT = "DOCUMENT",
  EVENT_ROUTE_INVOCATION_HISTORY = "EVENT_ROUTE_INVOCATION_HISTORY",
  INVENTORY = "INVENTORY",
  INVOICE = "INVOICE",
  INVOICE_AR_COMMAND = "INVOICE_AR_COMMAND",
  INVOICE_BILL_RUN = "INVOICE_BILL_RUN",
  INVOICE_STATUS_DETAIL = "INVOICE_STATUS_DETAIL",
  JOB = "JOB",
  LOGISTIC_ORDER_ARTICLE = "LOGISTIC_ORDER_ARTICLE",
  MESSAGE = "MESSAGE",
  METRIC = "METRIC",
  NETWORK_ELEMENT = "NETWORK_ELEMENT",
  NONE = "NONE",
  NOTE = "NOTE",
  NP_CASE = "NP_CASE",
  NRDB_CLIENT = "NRDB_CLIENT",
  NRDB_CONFIG = "NRDB_CONFIG",
  NRDB_MESSAGE = "NRDB_MESSAGE",
  NRDB_NUMBER = "NRDB_NUMBER",
  NRDB_PROXY = "NRDB_PROXY",
  ONEOFF_BALANCE = "ONEOFF_BALANCE",
  ORDER = "ORDER",
  PAYMENT = "PAYMENT",
  PAYMENT_JOURNAL = "PAYMENT_JOURNAL",
  PAYMENT_PROVIDER = "PAYMENT_PROVIDER",
  PAYMENT_PROVIDER_HISTORY = "PAYMENT_PROVIDER_HISTORY",
  PRODUCT = "PRODUCT",
  PRODUCT_OFFERING = "PRODUCT_OFFERING",
  PRODUCT_OFFERING_TOPUP = "PRODUCT_OFFERING_TOPUP",
  PROVISIONER = "PROVISIONER",
  RATING_EVENT = "RATING_EVENT",
  REPAYMENT = "REPAYMENT",
  REPORT = "REPORT",
  RESELLER = "RESELLER",
  SDR = "SDR",
  SERVICE_PORT = "SERVICE_PORT",
  SERVICE_PORT_INVOCATION_HISTORY = "SERVICE_PORT_INVOCATION_HISTORY",
  SUBSCRIPTION = "SUBSCRIPTION",
  SYS_USER = "SYS_USER",
  TASK = "TASK",
  TASK_LIST = "TASK_LIST",
  TASK_NOTIFICATION = "TASK_NOTIFICATION",
  USER = "USER",
  WEB_APPLICATION = "WEB_APPLICATION",
}
