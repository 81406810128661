import { API, APIMutationResult, useAPIMutation } from "core/api";
import { invalidateFindBookmarks } from "./useFindBookmarks";

/**
 * Clear all bookmarks that exist in DB for current user (ignore customer group).
 * @returns APIMutationResult
 */
export function useClearBookmarks(): APIMutationResult<undefined, number> {
  return useAPIMutation<undefined, number>(
    API.myAccount.bookmarks,
    (_customerGroupId) => ({
      method: "DELETE",
    }),
    {
      onSuccess(_, params) {
        invalidateFindBookmarks(params);
      },
    }
  );
}
