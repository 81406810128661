import { AllowedKey, ShortcutConfig } from "..";
import { isMacOS } from "../helpers";
import { ShortcutContainer } from "./ShortcutKeys.styles";

interface ShortcutKeysProps {
  config: ShortcutConfig;
}

/** Creates a shortcut keys label */
export function ShortcutKeys({ config }: ShortcutKeysProps) {
  const keys = {
    "⌘": isMacOS() && config.osMeta === true,
    "⌥": isMacOS() && config.alt === true,
    ctrl: !isMacOS() && config.osMeta === true,
    alt: !isMacOS() && config.alt === true,
    "⇧": config.shift === true,
    [matchKey(config.key)]: true,
  };

  const usedKeys = Object.entries(keys)
    .filter(([, value]) => value)
    .map(([key]) => key);

  return (
    <ShortcutContainer>
      {usedKeys.map((key) => (
        <span key={key}>{key}</span>
      ))}
    </ShortcutContainer>
  );
}

/**
 * Matches key to symbol
 * @param key Target allowed key
 * @returns Visible key symbol
 */
export function matchKey(key: AllowedKey): string {
  const keys: Partial<Record<AllowedKey, string>> = {
    ArrowLeft: "←",
    ArrowRight: "→",
    ArrowUp: "↑",
    ArrowDown: "↓",
    Enter: "⏎",
    Backspace: "⌫",
    Tab: "⇥",
  };
  return keys[key] ?? key;
}
