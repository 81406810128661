import {
  ConfigureInvoiceDunningFlowData,
  ConfigureInvoiceDunningFlowStepConfig,
} from "core/api";
import { StepDataResolver } from "../models";

/**
 * Configure Invoice Dunning data resolver
 *
 */
export const configureInvoiceDunningDataResolver: StepDataResolver<
  ConfigureInvoiceDunningFlowStepConfig,
  ConfigureInvoiceDunningFlowData
> = ({ data }) => {
  return {
    // externalInvoiceId is not editable by the user
    // => simply return the data initially received from the server
    externalInvoiceId: data.externalInvoiceId,
  };
};
