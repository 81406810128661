import { Box, BoxProps } from "common/components";
import { useContainerSize } from "common/hooks";
import { useCallback, useEffect, useRef, useState } from "react";

export function useResponsiveOverflow() {
  const { ref: outerRef, width: outerWidth } =
    useContainerSize<HTMLDivElement>();
  const innerRef = useRef<HTMLDivElement>();
  const [innerWidth, setInnerWidth] = useState(0);

  useEffect(() => {
    const _innerWidth = innerRef.current?.getBoundingClientRect().width ?? 0;
    setInnerWidth((state) => (state >= _innerWidth ? state : _innerWidth));
  }, [outerWidth]);

  const isContentOverflow = innerWidth > outerWidth;

  const OverflowContainer = useCallback(
    (props: BoxProps) => (
      <Box
        sx={{ height: "100%" }}
        ref={outerRef}
        display="grid"
        overflow="hidden"
        whiteSpace="nowrap"
        justifyContent="end"
      >
        <Box
          ref={innerRef}
          display="grid"
          gridAutoFlow="column"
          width="fit-content"
          alignItems="center"
          gap={2}
          {...props}
        ></Box>
      </Box>
    ),
    [outerRef, innerRef]
  );

  return {
    OverflowContainer,
    isContentOverflow,
  };
}

export function useDrawerState(close: boolean) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (close && open) {
      setOpen(false);
    }
  }, [close, open]);

  const toggleDrawer = useCallback(
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpen(open);
    },
    []
  );

  return { open, toggleDrawer };
}
