import { useAuthenticationContext } from "core/auth";
import { ReactElement, useEffect } from "react";
import { PageNotFound } from "../PageNotFound";

export function PageNotFoundScreen(): ReactElement {
  const { redirectToLoginPage } = useAuthenticationContext();

  useEffect(() => {
    redirectToLoginPage();
  }, [redirectToLoginPage]);

  return <PageNotFound />;
}
