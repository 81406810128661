import { importWidget } from "screens";
import { LoadingView } from "core/components/Screen/DynamicView";
import {
  DynamicWidget,
  DynamicWidgetProps,
} from "core/components/Screen/DynamicWidget";

export type SelfcareDynamicWidgetProps = Omit<
  DynamicWidgetProps,
  "importWidget"
>;

/** Dynamically loads a widget component */
export function SelfcareDynamicWidget(props: SelfcareDynamicWidgetProps) {
  return (
    <DynamicWidget
      importWidget={importWidget}
      fallback={<LoadingView />}
      {...props}
    />
  );
}
