import { Button, CommonDialog } from "common/components";
import { useTranslation } from "i18n";

export interface CancelFlowDialogProps {
  /** If the dialog should be open */
  open: boolean;
  /** The name of the action */
  actionName: string;
  /** If the cancel request is loading */
  cancelIsLoading: boolean;
  /** Closes the dialog */
  closeDialog(): void;
  /** Called when the user decides to cancel the action */
  onCancel(): void;
}

/** Confirm dialog for canceling a flow action */
export function CancelFlowDialog({
  open,
  actionName,
  closeDialog,
  onCancel,
  cancelIsLoading,
}: CancelFlowDialogProps) {
  const { t } = useTranslation(["action"]);
  return (
    <CommonDialog
      open={open}
      maxWidth="xs"
      title={t("action:cancelDialog.title", { action: actionName })}
      dataCy="cancelFlowDialog"
      onClose={(_, reason) => {
        if (reason === "escapeKeyDown") {
          closeDialog();
        }
      }}
      actions={
        <>
          <Button
            color="primary"
            variant="text"
            onClick={closeDialog}
            autoFocus
            disabled={cancelIsLoading}
            data-cy="closeButton"
            sx={{ marginLeft: "auto" }}
          >
            {t("action:cancelDialog.closeButton")}
          </Button>
          <Button
            color="primary"
            variant="text"
            onClick={onCancel}
            loading={cancelIsLoading}
            data-cy="cancelButton"
          >
            {t("action:cancelDialog.cancelButton")}
          </Button>
        </>
      }
    >
      {t("action:cancelDialog.body", { action: actionName })}
    </CommonDialog>
  );
}
