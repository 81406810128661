import { Logout } from "common/assets/icons";
import { IconButton } from "common/components";
import { useTranslation } from "i18n";
import { useLogoutDialog } from "./useLogoutDialog";

export function LogoutButton() {
  const { t } = useTranslation(["common", "login"]);
  const { LogoutDialog, toggleOpen } = useLogoutDialog();

  return (
    <>
      <IconButton
        aria-label={t("common:logout")}
        onClick={toggleOpen}
        sx={{ justifySelf: "start" }}
      >
        <Logout sx={{ transform: "rotateY(180deg)" }} />
      </IconButton>
      <LogoutDialog />
    </>
  );
}
