import {
  EntityType,
  FilterValueState,
  FilterValueStates,
  SearchFilter,
  TableColumnConfig,
  TaggedTableData,
  useAPIQuery,
} from "core/api";
import {
  useInfinityQuerySearch,
  useSearchFilterPanel,
  useTableSorting,
} from "core/components";
import { useLocalSearch } from "core/hooks";
import { useCallback } from "react";

export interface TableSearchOptions<
  TData extends TaggedTableData = TaggedTableData
> {
  /** Table column configs */
  columns: TableColumnConfig[];
  /** Table data */
  items: TData[] | undefined;
  /** Function that sets selected tags state */
  setSelectedTags: (tags: string[]) => void;
  /** Selected tags state */
  selectedTags: string[] | undefined;
  /** Search string for local search (used for static tables) */
  search: string | undefined;
  /** If search should handled server side (infinity query) */
  infinitySearch?: boolean;
  /** Search string for infinity search (used for Infinity tables) */
  infinitySearchValue: string | undefined;
  /** Unique search id */
  searchId?: string;
  /** API url */
  apiUrl?: string;
  /** Table items entity type */
  entityType: EntityType;

  tableOptionId: number;
  /** Optional: the initial value of the filter
   * @see SearchFilterPanelOptions#initialFilterStates
   */
  initialFilterStates?: FilterValueStates;
}

export interface TableSearchResult<
  TData extends TaggedTableData = TaggedTableData
> extends Pick<
    ReturnType<typeof useTableSorting>,
    "setPage" | "setPageSize" | "onSortChange"
  > {
  /** Filtered table items */
  filteredItems: TData[];
  /** Filter tags */
  tags: string[];
  /** Toggle selection for target tag */
  toggleTag(tag: string): void;
  /** Checks if tag is selected */
  isSelectedTag(tag: string): boolean;
  /** Total item count */
  totalCount: number;
  /** If a query is fetching table items */
  isFetching: boolean;
  /** The Search Filters that have been configured for the given Entity Type */
  filterFields: SearchFilter[];
  /**
   * An array that contains the current state of the filter values that were
   * selected by the user in the SearchFilterPanel. [LucaN] my best guess!
   */
  filterStates: FilterValueStates;
  /** A function that updates the filterState with the value of a filter field.
   * It is implemented in useSearchFilterPanel. It should be passed to the FilterPanelField.
   * See {@link useSearchFilterPanel} getOnChange() function in useSearchFilterPanel */
  getOnChange: <TValueState extends FilterValueState>(
    id: number
  ) => (state: TValueState) => void;
  /** Function that should be used to reset the state of all filters in the Filter SearchFilterPanel */
  resetFilterPanel: () => void;
  /** The filterState that was applied to populate the table. [LucaN] best guess!*/
  activeFilterState: FilterValueStates | undefined;
  /** Function that should be called when a search is triggered.
   * It sets the filter values. */
  onSearch: (newFilterStates: FilterValueStates) => void;
}

/**
 * Handles table client side filtering with text and tags.
 * Search can also be handled on the server-side by setting the infinitySearch to true.
 *
 * @returns Search state and methods
 */
export function useTableSearch<
  TData extends TaggedTableData = TaggedTableData
>({
  columns,
  items,
  setSelectedTags,
  selectedTags,
  search,
  infinitySearch,
  infinitySearchValue,
  searchId,
  apiUrl,
  entityType,
  tableOptionId,
  initialFilterStates,
}: TableSearchOptions<TData>): TableSearchResult<TData> {
  /* In the case of static tables, if 'items' is not provided and 'apiUrl'
   * is provided, then get the data through the REST API */
  const { data, fetchStatus } = useAPIQuery<TData[]>(
    ["formTable", apiUrl],
    apiUrl ?? "",
    {},
    { enabled: !!apiUrl && !items && !infinitySearch }
  );
  const { order, orderBy, page, pageSize, setPage, setPageSize, onSortChange } =
    useTableSorting();

  const {
    filters,
    setFilters,
    filterFields,
    filterStates,
    getOnChange,
    resetFilterPanel,
    activeFilterState,
    formatToFilterQuery,
  } = useSearchFilterPanel({
    entityType,
    tableOptionId,
    disableUrlParameters: false,
    initialFilterStates,
  });

  const onSearch = useCallback(
    (newFilterStates: FilterValueStates) => {
      const nextFilterState = newFilterStates ?? filterStates;
      const filter = formatToFilterQuery(nextFilterState);
      // N.B. search string and searchId are set at a higher level
      setFilters(filter);
    },
    [filterStates, formatToFilterQuery, setFilters]
  );

  const {
    totalCount: infinityTotalCount,
    fetchStatus: infinityFetchStatus,
    currentPageItems = [],
  } = useInfinityQuerySearch({
    apiUrl,
    entityType,
    currentSearch: infinitySearchValue ?? "",
    order,
    orderBy,
    page,
    pageSize,
    filters,
    searchDisabled: false,
    searchId,
    enabled: infinitySearch,
  });

  const localSearchItems = items ?? data ?? [];

  const localSearch = useLocalSearch(
    !infinitySearch,
    columns,
    localSearchItems,
    search,
    orderBy,
    order === "asc",
    setSelectedTags,
    selectedTags
  );

  return {
    ...localSearch,
    filteredItems: infinitySearch
      ? currentPageItems
      : localSearch.filteredItems,
    onSortChange,
    setPage,
    setPageSize,
    isFetching: [fetchStatus, infinityFetchStatus].includes("fetching"),
    totalCount: infinitySearch ? infinityTotalCount : localSearchItems.length,
    filterFields,
    filterStates,
    getOnChange,
    resetFilterPanel,
    activeFilterState,
    onSearch,
  };
}
