import { StorageKey } from "core/appSettings";
import { useEffect } from "react";
import { createStore } from "core/store";

/**
 * Registers current application id in sessionstorage
 * @param applicationId Current application id
 */
export function useRegisterAppConfigId(appConfigId: number | undefined) {
  const { state: appConfigIdState, updateState: updateAppConfigId } =
    useAppConfigIdStore();

  useEffect(() => {
    const _applicationConfigId = appConfigId ?? -1;
    if (appConfigIdState !== _applicationConfigId) {
      updateAppConfigId(_applicationConfigId);
    }
  }, [appConfigIdState, updateAppConfigId, appConfigId]);
}

const useAppConfigIdStore = createStore<number>({
  name: StorageKey.CURRENT_APPLICATION_CONFIG_ID,
  storage: sessionStorage,
  initialState: -1,
});

export function getCurrentAppConfig() {
  const _envAppConfigId = parseInt(process.env.REACT_APP_APP_CONFIG_ID ?? "");
  if (_envAppConfigId) {
    return _envAppConfigId;
  }
  return useAppConfigIdStore.getState().state;
}
