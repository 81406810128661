import { APIError, clearAllQueryData, queryAborter } from "core/api";
import { StorageKey, clearLS } from "core/appSettings";
import { AuthUserResponse, AuthenticationState } from "core/auth/models";
import {
  LoginData,
  logoutRequest,
  refreshRequest,
  setUserQueryData,
  useLoginRequest,
  useOneTimePasswordRequest,
} from "core/auth/util";
import { Cache, DB } from "core/idb";
import { Messages } from "core/message";
import { useTranslation } from "i18n";
import { useCallback, useEffect, useReducer, useRef } from "react";

import {
  clearLocalStoreAuthorization,
  tokenExpiresInMinutes,
  tokenIsValid,
} from "../util/token";
import { authenticationReducer, initialState } from "./authenticationReducer";
import { useRedirect } from "./useRedirect";

export interface AuthenticationMethods {
  /**
   *
   * @param data A data structure that contains login credentials and options.
   * @param executeOnError A function that is executed in case the login fails.
   */
  login(data: LoginData, executeOnError?: () => void): void;
  logout(): void;
  getOneTimePassword(
    userName: string,
    callback?: (success: boolean, response?: Response | APIError) => void
  ): void;
  redirectToLoginPage(): void;
  redirectToLogoutPage(): void;
  navigateToScreen(): void;
  selectCustomerGroup(customerGroup: number): void;
}

export interface Authentication extends AuthenticationMethods {
  state: AuthenticationState;
  isLoggingIn: boolean;
  isRequestingOTP: boolean;
}

type AuthenticatedDispatch = (options?: {
  defaultCustomerGroupId?: number;
}) => void;

/** Called when user is authenticated */
export type OnAuthenticatedFunction<TUserResponse extends AuthUserResponse> = (
  user: TUserResponse,
  withDefaultCustomerGroup: boolean,
  dispatch: AuthenticatedDispatch
) => void;

/** Called on login success */
export type OnLoginSuccess<TUserResponse extends AuthUserResponse> = (
  user: TUserResponse
) => void;

/** Supports using hooks. Called on every state change. */
export type UseAuthenticationEffectsHook = (state: AuthenticationState) => void;

/**
 * Settings to differentiate authentication logic for different apps
 *
 * @example
 *
 * ```
 * const useAuthenticationEffects: UseAuthenticationEffectsHook = (
 *   state
 * ) => {
 *   useVisibilityChange((visibility) => {
 *     if (visibility === "visible" && state.currentCustomerGroup) {
 *       setLastCustomerGroupId(state.currentCustomerGroup);
 *     }
 *   });
 * };
 *
 * const onLoginSuccess: OnLoginSuccess<UserResponse> = (user) => {
 *   const redirectUrl = getRedirectUrl();
 *   setRedirectURL(`/${redirectUrl || user.defaultApp}`);
 * };
 *
 * const onAuthenticated: OnAuthenticatedFunction<UserResponse> = (
 *   user,
 *   withDefaultCustomerGroup,
 *   dispatch
 * ) => {
 *   changeLanguage(user.systemUser.language);
 *   setUserQueryData(user);
 *   dispatch();
 * };
 *
 * export const authSettings: AuthenticationSettings<UserResponse> = {
 *   useAuthenticationEffects,
 *   onLoginSuccess,
 *   onAuthenticated,
 * };
 * ```
 */
export interface AuthenticationSettings<
  TUserResponse extends AuthUserResponse
> {
  /** Called when user is authenticated */
  onAuthenticated?: OnAuthenticatedFunction<TUserResponse>;
  /** Called on login success */
  onLoginSuccess?: OnLoginSuccess<TUserResponse>;
  /** Supports using hooks. Called on every state change. */
  useAuthenticationEffects?: UseAuthenticationEffectsHook;
}

/**
 * How often token expiration is checked. Short interval is needed
 * to quickly react to if localstorage has been cleared.
 */
const TOKEN_VALIDATION_INTERVAL = 1500;

export function useAuthentication<TUserResponse extends AuthUserResponse>({
  onAuthenticated,
  onLoginSuccess,
  useAuthenticationEffects,
}: AuthenticationSettings<TUserResponse> = {}): Authentication {
  const [state, dispatch] = useReducer(authenticationReducer, initialState);
  const { navigateToScreen, redirectToLoginPage, redirectToLogoutPage } =
    useRedirect();
  const refreshRef = useRef<NodeJS.Timeout>();
  const { t } = useTranslation(["error"]);
  const hasCheckedLoginRef = useRef(false);
  const { mutate: loginRequest, isLoading: isLoggingIn } =
    useLoginRequest<TUserResponse>();

  const { mutate: oneTimePasswordRequest, isLoading: isRequestingOTP } =
    useOneTimePasswordRequest();

  const forceLogout = useCallback(
    (error: APIError) => {
      redirectToLogoutPage();
      clearLocalStoreAuthorization();
      Cache.clearCache();
      clearAllQueryData();
      clearLS();
      dispatch({ type: "ERROR", error }); // Extract
      if (navigator.userAgent.includes("Firefox")) {
        // Reload tab to ensure indexedDB is cleared since
        // it's delayed to next session in Firefox
        window.location.reload();
      }
    },
    [redirectToLogoutPage]
  );

  const setUserAsAuthenticated = useCallback(
    (user: TUserResponse, defaultCustomerGroup = false) => {
      if (onAuthenticated) {
        onAuthenticated(user, defaultCustomerGroup, (options) =>
          dispatch({ type: "AUTHENTICATED", ...options })
        );
      } else {
        setUserQueryData<TUserResponse>(user);
        dispatch({ type: "AUTHENTICATED" });
      }
    },
    []
  );

  /*
    Initialize DB when logged in.

    NOTE: To prevent race condition issues with delayed deletion
    of indexedDB in Firefox the database is initialized on login.
  */
  useEffect(() => {
    if (state.isAuthenticated) {
      DB.initializeDB();
    }
  }, [state.isAuthenticated]);

  /* On page load refresh token if valid and set user */
  useEffect(() => {
    if (!hasCheckedLoginRef.current) {
      if (tokenIsValid()) {
        hasCheckedLoginRef.current = true;
        refreshRequest<TUserResponse>(true)
          .then((user) => {
            setUserAsAuthenticated(user);
          })
          .catch((error) => {
            forceLogout(error);
          });
      } else {
        dispatch({ type: "LOADED" });
      }
    }
  }, [forceLogout, setUserAsAuthenticated]);

  /* Initiate refresh interval if authenticated and no current interval */
  useEffect(() => {
    if (state.isAuthenticated && !refreshRef.current) {
      refreshRef.current = setInterval(() => {
        if (tokenExpiresInMinutes() < 2) {
          refreshRequest().catch((error) => {
            forceLogout(error);
          });
        }
      }, TOKEN_VALIDATION_INTERVAL);

      /* Clear interval on unmount */
      return () => {
        if (refreshRef.current) {
          clearInterval(refreshRef.current);
          refreshRef.current = undefined;
        }
      };
    }
  }, [state.isAuthenticated, forceLogout]);

  /* Clear interval if logged out */
  useEffect(() => {
    if (!state.isAuthenticated && refreshRef.current) {
      clearInterval(refreshRef.current);
    }
  }, [state.isAuthenticated]);

  /* Update state if another tab logs out */
  useEffect(() => {
    const checkStorage = (event: StorageEvent) => {
      if (event.key === StorageKey.AUTH_TOKEN && !event.newValue) {
        dispatch({ type: "LOGGED_OUT" });
      }
    };
    window.addEventListener("storage", checkStorage);
    return () => window.removeEventListener("storage", checkStorage);
  }, []);

  const login = (data: LoginData, executeOnError?: () => void) => {
    Cache.clearCache();
    hasCheckedLoginRef.current = true;

    loginRequest(data, {
      onSuccess(user) {
        onLoginSuccess?.(user);
        setUserAsAuthenticated(user, true);
        navigateToScreen();
      },
      onError(error) {
        if (executeOnError) {
          executeOnError();
        }
        if (data.isProvider) {
          forceLogout(error);
        } else {
          dispatch({ type: "ERROR", error });
        }
      },
    });
  };

  const logout = () => {
    queryAborter.abortAll();
    logoutRequest()
      .then(() => {
        dispatch({ type: "LOGGED_OUT" });
        Cache.clearCache();
        clearAllQueryData();
        redirectToLogoutPage();
        clearLS();
      })
      .catch((error) => {
        forceLogout(error);
        Messages().addMessage({
          type: "error",
          text: t("error:auth.logoutFail"),
          persistNavigation: true,
          noTimeout: true,
        });
      })
      .finally(() => {
        queryAborter.createNewController();
      });
  };

  /**
   * A function that initiates a request to the api for a one time password.
   * This password is not returned here, but rather sent to the user with another
   * form of communication.
   * @param userName The username for the person that wants a one time password.
   * This username needs to be approved to use this functionality.
   * @param callback An optional parameter, a function that will recieve the success status
   * for the api call as well as the full response or error if needed.
   *
   * @example The callback should look something like this.
   *
   * ```
   * const onRequestOTP = (formData: FormState) => {
   *  queryClient.removeQueries();
   *  getOneTimePassword(formData.username, (success, data) => {
   *    console.log("data from onRequestOTP:", data);
   *    setOtpSuccess(success);
   *  });
   * };
   * ```
   */
  const getOneTimePassword = (
    userName: string,
    callback?: (success: boolean, data?: Response | APIError) => void
  ) => {
    Cache.clearCache();

    oneTimePasswordRequest(
      {
        userName,
        customerGroupId: process.env.REACT_APP_CUSTOMER_GROUP_ID ?? "",
      },
      {
        onSuccess(data) {
          callback?.(data.ok, data);
        },
        onError(error) {
          callback?.(false, error);
        },
      }
    );
  };

  const selectCustomerGroup = (customerGroup: number) => {
    if (customerGroup !== state.currentCustomerGroup) {
      Cache.clearCache();
      dispatch({ type: "SELECT_CUSTOMER_GROUP", customerGroup });
    }
  };

  useAuthenticationEffects?.(state);

  return {
    state,
    isLoggingIn,
    isRequestingOTP,
    login,
    logout,
    getOneTimePassword,
    redirectToLoginPage,
    redirectToLogoutPage,
    navigateToScreen,
    selectCustomerGroup,
  };
}
