import { Backdrop, CircularProgress, Typography } from "common/components";

export interface DownloadBackdropProps {
  open: boolean;
  title: string;
}

export function DownloadBackdrop({ open, title }: DownloadBackdropProps) {
  return (
    <Backdrop
      open={open}
      mountOnEnter
      unmountOnExit
      sx={{
        zIndex: (theme) => theme.zIndex.modal,
        color: (theme) => theme.palette.background.paper,
        display: "grid",
        alignContent: "center",
        placeItems: "center",
        rowGap: (theme) => theme.spacing(2),
      }}
    >
      <CircularProgress color="inherit" />
      <Typography variant="h5">{title}</Typography>
    </Backdrop>
  );
}
