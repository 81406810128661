/**
 * Invoice status code class used for several tasks.
 * 1. To mark Invoice Status on periods
 * 2. To mark status on invoices
 * 3. to mark status on credit notes.
 */
export enum InvoiceStatus {
  // Note, description of these are in Billiant core. The enum order corresponds to the core enum order.
  NOT_INVOICED = "NOT_INVOICED",
  INVOICED = "INVOICED",
  NO_INVOICE_PRODUCED = "NO_INVOICE_PRODUCED",
  INVOICING_IN_PROGRESS = "INVOICING_IN_PROGRESS",
  INVOICE_SCHEDULED_FOR_LATER_RUN = "INVOICE_SCHEDULED_FOR_LATER_RUN",
  NO_INVOICE_MINIMUM_AMOUNT = "NO_INVOICE_MINIMUM_AMOUNT",
  PURCHASE_PENDING = "PURCHASE_PENDING",
  PURCHASE_CANCELLED = "PURCHASE_CANCELLED",
  INVOICE_ERROR = "INVOICE_ERROR",
  WRITE_OFF = "WRITE_OFF",
  INVOICED_MINIMUM_AMOUNT = "INVOICED_MINIMUM_AMOUNT",
  CREDIT_NOTE_REGISTERED = "CREDIT_NOTE_REGISTERED",
  CREDIT_NOTE_APPROVED = "CREDIT_NOTE_APPROVED",
  CREDIT_NOTE_REJECTED = "CREDIT_NOTE_REJECTED",
  CREDIT_NOTE_CREATED = "CREDIT_NOTE_CREATED",
  CREDIT_NOTE_SENT = "CREDIT_NOTE_SENT",
}
