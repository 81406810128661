/**
 * Enum for the possible values of the position property
 * in ActionConfig.ts
 *
 * Corresponds to the ActionPosition.java enum in the REST API.
 */
export enum ActionPosition {
  SCREEN_TOPBAR_ONLY = "SCREEN_TOPBAR_ONLY",
  SCREEN_TOPBAR_AND_VIEW_BODY = "SCREEN_TOPBAR_AND_VIEW_BODY",
  VIEW_TOPBAR_ONLY = "VIEW_TOPBAR_ONLY",
  VIEW_TOPBAR_AND_BODY = "VIEW_TOPBAR_AND_BODY",
  VIEW_BODY_ONLY = "VIEW_BODY_ONLY",
  SCREEN_TOPBAR_AND_WIDGET_BODY = "SCREEN_TOPBAR_AND_WIDGET_BODY",
  WIDGET_TOPBAR_ONLY = "WIDGET_TOPBAR_ONLY",
  WIDGET_TOPBAR_AND_BODY = "WIDGET_TOPBAR_AND_BODY",
  WIDGET_BODY_ONLY = "WIDGET_BODY_ONLY",
}
