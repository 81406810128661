import {
  QueryFunctionContext,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
} from "@tanstack/react-query";
import { APIError, queryAborter, useAPIRequest } from "core/api";
import { IgnoreError, RequestInitWithParams } from "core/api/interfaces";
import { useAuthenticationContext } from "core/auth";
import { useTranslation } from "i18n";

export interface InfiniteAPIQueryOptions<TData, TFunctionData>
  extends UseInfiniteQueryOptions<
    TFunctionData,
    APIError,
    TData,
    TFunctionData
  > {
  excludeCustomerGroup?: boolean;
  /** If cache in IndexedDB should be ignored */
  noCache?: boolean;
  /** Ignore all errors or based on request status codes */
  ignoreError?: IgnoreError;
}

export type InfiniteAPIQueryResult<TData> = UseInfiniteQueryResult<
  TData,
  APIError
>;

export type InfiniteAPIQueryContext<TFunctionData> = QueryFunctionContext<
  InfiniteAPIQueryKey,
  TFunctionData
>;

export type InfiniteAPIQueryKey = any[] | readonly any[];

/**
 * Hook used for Infinite queries; ie. queries that can result
 * in long lists of results where the result should be loaded piecemeal.
 * Contains functionality for pagination.
 *
 * For instance, used in tables to fetch a few pages of data at a time and
 * load more as the user scrolls through the table.
 *
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/useInfiniteQuery TanStack Query | useInfiniteQuery}.
 *
 * @param queryKey  Key used by react-query to store query result. Must include relative URL and parameters
 * @param relativeUrl Relative URL to request in REST API
 * @param requestInit Initialization parameters
 * @param options Options to customize query
 * @returns InfiniteAPIQueryResult, type based on UseInfiniteQueryResult from react-query, see their docs
 *
 * ### Basic usage
 *
 * @example
 *
 * ```
 * function useFindTasks({
 *   enabled = true,
 *   ...options
 * }: FindTasksOptions): InfiniteAPIQueryResult<Page<Task>> {
 *   function requestInitFun(
 *     context: InfiniteAPIQueryContext<FindTasksOptions>
 *   ): RequestInitWithParams {
 *     return {
 *       queryParams: {
 *         search: options.search,
 *         orderBy: options.orderBy,
 *         type: options?.type ? options.type : "OPEN",
 *         ascending: options.ascending,
 *         pageNo: context.pageParam
 *           ? context.pageParam?.pageNo
 *           : options.pageNo ?? 0,
 *         pageSize: options.pageSize,
 *         filters: options.filters,
 *       },
 *       pathParams: {
 *         taskListId: options.taskListId ?? -1,
 *       },
 *     };
 *   }
 *
 *   function nextPage(
 *     lastPage: FindTasksOptions,
 *     _allPages: FindTasksOptions[]
 *   ) {
 *     const pageNo = lastPage.pageNo ?? options.pageNo;
 *     return {
 *       pageNo: pageNo !== undefined ? pageNo + 1 : undefined
 *     };
 *   }
 *
 *   function previousPage(
 *     lastPage: FindTasksOptions,
 *     _allPages: FindTasksOptions[]
 *   ) {
 *     if (lastPage.pageNo && lastPage.pageNo > 1) {
 *       return { pageNo: lastPage.pageNo - 1 };
 *     }
 *     return undefined;
 *   }
 *
 *   return useInfiniteAPIQuery<Page<Task>, FindTasksOptions>(
 *     ["findTasks", options],
 *     options.taskListId ? API.taskservice.taskList.tasks : API.taskservice.tasks,
 *     requestInitFun,
 *     {
 *       enabled: enabled && options.search.length > 0,
 *       getNextPageParam: nextPage,
 *       getPreviousPageParam: previousPage,
 *     }
 *   );
 * }
 * ```
 */
export function useInfiniteAPIQuery<TData, TFunctionData>(
  queryKey: InfiniteAPIQueryKey,
  relativeUrl: string,
  requestInit: (
    data: InfiniteAPIQueryContext<TFunctionData>
  ) => RequestInitWithParams,
  options?: InfiniteAPIQueryOptions<TData, TFunctionData>
): InfiniteAPIQueryResult<TData> {
  const {
    state: { isAuthenticated, currentCustomerGroup },
  } = useAuthenticationContext();
  const { i18n } = useTranslation("common");
  const { requestAPI } = useAPIRequest();
  return useInfiniteQuery<TFunctionData, APIError, TData>(
    [
      ...(Array.isArray(queryKey) ? queryKey : [queryKey]),
      ...(options?.excludeCustomerGroup ? [] : [currentCustomerGroup]),

      i18n.language,
    ],
    (data: InfiniteAPIQueryContext<TFunctionData>) =>
      requestAPI(
        relativeUrl,
        {
          method: "GET",
          credentials: "include",
          mode: "same-origin",
          signal: queryAborter.getSignal(),
          ...requestInit?.(data),
        },
        { noCache: options?.noCache, ignoreError: options?.ignoreError }
      ),
    { ...options, enabled: (options?.enabled ?? true) && isAuthenticated }
  );
}
