import { FontIcon } from "common/assets/icons";
import { Button, IconButton, SxProps, Theme, Tooltip } from "common/components";
import { Link } from "common/routing";
import { RequiredAccess, useAccessLevels, useConfigMode } from "core/auth";
import { MouseEvent, ReactElement } from "react";
import { ActionButton } from ".";
import { StyledActionButtonContainer } from "./ActionButtons.styles";
import { ActionsMenu } from "./ActionsMenu";
import { useActionsMaxCount } from "./useActionsMaxCount";
export type ActionButtonType = "regular" | "icon";

export interface ActionButtonsProps {
  /** Actions to use. */
  actions: ActionButton[];
  /** Max width of actions. Defaults to container width. */
  maxWidth?: number;
  /** Maximum number of actions allowed */
  maxActions?: number;
  sx?: SxProps<Theme>;
}

export function ActionButtons({
  actions,
  maxWidth,
  maxActions,
  sx,
}: ActionButtonsProps): ReactElement | null {
  const { containerRef, maxCount } = useActionsMaxCount(
    actions,
    maxWidth,
    maxActions
  );
  const { hasAccess } = useAccessLevels(undefined);

  const accessFilter = (
    requiredAccess: RequiredAccess | undefined,
    visible: boolean | undefined
  ): boolean => {
    return (
      (visible === undefined || visible) &&
      (!requiredAccess || hasAccess(requiredAccess))
    );
  };

  const { state: configModeEnabled } = useConfigMode();

  const accessableActions = actions.filter(({ requiredAccess, visible }) =>
    accessFilter(requiredAccess, visible)
  );
  if (accessableActions.length === 0) {
    return null;
  }
  // The maxCount first actions are displayed as large action <Buttons>
  // The rest are displayed in a drop-down menu <ActionsMenu>
  return (
    <StyledActionButtonContainer
      data-cy="actionButtonContainer"
      ref={containerRef}
      sx={sx}
    >
      {accessableActions
        .slice(0, maxCount)
        .map(
          (
            {
              buttonType,
              label,
              Icon,
              icon,
              variant,
              color,
              size,
              href,
              onClick,
              disabled,
              actionId,
            },
            index
          ) => {
            const ButtonIcon =
              icon !== undefined
                ? (props: JSX.IntrinsicAttributes) => (
                    <FontIcon icon={icon} {...props} />
                  )
                : Icon;

            const commonButtonProps = {
              LinkComponent: href ? Link : undefined,
              to: href,
              color: color ?? "primary",
              onClick,
              disabled,
            };

            const handleRightClick = (e: MouseEvent) => {
              if (configModeEnabled) {
                e.preventDefault();
                navigator.clipboard.writeText("" + actionId);
              }
            };

            if (buttonType === "icon" && ButtonIcon) {
              return (
                <Tooltip key={label + "_" + index} title={label}>
                  <span>
                    <IconButton
                      {...commonButtonProps}
                      data-cy={"action_icon" + label}
                      aria-label={label}
                      size="large"
                    >
                      <ButtonIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              );
            }
            return (
              <Button
                key={label + "_" + index}
                {...commonButtonProps}
                data-cy={"actionButton" + label.replace(/[\s\\/]/g, "")}
                variant={variant ?? "contained"}
                size={size ?? "small"}
                startIcon={ButtonIcon && <ButtonIcon />}
                sx={{ minWidth: "fit-content" }}
                aria-haspopup
                onContextMenu={handleRightClick}
              >
                {/* Actionbuttons were deemed unlikely to receive additional edits, hence
                  the config mode modification is done directly to the label instead of adding
                  another component. This also means the config mode enabled check has to be done here. 
                  Config mode also provides the ability to right click enabled actionbuttons (doesn't
                  work on disabled, grey'd out, buttons) to copy their displayed actionId*/}

                {configModeEnabled
                  ? label + " (A.Id: " + actionId + ")"
                  : label}
              </Button>
            );
          }
        )}
      {maxCount < accessableActions.length && (
        <ActionsMenu actions={accessableActions.slice(maxCount)} />
      )}
    </StyledActionButtonContainer>
  );
}
