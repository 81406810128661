import { styled } from "@mui/material/styles";

export const StyledIconCardGridNav = styled("nav", {
  shouldForwardProp: (prop) => prop !== "width",
})<StyleProps>(({ theme, width }) => ({
  gridTemplateColumns: getTemplateColumns(width),
  display: "grid",
  gap: theme.spacing(1),
  "& > *": {
    minWidth: "10rem",
  },
}));

export interface StyleProps {
  width: number;
}

function getTemplateColumns(width: number): string {
  switch (true) {
    case width <= 400:
      return "repeat(1, 1fr)";
    case width <= 600:
      return "repeat(2, 1fr)";
    case width <= 720:
      return "repeat(3, 1fr)";
    case width <= 900:
      return "repeat(4, 1fr)";
    case width <= 1500:
      return "repeat(5, 1fr)";
    default:
      return "repeat(6, 1fr)";
  }
}

export {};
