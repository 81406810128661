import { AvailabilityStatus } from "..";

export interface DocumentType {
  version: number;
  documentTypeId: number;
  name: string;
  description: string;
  printerQueueId: number;
  printerQueue: string;
  documentGroupId: number;
  documentGroup: string;
  documentStoreId: number;
  documentStore: string;
  defaultType: boolean;
  availabilityStatus: AvailabilityStatus;
}

export const DocumentTypeDefaults: DocumentType = {
  documentTypeId: -1,
  name: "",
  description: "",
  printerQueueId: -1,
  printerQueue: "",
  defaultType: false,
  documentGroupId: -1,
  documentGroup: "",
  documentStoreId: -1,
  documentStore: "",
  version: -1,
  availabilityStatus: AvailabilityStatus.IN_USE,
};
