import { Typography } from "common/components";
import {
  ProductPartsConfigurationFlowData,
  ProductPartsConfigurationFlowStep,
} from "core/api";
import { useTranslation } from "i18n";
import React, { ReactElement } from "react";
import { FlowStepComponentProps } from "../models";
import { StyledStepContentWrapper } from "../steps.styles";
import { CardinalityRowInput } from "./CardinalityRowInput";
import { CardinalityRowSelectInput } from "./CardinalityRowSelectInput";
import { StyledHeaders } from "./ProductPartsConfiguration.styles";

export type ProductPartsConfigurationProps = FlowStepComponentProps<
  ProductPartsConfigurationFlowStep,
  ProductPartsConfigurationFlowData
>;

/** Product Offering Configuration step component */
export function ProductPartsConfiguration({
  data,
  flowStep,
}: ProductPartsConfigurationProps): ReactElement {
  const { cardinalityRowList } = data;
  const { t } = useTranslation("product");

  return (
    <StyledStepContentWrapper>
      <StyledHeaders>
        <Typography textAlign="center" variant="h6" marginLeft={1}>
          {t("product:productPart")}
        </Typography>
        <Typography textAlign="left" variant="h6" width={"35%"} marginRight={1}>
          {t("product:numberOfParts")}
        </Typography>
      </StyledHeaders>
      {cardinalityRowList.map((rowData) => (
        <React.Fragment key={rowData.rowId}>
          {Array.isArray(rowData.oneOf) ? (
            //  Render a cardinality row with a Select if `rowData.oneOf` is an array.
            <CardinalityRowSelectInput
              key={rowData.rowId}
              selectionCardinalityRowData={rowData.oneOf}
              startOption={rowData.currentOneOf}
              id={rowData.rowId}
              flowStep={flowStep}
            />
          ) : (
            <CardinalityRowInput
              key={rowData.rowId}
              id={rowData.rowId}
              cardinalityRowData={rowData}
              flowStep={flowStep}
            />
          )}
        </React.Fragment>
      ))}
    </StyledStepContentWrapper>
  );
}
