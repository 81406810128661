import { FormSlider, Typography } from "common/components";
import { addFlowStepPrefix } from "common/utils";
import { ProductPartsConfigurationFlowStep } from "core/api";
import { CardinalityRow } from "core/api/models/CardinalityRow";
import { ReactElement, useState } from "react";
import { StyledCardinalityRow } from "./CardinalityRowInput.styles";

export interface CardinalityRowInputProps {
  /** The unique identifier for the CardinalityRow */
  id: number;
  /** The flow step associated with the product offering configuration */
  flowStep: ProductPartsConfigurationFlowStep;
  /** The data for the CardinalityRow */
  cardinalityRowData: CardinalityRow;
}
/**
 * A component with a row of cardinality information for a product offering
 * with the title and a Form slider.
 */
export function CardinalityRowInput({
  id,
  cardinalityRowData,
  flowStep,
}: CardinalityRowInputProps): ReactElement {
  const { currentValue, minValue, maxValue, productOffering } =
    cardinalityRowData;
  const [sliderValue, setSliderValue] = useState(currentValue);

  /**
   * Handles the change event of a slider by updating the slider value
   *
   * @param newValue The new value of the slider.
   */
  const handleSliderChange = (newValue: number) => {
    setSliderValue(newValue);
  };

  return (
    <StyledCardinalityRow data-cy={"cardinalityRow"} tabIndex={0}>
      <Typography textAlign="center" marginLeft={1}>
        {cardinalityRowData.productOffering?.name}
      </Typography>
      <FormSlider
        key={id}
        minValue={minValue}
        maxValue={maxValue}
        currentValue={currentValue}
        fieldName={addFlowStepPrefix(flowStep, "formSlider_", id.toString())}
        defaultValue={{
          name: productOffering?.name,
          currentValue: sliderValue,
        }}
        onChange={handleSliderChange}
      />
    </StyledCardinalityRow>
  );
}
