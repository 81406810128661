import { styled } from "@mui/material/styles";
import { CardContent, Container } from "common/components";
import { Link } from "common/routing";

export const LoginContainer = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  width: "100%",
  height: "100vh",
  display: "grid",
  placeItems: "center",
}));

export const StyledContainer = styled(Container)(() => ({
  display: "grid",
  placeContent: "center",
  link: {
    textDecoration: "none",
  },
}));

export const StyledCardContent = styled(CardContent)(({ theme }) => ({
  display: "grid",
  rowGap: theme.spacing(3),
  placeItems: "center",
}));

export const StyledLink = styled(Link)(() => ({ textDecoration: "none" }));

export const StyledImg = styled("img")(() => ({
  width: "86px",
  height: "auto",
}));
