import { formatFormDatePickerValue } from "common/components";
import { CreatePaymentFlowData, CreatePaymentFlowStep } from "core/api";
import { StepDataResolver } from "../models";

/**
 *  Create payment data resolver
 *
 */
export const createPaymentDataResolver: StepDataResolver<
  CreatePaymentFlowStep,
  CreatePaymentFlowData
> = ({ data, fieldValues }) => {
  return {
    paymentReference: fieldValues.paymentReference ?? data.paymentReference,
    paymentDate: fieldValues.paymentDate
      ? formatFormDatePickerValue(fieldValues.paymentDate)
      : data.paymentDate,
    paidAmount: fieldValues.paidAmount ?? data.paidAmount,
    paymentType:
      data.applicablePaymentTypes.find(
        (payment) => payment.id === fieldValues.paymentType
      ) ?? data.paymentType,
  };
};
