import { formatFormDatePickerValue } from "common/components";
import { BindsFlowData, BindsFlowStep } from "core/api";
import { StepDataResolver } from "../models";

/**
 * binds data resolver
 */
export const bindsDataResolver: StepDataResolver<
  BindsFlowStep,
  BindsFlowData
> = ({ data, fieldValues }) => {
  return {
    adjustmentDate: fieldValues.adjustmentDate
      ? formatFormDatePickerValue(fieldValues.adjustmentDate)
      : data.adjustmentDate,
    supressFees: fieldValues.supressFees ?? data.supressFees,
    overrideBinds: fieldValues.overrideBinds ?? data.overrideBinds,
  };
};
