/**
 * Address sub type code class.
 * <p>
 *  Corresponds to the AddressTarget enum in Billiant APIs.
 */
export enum AddressTarget {
  OTHER = "OTHER",
  INVOICE = "INVOICE",
  LEGAL = "LEGAL",
  LEGAL_AND_INVOICE = "LEGAL_AND_INVOICE",
}
