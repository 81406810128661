import { SvgIconProps } from "common/components";
import {
  CheckBoxOutlineBlankOutlined,
  CheckBoxOutlined,
} from "common/assets/icons";
import { ReactElement } from "react";

export interface CheckboxIconProps extends SvgIconProps {
  checked?: boolean;
}

export function CheckboxIcon({
  checked,
  ...props
}: CheckboxIconProps): ReactElement {
  const Icon = checked ? CheckBoxOutlined : CheckBoxOutlineBlankOutlined;
  return (
    <Icon
      {...props}
      sx={{ color: (theme) => theme.palette.grey[800], ...props.sx }}
    />
  );
}
