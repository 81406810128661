import { omit } from "lodash";
import { useEffect } from "react";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { ShortcutConfig, ShortcutsSettings } from ".";

export type ShortcutsState = Record<string, ShortcutConfig>;

interface ShortcutsStateStore {
  shortcuts: ShortcutsState;
  /**
   * Adds shortcuts to global state
   * @param shortcuts Shortcuts to add
   */
  addShortcuts<IKey extends string>(shortcuts: ShortcutsSettings<IKey>): void;
  /**
   * Removes shortcuts from global state
   * @param shortcuts Shortcuts to remove
   */
  removeShortcuts<IKey extends string>(
    shortcuts: ShortcutsSettings<IKey>
  ): void;
}

/** Global shortcuts state */
const useShortcutsStore = create<ShortcutsStateStore>()(
  devtools((set) => ({
    shortcuts: {},
    addShortcuts: (shortcuts) => {
      set((state) => ({
        ...state,
        shortcuts: { ...state.shortcuts, ...shortcuts },
      }));
    },
    removeShortcuts: (shortcuts) => {
      set((state) => ({
        ...state,
        shortcuts: omit(state.shortcuts, Object.keys(shortcuts)),
      }));
    },
  }))
);

/**
 * Subscribes to global shortcuts state
 * @returns Currently available keyboard shortcuts
 */
export function useShortcutsSubscriber() {
  return useShortcutsStore((state) => state.shortcuts);
}

/**
 * Adds keyboard shortcuts to global state and
 * removes them on unmount
 * @param shortcuts Keyboard shortcuts settings
 * @param enabled Set to false to disable this hook. The hook is enabled by default
 */
export function useAddGlobalShortcuts<IKey extends string>(
  shortcuts: ShortcutsSettings<IKey>,
  enabled = true
) {
  const { addShortcuts, removeShortcuts } = useShortcutsStore(
    ({ addShortcuts, removeShortcuts }) => ({ addShortcuts, removeShortcuts })
  );
  useEffect(() => {
    if (enabled) {
      addShortcuts(shortcuts);
    }

    return () => {
      if (enabled) {
        removeShortcuts(shortcuts);
      }
    };
  }, [addShortcuts, enabled, removeShortcuts, shortcuts]);
}
