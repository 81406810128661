import {
  FormCheckBox,
  FormCurrencyField,
  FormDatePicker,
  FormTextField,
} from "common/components";
import { addFlowStepPrefix } from "common/utils";
import { RepaymentFlowData, RepaymentFlowStep } from "core/api";
import { useTranslation } from "i18n";
import { FlowStepComponentProps } from "../models";
import { StyledStepContentWrapper } from "../steps.styles";

export type RepaymentProps = FlowStepComponentProps<
  RepaymentFlowStep,
  RepaymentFlowData
>;
/** Repay a payment step component */
export function Repayment({ control, data, flowStep }: RepaymentProps) {
  const { t } = useTranslation(["payment"]);
  const {
    repaymentDate,
    repaymentAmount,
    currencyCode,
    paymentAmount,
    remainingAmount,
    message,
    approved,
    allowedToApprove,
  } = data;

  return (
    <StyledStepContentWrapper sx={{ gridTemplateColumns: "repeat(2, 1fr)" }}>
      <FormCurrencyField
        fieldName={addFlowStepPrefix(flowStep, "paymentAmount")}
        defaultValue={paymentAmount}
        currencyCode={currencyCode}
        control={control}
        allowNegative={false}
        label={t("payment:repayment.paymentAmount")}
        sx={{ gridColumn: "1 / span 1" }}
        disabled={true}
      />
      <FormCurrencyField
        fieldName={addFlowStepPrefix(flowStep, "remainingAmount")}
        defaultValue={remainingAmount}
        currencyCode={currencyCode}
        control={control}
        allowNegative={false}
        label={t("payment:repayment.remainingAmount")}
        disabled={true}
      />
      <FormCurrencyField
        fieldName={addFlowStepPrefix(flowStep, "repaymentAmount")}
        defaultValue={repaymentAmount}
        control={control}
        currencyCode={currencyCode}
        label={t("payment:repayment.repaymentAmount")}
        required
        allowNegative={false}
      />
      <FormDatePicker
        fieldName={addFlowStepPrefix(flowStep, "repaymentDate")}
        defaultValue={repaymentDate}
        control={control}
        label={t("payment:repayment.repaymentDate")}
        required
      />
      <FormCheckBox
        control={control}
        fieldName={addFlowStepPrefix(flowStep, "approved")}
        label={t("payment:repayment.approvedRepayment")}
        defaultValue={approved}
        disabled={!allowedToApprove}
      />
      <FormTextField
        fieldName={addFlowStepPrefix(flowStep, "message")}
        defaultValue={message}
        control={control}
        label={t("payment:repayment.message")}
        sx={{ gridColumn: "1 / span 2" }}
        required
      />
    </StyledStepContentWrapper>
  );
}
