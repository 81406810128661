import {
  API,
  APIQueryResult,
  getAPIQueryData,
  setAPIQueryData,
  useAPIQuery,
  User,
} from "core/api";

export const CURRENT_USER_QUERY_KEY = "currentUser";

/**
 * Fetch UserResponse object
 * @param enabled Directive for useQuery.
 * @returns a complete UserResponse
 */
export function useFetchCurrentUser(enabled = true): APIQueryResult<User> {
  return useAPIQuery<User>(
    [CURRENT_USER_QUERY_KEY],
    API.userservice.currentUser,
    {},
    { enabled }
  );
}

export function getCurrentUserData() {
  return getAPIQueryData<User>([CURRENT_USER_QUERY_KEY], true);
}

export function setCurrentUserData(user: User) {
  setAPIQueryData<User>([CURRENT_USER_QUERY_KEY], user, true);
}
