import { ViewType } from "core/api";
import { lazy, LazyExoticComponent } from "react";

/**
 * Lazily imports view.
 * @param viewType Type of view to import
 * @returns View component
 */
export function importView(viewType: ViewType) {
  if (viewType in views) {
    return views[viewType];
  }
  return lazy(() => import("screens/views/NullView"));
}

export const views: Record<string, LazyExoticComponent<any>> = {
  Dashboard: lazy(() => import("screens/views/DashboardView")),
  ProductOfferingOptions: lazy(
    () => import("screens/views/ProductOfferingOptionsView")
  ),
  ChangePaymentMethod: lazy(
    () => import("screens/views/ChangePaymentMethodView")
  ),
};
