import { useLocation } from "common/routing";
import { createContext, useContext } from "react";

export interface ScreenLevelContextValue {
  firstLevelPath?: string;
}

export const ScreenLevelContext = createContext<ScreenLevelContextValue>({});

export function useScreenLevelContext() {
  return useContext(ScreenLevelContext);
}

export function useFirstScreenLevel() {
  const { pathname } = useLocation();
  const { firstLevelPath } = useScreenLevelContext();
  const firstLevel = pathname === firstLevelPath;

  return { firstLevel, firstLevelPath };
}
