import { Box } from "common/components";
import {
  BaseActionFlowSettings,
  CreateFlowParams,
  FlowData,
  FlowStep,
  StepMessage,
  useFetchActionFlow,
} from "core/api";
import { EditGeneral, IdType } from "core/components";
import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { StepDetails } from "./ActionDialog/components";
import { getCurrentData, insertStepValues } from "./flowUtils";
import { StepperDirection } from "./models";
import { getStepComponent } from "./steps";
import { StyledStepComponentWrapper } from "./steps/steps.styles";
import { useCancelFlow } from "./useCancelFlow";
import { useCurrentStep } from "./useCurrentStep";
import { useStepEvaluation } from "./useStepEvaluation";
import { useSubmitFlow } from "./useSubmitFlow";
export interface ActionFlowSettings
  extends BaseActionFlowSettings,
    CreateFlowParams {
  /** Function for closing dialog */
  closeDialog?(): void;
}

/**
 * Manages action flow state
 *
 * Returns state, functions and components needed to create
 * a {@link Flow} stepper/form.
 *
 * @param flowSettings
 * @param options
 * @returns Action flow state
 */

export function useActionFlow({
  actionId,
  entityType,
  entityId,
  screenId,
  viewId,
  widgetId,
  invokedFromTable,
  closeDialog,
}: ActionFlowSettings) {
  const params = { screenId, viewId, widgetId, invokedFromTable };
  const { data, fetchStatus, ...queryResult } = useFetchActionFlow({
    actionId,
    entityType,
    entityId,
    params,
  });
  // Last performed navigation direction in stepper
  const [direction, setDirection] = useState<StepperDirection>("forward");
  const [apiErrorMessage, setApiErrorMessage] = useState<string>();
  const {
    currentStep,
    currentStepId,
    currentSubStep,
    currentSubStepId,
    setCurrentStepId,
    setCurrentSubStepId,
  } = useCurrentStep(data);
  const { control, register, getValues, setValue, setError, ...formResult } =
    useForm({
      mode: "all",
    });

  /** If any field in the form has been touched */
  const isFormTouched =
    Object.keys(formResult.formState.touchedFields).length > 0;

  const { onDialogClose, closeFlowDialog, CancelConfirmDialog } = useCancelFlow(
    {
      actionId,
      data,
      currentStepId,
      isFormTouched,
      closeDialog,
      getValues,
      removeData: queryResult.remove,
    }
  );

  const { onSubmit, performState } = useSubmitFlow({
    actionId,
    entityType,
    entityId,
    params,
    data,
    currentStepId,
    closeDialog: closeFlowDialog,
    setErrorMessage: setApiErrorMessage,
  });
  const { evaluateStep, evaluateOnChange, evaluateState } = useStepEvaluation({
    actionId,
    currentStep,
    currentSubStep,
    currentStepId,
    currentSubStepId,
    direction,
    data,
    getValues,
    params,
  });

  /** Index of current step */
  const activeStepIndex = data?.steps.findIndex(
    ({ id }) => id === currentStepId
  );
  /** If there's a step after current step */
  const hasNextStep =
    currentStepId && (activeStepIndex ?? 0) < (data?.steps?.length ?? 0) - 1;
  /** If there's a step before current step */
  const hasPreviousStep = (activeStepIndex ?? 0) > 0;

  /**
   * Go forward to next step.
   * Triggers form validation and blocks navigation on errors.
   * If the step should evaluate on leave the next state is decided
   * by the server.
   */
  const nextStep = async () => {
    const isValid = await formResult.trigger();
    if (hasNextStep && data && activeStepIndex !== undefined && isValid) {
      const updatedFlow = insertStepValues(data, getValues(), currentStepId);
      data.data = updatedFlow.data;

      if (shouldEvaluteOnLeave(currentStep, currentSubStepId)) {
        evaluateStep("LEAVE", data);
      } else {
        setCurrentStepId(data.steps[activeStepIndex + 1].id);
      }
      setDirection("forward");
    }
  };

  /**
   * Go back to previous step.
   * Skips steps that inject other steps and clears form errors
   * to avoid blocking navigation forward again.
   */
  const previousStep = async () => {
    if (hasPreviousStep && data && activeStepIndex !== undefined) {
      const stepId = data.steps
        .slice(0, activeStepIndex)
        .reverse()
        .find(({ injectSteps }) => !injectSteps)?.id;
      if (stepId) {
        formResult.clearErrors();
        setCurrentStepId(stepId);
        setDirection("back");
      }
    }
  };

  /** Flow data for current step */
  const stepProps = useMemo(
    () => getCurrentData(data, currentStepId),
    [data, currentStepId]
  );

  /** Displays general information about the current step */
  const StepHeader = () => {
    if (currentStep) {
      return (
        <Box data-cy="stepHeader">
          <EditGeneral idType={IdType.Flow} id={data?.flowId} />
          <EditGeneral idType={IdType.Step} id={currentStep.stepId} />
          <StepDetails
            title={currentStep.name}
            hint={currentStep.hint}
            description={currentStep.description}
            icon={currentStep.icon}
            messages={stepProps?.messages}
            apiError={apiErrorMessage}
            showStepTitle={data?.config?.showStepTitle}
          />
        </Box>
      );
    }
    return null;
  };

  interface StepComponentProps {
    flowStep: FlowStep;
    stepData: FlowData;
  }

  /**
   * Renders a step component based on config.
   * Use externally when rendering multiple steps at the same time.
   */
  const StepComponent = useCallback(
    ({ flowStep, stepData }: StepComponentProps) => {
      const Component = getStepComponent(flowStep.uiComponent);

      if (!data) {
        return null;
      }

      return (
        <StyledStepComponentWrapper data-cy="stepComponent">
          {/*
            Render a hidden field that unregisters itself when not rendered. Helps to make sure
            the isValid state is up to date even when going back to a previous step. It does not
            update by using the trigger function in this case.
          */}
          <input
            type="hidden"
            {...register(flowStep.id, {
              shouldUnregister: true,
            })}
          />
          <Component
            key={flowStep.id} // Force React to render steps as separate instances
            control={control}
            flow={data}
            flowStep={flowStep}
            data={stepData}
            getValues={getValues}
            isLoading={fetchStatus === "fetching"}
            setValue={setValue}
            setError={setError}
            setCurrentSubStepId={setCurrentSubStepId}
          />
        </StyledStepComponentWrapper>
      );
    },
    [
      data,
      control,
      register,
      getValues,
      setValue,
      setError,
      fetchStatus,
      setCurrentSubStepId,
    ]
  );

  /**
   * Renders the current step.
   * Use this when only showing one step (sub steps excluded) at a time.
   */
  const StepContent = useCallback(() => {
    if (currentStep && stepProps) {
      return <StepComponent flowStep={currentStep} stepData={stepProps} />;
    }
    return null;
  }, [currentStep, stepProps, StepComponent]);

  const hasDisableFlowButtons = checkForDisableFlowButtons(stepProps?.messages);

  return {
    ...queryResult,
    data,
    fetchStatus,
    form: { control, register, getValues, setValue, ...formResult },
    isFormTouched,
    currentStep,
    setCurrentStepId,
    stepProps,
    activeStepIndex,
    hasNextStep,
    hasDisableFlowButtons,
    hasPreviousStep,
    evaluateState,
    evaluateOnChange,
    performState,
    apiErrorMessage,
    nextStep,
    previousStep,
    onSubmit,
    onDialogClose,
    setCurrentSubStepId,
    StepHeader,
    StepContent,
    StepComponent,
    CancelConfirmDialog,
  };
}

function shouldEvaluteOnLeave(
  flowStep: FlowStep | undefined,
  subStepId: string | undefined
): boolean {
  return (
    !!flowStep?.evalOnLeave ||
    (flowStep?.uiComponent === "SelectStep" &&
      !!flowStep.config.steps.find((step) => step.id === subStepId)
        ?.evalOnLeave)
  );
}

/**
 * Checks if any StepMessage in the provided array has disableFlowButtons set to true.
 *
 * @param messages An array of StepMessage objects or undefined.
 * @returns True if any StepMessage has disableFlowButtons set to true, otherwise false.
 */
function checkForDisableFlowButtons(
  messages?: StepMessage[] | undefined
): boolean {
  if (!messages) {
    return false;
  }
  for (const message of messages) {
    if (message && message.disableFlowButtons === true) {
      return true;
    }
  }
  return false;
}
