import { usePathQuery } from "common/routing";
import { convertFiqlUrltoArray, formatToFIQL } from "common/utils";
import { FilterValueStates, SearchFilter } from "core/api";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

export interface UseSearchFiltersOutputProps {
  /** The current state of the filter search query (to include in the api url) */
  filters: string | string[] | undefined;
  /** Function to set The current state of the filter search string */
  setFilters: Dispatch<SetStateAction<string | string[] | undefined>>;
  /** TODO [LucaN] understand exactly what this is !!! */
  activeFilterState: FilterValueStates | undefined;
  /** Function that can be used to generate the filter search query that corresponds to a given filter state */
  formatToFilterQuery: (filterStates: FilterValueStates) => string[];
}

export function useSearchFilters(
  /** An array containing all the filter fields shown in the SearchFilterPanel */
  filterFields: SearchFilter[],
  /** Set to true to disable the link between the filter state and the search filter query in the api url. Defaults to false. */
  disableUrlParameters = false,
  /** Optional: the initial state of the filters */
  initialFilterStates?: FilterValueStates
): UseSearchFiltersOutputProps {
  const {
    params: { filters: urlFilters },
  } = usePathQuery<"filters">();
  // store the filters in a state so it can be updated without filter URL parameters
  const [filters, setFilters] = useState<string | string[] | undefined>(
    urlFilters
  );

  useEffect(() => {
    if (!disableUrlParameters) {
      setFilters(urlFilters);
    }
  }, [urlFilters, disableUrlParameters]);

  const activeFilterState = useMemo(() => {
    if (filters !== undefined) {
      // add correct fieldType to the filter url before update filter state
      const newFilterState = convertFiqlUrltoArray(filters);

      for (const { filterId, fieldType } of filterFields) {
        if (newFilterState[filterId] !== undefined) {
          newFilterState[filterId].fieldType = fieldType;
        }
      }
      return newFilterState;
    }
  }, [filters, filterFields]);

  const formatToFilterQuery = useCallback(
    (filterStates: FilterValueStates) => formatToFIQL(filterStates),
    []
  );

  useEffect(() => {
    if (
      !disableUrlParameters &&
      initialFilterStates &&
      Object.keys(initialFilterStates).length > 0
    ) {
      setFilters(formatToFilterQuery(initialFilterStates));
    }
  }, [disableUrlParameters, initialFilterStates, formatToFilterQuery]);

  return { filters, setFilters, activeFilterState, formatToFilterQuery };
}
