import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  Typography,
} from "common/components";
import { useTranslation } from "i18n";
import { useEffect } from "react";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { ShortcutKeys } from "../ShortcutKeys";
import { useShortcutsSubscriber } from "../globalState";
import { StyledListItem, StyledListItemText } from "./ShortcutsDialog.styles";

/** Dialog showing all available shortcuts */
export function ShortcutsDialog(): JSX.Element {
  const { t } = useTranslation(["common", "shortcuts"]);
  // NOTE: Disabled for now, might be removed completely
  //const [metaKeyIsDown, setMetaKeyIsDown] = useMetaKeyIsDown();
  const shortcutsState = useShortcutsSubscriber();
  const { open, closeDialog } = useShortcutsDialogStateStore();
  const shortcuts = Object.entries(shortcutsState);

  useEffect(() => {
    if (open && shortcuts.length === 0) {
      closeDialog();
    }
  }, [closeDialog, open, shortcuts]);

  return (
    <Dialog
      open={open && shortcuts.length > 0}
      onClose={closeDialog}
      fullWidth
      maxWidth="xs"
      data-cy="keyboardShortcutsDialog"
    >
      <DialogTitle>{t("common:keyboardShortcuts")}</DialogTitle>
      <DialogContent>
        <Typography variant="h6">
          {t("shortcuts:currentlyAvailable")}
        </Typography>
        <List>
          {shortcuts.map(([key, config]) => (
            <StyledListItem key={key}>
              <StyledListItemText primary={config.label} />
              <ShortcutKeys config={config} />
            </StyledListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>{t("common:buttons.close")}</Button>
      </DialogActions>
    </Dialog>
  );
}

interface DialogStateStore {
  open: boolean;
  openDialog(): void;
  closeDialog(): void;
}

const useShortcutsDialogStateStore = create<DialogStateStore>()(
  devtools((set) => ({
    open: false,
    openDialog: () => set({ open: true }),
    closeDialog: () => set({ open: false }),
  }))
);

export function useShortcutsDialogControls() {
  return useShortcutsDialogStateStore(({ openDialog, closeDialog }) => ({
    openDialog,
    closeDialog,
  }));
}
