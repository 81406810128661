import { ReactElement, ReactNode } from "react";
import { Helmet, HelmetProps } from "react-helmet-async";

export interface HeadProps extends HelmetProps {
  children: ReactNode;
}

export function Head({ children, ...props }: HeadProps): ReactElement {
  return (
    <Helmet
      titleTemplate="%s | Billiant Web"
      defaultTitle="Billiant Web"
      {...props}
    >
      {children}
    </Helmet>
  );
}
