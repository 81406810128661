import {
  API,
  APIQueryOptions,
  getAPIQueryData,
  TableConfig,
  useAPIQuery,
} from "core/api";

/**
 *
 * @param id
 * @param options
 * @returns
 */
export function useFetchTableConfig(
  tableId: number,
  options?: APIQueryOptions<TableConfig>
) {
  return useAPIQuery<TableConfig>(
    ["searchConfig", tableId],
    API.config.search,
    {
      pathParams: {
        searchId: tableId,
      },
    },
    { ...options, enabled: !!tableId && tableId > 0 }
  );
}

export function getTableConfigQueryData(tableId: number) {
  return getAPIQueryData<TableConfig>(["searchConfig", tableId]);
}
