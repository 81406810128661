import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  ErrorMessages,
} from "common/components";
import { ErrorDialogMessage } from "core/message/models";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { StyledAlert, StyledDialogTitle } from "./dialogs.styles";

export interface ErrorDialogProps {
  open: boolean;
  dialogMessages: ErrorDialogMessage[];
  onClose(): void;
  title?: string;
  includeCount?: boolean;
}

/**
 * Dialog component that lists message details.
 *
 * Only has support for fatal server messages.
 */
export function ErrorDialog({
  dialogMessages,
  open,
  onClose,
  title,
  includeCount = true,
}: ErrorDialogProps): ReactElement {
  const { t } = useTranslation(["error", "common"]);
  const dialogTitle =
    title ?? t("error:_500.fatalError.title", { count: dialogMessages.length });
  return (
    <Dialog open={open} maxWidth="lg" data-cy="errorDialog">
      <StyledDialogTitle>
        <StyledAlert variant="filled" severity="error">
          {dialogTitle}
        </StyledAlert>
      </StyledDialogTitle>
      <DialogContent>
        <ErrorMessages
          includeCount={includeCount}
          errorMessages={dialogMessages}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} data-cy="closeErrorDialog">
          {t("common:buttons.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
