export default {
  en: "English",
  enUS: "American English",
  gb: "British English",
  sv: "Svenska",
  da: "Dansk",
  fi: "Suomalainen",
  nb: "Norsk",
  fr: "Français",
  de: "Deutsch",
  lb: "Lëtzebuergesch",
  nlBe: "België Nederlands",
  fo: "Føroyskt",
  es: "Español",
  it: "Italiano",
} as const;
