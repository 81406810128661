import { SortingSettings, StorageKey } from "core/appSettings";
import { SortingSettingsDefault } from "core/appSettings/defaults/SortingSettingsDefault";
import { useCallback } from "react";
import { createRecordStore } from "../store";

export type SortingSettingsState = Record<string, SortingSettings>;

export interface SortingSettingsOptions {
  /** Sorting key */
  key: string;
  /** StorageKey */
  storageKey: StorageKey;
  /** Default value to order by */
  defaultOrderBy?: string;
  /** If default sort order is by ascending or descending */
  defaultAscending?: boolean;
}

export type SortingSettingsResult = {
  /** Sorting settings state */
  sortingSettings: SortingSettings;
  /** Sorting settings state update function */
  updateSettings(settings: Partial<SortingSettings>): void;
};

/** Use Sorting settings. Saves sorting, and pagination settings to localStorage */
export function useSortingSettings({
  key,
  storageKey,
  defaultOrderBy,
  defaultAscending,
}: SortingSettingsOptions): SortingSettingsResult {
  const { state: sortingSettings, updateState } = useSortingSettingsStore(
    storageKey + "_" + key,
    {
      ...SortingSettingsDefault,
      orderBy: defaultOrderBy || "",
      ascending: defaultAscending,
    }
  );

  const updateSettings = useCallback(
    (settings: Partial<SortingSettings>) => {
      updateState((state) => ({
        ...state,
        ...settings,
      }));
    },
    [updateState]
  );

  return { sortingSettings, updateSettings };
}

const useSortingSettingsStore = createRecordStore<SortingSettings>({
  name: StorageKey.SORTING_SETTINGS,
});
