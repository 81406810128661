import { Box, BoxProps } from "common/components";
import { FormProvider, FormProviderProps } from "react-hook-form";

export interface ActionFormProps extends BoxProps {
  /** Form context values */
  context: Omit<FormProviderProps, "children">;
}

/** Action form */
export function ActionForm({ context, ...props }: ActionFormProps) {
  return (
    <FormProvider {...context}>
      <Box component="form" {...props}></Box>
    </FormProvider>
  );
}
