/**
 * Availability status code class.
 * <p>
 *
 *
 * PLANNED The item is not ready to be used, typically configuration is in progress.
 * IN_USE - The item can be used.
 * DISABLED - For Future Use. A "deleted state, only users with admin privileges can see the item in the definition GUI's.
 * CLOSED - The item is visible but cannot be used anymore
 *
 */
/**
 * Name of the enum
 */

export enum AvailabilityStatus {
  PLANNED = "PLANNED",

  /**
   * The item can be used.
   */
  IN_USE = "IN_USE",

  /**
   *  For Future Use. A "deleted state, only users with admin privileges can see the item in the definition GUI's.
   */
  DISABLED = "DISABLED",

  /**
   * The item is visible but cannot be used anymore
   */
  CLOSED = "CLOSED",
}
