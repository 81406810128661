import { useCodeItems } from "core/hooks";
import { CodeObject, Task, TaskStatus } from "core/api";
import { useCallback } from "react";

export function useTaskStatus() {
  const { getCodeName } = useCodeItems();

  const findTaskStatusText = useCallback(
    (taskStatus: TaskStatus) => {
      return getCodeName(CodeObject.TaskStatus, taskStatus);
    },
    [getCodeName]
  );

  const getTaskStatus = useCallback(
    (task: Task) => {
      return {
        status: task?.status,
        reasonText: findTaskStatusText(task?.status),
      };
    },
    [findTaskStatusText]
  );

  return { getTaskStatus };
}
