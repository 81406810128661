import {
  FetchNextPageOptions,
  FetchPreviousPageOptions,
  InfiniteData,
  InfiniteQueryObserverResult,
} from "@tanstack/react-query";
import { APIError, IgnoreError, InfiniteAPIQueryResult, Page } from "core/api";
import { TableItemSearchObject } from "core/components";
import { useEffect } from "react";

export function updateLoadedPages<TData>(
  queryResults: InfiniteData<Page<TData>> | undefined,
  page: number,
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<InfiniteQueryObserverResult<Page<TData>, APIError>>,
  fetchPreviousPage: (
    options?: FetchPreviousPageOptions | undefined
  ) => Promise<InfiniteQueryObserverResult<Page<TData>, APIError>>,
  isFetching: boolean,
  maxPage: number
) {
  let currMinPage = 0;
  let currMaxPage = 0;

  const numPagesFetchAhead = 0;

  if (queryResults && queryResults.pages) {
    if (queryResults.pages[0].pageNo < 0) {
      console.error("ERROR: REST API returned a result set with PageNo < 0");
    } else {
      currMinPage = queryResults.pages[0].pageNo;
    }
    currMaxPage = queryResults.pages[queryResults.pages.length - 1].pageNo;
  }

  if (
    !isFetching &&
    queryResults &&
    queryResults.pages &&
    currMaxPage + 1 <= maxPage &&
    currMaxPage + 1 <= page + numPagesFetchAhead &&
    (currMaxPage + 1) *
      queryResults.pages.filter(
        (pageObj: Page<TData>) => pageObj.pageNo === currMaxPage + 1
      ).length ===
      0
  ) {
    fetchNextPage({
      pageParam: { pageNo: currMaxPage + 1 },
    });
  }

  if (
    !isFetching &&
    queryResults &&
    queryResults.pages &&
    currMinPage - 1 >= page - numPagesFetchAhead &&
    currMinPage - 1 >= 0 &&
    queryResults.pages.filter(
      (pageObj: Page<TData>) => pageObj.pageNo === currMinPage - 1
    ).length === 0
  ) {
    fetchPreviousPage({ pageParam: { pageNo: currMinPage - 1 } });
  }
}

/**
 * Hook used to keep data from an infinite query that returns pages loaded.
 * Loads more pages as the user scrolls through pages.
 * NOTE; This hook will handle updating the page of the filterObject.
 *       To avoid unnecessary reloading, only set filterObject.pageNo to an initial value.
 *       E.g, do not set it to a state variable
 * @param useQuery Query used to fetch the data
 * @param filterObject Object containing search parameters etc. for query
 * @param updateCurrentItems Function used by hook to send back which items should be displayed for current page
 * @param page Current page
 * @param pageSize Current pageSize
 */
export function useLoadPagedInfiniteQuery<
  TData,
  TFilterObject extends TableItemSearchObject
>(
  useQuery: (filter: TFilterObject) => InfiniteAPIQueryResult<Page<TData>>,
  filterObject: TFilterObject,
  page: number,
  pageSize: number,
  /** Ignore all errors or based on request status codes */
  ignoreError: IgnoreError
) {
  const {
    data: queryResults,
    fetchNextPage,
    fetchPreviousPage,
    isFetching,
    ...rest
  } = useQuery(filterObject);

  const totalCount = queryResults?.pages?.[0]?.totalCount ?? 0;
  const maxPage = Math.ceil(totalCount / pageSize) - 1;
  const currPage = queryResults?.pages.find((value: Page<TData>) => {
    return value.pageNo === page;
  });
  const currentPageItems = currPage?.items ?? [];

  //Update available items
  //when result of query is updated
  useEffect(() => {
    if (queryResults && queryResults.pages.length > 0) {
      updateLoadedPages(
        queryResults,
        page,
        fetchNextPage,
        fetchPreviousPage,
        isFetching,
        maxPage
      );
    }
  }, [
    queryResults,
    page,
    fetchNextPage,
    fetchPreviousPage,
    filterObject,
    isFetching,
    maxPage,
  ]);

  return { currentPageItems, totalCount, ignoreError, isFetching, ...rest };
}
