import { ScreenConfig, useFetchEntityQuery } from "core/api";
import { formatEntityUrl } from "core/components";

export function useScreenEntity(
  config: ScreenConfig | undefined,
  params: Record<string, any>
) {
  return useFetchEntityQuery({
    enabled: !!config?.entityFetchUrl,
    apiUrl: formatEntityUrl(
      params,
      config?.entityFetchUrl ?? "",
      getParamKeys(config)
    ),
    ids: getParamIds(config, params),
    entityType: config?.entityType,
  });
}

function getParamKeys(config: ScreenConfig | undefined): string[] {
  if (config?.entityIdPathParam && config?.childIdPathParam) {
    return [config.entityIdPathParam, config.childIdPathParam];
  } else if (config?.entityIdPathParam) {
    return [config.entityIdPathParam];
  } else {
    return [];
  }
}

function getParamIds(
  config: ScreenConfig | undefined,
  params: Record<string, string>
): string[] | undefined {
  if (config?.entityIdPathParam && config?.childIdPathParam) {
    return [params[config.entityIdPathParam], params[config.childIdPathParam]];
  } else if (config?.entityIdPathParam) {
    return [params[config.entityIdPathParam]];
  } else {
    return undefined;
  }
}
