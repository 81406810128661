import { lighten, styled } from "common/components";

export const StyledCardinalityRow = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderBottom: `1px solid ${theme.palette.divider}`,
  "&:focus": {
    backgroundColor: lighten(theme.palette.primary.main, 0.7),
  },
  "&:focus:hover": {
    backgroundColor: lighten(theme.palette.primary.main, 0.8),
  },
}));
