import { StatusIcon } from "common/assets/icons";
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  FilterOptionsState,
  ListItem,
  StyledListItemText,
  StyledSelectContainer,
  TextField,
} from "common/components";
import { Icon } from "core/api";
import { ReactElement, useRef } from "react";

/**
 * Props for the single select, almost identical to the existing MultiSelectProps except value type.
 */
interface SingleSelectProps<T> {
  /**
   * The field label
   */
  label: string;
  /**
   * OonChange function to call then selection changes.
   */
  onChange(
    event: React.SyntheticEvent,
    value: T,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<T> | undefined
  ): void;
  /**
   * Component id
   */
  id?: string;
  /**
   * Current value
   */
  value?: T;
  /**
   * Selectable values
   */
  options: readonly T[];
  /**
   * Function to retrieve the groupBy name from T
   */
  groupBy?(option: T): string;
  /**
   * Function to retrieve the id from T
   */
  getOptionId(option: T): string;
  /**
   * Function to retrieve the name from T
   */
  getOptionName(option: T): string;
  /**
   * Function to retrieve the icon name from T
   */
  getIcon?(option: T): Icon | undefined;

  /**
   * Function to match the text written in the input field with T items.
   */
  filterOptions?(options: T[], state: FilterOptionsState<T>): T[];
}

/**
 * Single-selection combobox based on the MUI Autocomplete component
 *
 * @remarks A special SingleSelect is needed since the multiple prop to the AutoComplete change
 * types of input resulting is difficult code. For a cleaner interface this is an intended
 * duplication of the MultiSelect. Also, rendering is done without checkboxes and chips since it is a single select.
 */
export function SingleSelect<T>({
  label,
  onChange,
  id,
  value,
  options,
  groupBy,
  getOptionId,
  getOptionName,
  getIcon,
  filterOptions,
  ...props
}: Readonly<SingleSelectProps<T>>): ReactElement {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <StyledSelectContainer>
      <Autocomplete
        onChange={onChange}
        clearOnEscape
        multiple={false}
        id={id}
        groupBy={groupBy}
        filterOptions={filterOptions}
        value={value ?? null}
        options={options}
        defaultValue={null}
        handleHomeEndKeys
        clearOnBlur
        size="small"
        renderOption={(props, option) => {
          const optionName = getOptionName(option);
          const icon = getIcon?.(option);
          // key must be AFTER ...props to overwrite the key that is contained in ...props
          return (
            <ListItem {...props} key={getOptionId(option)}>
              {icon && (
                <StatusIcon
                  iconName={icon.name}
                  iconVariant={icon.variant}
                  sx={{
                    marginRight: 0.5,
                    display: "grid",
                    alignItems: "center",
                  }}
                />
              )}
              <StyledListItemText
                primary={optionName}
                data-cy={optionName?.replace(/ |\./g, "")} // Suppress all spaces or '.' that might occur in the optionName
                primaryTypographyProps={{ variant: "body1" }}
              />
            </ListItem>
          );
        }}
        sx={{ minWidth: 250 }}
        getOptionLabel={getOptionName}
        renderInput={(params) => (
          <TextField ref={ref} {...params} label={label} />
        )}
        {...props}
      />
    </StyledSelectContainer>
  );
}
