import { ListItem, ListItemText } from "common/components";
import { convertBooleanToText, formatCurrency } from "common/utils";
import { FlowData, RepaymentFlowStep } from "core/api";
import { useTranslation } from "i18n";
import { Fragment } from "react";

export interface RepaymentConfirmationProps {
  flowData: FlowData;
  step: RepaymentFlowStep;
}

/**
 * Sub component to confirmation. The purpose of this component is
 * to make the main Confirmation component more readable,
 * abstracting some of the logic into smaller sub components.
 *
 * RepaymentConfirmation is specific to confirmation steps where
 * the flow contains a step with the uiComponent: "Repayment".
 *
 * @param flowData data property from the flow object, this is fetched through
 * const flowData = flow.data[step.id]; in the parent component.
 * @param getValues A helper function from react-hook-form. The function is passed
 * into the parent Confirmation component and in turn passed here.
 *
 * @returns A JSX element with payment details from the previous "Repayment" step in the flow.
 */

export function RepaymentConfirmation({
  flowData,
  step,
}: RepaymentConfirmationProps): JSX.Element {
  const { t } = useTranslation();
  const fieldFlowData =
    flowData.uiComponent === "Repayment" ? flowData : undefined;
  return (
    <Fragment key={step.id}>
      <ListItem>
        <ListItemText
          primary={t("payment:repayment:paymentReference")}
          secondary={fieldFlowData?.paymentReference}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={t("payment:repayment:repaymentAmount")}
          secondary={formatCurrency(
            fieldFlowData?.repaymentAmount || 0,
            fieldFlowData?.currencyCode ?? "?"
          )}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={t("payment:repayment:repaymentDate")}
          secondary={fieldFlowData?.repaymentDate}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={t("payment:repayment:message")}
          secondary={fieldFlowData?.message}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={t("payment:repayment:approvedRepayment")}
          secondary={convertBooleanToText(fieldFlowData?.approved)}
        />
      </ListItem>
    </Fragment>
  );
}
