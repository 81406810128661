import { API, CalendarRange, useAPIQuery } from "core/api";

export interface CalendarSetOptions {
  /** Calendar set id */
  calendarSetId: number | undefined;
  /** Start date in range */
  from: string | undefined;
  /** End date in range */
  to: string | undefined;
}

/** Fetches calendar ranges from calendar set */
export function useFetchCalendarRanges({
  calendarSetId,
  from,
  to,
}: CalendarSetOptions) {
  return useAPIQuery<CalendarRange[]>(
    ["calendarRanges", calendarSetId, from, to],
    API.common.calendars.ranges,
    {
      pathParams: {
        calendarSetId,
      },
      queryParams: {
        from,
        to,
      },
    },
    { enabled: !!calendarSetId && !!from && !!to }
  );
}
