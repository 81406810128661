import {
  API,
  APIQueryResult,
  PaymentMethodData,
  getCurrentUserData,
  useAPIQuery,
} from "core/api";

/**
 * Fetch PaymentMethodData object
 * @param enabled Directive for useQuery.
 * @returns a complete PaymentMethodData
 */
export function useFetchUserPaymentMethod(
  enabled = true
): APIQueryResult<PaymentMethodData> {
  return useAPIQuery<PaymentMethodData>(
    ["userPaymentMethod", getCurrentUserData()?.userId],
    API.userservice.paymentMethod,
    {},
    { enabled }
  );
}
