import { styled } from "@mui/material";
import {
  NavLink as ReactRouterNavLink,
  NavLinkProps as ReactRouterNavLinkProps,
} from "react-router-dom";

export interface NavLinkProps extends ReactRouterNavLinkProps {
  variant?: "filled" | "text";
  fullWidth?: boolean;
}

export const NavLink = styled(ReactRouterNavLink, {
  shouldForwardProp: (propName: string) =>
    !["variant", "fullWidth"].includes(propName),
})<NavLinkProps>(({ theme, variant = "filled", fullWidth = false }) => ({
  "&.active": {
    backgroundColor:
      variant === "filled" ? theme.palette.primary.light : undefined,
    borderRadius: theme.shape.borderRadius,
    "&, span, &:visited": {
      color: theme.palette.primary.main,
    },
  },
  "&:visited": {
    color: "inherit",
  },
  textDecoration: "none",
  width: fullWidth ? "100%" : undefined,
}));
NavLink.defaultProps = {
  className: ({ isActive }) => (isActive ? "active" : undefined),
};
