import { FormDatePicker } from "common/components";
import { addFlowStepPrefix } from "common/utils";
import { ChangeDueDateFlowData, ChangeDueDateFlowStep } from "core/api";
import { useTranslation } from "i18n";
import { FlowStepComponentProps } from "../models";
import { StyledStepContentWrapper } from "../steps.styles";

export type ChangeDueDateProps = FlowStepComponentProps<
  ChangeDueDateFlowStep,
  ChangeDueDateFlowData
>;

/** Change Due Date step component */
export function ChangeDueDate({ control, data, flowStep }: ChangeDueDateProps) {
  const { t } = useTranslation(["invoice,common"]);

  return (
    <StyledStepContentWrapper sx={{ gridTemplateColumns: "repeat(2, 1fr)" }}>
      <FormDatePicker
        fieldName={addFlowStepPrefix(flowStep, "dueDate")}
        defaultValue={data.dueDate}
        control={control}
        label={t("invoice:changeDueDate:dueDate")}
        required
        data-cy={"newDueDateInputField"}
      />
    </StyledStepContentWrapper>
  );
}
