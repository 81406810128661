import { StorageKey } from "core/appSettings";

const _envCustomerGroupId = parseInt(
  process.env.REACT_APP_CUSTOMER_GROUP_ID ?? ""
);

export const envCustomerGroupId = Number.isInteger(_envCustomerGroupId)
  ? _envCustomerGroupId
  : undefined;

export function setLastCustomerGroupId(customerGroupId: number) {
  localStorage.setItem(StorageKey.LAST_CUSTOMER_GROUP, String(customerGroupId));
}

export function getLastCustomerGroupId(): number | undefined {
  const lastCustomerGroupId = localStorage.getItem(
    StorageKey.LAST_CUSTOMER_GROUP
  );
  return lastCustomerGroupId ? parseInt(lastCustomerGroupId) : undefined;
}

export function setCurrentCustomerGroupId(customerGroupId: number) {
  sessionStorage.setItem(StorageKey.CUSTOMER_GROUP, String(customerGroupId));
  setLastCustomerGroupId(customerGroupId);
}

export function getCurrentCustomerGroupId(): number | undefined {
  if (envCustomerGroupId) {
    return envCustomerGroupId;
  }
  const customerGroupId = sessionStorage.getItem(StorageKey.CUSTOMER_GROUP);
  if (!customerGroupId) {
    const lastCustomerGroupId = getLastCustomerGroupId();
    if (lastCustomerGroupId) {
      setLastCustomerGroupId(lastCustomerGroupId);
    }
    return lastCustomerGroupId;
  }
  return parseInt(customerGroupId);
}
