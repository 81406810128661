import {
  FormControl,
  FormSlider,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "common/components";
import { addFlowStepPrefix } from "common/utils";
import { ProductPartsConfigurationFlowStep } from "core/api";
import { CardinalityRow } from "core/api/models/CardinalityRow";
import { useTranslation } from "i18n";
import { ReactElement, useState } from "react";
import { StyledCardinalityRow } from "../CardinalityRowInput/CardinalityRowInput.styles";

interface CardinalityRowSelectInputRowProps {
  /** The data for the selection cardinality rows */
  selectionCardinalityRowData: CardinalityRow[];
  /** The CardinalityRow that is the default option in the select */
  startOption: CardinalityRow | undefined;
  /** The unique identifier for the CardinalityRow */
  id: number;
  /** The flow step associated with the product offering configuration*/
  flowStep: ProductPartsConfigurationFlowStep;
}

/**
 * A component representing a row of cardinality information for product offerings
 * with a selectable dropdown and slider.
 */
export function CardinalityRowSelectInput({
  selectionCardinalityRowData,
  startOption,
  flowStep,
  id,
}: CardinalityRowSelectInputRowProps): ReactElement {
  const { t } = useTranslation("common");
  const [selectedValue, setSelectedValue] = useState<CardinalityRow>(
    startOption ?? selectionCardinalityRowData[0]
  );
  const [sliderValue, setSliderValue] = useState<number>(
    selectedValue?.currentValue ?? 0
  );

  /**
   * Handles the change event for the dropdown selection.
   *
   * @param event The event object for the dropdown selection change.
   */
  const handleSelectChange = (event: SelectChangeEvent) => {
    const selectedProductOffering = selectionCardinalityRowData.find(
      (cardinalityRow) =>
        cardinalityRow.productOffering?.name === event.target.value
    );
    setSelectedValue(selectedProductOffering ?? selectionCardinalityRowData[0]);
    setSliderValue(selectedProductOffering?.currentValue ?? 0);
  };

  /**
   * Handles the change event of the slider by updating the slider value
   * and setting the new value to the `currentValue` property of the `selectedValue` object.
   * This ensures that the latest state is used when updating the state variable.
   * Without this, the value was not always correct when changing both in the select and slider.
   *
   * @param newValue The new value of the slider.
   */
  const handleSliderChange = (newValue: number) => {
    const updatedSelectedValue = { ...selectedValue, currentValue: newValue };
    setSelectedValue(updatedSelectedValue);
    setSliderValue(newValue);
  };

  return (
    <StyledCardinalityRow data-cy={"selectionCardinalityRow"} tabIndex={0}>
      <FormControl>
        <InputLabel id="select-label">{t("common:choose")}</InputLabel>
        <Select
          labelId="select-label"
          value={selectedValue?.productOffering?.name || ""}
          onChange={handleSelectChange}
          label={t("common:choose")}
          data-cy={"select"}
          sx={{ marginLeft: 0.5 }}
        >
          {selectionCardinalityRowData.map((rowData) => (
            <MenuItem
              key={rowData.productOffering?.id}
              value={rowData.productOffering?.name}
              data-cy={rowData.productOffering?.name}
            >
              {rowData.productOffering?.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormSlider
        key={id}
        fieldName={addFlowStepPrefix(flowStep, "formSlider_", id?.toString())}
        minValue={selectedValue?.minValue}
        maxValue={selectedValue?.maxValue}
        currentValue={selectedValue?.currentValue}
        defaultValue={{
          currentValue: sliderValue,
          minValue: selectedValue.minValue,
          maxValue: selectedValue.maxValue,
          productOffering: selectedValue.productOffering,
        }}
        onChange={handleSliderChange}
      />
    </StyledCardinalityRow>
  );
}
