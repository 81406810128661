import { ListItem, ListItemText } from "common/components";
import { convertBooleanToText, formatCurrency, formatDate } from "common/utils";
import { FlowData, FlowStep } from "core/api";
import currency from "currency.js";
import { useTranslation } from "i18n";
import { Fragment } from "react";

export interface CreditInvoiceConfirmationProps {
  step: FlowStep;
  flowData: FlowData;
}

/**
 * Sub component to confirmation. The purpose of this component is
 * to make the main Confirmation component more readable,
 * abstracting some of the logic into smaller sub components.
 *
 * CreditInvoiceConfirmation is specific to confirmation steps where
 * the flow contains a step with the uiComponent: "CreditInvoice".
 *
 * @param flowData data property from the flow object, this is fetched through
 * const flowData = flow.data[step.id]; in the parent component.
 * @param getValues A helper function from react-hook-form. The function is passed
 * into the parent Confirmation component and in turn passed here.
 *
 * @returns A JSX element with invoice details from the previous "CreditInvoice" step in the flow.
 */
export function CreditInvoiceConfirmation({
  step,
  flowData,
}: CreditInvoiceConfirmationProps): JSX.Element {
  const { t } = useTranslation(["common"]);
  const fieldFlowData =
    flowData.uiComponent === "CreditInvoice" ? flowData : undefined;

  const totalAmount = fieldFlowData?.invoiceTotalAmountInclTax ?? 0;
  const maxAmountToCredit = fieldFlowData?.maxAmountToCreditInclTax ?? 0;
  const creditedAmount = fieldFlowData?.creditedAmountInclTax ?? 0;
  const remainingAmount = currency(maxAmountToCredit, {
    increment: 0.5,
  }).subtract(creditedAmount).value;

  return (
    <Fragment key={step.id}>
      <ListItem>
        <ListItemText
          primary={t("invoice:creditInvoice.invoice")}
          secondary={fieldFlowData?.externalInvoiceId}
          data-cy={"confirmCreditedInvoice"}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={t("invoice:creditInvoice.date")}
          secondary={formatDate(fieldFlowData?.creditDate)}
          data-cy={"confirmCreditNoteDate"}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={t("invoice:creditInvoice.totalInvoicedAmount")}
          secondary={formatCurrency(totalAmount, fieldFlowData?.currencyCode!)}
          data-cy={"confirmTotalInvoicedAmount"}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={t("invoice:creditInvoice.creditedAmount")}
          secondary={formatCurrency(
            creditedAmount,
            fieldFlowData?.currencyCode!
          )}
          data-cy={"confirmCreditedAmount"}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={t("invoice:remainingAmount")}
          secondary={formatCurrency(
            remainingAmount,
            fieldFlowData?.currencyCode!
          )}
          data-cy={"confirmRemainingAmount"}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={t("invoice:creditInvoice.creditReason")}
          secondary={`${fieldFlowData?.creditReason.name} | ${fieldFlowData?.creditReason.description}`}
          data-cy={"confirmReasonForCredit"}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={t("invoice:creditInvoice.creditApprove")}
          secondary={convertBooleanToText(fieldFlowData?.approve)}
          data-cy={"confirmApproveCreditNote"}
        />
      </ListItem>
    </Fragment>
  );
}
