export default {
  _404: {
    title: "Sidan kan inte hittas",
    urlNotFound: "URL finns inte",
    notFoundOrNotAvailable:
      "Källan du förfrågade hittas inte eller finns inte längre",
  },
  _500: {
    fatalError: {
      title: "Ett oväntat serverfel har inträffat",
      title_plural: "Flera oväntade serverfel har inträffat",
    },
  },
  backToApplications: "Gå tillbaka till mina applikationer",
  error: "fel",
  error_plural: "fel",
  errorWithCount: "{{count}} fel",
  errorWithCount_plural: "{{count}} fel",
  preventNavigationDialog: {
    title: "Osparade ändringar",
    body: "Du har osparade ändringar. Är du säker på att du vill lämna sidan och kasta dessa?",
    stayOnPage: "Stanna kvar",
    leavePage: "Lämna sidan",
  },
  route: {
    noAccess:
      "Du har inte access till begärd URL {{url}}. Din användarroll förhindrar det.",
  },
  fetchError:
    "Kunde ej ladda widgeten på grund av serverfel (Errorkod {{status}})",
  fields: {
    valueAmountError:
      "Error: Fält {{fieldName}} förväntade sig {{expectedAmount}} värden, fick endast {{actualAmount}}",
  },
  auth: {
    logoutFail:
      "Utloggningsbegäran misslyckades. Rensat lokala autentiseringsuppgifter.",
  },
  emptyQuery: "Fel: Servern returnerade inget värde",
  clientError: "Fel i Webappen: ",
  validationError: "Valideringsfel",
  screenLoadingError:
    "Sidan kunde ej laddas. Detta kan orsakas utav en ogiltig eller utgången URL. Meddelande från server: {{serverMessage}} ",
  screenLoadingError_nomesssage:
    "Sidan kunde ej laddas. Detta kan orsakas utav en ogiltig eller utgången URL.",
  failedToLoadWidget: "Misslyckades med att hämta widget",
} as const;
