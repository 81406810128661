import { useFetchSystemUser } from "core/api";
import { AccessLevel, RequiredAccess, UserAccess } from "core/auth";

export function useAccessLevels(appAccessKey: keyof UserAccess | undefined) {
  const { data: user, isSuccess: userIsLoaded } = useFetchSystemUser();

  /**
   * Check if user has access.
   *
   * @param requiredAccess Key and required minimum level for access
   * @returns If user has access or not
   */
  const hasAccess = (requiredAccess: RequiredAccess): boolean => {
    const userAccess = user?.access;
    if (userAccess) {
      const userAccessLevel =
        userAccess[requiredAccess.key] || AccessLevel.NO_ACCESS;
      const appAccessLevel = appAccessKey && userAccess[appAccessKey];

      const currentAccessLevel =
        appAccessLevel && appAccessLevel.level < userAccessLevel.level
          ? appAccessLevel
          : userAccessLevel;

      return (
        currentAccessLevel.level >= requiredAccess.level &&
        currentAccessLevel.level > AccessLevel.NO_ACCESS
      );
    }

    return false;
  };

  return { hasAccess, userIsLoaded };
}
