/**
 * The type of a history entry.
 */
export enum HistoryEntryType {
  /**
   * Message
   */
  MESSAGE = "MESSAGE",

  /**
   * Creation
   */
  CREATION = "CREATION",

  /**
   * Dynamic Property Change
   */
  DYNAMIC_PROPERTY_CHANGE = "DYNAMIC_PROPERTY_CHANGE",

  /**
   * Fixed Property Change
   */
  FIXED_PROPERTY_CHANGE = "FIXED_PROPERTY_CHANGE",

  /**
   * Category Change
   */
  CATEGORY_CHANGE = "CATEGORY_CHANGE",

  /**
   * Status Change
   */
  STATUS_CHANGE = "STATUS_CHANGE",

  /**
   * Migration
   */
  MIGRATION = "MIGRATION",

  /**
   * Payment Matched
   */
  PAYMENT_MATCHED = "PAYMENT_MATCHED",
}
