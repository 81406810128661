export enum AddressStatus {
  STATUS_NEW_EXTERNALLY = "STATUS_NEW_EXTERNALLY",
  STATUS_UPDATED_EXTERNALLY = "STATUS_UPDATED_EXTERNALLY",
  STATUS_IMPORT_ERROR = "STATUS_IMPORT_ERROR",
  STATUS_NEW_LOCALLY = "STATUS_NEW_LOCALLY",
  STATUS_UPDATED_LOCALLY = "STATUS_UPDATED_LOCALLY",
  STATUS_EXPORTED = "STATUS_EXPORTED",
  STATUS_COLLISION_WITH_LOCAL_CHANGE = "STATUS_COLLISION_WITH_LOCAL_CHANGE",
  STATUS_COLLISION_WITH_EXTERNAL_CHANGE = "STATUS_COLLISION_WITH_EXTERNAL_CHANGE",
  STATUS_TARGETED_FOR_REMOVAL = "STATUS_TARGETED_FOR_REMOVAL",
  STATUS_UPDATED_EXTERNALLY_CUST_ADDRESS_COLLISION = "STATUS_UPDATED_EXTERNALLY_CUST_ADDRESS_COLLISION",
}
