import {
  API,
  InfiniteAPIQueryContext,
  InfiniteAPIQueryOptions,
  InfiniteAPIQueryResult,
  useInfiniteAPIQuery,
} from "core/api";
import { RequestInitWithParams } from "core/api/interfaces";
import { Page, Product } from "core/api/models";

/** Type of products to fetch */
type ProductTypes = "INUSE" | "ALL";

/**
 * Product
 */
export interface FindCurrentUserProductsOptions {
  /** Directive for useQuery. */
  enabled?: boolean;
  /** Type of Products to return */
  type?: ProductTypes;
  /** Number of pages to offset */
  pageNo?: number;
  /** Max size of each page */
  pageSize?: number;
  onSuccess?(data: InfiniteAPIQueryResult<Page<Product>>["data"]): void;
}

export function useFindCurrentUserProducts({
  enabled = true,
  onSuccess,
  ...options
}: FindCurrentUserProductsOptions = {}): InfiniteAPIQueryResult<Page<Product>> {
  function requestInitFun(
    context: InfiniteAPIQueryContext<FindCurrentUserProductsOptions>
  ): RequestInitWithParams {
    return {
      queryParams: {
        type: options.type ?? "INUSE",
        pageNo: context.pageParam
          ? context.pageParam?.pageNo
          : options.pageNo ?? 0,
        pageSize: options.pageSize,
      },
    };
  }

  function nextPage(
    lastPage: FindCurrentUserProductsOptions,
    allPages: FindCurrentUserProductsOptions[]
  ) {
    const defObj = lastPage ? lastPage : options;
    return {
      pageNo: typeof defObj.pageNo === "number" ? defObj.pageNo + 1 : undefined,
    };
  }

  function previousPage(
    lastPage: FindCurrentUserProductsOptions,
    allPages: FindCurrentUserProductsOptions[]
  ) {
    if (lastPage.pageNo && lastPage.pageNo > 1) {
      return {
        pageNo: lastPage.pageNo && lastPage.pageNo - 1,
      };
    }

    return undefined;
  }

  return useInfiniteAPIQuery<Page<Product>, FindCurrentUserProductsOptions>(
    ["findCurrentUserProducts", options],
    API.userservice.userProducts,
    requestInitFun,
    {
      enabled: enabled,
      getNextPageParam: nextPage,
      getPreviousPageParam: previousPage,
      onSuccess,
    }
  );
}
