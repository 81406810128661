import { ListItem, ListItemText } from "common/components";
import { FlowData, FlowStep } from "core/api";
import { useTranslation } from "i18n";
import { Fragment } from "react";

export interface ProductPartsConfigurationConfirmationProps {
  step: FlowStep;
  flowData: FlowData;
}

/**
 * Sub component to confirmation. The purpose of this component is
 * to make the main Confirmation component more readable,
 * abstracting some of the logic into smaller sub components.
 *
 * ProductPartsConfigurationConfirmation is specific to confirmation steps where
 * the flow contains a step with the uiComponent: "ProductPartsConfiguration".
 *
 * @param flowData data property from the flow object, this is fetched through
 * const flowData = flow.data[step.id]; in the parent component.
 * @param step The current step in the parent components loop.
 *
 * @returns A JSX element with cardinality details for the selected Product offering from the previous "ProductPartsConfiguration" step in the flow.
 */
export function ProductPartsConfigurationConfirmation({
  step,
  flowData,
}: ProductPartsConfigurationConfirmationProps): JSX.Element {
  const { t } = useTranslation(["product"]);
  const fieldFlowData =
    flowData.uiComponent === "ProductPartsConfiguration" ? flowData : undefined;

  return (
    <Fragment key={step.id}>
      {fieldFlowData?.cardinalityRowList.map((cardinalityRow, index) => (
        <Fragment key={index}>
          <ListItem>
            <ListItemText
              primary={
                cardinalityRow.currentOneOf?.productOffering?.name ||
                cardinalityRow.productOffering?.name
              }
              secondary={`${t("product:numberOfParts")} ${
                cardinalityRow.currentOneOf?.currentValue !== undefined
                  ? cardinalityRow.currentOneOf?.currentValue
                  : cardinalityRow.currentValue
              }`}
              data-cy={"productOffering" + index}
            />
          </ListItem>
        </Fragment>
      ))}
    </Fragment>
  );
}
