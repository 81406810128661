import { TextField } from "common/components";
import { formatDisplayValue, getFieldProps } from "common/utils";
import type {
  BaseField,
  FieldConfig,
  FieldKey,
  FieldUIComponent,
} from "core/api";
import { ReadOnlyField, ReadOnlyFieldProps } from "core/components";

export interface ReadOnlyConfigFieldProps
  extends Pick<
    ReadOnlyFieldProps,
    "align" | "labelPosition" | "sx" | "data-cy"
  > {
  /**
   * Field key from config, to be able to render properly on broken config.
   */
  fieldKey: FieldKey;
  /**
   * Field config
   */
  config?: FieldConfig;
  /**
   * Field data
   */
  data?: BaseField;
  /** If displaying an icon */
  showIcon?: boolean;
  /** Horizontal alignment to display the field’s value. Valid values are “left“, “center” or “right”. */
  align?: string;
}

/**
 * Constructs a read-only field based on Field data.
 *
 * @returns
 */
export function ReadOnlyConfigField({
  fieldKey,
  config,
  data,
  labelPosition,
  showIcon,
  align,
  sx,
  "data-cy": dataCy,
}: Readonly<ReadOnlyConfigFieldProps>): JSX.Element {
  const label = config?.label || "";
  if (!config) {
    return (
      <TextField
        data-cy={dataCy}
        error
        label={fieldKey.key}
        variant="standard"
        helperText={
          "No field found for " + fieldKey.key + ", configuration error?"
        }
        inputProps={{ readOnly: true }}
        InputLabelProps={{ variant: "standard", shrink: true }}
        size="small"
        sx={sx}
      />
    );
  } else {
    // Filter out undefined values and then convert the array to a string array
    const urls: (string | undefined)[] = (data?.values || []).map(
      (v) => v?.url
    );

    // The uiComponent used to render the field is defined in config.uiComponent,
    // but can be overridden by the optional uiComponent property on the Field
    const _uiComponent = data?.uiComponent ?? config.uiComponent;

    return (
      <ReadOnlyField
        data-cy={fieldKey.key.replaceAll(" ", "")}
        uiComponent={_uiComponent}
        label={label}
        align={align}
        labelPosition={labelPosition}
        urls={urls}
        showIcon={showIcon}
        sx={sx}
        {...getFieldProps(_uiComponent, data, config.values)}
      />
    );
  }
}

export function getFormattedReadOnlyConfigValue(
  uiComponent: FieldUIComponent,
  data: BaseField | undefined
): string {
  return formatDisplayValue(uiComponent, getFieldProps(uiComponent, data, []));
}
