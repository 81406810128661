import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
} from "common/components";
import { StorageKey } from "core/appSettings";
import { createStore } from "core/store";
import { useTranslation } from "i18n";
import { ReactElement, ReactNode } from "react";
import { StyledDialog } from "./CookieDialog.styles";

export interface CookieDialogProps extends Omit<DialogProps, "open"> {
  /** Dialog content. Overrides default. */
  children?: ReactNode;
}

const COOKIES_ACCEPTED = 1;

/**
 * Dislays a dialog (modal window) with cookie information if the user
 * have not previously consent to cookies. One can read more about the cookies
 * on an external site. Usless the user presses "OK" the dialog will always be display on page load.
 */
export function CookieDialog({
  children,
  lang,
  ...props
}: CookieDialogProps): ReactElement {
  const { t } = useTranslation(["common"]);
  const { state: appState, updateState: updateLocalStorage } =
    useAcceptCookies();
  const saveCookieConsent = () => {
    updateLocalStorage(COOKIES_ACCEPTED);
  };

  const open = appState !== COOKIES_ACCEPTED;

  return (
    <StyledDialog
      open={open}
      maxWidth="xs"
      {...props}
      PaperProps={{
        style: { position: "absolute", bottom: "0px", maxWidth: "800px" },
      }}
    >
      <DialogContent>
        {children ?? (
          <DialogContentText>
            {t("common:cookieText", { lng: lang })}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button data-cy="cookie_ok" autoFocus onClick={saveCookieConsent}>
          {t("common:buttons.ok", { lng: lang })}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}

const useAcceptCookies = createStore<number | undefined>({
  initialState: undefined,
  name: StorageKey.COOKIES_ACCEPTED,
});
