import { useCodeItems } from "core/hooks";
import { CodeObject, User, UserStatus } from "core/api";
import { useCallback } from "react";

export function useUserStatus() {
  const { getCodeName } = useCodeItems();

  const findUserStatusText = useCallback(
    (userStatus: UserStatus) => {
      return getCodeName(CodeObject.UserStatus, userStatus);
    },
    [getCodeName]
  );

  const getUserStatus = useCallback(
    (user: User) => {
      return {
        status: user?.status,
        reasonText: findUserStatusText(user?.status),
      };
    },
    [findUserStatusText]
  );

  return { getUserStatus };
}
