import { Box, CircularProgress } from "common/components";
import { FlowData, FlowStepComponent } from "core/api";
import { ComponentType } from "react";
import { FieldValues } from "react-hook-form";
import {
  FlowStepComponentProps,
  StepComponent,
  StepComponentSettings,
  StepDataResolver,
  StepDataResolverParams,
  StepSettings,
} from "./models";
import { steps } from "./stepTypesConfig";

/**
 * Creates a settings object for the step. This step needs to be added to the
 * list of steps in the stepTypesConfig.
 *
 * Steps that only displays data usually doesn't require a resolver function.
 * If values are stored in the form, add a resolver, if not, omit it.
 *
 * @param uiComponent Step UI Component name
 * @param component Step component
 * @param dataResolver Step data resolver. Only required when step has form data
 * @returns StepSettings for step
 */
export function createStepSettings<
  TProps extends FlowStepComponentProps,
  TUIComponent extends TProps["flowStep"]["uiComponent"]
>(
  uiComponent: TUIComponent,
  component: ComponentType<TProps>,
  dataResolver?: StepDataResolver<TProps["flowStep"], TProps["data"]>
): StepSettings<TProps, TUIComponent> {
  return { uiComponent, component, dataResolver };
}

/** Record of all registered step components/resolvers */
const stepConfigMap: StepComponentSettings = steps.reduce(
  (stepMap, step) => ({ ...stepMap, [step.uiComponent]: step }),
  {}
);

/**
 * Get step component based on UI component type.
 * If the uiComponent is not found in the steps configuration, the Empty
 * step component will be returned,
 *
 * @param uiComponent Step UI Component type
 * @returns Step component matching **uiComponent**, or the **Empty** step
 *   component if no matching component was found.
 */
export function getStepComponent(
  uiComponent: FlowStepComponent | undefined
): StepComponent {
  const Component = uiComponent && stepConfigMap[uiComponent]?.component;
  const emptyComponent = stepConfigMap["Empty"]?.component;
  return (
    Component ??
    emptyComponent ??
    (() => (
      <Box display="grid" sx={{ placeItems: "center" }}>
        <CircularProgress />
      </Box>
    ))
  );
}

/**
 * Returns updated values in step data based on form field values
 * @param stepData Flow step data
 * @param fieldValues Form field values
 * @returns Resolved data values
 */
export function stepDataResolver({
  flow,
  flowStep,
  data,
  fieldValues,
}: StepDataResolverParams): Partial<FlowData> | undefined {
  // Take all field values for my step and remove the step prefix.
  const stepValues = Object.entries(fieldValues)
    .filter(
      ([key]) =>
        key.startsWith(flowStep.id) || key.startsWith("include_" + flowStep.id)
    )
    .reduce(
      (newObject, [key, value]) => ({
        ...newObject,
        [key.replace(`${flowStep.id}_`, "")]: value,
      }),
      {} as FieldValues
    );

  return stepConfigMap[data.uiComponent]?.dataResolver?.({
    flow,
    flowStep,
    data,
    fieldValues: stepValues,
  });
}
