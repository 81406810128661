import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "common/components";
import { useToggle } from "common/hooks";
import { useFetchCurrentUser } from "core/api";
import { useAuthenticationContext } from "core/auth";
import { useTranslation } from "i18n";
import { showKindly } from "utils";

export function useLogoutDialog() {
  const { t } = useTranslation(["common", "login"]);
  const { logout } = useAuthenticationContext();
  const [open, toggleOpen] = useToggle();
  const { data } = useFetchCurrentUser();

  const logoutWrapper = () => {
    showKindly(false);
    logout();
  };

  const LogoutDialog = () => (
    <Dialog open={open} onClose={toggleOpen} maxWidth="xs" fullWidth>
      <DialogTitle>{t("login:logoutDialog.title")}</DialogTitle>
      <DialogContent>{data?.userName}</DialogContent>
      <DialogActions>
        <Button onClick={logoutWrapper} fullWidth>
          {t("common:logout")}
        </Button>
      </DialogActions>
    </Dialog>
  );

  return { LogoutDialog, open, toggleOpen };
}
