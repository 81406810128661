/**
 * Enum with variants for StatusIcon
 */
export enum IconVariant {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  IN_PROGRESS = "IN_PROGRESS",
  CANCELED = "CANCELED",
  CLOSED = "CLOSED",
  ONHOLD = "ONHOLD",
}
