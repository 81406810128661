import { MessageStore, useMessageStore } from ".";

/** Subscribes to message service and hooks its state into a React state */
export function useCurrentMessages(): MessageStore {
  const state = useMessageStore();
  return {
    ...state,
    messages: [...state.messages].reverse(),
  };
}
