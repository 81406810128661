import {
  LabeledSelect,
  LabeledSelectProps,
  SelectOption,
} from "common/components";
import { useTranslation } from "i18n";
import { ReactElement, useEffect } from "react";
import {
  FormControlledFieldProps,
  withFormController,
} from "../FormController";

export interface FormSelectProps<TOption extends SelectOption = SelectOption>
  extends FormControlledFieldProps,
    Omit<LabeledSelectProps<TOption>, "defaultValue" | "label"> {}

/**
 * Adds form handling for FormSelect.
 *
 * @param fieldName Static string used for the unique ids of the inner components.
 * @param control Form control.
 * @param dataList The list of selectable key:value pairs.
 * @param props Any remaining properties.
 * @param includeEmptyOption If set, add an empty value (-1, <None>) first in the select
 */
export const FormSelect = withFormController<FormSelectProps>(
  ({ helperText, renderState, ...props }): ReactElement => {
    const { t } = useTranslation(["common"]);
    const {
      field,
      fieldState: { error },
      gridColumn,
      isDisabled,
      setExtendedRules,
    } = renderState;

    useEffect(() => {
      setExtendedRules({
        validate: {
          notEmptyRequiredValue: (value) => {
            if (
              props.required &&
              (value === "-1" || (Array.isArray(value) && value.length === 0))
            ) {
              return t("common:forms.fieldIsMandatory");
            }
            return true;
          },
        },
      });
    }, [setExtendedRules, t, props.required]);

    // Remove the 'fieldName' prop from the input props for <LabeledSelect>
    // This avoids the warning "React does not recognize the `fieldName` prop on a DOM element."
    const filteredProps: any = { ...props };
    delete filteredProps.fieldName;

    return (
      <LabeledSelect
        fullWidth
        error={!!error}
        helperText={error?.message ?? helperText}
        {...(filteredProps as LabeledSelectProps)}
        {...field}
        disabled={isDisabled}
        FormControlProps={{
          ...props.FormControlProps,
          sx: [
            { gridColumn },
            ...((Array.isArray(props.FormControlProps?.sx)
              ? props.FormControlProps?.sx
              : [props.FormControlProps?.sx]) ?? []),
          ],
        }}
      />
    );
  }
);
