export interface VATAmounts {
  amountExclVat: number;
  amountVat: number;
  amountInclVat: number;
}

/**
 * Calculates the VAT amounts for the given input.
 * The input can include the amount either excluding or including VAT.
 *
 * @remarks
 * IF the given inputAmount represents the amount including VAT (i.e. isInputAmountInclVat === true)
 * THEN the function calculates the amount excluding VAT.
 *
 * IF the given inputAmount represents the amount excluding VAT (i.e. isInputAmountInclVat === false)
 * THEN the function calculates the amount including VAT.
 *
 * In both cases the function returns a VATAmounts object that includes
 * the amount excluding VAT, the VAT amount, and the amount including VAT.
 *
 * @param inputAmount The amount for which the VAT needs to be calculated.
 *  This amount either includes VAT if isInputAmountInclVat === true,
 *  or excludes VAT if isInputAmountInclVat === false.
 * @param isInputAmountInclVat Set to true if the inputAmount includes VAT,
 *  set to false if the inputAmount excludes VAT.
 * @param vatRate The VAT rate, for example 0.25 for 25% VAT.
 * @returns  a VATAmounts object that includes the amount excluding VAT,
 */
export function calculateVATAmounts(
  inputAmount: number,
  isInputAmountInclVat: boolean,
  vatRate: number
): VATAmounts {
  return {
    amountExclVat: isInputAmountInclVat
      ? inputAmount / (1.0 + vatRate)
      : inputAmount,
    amountVat: isInputAmountInclVat
      ? (inputAmount * vatRate) / (1.0 + vatRate)
      : inputAmount * vatRate,
    amountInclVat: isInputAmountInclVat
      ? inputAmount
      : inputAmount * (1.0 + vatRate),
  };
}
