/**
 * Available locale code class.
 * <p>
 *
 *
 * en - English
 * sv - Swedish
 * da - Danish
 * fi - Finnish
 * nb - Norwegian
 * fr - French
 * de - German
 * lb - Luxembourgish
 * nlBe - Dutch (Belgium)
 * fo - Faroese
 * es - Spanish
 * it - Italian
 *
 * <p>
 * NOTE! When adding/changing available locales, don't forget to do the same thing to the server ends LocaleFormatter class.
 */
export enum AvailableLocale {
  /**
   * English (American)
   */
  enUS = "en-US",

  /**
   * English (British)
   */
  enGB = "en-GB",

  /**
   * Swedish
   */
  sv = "sv",

  /**
   * Danish
   */
  da = "da",

  /**
   * Finnish
   */
  fi = "fi",

  /**
   * Norwegian
   */
  nb = "nb",

  /**
   * French
   */
  fr = "fr",

  /**
   * German
   */
  de = "de",

  /**
   * Luxembourgish
   */
  lb = "lb",

  /**
   * Dutch (Belgium)
   */
  nlBe = "nl-be",

  /**
   * Faroese
   */
  fo = "fo",

  /**
   * Spanish
   */
  es = "es",

  /**
   * Italian
   */
  it = "it",
}
