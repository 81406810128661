import { API, APIQueryResult, useAPIQuery } from "core/api";
import { CodeItem } from "core/api/models";

/**
 * Return enum values for code objects exposed to the client.
 *
 * @param enabled Directive for useQuery.
 *
 * @returns
 */
export function useFetchEnums(
  enabled = true
): APIQueryResult<Record<string, CodeItem[]>> {
  return useAPIQuery(
    ["fetchEnums"],
    API.common.enums,
    {},
    {
      enabled,
      excludeCustomerGroup: true,
      /*
        Some screens have several observers on this query
        that renders simultaneously. On request errors this
        creates an infinite loop of retries since the
        failureCount of the query is constantly reset to 0.
        
        Might be related to this issue:
        - https://github.com/tannerlinsley/react-query/issues/2434

        The bug might have been introduced in React Query 3.9.0
        after a big re-write.

        To prevent this from happening we disable the default
        behaviour of retrying on mount.
      */
      retryOnMount: false,
    }
  );
}
