import { FieldLayoutConfig } from "core/api";
import { useTranslation } from "i18n";
import { isEqual } from "lodash";
import {
  ComponentType,
  createContext,
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Controller,
  ControllerProps,
  FieldValues,
  Path,
  useFormContext,
} from "react-hook-form";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

/** Render state values for Form field */
export type FormFieldRenderValues<
  TFieldValues extends FieldValues = any,
  TName extends Path<TFieldValues> = Path<TFieldValues>
> = Parameters<ControllerProps<TFieldValues, TName>["render"]>[0] & {
  /**
   * If the field should be disabled.
   * Includes check of the `disabled` property.
   */
  isDisabled: boolean;
  /**
   * Formatted CSS `grid-column` value to apply to
   * the root/container element of the field.
   *
   * Use with `combineSx` from `styles/theme`.
   *
   * ```
   * function Component(props: ComponentProps) {
   *   return <Box sx={combineSx({ gridColumn }, props.sx)} />;
   * };
   * ```
   */
  gridColumn: string;
};

export type FormControllerProps<
  TFieldValues extends FieldValues = any,
  TName extends Path<TFieldValues> = Path<TFieldValues>
> = Pick<ControllerProps<TFieldValues, TName>, "control" | "defaultValue"> &
  FieldLayoutConfig & {
    /** Field name. Used as key for value in form state. */
    fieldName: ControllerProps<TFieldValues, TName>["name"];
    /** Field validation rules */
    validationRules?: ControllerProps<TFieldValues, TName>["rules"];
    /**
     * Server error message.
     * Clears after next validation in form if value has changed.
     */
    serverError?: string;
    /** If the field is required */
    required?: boolean;
    /** RegEx validation pattern */
    pattern?: string;
    /** Server side generated pattern hint */
    patternHint?: string;
    /** Minimum character length in field value */
    minLength?: number;
    /** Maximum character length in field value */
    maxLength?: number;
    /** Length of the social security number */
    socialSecurityNumberLength?: number;
    /** If field is disabled */
    disabled?: boolean;
    /** Data-cy value for Cypress testing. */
    dataCy?: string;
    /** If field should trigger server evaluation on change  */
    evaluateOnChange?: boolean;
    /** Renders field inside a Controller. */
    render(
      /** Render state. */
      state: FormFieldRenderValues<TFieldValues, TName>
    ): ReturnType<ControllerProps<TFieldValues, TName>["render"]>;
  };

export type FormControlledFieldProps<
  TFieldValues extends FieldValues = any,
  TName extends Path<TFieldValues> = Path<TFieldValues>
> = Omit<FormControllerProps<TFieldValues, TName>, "render"> &
  FieldLayoutConfig & {
    /** Field label. */
    label?: string;
    /** Data-cy value for Cypress testing. */
    "data-cy"?: string;
  };

export interface WithFormControllerProps<
  TFieldValues extends FieldValues = any,
  TName extends Path<TFieldValues> = Path<TFieldValues>
> {
  /**
   * Render state of Form component.
   * Required to use for state in all Form components.
   *
   * Available values:
   * - **field** - field props ({@link https://react-hook-form.com/api/usecontroller/controller/ react-hook-form#Controller})
   * - **fieldState** - field state ({@link https://react-hook-form.com/api/usecontroller/controller/ react-hook-form#Controller})
   * - **formState** - form state ({@link https://react-hook-form.com/api/usecontroller/controller/ react-hook-form#Controller})
   * - **isDisabled** - field disabled state
   * - **gridColumn** - field `grid-column` value
   */
  renderState: FormFieldRenderValues<TFieldValues, TName> & {
    /** Validation rules for Form field */
    validationRules?: FormControlledFieldProps["validationRules"];
    /**
     * Use to extend validation rules of Form field. Call inside an useEffect.
     *
     * @example
     *
     * ```
     * useEffect(() => {
     *   setExtendedRules({
     *     // Default rules can be overridden.
     *     // Overwrites the default 'required' rule in FormController.
     *     required: {
     *       value: props.required && !isDisabled,
     *       message: t("common:forms.fieldIsMandatory"),
     *     },
     *     // The 'validate' object can be used to create custom rules.
     *     validate: {
     *       // NOTE: Just an example. This case for text fields is
     *       // covered by the 'required' rule in FormController.
     *       nonEmptyRequiredValue: (value) => {
     *         if (props.required && value === "") {
     *           // Return error message if field is invalid
     *           return t("common:forms.fieldIsMandatory");
     *         }
     *         // Return true if field is valid.
     *         return true;
     *       },
     *     },
     *   });
     * }, [setExtendedRules, t, props.required]);
     * ```
     */
    setExtendedRules: Dispatch<
      SetStateAction<FormControlledFieldProps["validationRules"]>
    >;
  };
}

/**
 * Form controller component with built-in validation rules for:
 * - Required
 * - Pattern
 * - Min length
 * - Max length
 * - Server error message
 *
 * Adds an abstraction layer on top of
 * {@link https://react-hook-form.com/api/usecontroller/controller/ Controller}
 * from `react-hook-form`.
 *
 * @see withFormController for usage instructions when creating Form components.
 */
export function FormController<
  TFieldValues extends FieldValues = FieldValues,
  TName extends Path<TFieldValues> = Path<TFieldValues>
>({
  fieldName,
  control,
  validationRules,
  defaultValue,
  serverError,
  required = false,
  pattern,
  patternHint,
  minLength,
  maxLength,
  columnSpan = 1,
  columnStart,
  dependsOn,
  disabled,
  render,
}: FormControllerProps<TFieldValues, TName>): ReactElement {
  const regExPattern = pattern ? new RegExp(pattern) : undefined;
  const context = useFormContext();
  const { t } = useTranslation(["common"]);
  const formDefaults = useFormDefaults();
  const dependsOnValue = context?.watch(
    dependsOn ? dependsOn?.key.key + dependsOn?.key.variant : ""
  );
  const isDisabled = !!dependsOn && dependsOn.disabledIf === dependsOnValue;

  // Clear errors for disabled field
  useEffect(() => {
    if (
      isDisabled &&
      context &&
      !isEqual(context.getValues(fieldName), defaultValue)
    ) {
      context.clearErrors(fieldName);
      if (dependsOn?.resetIfDisabled) {
        context.setValue(fieldName, defaultValue as any);
      }
      context.trigger();
    }
  }, [isDisabled, context, defaultValue, dependsOn, fieldName]);

  // Clear errors and the value of the field, when it has a pattern, disabled and isn't required.
  // Used to clear the TextField for FormOptionalFieldWrapper, when the checkbox is unchecked.
  useEffect(() => {
    if (!required && pattern && disabled && context) {
      context.clearErrors(fieldName);
      context.setValue(fieldName, "" as any);
    }
  }, [required, pattern, fieldName, context]);

  // This is probably a very temp solution to ensure the value set by server in evalOnEnter is used. //JSO
  const setValue = context?.setValue;
  useEffect(() => {
    if (
      defaultValue &&
      setValue &&
      formDefaults?.isNewDefault(fieldName, defaultValue)
    ) {
      setValue(fieldName, defaultValue as any);
    }
  }, [fieldName, defaultValue, setValue, formDefaults]);

  // Trigger field validation on server error
  useEffect(() => {
    if (serverError && context) {
      context.trigger(fieldName);
    }
  }, [serverError, context, fieldName]);

  const minMaxMessage =
    minLength === maxLength
      ? t("common:forms.lengthExactly", { count: maxLength })
      : t("common:forms.lengthBetween", { minLength, maxLength });
  return (
    <Controller
      name={fieldName}
      control={control}
      render={(state) =>
        render({
          ...state,
          isDisabled: isDisabled || !!disabled,
          gridColumn: getFormGridColumn(columnSpan, columnStart),
        })
      }
      defaultValue={defaultValue}
      rules={{
        required: {
          value: required && !isDisabled,
          message: t("common:forms.fieldIsMandatory"),
        },
        pattern: regExPattern && {
          value: regExPattern,
          message: patternHint ?? t("common:forms.pattern", { pattern }),
        },
        minLength:
          minLength !== undefined
            ? {
                value: minLength,
                message: maxLength
                  ? minMaxMessage
                  : t("common:forms.minLength", { count: minLength }),
              }
            : undefined,
        maxLength:
          maxLength !== undefined
            ? {
                value: maxLength,
                message: minLength
                  ? minMaxMessage
                  : t("common:forms.maxLength", { count: maxLength }),
              }
            : undefined,
        ...validationRules,
        validate: {
          ...validationRules?.validate,
          unfixedServerError: (value) => {
            const hasUnfixedError = !!serverError && defaultValue === value;
            if (hasUnfixedError) {
              return serverError;
            }
            return true;
          },
        },
      }}
    />
  );
}

/**
 * Returns a formatted grid-column value for form fields
 * @param columnSpan Amount of columns to span
 * @param columnStart Column to start at
 * @returns Formatted grid-column value
 */
export function getFormGridColumn(columnSpan: number, columnStart?: number) {
  if (columnStart) {
    return `${columnStart} / span ${columnSpan}`;
  }
  return `span ${columnSpan}`;
}

/**
 * Higher-Order Component (HOC). Extends field component by wrapping it
 * inside a `FormController`.
 *
 * @param FieldComponent Field component to extend
 * @returns Form field component
 *
 * ## Basic usage
 *
 * All Form components need to use these values from the `renderState` prop:
 * - **field** - field props ({@link https://react-hook-form.com/api/usecontroller/controller/ react-hook-form#Controller})
 * - **fieldState.error** - validation error ({@link https://react-hook-form.com/api/usecontroller/controller/ react-hook-form#Controller})
 * - **isDisabled** - field `disabled` state
 * - **gridColumn** - field CSS `grid-column` value, apply to
 * the root/container element of the field
 *
 * ### Creating a Form component
 * @example
 *
 * ```
 * type FormTextFieldProps = TextFieldProps & FormControlledFieldProps;
 *
 * const FormTextField = withFormController<FormTextFieldProps>(
 *   ({
 *     fieldName,
 *     helperText,
 *     renderState,
 *     ...props
 *   }): ReactElement => {
 *     const {
 *       field,
 *       fieldState: { error },
 *       isDisabled,
 *       gridColumn,
 *     } = renderState;
 *     return (
 *       <TextField
 *         error={!!error}
 *         data-cy={fieldName}
 *         InputLabelProps={{ required: props.required }}
 *         {...props}
 *         {...field}
 *         disabled={isDisabled}
 *         helperText={error ? error.message : helperText}
 *         sx={combineSx({ gridColumn }, props.sx)}
 *       />
 *     );
 *   }
 * );
 * ```
 *
 * ### Extend validation rules
 * @example
 *
 * ```
 * const FormTextField = withFormController<FormTextFieldProps>(
 *   ({
 *     fieldName,
 *     helperText,
 *     renderState,
 *     ...props
 *   }): ReactElement => {
 *     const { t } = useTranslation(["common"]);
 *     const {
 *       field,
 *       fieldState: { error },
 *       isDisabled,
 *       gridColumn,
 *       setExtendedRules,
 *     } = renderState;
 *
 *     useEffect(() => {
 *       setExtendedRules({
 *         // Default rules can be overridden.
 *         // Overwrites the default 'required' rule in FormController.
 *         required: {
 *           value: props.required && !isDisabled,
 *           message: t("common:forms.fieldIsMandatory"),
 *         },
 *         // The 'validate' object can be used to create custom rules.
 *         validate: {
 *           // NOTE: Just an example. This case for text fields is
 *           // covered by the 'required' rule in FormController.
 *           nonEmptyRequiredValue: (value) => {
 *             if (props.required && value === "") {
 *               // Return error message if field is invalid
 *               return t("common:forms.fieldIsMandatory");
 *             }
 *             // Return true if field is valid.
 *             return true;
 *           },
 *         },
 *       });
 *     }, [setExtendedRules, t, props.required]);
 *
 *     return (
 *       <TextField
 *         error={!!error}
 *         data-cy={fieldName}
 *         InputLabelProps={{ required: props.required }}
 *         {...props}
 *         {...field}
 *         disabled={isDisabled}
 *         helperText={error ? error.message : helperText}
 *         sx={combineSx({ gridColumn }, props.sx)}
 *       />
 *     );
 *   }
 * );
 * ```
 */
export function withFormController<TFieldProps extends {}>(
  FieldComponent: ComponentType<
    TFieldProps & FormControlledFieldProps & WithFormControllerProps
  >
): ComponentType<TFieldProps & FormControlledFieldProps> {
  return (props) => {
    const [extendedRules, setExtendedRules] =
      useState<FormControllerProps["validationRules"]>();
    const context = useFormContext();
    const isValidating = context?.formState.isValidating;

    return (
      <FormController
        columnSpan={props.columnSpan}
        columnStart={props.columnStart}
        fieldName={props.fieldName}
        control={props.control}
        required={props.required}
        disabled={props.disabled}
        maxLength={props.maxLength}
        minLength={props.minLength}
        socialSecurityNumberLength={props.socialSecurityNumberLength}
        pattern={props.pattern}
        patternHint={props.patternHint}
        serverError={props.serverError}
        dependsOn={props.dependsOn}
        render={(renderState) => {
          const isFieldValidated = !renderState.fieldState.error;

          useEvaluateOnChange(
            !!props.evaluateOnChange && !isValidating && isFieldValidated,
            props.fieldName,
            renderState.field.value,
            props.defaultValue
          );
          // Create new object with the same properties
          const { ...fieldProps } = props;
          // Remove props that should not be passed to field component
          delete fieldProps.validationRules;
          delete fieldProps.dependsOn;
          delete fieldProps.columnSpan;
          delete fieldProps.columnStart;
          delete fieldProps.patternHint;
          delete fieldProps.serverError;
          delete fieldProps.defaultValue;
          delete fieldProps.evaluateOnChange;
          delete fieldProps.socialSecurityNumberLength;

          return (
            <FieldComponent
              {...fieldProps}
              renderState={{
                ...renderState,
                validationRules: props.validationRules,
                setExtendedRules,
              }}
            />
          );
        }}
        defaultValue={props.defaultValue}
        validationRules={{
          ...extendedRules,
          ...props.validationRules,
          validate: {
            ...extendedRules?.validate,
            ...props.validationRules?.validate,
          },
        }}
      />
    );
  };
}

interface FormDefaultsStore {
  defaults: Record<string, any>;
  isNewDefault(fieldName: string, value: any): boolean;
  previousValues: Record<string, any>;
  setPreviousValue(fieldName: string, value: any): void;
}

const createFormControllerStore = () =>
  create<FormDefaultsStore>()(
    devtools((set, get) => ({
      defaults: {},
      isNewDefault: (fieldName, value) => {
        if (!isEqual(value, get().defaults[fieldName])) {
          set((state) => ({
            ...state,
            defaults: { ...state.defaults, [fieldName]: value },
          }));
          return true;
        }
        return false;
      },
      previousValues: {},
      setPreviousValue: (fieldName, value) => {
        if (!isEqual(value, get().previousValues[fieldName])) {
          set((state) => ({
            ...state,
            previousValues: { ...state.previousValues, [fieldName]: value },
          }));
        }
      },
    }))
  );

type FormDefaultsHook = ReturnType<typeof createFormControllerStore>;

interface FormControllerContextState {
  /** Controller Store hook */
  useControllerStore?: FormDefaultsHook;
  /** Trigger on change evaluation */
  evaluateOnChange?(onComplete?: () => void): void;
}

export const FormControllerContext = createContext<FormControllerContextState>({
  evaluateOnChange: () => {},
});

export function useFormDefaults(): FormDefaultsStore | undefined {
  const { useControllerStore } = useContext(FormControllerContext);
  return useControllerStore?.();
}

/**
 * Trigger evaluation of Flow on value change if enabled
 * @param shouldEvaluateOnChange If field should trigger evaluation on change
 * @param fieldName Field name
 * @param value Current field value
 * @param defaultValue Default field value
 */
export function useEvaluateOnChange<TValue>(
  shouldEvaluateOnChange: boolean,
  fieldName: string,
  value: TValue,
  defaultValue: TValue
) {
  const { evaluateOnChange, useControllerStore } = useContext(
    FormControllerContext
  );
  const { previousValues, setPreviousValue } = useControllerStore?.() ?? {};

  useEffect(() => {
    if (
      shouldEvaluateOnChange &&
      value !== "" &&
      !isEqual(value, previousValues?.[fieldName] ?? defaultValue)
    ) {
      setPreviousValue?.(fieldName, value);

      evaluateOnChange?.();
    }
  }, [shouldEvaluateOnChange, value, defaultValue, previousValues, fieldName]);
}

export interface FormControllerProviderProps {
  children: ReactNode;
  evaluateOnChange(): void;
}

/**
 * Form Controller Context Provider
 *
 * Used for the Flow form to keep track of previous values and pass the
 * on change evaluation function to the FormController.
 */
export function FormControllerProvider({
  children,
  evaluateOnChange,
}: Readonly<FormControllerProviderProps>) {
  const useControllerStore = useMemo(() => createFormControllerStore(), []);
  return (
    <FormControllerContext.Provider
      value={{ useControllerStore, evaluateOnChange }}
    >
      {children}
    </FormControllerContext.Provider>
  );
}
