import { API, APIQueryResult, useAPIQuery } from "core/api";
import { Currency } from "core/api/models";

/**
 * Find all in-use currencies.
 *
 * @param enabled Directive for useQuery.
 *
 * @returns
 */
export function useFetchCurrencies(enabled = true): APIQueryResult<Currency[]> {
  return useAPIQuery(
    ["fetchCurrencies"],
    API.common.currencies,
    {},
    { enabled }
  );
}
