import { ListItem, ListItemText } from "common/components";
import { FlowData, SetResellerFlowStep } from "core/api";
import { Fragment } from "react";

export interface SetResellerConfirmationProps {
  step: SetResellerFlowStep;
  flowData: FlowData;
  title?: string; // optional title to override the use of the steps name
}

/**
 * Sub component to confirmation.
 *
 * SetResellerConfirmation is specific to confirmation steps where
 * the flow contains a step with the uiComponent: "SetReseller".
 *
 * @param step The current step in the parent components loop.
 * @param flowData data property from the flow object, this is fetched through
 *
 * @returns A JSX element with item(s) selected in the table.
 */
export function SetResellerConfirmation({
  step,
  flowData,
  title,
}: SetResellerConfirmationProps): JSX.Element {
  const fieldFlowData =
    flowData.uiComponent === "SetReseller" ? flowData : undefined;

  const selectedReseller = step.config.resellers.find(
    ({ id }) => id === fieldFlowData?.reseller.values[0].value
  );

  const agent = fieldFlowData?.reseller.values[1].value;
  const text = (selectedReseller?.name || "") + (agent ? "/" + agent : "");

  return (
    <Fragment key={step.id}>
      <ListItem>
        <ListItemText primary={title ? title : step.name} secondary={text} />
      </ListItem>
    </Fragment>
  );
}
