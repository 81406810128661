import { formatFormDatePickerValue } from "common/components";
import { RepaymentFlowData, RepaymentFlowStep } from "core/api";
import { StepDataResolver } from "../models";

/**
 * Repayment  data resolver
 *
 */
export const repaymentDataResolver: StepDataResolver<
  RepaymentFlowStep,
  RepaymentFlowData
> = ({ data, fieldValues }) => {
  return {
    approved: fieldValues.approved ?? data.approved,
    allowedToApprove: fieldValues.allowedToApprove ?? data.allowedToApprove,
    repaymentDate: fieldValues.repaymentDate
      ? formatFormDatePickerValue(fieldValues.repaymentDate)
      : data.repaymentDate,
    message: fieldValues.message ?? data.message,
    repaymentAmount: fieldValues.repaymentAmount ?? data.repaymentAmount,
  };
};
