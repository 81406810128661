import { API, APIQueryResult, EntityType, useFetchEntityQuery } from "core/api";
import { getAPIQueryData } from "core/api/useAPIQuery";
import { SystemUser, UserResponse } from "core/auth/models";
import { getI18n } from "i18n";
import { requireLanguage } from "i18n/init";
import { AvailableLocale } from "i18n/models";
import { fetchUserQueryKey } from "./constants";

/**
 * Fetch UserResponse object
 * @param enabled Directive for useQuery.
 * @returns a complete UserResponse
 */
export function useFetchSystemUser(
  enabled = true
): APIQueryResult<UserResponse> {
  return useFetchEntityQuery<UserResponse>({
    apiUrl: API.myAccount.myaccount,
    entityType: EntityType.SYS_USER,
    ids: [], // Should be empty since it is /myaccount
    enabled,
    staleTime: 15 * 60 * 1000,
    excludeCustomerGroup: true,
  });
}

export function getCurrentSystemUser(): UserResponse | undefined {
  return getAPIQueryData<UserResponse>(fetchUserQueryKey, true);
}

export type UserLocaleType = keyof Pick<
  SystemUser,
  "language" | "amountLocale" | "dateLocale" | "numberLocale"
>;

export function getUserLocale(locale: UserLocaleType): string {
  const lang = getCurrentSystemUser()?.systemUser[locale] ?? getI18n().language;
  requireLanguage(lang as AvailableLocale);
  return lang;
}
