/**
 * The Payment status for an invoice can have the values specified by this enum.
 */
export enum InvoicePaymentStatus {
  /**
   * Start status set for all generated Invoices
   */
  NOT_PAID = "NOT_PAID",

  /**
   * Set when an Invoice is closed due to credit notes and/or payments matched.
   */
  PAID = "PAID",

  /**
   * Set when a payment is matched without closing the Invoice (or when the invoice is partly
   * credited?).
   */
  PARTLY_PAID = "PARTLY_PAID",

  /**
   * Legacy status. Shall not be used.
   */
  OVERPAID = "OVERPAID",

  /**
   * NOT USED
   */
  WRITTEN_OFF = "WRITTEN_OFF",
}
