import { formatFormDatePickerValue } from "common/components";
import {
  ChangeEntityStatusFlowData,
  ChangeEntityStatusFlowStep,
} from "core/api";
import { StepDataResolver } from "../models";

/**
 * Change Entity status data resolver
 *
 * Formats order attributes and status reason id based on form field values
 */
export const changeEntityStatusDataResolver: StepDataResolver<
  ChangeEntityStatusFlowStep,
  ChangeEntityStatusFlowData
> = ({ data, fieldValues }) => {
  return {
    orderAttributes: {
      ...data.orderAttributes,
      approved: fieldValues.approved ?? data.orderAttributes.approved,
      effectiveDate: fieldValues.effectiveDate
        ? formatFormDatePickerValue(fieldValues.effectiveDate)
        : data.orderAttributes.effectiveDate,
    },
    statusReasonId: fieldValues.statusReasonId ?? data.statusReasonId,
  };
};
