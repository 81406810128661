import { ListItem, ListItemText } from "common/components";
import { formatCurrency, formatDate } from "common/utils";
import { FlowData, FlowStep } from "core/api";
import { useTranslation } from "i18n";
import { Fragment } from "react";

export interface CreatePaymentConfirmationProps {
  step: FlowStep;
  flowData: FlowData;
}

/**
 * Sub component to confirmation. The purpose of this component is
 * to make the main Confirmation component more readable,
 * abstracting some of the logic into smaller sub components.
 *
 * CreatePaymentConfirmation is specific to confirmation steps where
 * the flow contains a step with the uiComponent: "CreatePayment".
 *
 * @param flowData data property from the flow object, this is fetched through
 * const flowData = flow.data[step.id]; in the parent component.
 * @param getValues A helper function from react-hook-form. The function is passed
 * into the parent Confirmation component and in turn passed here.
 *
 * @returns A JSX element with invoice details from the previous "CreatePayment" step in the flow.
 */
export function CreatePaymentConfirmation({
  step,
  flowData,
}: CreatePaymentConfirmationProps): JSX.Element {
  const { t } = useTranslation(["common"]);
  const fieldFlowData =
    flowData.uiComponent === "CreatePayment" ? flowData : undefined;

  return (
    <Fragment key={step.id}>
      <ListItem>
        <ListItemText
          primary={t("invoice:payInvoice:paidAmount")}
          secondary={formatCurrency(
            fieldFlowData?.paidAmount || 0,
            fieldFlowData?.currencyCode!
          )}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={t("invoice:payInvoice:paymentType")}
          secondary={fieldFlowData?.paymentType.name}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={t("invoice:payInvoice:paymentDate")}
          secondary={formatDate(fieldFlowData?.paymentDate)}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={t("invoice:payInvoice:paymentReference")}
          secondary={fieldFlowData?.paymentReference}
        />
      </ListItem>
    </Fragment>
  );
}
