import { SelectStepFlowData, SelectStepFlowStep } from "core/api";
import { StepDataResolver } from "../models";

/**
 * SelectStep data resolver
 *
 * Formats select step based on form field values
 */
export const selectStepDataResolver: StepDataResolver<
  SelectStepFlowStep,
  SelectStepFlowData
> = ({ fieldValues, data }) => {
  const selectedStepId: string | undefined =
    fieldValues.selectedStepId ?? data.selectedStepId;
  return {
    selectedStepId,
  };
};
