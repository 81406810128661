import { CheckCircle } from "common/assets/icons";
import { Chip, ChipProps, Tooltip } from "common/components";
import { formatCurrency, formatDate } from "common/utils";
import {
  FilterFieldType,
  FilterValueState,
  FilterValueStates,
  getFilterValuesQueryData,
} from "core/api";
import { useTranslation } from "i18n";

export type FilterChipProps = ChipProps;

export function FilterChip(props: FilterChipProps) {
  return (
    <Tooltip title={<>{props.label ?? props.icon}</>}>
      <Chip
        color="primary"
        size="small"
        {...props}
        sx={{ minWidth: 50, maxWidth: 180, ...props.sx }}
      />
    </Tooltip>
  );
}

export interface FilterGroupContentProps {
  /** Id of filter group label */
  labelId: string;
  /** Current filter state */
  filterState: FilterValueState;
  /** Function to trigger search with filter values */
  triggerSearch(
    filterState?: (state: FilterValueStates) => FilterValueStates
  ): void;
}

/** Filter group content */
export function FilterGroupContent({
  labelId,
  filterState,
  triggerSearch,
}: FilterGroupContentProps) {
  const { t } = useTranslation(["common"]);
  const {
    value,
    currencyCode,
    minValue,
    maxValue,
    filterId,
    requireCustomerNo,
    valuesUrl,
  } = filterState;

  const updateFilter = (data: FilterValueState, remove?: boolean) => {
    const newState = (state: FilterValueStates) => {
      if (remove) {
        delete state[data.filterId];
        return state;
      }
      return {
        ...state,
        [data.filterId]: data,
      };
    };

    triggerSearch(newState);
  };

  switch (filterState.fieldType) {
    case FilterFieldType.Amount:
      return (
        <>
          {minValue && currencyCode && (
            <FilterChip
              label={t("common:searchFilter.fromValue", {
                value: formatCurrency(
                  parseFloat(minValue),
                  currencyCode as string
                ),
              })}
              onDelete={() =>
                updateFilter(
                  { ...filterState, minValue: undefined },
                  !filterState.maxValue
                )
              }
            />
          )}
          {maxValue && currencyCode && (
            <FilterChip
              label={t("common:searchFilter.toValue", {
                value: formatCurrency(
                  parseFloat(maxValue),
                  currencyCode as string
                ),
              })}
              onDelete={() => {
                updateFilter(
                  { ...filterState, maxValue: undefined },
                  !filterState.minValue
                );
              }}
            />
          )}
        </>
      );
    case FilterFieldType.Select: {
      const filterValues = getFilterValuesQueryData(
        parseInt(String(filterId)),
        requireCustomerNo || false,
        valuesUrl
      );
      if (Array.isArray(value)) {
        return (
          <>
            {value
              .map((filterFieldId) =>
                filterValues?.find((field) => field.id === filterFieldId)
              )
              .filter((_value) => _value)
              .map((filterValue) => (
                <FilterChip
                  key={String(filterId) + filterValue?.id}
                  label={filterValue?.name}
                  onDelete={() => {
                    const newValues = value.filter(
                      (_value) => _value !== filterValue?.id
                    );
                    updateFilter(
                      {
                        ...filterState,
                        value: newValues,
                      },
                      newValues.length === 0
                    );
                  }}
                />
              ))}
          </>
        );
      }
      return (
        <FilterChip
          label={filterValues?.find((filter) => filter.id === value)?.name}
          onDelete={() =>
            updateFilter({ ...filterState, value: undefined }, true)
          }
        />
      );
    }
    case FilterFieldType.Date:
      return (
        <>
          {minValue && (
            <FilterChip
              label={t("common:searchFilter.fromValue", {
                value: formatDate(minValue),
              })}
              onDelete={() =>
                updateFilter({ ...filterState, minValue: undefined }, !maxValue)
              }
            />
          )}
          {maxValue && (
            <FilterChip
              label={t("common:searchFilter.toValue", {
                value: formatDate(maxValue),
              })}
              onDelete={() =>
                updateFilter({ ...filterState, maxValue: undefined }, !minValue)
              }
            />
          )}
        </>
      );
    case FilterFieldType.Boolean:
      return (
        <FilterChip
          icon={<CheckCircle fontSize="small" />}
          aria-labelledby={labelId}
          onDelete={() => updateFilter(filterState, true)}
        />
      );
    default:
      return (
        <FilterChip
          label={value}
          onDelete={() => updateFilter(filterState, true)}
        />
      );
  }
}
