import { WarningOutlined } from "@mui/icons-material";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { ErrorDialogMessage } from "core/message/models";
import { useTranslation } from "react-i18next";

export interface ErrorMessagesProps {
  includeCount: boolean;
  errorMessages: ErrorDialogMessage[];
}

/**
 * Component used to display a list of error messages
 * @param param0
 * @returns
 */
export function ErrorMessages({
  includeCount,
  errorMessages,
}: ErrorMessagesProps) {
  const { t } = useTranslation(["error"]);

  return (
    <>
      {includeCount && (
        <Typography variant="h5">
          {t("error:errorWithCount", { count: errorMessages.length })}
        </Typography>
      )}
      <List>
        {errorMessages.map(({ details }) => (
          <ListItem key={details}>
            <ListItemIcon>
              <WarningOutlined />
            </ListItemIcon>
            <ListItemText secondary={details} />
          </ListItem>
        ))}
      </List>
    </>
  );
}
