import { Card, styled, Typography } from "common/components";
import { LinkProps } from "common/routing";
import { ComponentType } from "react";

export const StyledTypography = styled(Typography)(({ theme }) => ({
  "& > p, & > span": {
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  "&:hover": {
    textOverflow: "",
  },
  textOverflow: "ellipsis",
  overflow: "hidden",
  marginRight: theme.spacing(2),
  color: theme.palette.common.black,
  maxWidth: "inherit",
  minWidth: "100%",
  whiteSpace: "nowrap",
}));

export type CardGridVariant = "grid" | "row";

export interface StyledCardProps {
  component: ComponentType<LinkProps>;
  to: LinkProps["to"];
  secondaryText?: boolean;
  bottomSecondaryText: boolean;
  gridVariant?: CardGridVariant;
}

function gridTemplateAreas(
  secondaryText?: boolean,
  bottomSecondaryText: boolean = false,
  variant?: CardGridVariant
) {
  if (variant === "row") {
    return `"icon topText mainText secondaryText bottomText bottomSecondaryText action"`;
  }
  const mainRow = secondaryText
    ? "icon mainText secondaryText . action"
    : bottomSecondaryText
    ? "icon mainText . . action"
    : "icon mainText action";
  const topRow =
    secondaryText || bottomSecondaryText
      ? "icon topText . . action"
      : "icon topText action";
  const bottomRow = bottomSecondaryText
    ? "icon bottomText bottomSecondaryText . action"
    : secondaryText
    ? "icon bottomText . . action"
    : "icon bottomText action";
  return `"${topRow}"
            "${mainRow}"
            "${bottomRow}"`;
}

function gridTemplateColumns(
  spacing: string,
  secondaryText?: boolean,
  bottomSecondaryText: boolean = false,
  variant?: CardGridVariant
) {
  if (variant === "row") {
    return secondaryText
      ? bottomSecondaryText
        ? `min-content 2fr 2fr 1fr 1fr 1fr min-content`
        : `min-content 2fr 2fr 1fr 1fr min-content`
      : bottomSecondaryText
      ? `min-content 2fr 2fr 1fr 1fr min-content`
      : `min-content 2fr 2fr 1fr min-content`;
  } else {
    return secondaryText || bottomSecondaryText
      ? `min-content ${spacing} min-content 1fr`
      : `min-content 1fr min-content`;
  }
}

export const StyledCard = styled(Card, {
  shouldForwardProp: (prop: string) =>
    !["secondaryText", "bottomSecondaryText", "gridVariant"].includes(prop),
})<StyledCardProps>(
  ({ theme, secondaryText, bottomSecondaryText, gridVariant }) => ({
    margin: "auto",
    flexGrow: 1,
    padding: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    textOverflow: "ellipsis",
    textDecoration: "none",
    display: "grid",
    columnGap: theme.spacing(1),
    gridTemplateColumns: gridTemplateColumns(
      theme.spacing(25),
      secondaryText,
      bottomSecondaryText,
      gridVariant
    ),
    alignItems: gridVariant === "row" ? "center" : undefined,
    gridTemplateRows: gridVariant === "row" ? undefined : "repeat(3, 1.3em)",
    gridTemplateAreas: gridTemplateAreas(
      secondaryText,
      bottomSecondaryText,
      gridVariant
    ),
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
      cursor: "pointer",
    },
  })
);

export interface StyledGridAreaProps {
  gridArea: "action" | "icon" | "mainText" | "secondaryText";
}

export const StyledGridArea = styled("div", {
  shouldForwardProp: (prop) => prop !== "gridArea",
})<StyledGridAreaProps>(({ theme, gridArea }) => ({
  gridArea,
  display: "grid",
  alignContent: "center",
  color: theme.palette.grey[600],
}));
