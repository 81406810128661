import { CircularProgress, styled } from "common/components";

const LoadingContainer = styled("div")(({ theme }) => ({
  display: "grid",
  placeContent: "center",
  width: "100%",
  minHeight: "6rem",
  height: "100%",
}));

export function LoadingView() {
  return (
    <LoadingContainer>
      <CircularProgress />
    </LoadingContainer>
  );
}
