import { Typography } from "@mui/material";
import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormHelperText,
  FormLabel,
} from "common/components";
import { removeFlowStepPrefix } from "common/utils";
import { ReactElement } from "react";
import {
  FormControlledFieldProps,
  withFormController,
} from "../FormController";

export interface FormCheckBoxProps
  extends Omit<CheckboxProps, "defaultValue">,
    FormControlledFieldProps {}

/**
 * Adds form handling for Checkbox.
 *
 * @param fieldName Static string used for the unique ids of the inner components.
 * @param label The checkbox label.
 * @param control Form control.
 * @param props Any remaining properties.
 */
export const FormCheckBox = withFormController<FormCheckBoxProps>(
  ({ fieldName, label, renderState, ...props }): ReactElement => {
    const {
      field,
      fieldState: { error },
      gridColumn,
      isDisabled,
    } = renderState;
    return (
      <FormControl sx={{ gridColumn }}>
        <FormLabel required={props.required}>
          <Checkbox
            {...props}
            {...field}
            name={fieldName}
            id={fieldName}
            checked={field.value ?? false}
            disabled={isDisabled}
            onChange={(e) => field.onChange(e.target.checked)}
            data-cy={removeFlowStepPrefix(fieldName)}
          />
          <Typography component="span">{label}</Typography>
        </FormLabel>
        <FormHelperText error={!!error}>{error?.message}</FormHelperText>
      </FormControl>
    );
  }
);
