import { MouseEvent, useEffect, useState } from "react";

/** Keeps track of collapse state */
export function useCollapseState<TData>(tableItems: TData[]) {
  const [open, setOpen] = useState<Array<number>>([]);

  useEffect(() => {
    setOpen([]);
  }, [tableItems]);

  const toggleCollapse =
    (index: number) => (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      if (open.includes(index)) {
        setOpen(open.filter((_index) => _index !== index));
      } else {
        setOpen([...open, index]);
      }
    };

  const isOpen = (index: number) => open.includes(index);

  return { isOpen, toggleCollapse };
}
