import { Flow, useCancelActionFlow } from "core/api";
import { useCallback, useState } from "react";
import { FieldValues, UseFormGetValues } from "react-hook-form";
import { CancelFlowDialog } from "./ActionDialog/components";

export interface CancelFlowSettings {
  /** Action id */
  actionId: number;
  /** Flow response */
  data: Flow | undefined;
  /** Current step id */
  currentStepId: string | undefined;
  /** If any fields has been touched in form */
  isFormTouched: boolean;
  /** Get form values */
  getValues: UseFormGetValues<FieldValues>;
  /** Remove flow response from cache */
  removeData(): void;
  /** Close dialog */
  closeDialog?(): void;
}

/** Manages canceling action flow */
export function useCancelFlow({
  actionId,
  closeDialog,
  removeData,
  data,
  isFormTouched,
}: CancelFlowSettings) {
  const [open, setOpen] = useState(false);
  const { mutate, status } = useCancelActionFlow({ actionId });

  const closeFlowDialog = useCallback(() => {
    closeDialog?.();
    removeData();
  }, [closeDialog, removeData]);

  const cancel = useCallback(() => {
    if (data) {
      mutate(data); // Isn't this enough? //JSO
      //mutate(insertStepValues(data, getValues(), currentStepId)); // Why do this on cancel? //JSO
    }
    closeFlowDialog();
  }, [mutate, data, closeFlowDialog]);

  const onDialogClose = useCallback(() => {
    if (isFormTouched) {
      setOpen(true);
    } else {
      cancel();
    }
  }, [setOpen, cancel, isFormTouched]);

  const CancelConfirmDialog = () => {
    return (
      <CancelFlowDialog
        open={open}
        actionName={data?.name ?? ""}
        cancelIsLoading={status === "loading"}
        closeDialog={() => setOpen(false)}
        onCancel={cancel}
      />
    );
  };

  return { onDialogClose, closeFlowDialog, CancelConfirmDialog };
}
