import { Box, Button } from "common/components";
import { FilterValueState, FilterValueStates, SearchFilter } from "core/api";
import { useTranslation } from "i18n";
import { FilterChip, FilterGroup, FilterGroupContent } from "./FilterGroup";

export interface AppliedFiltersProps {
  /** Search value */
  searchValue: string | undefined;
  /** Available filters */
  filterFields: SearchFilter[];
  /** Active filter values state */
  activeFilters: FilterValueStates | undefined;
  /** If active search filters should be displayed */
  showActiveSearchFilters?: boolean;
  /** Function to trigger search with filter values */
  triggerSearch(
    filterState?: (state: FilterValueStates) => FilterValueStates
  ): void;
}

/** Displays applied filters in current search query */
export function AppliedFilters({
  searchValue,
  filterFields,
  activeFilters,
  showActiveSearchFilters,
  triggerSearch,
}: AppliedFiltersProps) {
  const { t } = useTranslation(["common"]);

  if (!showActiveSearchFilters || (!searchValue && !activeFilters)) {
    return null;
  }

  return (
    <Box display="flex" flexWrap="wrap" columnGap={(theme) => theme.spacing(2)}>
      {searchValue && (
        <FilterGroup label={t("common:searchFilter.searchText")}>
          <FilterChip label={searchValue} />
        </FilterGroup>
      )}
      {activeFilters && (
        <>
          {Object.entries(activeFilters).map(
            ([filterId, filterState]: [string, FilterValueState]) => {
              const currentFilter = filterFields?.find(
                (filter) => filter.filterId === parseInt(filterId)
              );
              const labelId = `filter-group-${filterState.filterId}`;
              return (
                <FilterGroup
                  key={filterId}
                  labelId={labelId}
                  label={currentFilter?.displayName}
                >
                  <FilterGroupContent
                    labelId={labelId}
                    filterState={filterState}
                    triggerSearch={triggerSearch}
                  />
                </FilterGroup>
              );
            }
          )}
          <Button
            variant="text"
            size="small"
            onClick={() => triggerSearch(() => ({}))}
            sx={{ alignSelf: "flex-end" }}
          >
            {t("common:buttons.clear")}
          </Button>
        </>
      )}
    </Box>
  );
}
