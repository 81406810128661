import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getUserLocale } from "core/api";
import dayjs from "dayjs";
import { requireLanguage } from "i18n/init";
import { AvailableLocale } from "i18n/models";
/**
 * Displaying date formatted for language settings
 * @param date The date or timestamp that should be formatted
 * @param format The format to according to https://day.js.org/docs/en/display/format#list-of-localized-formats
 *        default value is "L" => 	M/D/YYYY	08/16/2018
 * @param locale Override user locale
 * @returns A locale formatted date
 */
export function formatDate(
  date: string | Date | undefined,
  format = "L",
  locale?: string
): string {
  if (isValidDate(date)) {
    if (locale) {
      requireLanguage(locale as AvailableLocale);
    }

    return dayjs(date).locale(getDateLocale(locale)).format(format);
  }
  return "";
}

/**
 * Displaying timestamp formatted for language settings
 *
 * @param timestamp timestamp that should be formatted
 * @param includeSeconds default false, if true format used is "L LTS" otherwise "L LT"
 * @param locale Override user locale
 *
 * @returns A locale formatted date
 */
export function formatTimestamp(
  timestamp: string | Date | undefined,
  includeSeconds = false,
  locale?: string
): string {
  return formatDate(timestamp, includeSeconds ? "L LTS" : "L LT", locale);
}

/**
 * Checks if a date is a valid date
 * @param date The date or timestamp that should be checked
 * @param format Date format
 * @param strict If strict validation should be used
 * @returns If date is a valid date
 */
export function isValidDate(
  date: string | Date | undefined,
  format?: string,
  strict = false
): boolean {
  const locale = getDateLocale();
  return !!date && dayjs(date, format, locale, strict).isValid();
}

export function timeFromNow(date: string | Date | undefined): string {
  if (isValidDate(date)) {
    return dayjs(date).fromNow();
  }
  return "";
}

/**
 * Adds days in relation to target date and formats a new date
 * @param days Day count
 * @param date Target date. Defaults to today.
 * @returns New date
 */
export function addDays(
  days: number,
  date?: string | Date | undefined
): string {
  return dayjs(date).add(days, "days").toISOString();
}

/**
 * Gets the earliest date
 * @param dates Dates to compare
 * @returns The earliest date
 */
export function minDate(
  ...dates: Array<string | Date | dayjs.Dayjs>
): dayjs.Dayjs {
  return dayjs.min(...dates.map((date) => dayjs(date)));
}

/**
 * Gets the latest date
 * @param dates Dates to compare
 * @returns The latest date
 */
export function maxDate(
  ...dates: Array<string | Date | dayjs.Dayjs>
): dayjs.Dayjs {
  return dayjs.max(...dates.map((date) => dayjs(date)));
}

/**
 * Compares 2 strings that represent dates.
 * This function can be used as sorting function in Array.sort().
 *
 * @param a A string that represents a date
 * @param b Another string that represents a date
 * @param ascending Set to **true** for ascending order, or **false** for descending order.
 *
 * @returns 0 if a represents exactly the same date as b,
 *   else if ascending is true:
 *     - returns a number < 0 if a is before b
 *     - returns a number > 0 if b is before a
 *   else if ascending is false:
 *     - returns a number < 0 if b is before a
 *     - returns a number > 0 if a is before b
 *
 */
export function compareAsDate(
  a: string,
  b: string,
  ascending: boolean
): number {
  const aDate = new Date(a);
  const bDate = new Date(b);
  return ascending
    ? aDate.getTime() - bDate.getTime()
    : bDate.getTime() - aDate.getTime();
}

/**
 * Custom MUI picker date adapter. Extends the library provided adapter
 * and uses our locale settings instead.
 */
export class DateAdapter extends AdapterDayjs {
  constructor(...params: ConstructorParameters<typeof AdapterDayjs>) {
    const locale = getDateLocale();
    requireLanguage(locale as AvailableLocale);
    super({ ...params[0], locale });
  }
}

/**
 * Our locales are provided in BCP 47 (e.g. en-GB) but Dayjs expects
 * lowercase values (e.g. en-gb), which means this function should be
 * used when providing Dayjs with a locale.
 * @returns Lowercase user date locale format
 */
function getDateLocale(locale?: string): string {
  const _locale =
    locale?.toLowerCase() ?? getUserLocale("dateLocale").toLowerCase();
  // Dayjs uses 'en' for 'en-us'
  return _locale === "en-us" ? "en" : _locale;
}

/**
 * Formats the input date into an ISO date format "YYYY-MM-DD".
 *
 * @remarks Assumes date is in local time zone, not UTC.
 *
 * @param date the input date
 *
 * @returns The input date, formatted into the YYYY-MM-DD format.
 *
 */
export function formatToISODate(date: Date): string {
  const formatter = new Intl.NumberFormat("en-IN", { minimumIntegerDigits: 2 });
  // N.B. getMonth() returns a zero based month index!
  return `${date.getFullYear()}-${formatter.format(
    date.getMonth() + 1
  )}-${formatter.format(date.getDate())}`;
}
