import { APIError } from "core/api";
import { AuthenticationState } from "core/auth/models";
import {
  getCurrentCustomerGroupId,
  setCurrentCustomerGroupId,
} from "core/auth/util";

export type AuthenticationAction =
  | {
      type: "AUTHENTICATED";
      defaultCustomerGroupId?: number;
    }
  | { type: "ERROR"; error: APIError }
  | { type: "LOGGED_OUT" }
  | { type: "LOADED" }
  | { type: "SELECT_CUSTOMER_GROUP"; customerGroup: number };

export const initialState: AuthenticationState = {
  loaded: false,
  isAuthenticated: false,
  hasLoggedOut: false,
  currentCustomerGroup: getCurrentCustomerGroupId(),
};

export function authenticationReducer(
  state: AuthenticationState,
  action: AuthenticationAction
): AuthenticationState {
  switch (action.type) {
    case "AUTHENTICATED":
      if (action.defaultCustomerGroupId) {
        setCurrentCustomerGroupId(action.defaultCustomerGroupId);
      }
      return {
        ...state,
        loaded: true,
        isAuthenticated: true,
        hasLoggedOut: false,
        currentCustomerGroup: getCurrentCustomerGroupId(),
        error: undefined,
      };
    case "ERROR":
      return {
        ...state,
        loaded: true,
        isAuthenticated: false,
        error: action.error,
      };
    case "LOADED":
      return {
        ...state,
        loaded: true,
      };
    case "LOGGED_OUT":
      return {
        ...state,
        loaded: true,
        isAuthenticated: false,
        hasLoggedOut: true,
      };
    case "SELECT_CUSTOMER_GROUP":
      setCurrentCustomerGroupId(action.customerGroup);
      return {
        ...state,
        currentCustomerGroup: action.customerGroup,
      };
    default:
      return state;
  }
}
