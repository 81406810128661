export default {
  _404: {
    title: "Page not found",
    urlNotFound: "URL not found",
    notFoundOrNotAvailable:
      "The resource you requested is not found or is no longer available",
  },
  _500: {
    fatalError: {
      title: "An unexpected fatal server error has occurred",
      title_plural: "Several unexpected fatal server errors have occurred",
    },
  },
  backToApplications: "Go back to my applications",
  error: "error",
  error_plural: "errors",
  errorWithCount: "{{count}} error",
  errorWithCount_plural: "{{count}} errors",
  preventNavigationDialog: {
    title: "Unsaved changes",
    body: "You have unsaved changes. Are you sure you want to leave this page and discard them?",
    stayOnPage: "Stay on page",
    leavePage: "Leave page",
  },
  route: {
    noAccess:
      "You have no access to requested URL {{url}}. Your user role prevents that.",
  },
  fetchError:
    "Could not load widget due to server error (Error Code {{status}})",
  fields: {
    valueAmountError:
      "Error: Field {{fieldName}} expected {{expectedAmount}} values, received only {{actualAmount}}",
  },
  auth: {
    logoutFail:
      "Logout request failed. Cleared local authentication credentials.",
  },
  emptyQuery: "Error: Server returned no value",
  clientError: "Web client error: ",
  validationError: "Validation error",
  screenLoadingError:
    "Error occurred while loading page. This could be caused by an invalid or expired URL. Server message; {{serverMessage}} ",
  screenLoadingError_nomessage:
    "Error occurred while loading page. This could be caused by an invalid or expired URL.",
  failedToLoadWidget: "Failed to load widget",
} as const;
