/**
 * Customer status code class.
 * <p>
 *  Corresponds to the CustomerStatus enum in Billiant APIs.
 */
export enum CustomerStatus {
  /**
   * Registered, the customer and optional product(s) can be registered but
   * not yet active. For instance we wait for a credit check or a service
   * availability/quality check. At least the product/subscription orders must be put on
   * hold until customer is active
   */
  REGISTERED = "REGISTERED",

  /**
   * Active, the customer has passed the check(s). Products shall work
   * and orders on hold shall be released.
   */
  ACTIVE = "ACTIVE",

  /**
   * Canceled, the customer did not pass the checks – customer could not be
   * active. All registered products will also be canceled.
   */
  CANCELLED = "CANCELLED",

  /**
   * Closing, the customer is about to be closed, we don’t allow any new
   * products to be created – existing subscriptions remain (but we expect
   * them to be closed in a near future). Invoice requests are still scheduled.
   */
  CLOSING = "CLOSING",

  /**
   * Closed, the customer is not a customer anymore – record just kept hidden.
   * No normal invoice requests will be created.
   */
  CLOSED = "CLOSED",

  /**
   * Pending, the customer has been saved but is still being configured.
   */
  PENDING = "PENDING",
}
