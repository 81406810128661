export default {
  cancelDialog: {
    title: 'Aktion "{{action}}" verwerfen?',
    body: "Die eingegebenen Daten werden verloren.",
    cancelButton: "Änderungen verwerfen",
    closeButton: "Abbrechen",
  },

  transferProductStep: {
    copyProduct: "Produkt kopieren",
    transferBinds: "Vertragliche Bindung übertragen",
    deactivateStatusReasonId: "Grund der Deaktivierung",
    activateStatusReasonId: "Grund der Aktivierung",
  },

  findInventoryStep: {
    searchText: "Suchbegriff",
    status: "Status",
    location: "Standort",
    article: "Artikel",
    maxInvItems: "# Ergebnisse begrenzen auf",
  },

  findNetworkElementStep: {
    searchText: "Suchbegriff",
    status: "Status",
    networkElementType: "Netzelementtyp",
    maxTableItems: "#Elemente begrenzen auf",
  },
} as const;
