import { TextField, TextFieldProps } from "common/components";
import { ReactElement } from "react";
import { combineSx } from "styles/theme";
import { removeFlowStepPrefix } from "../../../../utils";
import {
  FormControlledFieldProps,
  withFormController,
} from "../FormController";

export type FormTextFieldProps = Omit<TextFieldProps, "variant"> &
  FormControlledFieldProps & {
    renderValue?: (value: any) => any;
  };

/**
 * Adds form handling for TextField.
 *
 * @param fieldName Static string used for the unique ids of the inner components.
 * @param control Form control.
 * @param mandatoryLabel Add own label for mandatory fields (default value is field name + "(mandatory)").
 * @param validationRules Optional validation rule object, se below for example.
*          If triggered the helper text is replaced with active validation rule message.
 * @param props Any remaining properties.
 * 
 * example of a (password) validaton rule:
 * 
 * {
      required: t(translation object text)
      minLength: {
        value: 3,
        message: "Least 3 characters",
    }
 */
export const FormTextField = withFormController<FormTextFieldProps>(
  ({
    fieldName,
    helperText,
    renderValue,
    renderState,
    ...props
  }): ReactElement => {
    const {
      field: { value, ...field },
      fieldState: { error },
      isDisabled,
      gridColumn,
    } = renderState;
    return (
      <TextField
        value={renderValue?.(value) ?? value ?? ""}
        error={!!error}
        data-cy={removeFlowStepPrefix(fieldName)}
        InputLabelProps={{ required: props.required }}
        {...props}
        {...field}
        disabled={isDisabled}
        /*
          put the "helperText" attribute after props so error messages can be 
          displayed instead of helper text if needed
        */
        helperText={error ? error.message : helperText}
        sx={combineSx({ gridColumn }, props.sx)}
      />
    );
  }
);
