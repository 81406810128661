/**
 * Enum for keys in local and session storage.
 */
export enum StorageKey {
  /**
   * Key for storing Printer Queue application settings.
   */
  PRINTER_QUEUE = "PrinterQueueApp_Settings",
  /**
   * Key for storing the user's acceptance of cookies.
   */
  COOKIES_ACCEPTED = "Cookies_Accepted",
  /**
   * Key for storing the current customer group.
   */
  CUSTOMER_GROUP = "currentCustomerGroup",
  /**
   * Key for storing the last selected customer group.
   */
  LAST_CUSTOMER_GROUP = "lastCustomerGroup",
  /**
   * Key for storing the ID of the current application configuration.
   */
  CURRENT_APPLICATION_CONFIG_ID = "currentApplicationConfigId",
  /**
   * Key for storing the default ID provider.
   */
  DEFAULT_ID_PROVIDER = "Default_ID_Provider",
  /**
   * Key for storing the predefined ID provider.
   */
  PREDEFINED_ID_PROVIDER = "Predefined_ID_Provider",
  /**
   * Key for storing the authorization token.
   */
  AUTH_TOKEN = "authorization",
  /**
   * Key for storing the refresh token.
   */
  REFRESH_TOKEN = "refreshToken",
  /**
   * Key for storing table option settings. Used in SORTING_SETTINGS.
   */
  TABLE_OPTION_SETTINGS = "tableOptionSettings",
  /**
   * Key for storing list settings. Used in SORTING_SETTINGS.
   */
  LIST_SETTINGS = "listSettings",
  /**
   * Key for storing sorting-related settings like page size and sort order.
   */
  SORTING_SETTINGS = "sortingSettings",
  /**
   * Key for storing action dialog settings.
   */
  ACTION_DIALOG = "flowSettings",
  /**
   * Key for storing autocomplete settings for search.
   */
  AUTOCOMPLETE = "autocomplete",
  /**
   * Key for storing the client ID used for login by tests.
   */
  CLIENT_ID = "clientId", // For login by tests - they can set a client id
  /**
   * Key for controlling whether clients should expire on login (used for testing).
   */
  EXPIRE_CLIENTS = "expireClients", // for login by tests, if true then each login expire other logins with the same clientId as the login.
  /**
   * Key for storing the state of layouts (collapsed or not).
   */
  LAYOUTS_COLLAPSED = "layoutsCollapsed",
  /**
   * Key for storing the redirect URL.
   */
  REDIRECT_URL_KEY = "redirectURL",
  /**
   * Key for storing the URL to redirect to after logging out.
   */
  LOGGED_OUT_URL_KEY = "loggedOutURL",
  /**
   * Key for storing the selected language for Selfcare.
   */
  SELFCARE_LANGUAGE = "selfcareLanguage",
  /** Key for the configuration mode. */
  CONFIG_MODE = "configMode",
  /**
   * Key for storing the state of sidebar (collapsed or not).
   */
  SIDEBAR_COLLAPSED = "sidebarCollapsed",
}

/** Items to clear on logout */
const clearList: StorageKey[] = [
  StorageKey.CUSTOMER_GROUP,
  StorageKey.LAST_CUSTOMER_GROUP,
  StorageKey.CURRENT_APPLICATION_CONFIG_ID,
  StorageKey.AUTH_TOKEN,
  StorageKey.REFRESH_TOKEN,
  StorageKey.TABLE_OPTION_SETTINGS,
  StorageKey.LIST_SETTINGS,
  StorageKey.ACTION_DIALOG,
  StorageKey.CLIENT_ID,
  StorageKey.EXPIRE_CLIENTS,
  StorageKey.REDIRECT_URL_KEY,
  StorageKey.LOGGED_OUT_URL_KEY,
  StorageKey.AUTOCOMPLETE,
];

/** Clears all listed items */
export function clearLS(): void {
  for (const key of clearList) {
    localStorage.removeItem(key);
    sessionStorage.removeItem(key);
  }
}
